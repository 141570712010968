<input #thisInput
  [id]="getProp('displayName')"
  [class]="getProp('displayName')"
  [type]="getProp('type')"
  [value]="getProp('cache-data') === '1' ? projectDataCacheService.getValue(getProp('displayName')) : getProp('value')"
  (input)="getProp('cache-data') === '1' ? projectDataCacheService.setValue(getProp('displayName'), thisInput.value) : setPropAsHelper('value', thisInput.value)"
  [checked]="getProp('value')"
  (change)="setPropAsHelper('value', thisInput.value)"
  [step]="getProp('step')"
  [style.color]="getProp('normal-color')"
  [style.backgroundColor]="getProp('normal-background-color')"
  (mouseover)="thisInput.style.color = getProp('hover-color'); thisInput.style.backgroundColor = getProp('hover-background-color')"
  (mouseout)="thisInput.style.color = getProp('normal-color'); thisInput.style.backgroundColor = getProp('normal-background-color')"
/>
