<mat-form-field [appearance]="getAppearance()">
  <mat-label>{{getProp('Label')}}</mat-label>
  <mat-select [disabled]="getProp('disabled')=='1'" #thisSelect>

    <mat-select-trigger>
      <mat-icon *ngIf="getProp('Icon'+thisSelect.value) != '' && getProp('iconRight')!='1'">{{getProp('Icon'+thisSelect.value)}}</mat-icon>
      {{getProp('Label'+thisSelect.value)}}
      <mat-icon *ngIf="getProp('Icon'+thisSelect.value) != '' && getProp('iconRight')=='1'">{{getProp('Icon'+thisSelect.value)}}</mat-icon>
    </mat-select-trigger>

    <mat-option *ngFor="let i of getAmount()" [value]="i">
      <mat-icon *ngIf="getProp('Icon'+i) != '' && getProp('iconRight')!='1'">{{getProp('Icon'+i)}}</mat-icon>
      {{getProp("Label"+i)}}
      <mat-icon *ngIf="getProp('Icon'+i) != '' && getProp('iconRight')=='1'">{{getProp('Icon'+i)}}</mat-icon>
    </mat-option>

  </mat-select>
</mat-form-field>
