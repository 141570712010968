import { Component, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { CardSettingsModel, SwimlaneSettingsModel } from '@syncfusion/ej2-angular-kanban';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { KanbanCardDroppedTrigger } from '../../automations';
import { ApiCallGenerator } from '../../code-generation/generators/snippets/api-call.generator';
import { ApiCallDialog } from 'src/app/Dialogs/Dialog API Call/apiDialog';

@Component({
  selector: 'sf-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss'],
})
export class KanbanComponent extends LayoutComponent {
  @ViewChild('apiCallDialog', { static: true })
  public apiCallDialog: ApiCallDialog;

  public dataSource: any[];
  public keyField: string;

  public cardSettings: CardSettingsModel;
  public swimlaneSettings: SwimlaneSettingsModel;

  public columns: KanbanColumn[] = [];

  public transitionColumnsForColumns: string[][] = [];
  public allowDragForColumns: boolean[] = [];
  public allowDropForColumns: boolean[] = [];

  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'sf-kanban';

    this.elementService.setSelected(this);
  }

  public ngAfterViewInit(): void {
    this.ensureProp({ key: 'event', value: '$event', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.ensureProp({ key: 'dataSource', value: `[{ "id": 1, "title": "A", "status": "Open" }, { "id": 2, "title": "B", "status": "Close" }]`, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'keyField', value: 'status', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'keyFields', value: 'Open, Close', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'headerTexts', value: 'Backlog, Done', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'swimlaneSettingsKeyField', value: '', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'headerField', value: 'id', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'headerTextField', value: '', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'contentField', value: 'title', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'swimlaneSettingsAllowDragAndDrop', value: 'true', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.dataSource = JSON.parse(this.getProp('dataSource'));
    this.keyField = this.getProp('keyField');

    this.cardSettings = { contentField: this.getProp('contentField'), headerField: this.getProp('headerField') };
    this.swimlaneSettings = { keyField: this.getProp('swimlaneSettingsKeyField'), allowDragAndDrop: this.getProp('swimlaneSettingsAllowDragAndDrop') === 'true' ? true : false };

    const keyFields = this.getProp('keyFields').trim().replaceAll(' ', '').split(',');
    const headerTexts = this.getProp('headerTexts').trim().replaceAll(' ', '').split(',');
    this.columns = [];
    for (let index = 0; index < keyFields.length; index++) {
      this.columns.push({ headerText: headerTexts[index], keyField: keyFields[index] });
      this.getTransitionColumns(index);
      this.getSwimlaneSettingsAllowDrag(index);
      this.getSwimlaneSettingsAllowDrop(index);
    }

    this.elementService.changeDetector.detectChanges();

    // Fetch data from API
    this.apiCallDialog.apiCalls.forEach(apiCall => {
      apiCall.fetchApi(() => {
        this.setColumnAndDataArray(this.apiCallDialog.columnData, this.apiCallDialog.dataData);
      });
    });
  }

  public setDataSource(value: string): void {
    this.setProp({ key: 'dataSource', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.dataSource = JSON.parse(value);
  }

  public setKeyField(value: string): void {
    this.setProp({ key: 'keyField', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.keyField = value;
  }

  public setKeyFields(value: string): void {
    this.setProp({ key: 'keyFields', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    const headerTexts = this.getProp('headerTexts').trim().replaceAll(' ', '').split(',');
    const keyFields = this.getProp('keyFields').trim().replaceAll(' ', '').split(',');
    this.columns = [];
    for (let index = 0; index < keyFields.length; index++) {
      this.columns.push({ headerText: headerTexts[index], keyField: keyFields[index] });
    }
  }

  public setHeaderTexts(value: string): void {
    this.setProp({ key: 'headerTexts', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    const headerTexts = this.getProp('headerTexts').trim().replaceAll(' ', '').split(',');
    const keyFields = this.getProp('keyFields').trim().replaceAll(' ', '').split(',');
    this.columns = [];
    for (let index = 0; index < keyFields.length; index++) {
      this.columns.push({ headerText: headerTexts[index], keyField: keyFields[index] });
    }
  }

  public setSwimlaneSettingsKeyField(value: string): void {
    this.setProp({ key: 'swimlaneSettingsKeyField', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.swimlaneSettings = { keyField: value };
  }

  public setHeaderField(value: string): void {
    this.setProp({ key: 'headerField', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.cardSettings = { contentField: this.getProp('contentField'), headerField: this.getProp('headerField') };
  }

  public setHeaderTextField(value: string): void {
    this.setProp({ key: 'headerTextField', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
  }

  public setContentField(value: string): void {
    this.setProp({ key: 'contentField', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.cardSettings = { contentField: this.getProp('contentField'), headerField: this.getProp('headerField') };
  }

  public setSwimlaneSettingsAllowDragAndDrop(value: boolean): void {
    this.setProp({ key: 'swimlaneSettingsAllowDragAndDrop', value: ''+value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.swimlaneSettings = { ...this.swimlaneSettings, allowDragAndDrop: value };
  }

  public setTransitionColumns(index: number, key: string, value: string): void {
    this.setProp({ key: key, value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.getTransitionColumns(index);
  }

  public getTransitionColumns(index: number): void {
    const values = this.getProp('transitionColumns' + index).replaceAll(' ', '').split(',');
    this.transitionColumnsForColumns[index] = values;
  }

  public setSwimlaneSettingsAllowDrag(index: number, key: string, value: boolean): void {
    this.setProp({ key: key, value: ''+value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.allowDragForColumns[index] = value;
  }

  public getSwimlaneSettingsAllowDrag(index: number): void {
    const value = this.getProp('swimlaneSettingsAllowDrag' + index) ?? 'true';
    this.allowDragForColumns[index] = value === 'true' ? true : false;
  }

  public setSwimlaneSettingsAllowDrop(index: number, key: string, value: boolean): void {
    this.setProp({ key: key, value: ''+value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.allowDropForColumns[index] = value;
  }

  public getSwimlaneSettingsAllowDrop(index: number): void {
    const value = this.getProp('swimlaneSettingsAllowDrop' + index) ?? 'true';
    this.allowDropForColumns[index] = value === 'true' ? true : false;
  }

  public setColumnAndDataArray(columnsArrayFromApiCall: any[], dataArrayFromApiCall: any[]) {
    if (!dataArrayFromApiCall[0]) { return; }

    this.dataSource = dataArrayFromApiCall;
    this.setProp({ key: 'dataSource', value: JSON.stringify(this.dataSource), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    Object.keys(dataArrayFromApiCall[0]).forEach((column, i) => {
      this.columns[i] = { headerText: column, keyField: column }
    });
  }

  public onKanbanDragStop(event: any) {
    const match = this.dataSource.find(d => d[this.cardSettings.headerField!] === event.data[0][this.cardSettings.headerField!]);
    if (match) {
      match[this.keyField] = event.data[0][this.keyField];
      this.setProp({ key: 'dataSource', value: JSON.stringify(this.dataSource), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

      this.triggers.forEach((triggerId: string) => {
        const trigger = this.findTrigger(triggerId);
        if (trigger) {
          const kanbanCardDroppedTrigger = trigger as KanbanCardDroppedTrigger;
          kanbanCardDroppedTrigger.input = event.data;
          kanbanCardDroppedTrigger.triggerAction();
        }
      });
    }
  }

  public getHtmlTemplate(): string {
    const template =
`<ejs-kanban
  id="getProp('displayName')"
  [keyField]="keyField_getProp('displayName')"
  [dataSource]="dataSource_getProp('displayName')"
  [cardSettings]="cardSettings_getProp('displayName')"
  [swimlaneSettings]="swimlaneSettings_getProp('displayName')"
  (dragStop)="onKanbanDragStop_getProp('displayName')($event)"
>
  <e-columns>

    <e-column *ngFor="let column of columns_getProp('displayName'); let colIndex = index"
      [headerText]="column.headerText"
      [keyField]="column.keyField"
      [transitionColumns]="transitionColumnsForColumns_getProp('displayName')[colIndex]"
      [allowDrag]="allowDragForColumns_getProp('displayName')[colIndex]"
      [allowDrop]="allowDropForColumns_getProp('displayName')[colIndex]"
    ></e-column>

    <ng-template #cardSettingsTemplate let-data>
      <div class="custom-card">
        <div>{{{}}{{{}}{{data['getProp('headerTextField')']}}{{}}}{{}}}</div>
        <div>{{{}}{{{}}{{data['getProp('contentField')']}}{{}}}{{}}}</div>
      </div>
    </ng-template>

  </e-columns>
</ejs-kanban>
`;
    return template;
  }

  public getCssTemplate(): string {

		const stylesStrings = this.getStyleAttributesForInner().split(';').join(';\n\t')

    const styles =
`#${this.getProp('displayName')}_dropzone {

	${stylesStrings.substring(0, stylesStrings.length-2)}}
}

.custom-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.custom-card > div:first-child {
  font-size: 14px;
  color: #333;
}

.custom-card > div:nth-child(2) {
  font-size: 14px;
  color: #666;
}
`;
    return styles;
  }

  public getCodeTemplate(): string {
    const apiCallGenerator = new ApiCallGenerator(this.dataService);
    apiCallGenerator.generate(this.dataService.projectContext.dataTable.get(this.id)!);

    let apiCall = +this.getProp("apiCalls") <= 0 ? false : true
    if(apiCall) this.elementService.addAboveCodeForApiCall()

    const fetchCall = `this.fetchCall_${this.getProp('displayName')}().then((result) => this.setColumnAndDataArray_${this.getProp('displayName')}(this.columns_${this.getProp('displayName')}, result?.data ?? []));`;
    const ngOnInitCode =
`${apiCall ? fetchCall : ''}`;
    if (!this.elementService.ngOnInitCode.includes(ngOnInitCode)) {
      this.elementService.ngOnInitCode += '\n    ' + ngOnInitCode;
    }

    const importCode =
`import { CardSettingsModel, SwimlaneSettingsModel } from '@syncfusion/ej2-angular-kanban';
import { KanbanCardDroppedTrigger } from './automations.service';`;
    if (!this.elementService.aboveCode.includes(importCode)) {
      this.elementService.aboveCode += importCode;
    }

    const code =
`  public keyField_getProp('displayName'): string = '${this.getProp('keyField')}';
  public dataSource_getProp('displayName'): any[] = [];
  public cardSettings_getProp('displayName'): CardSettingsModel = { contentField: '${this.getProp('contentField')}', headerField: '${this.getProp('headerField')}' };
  public swimlaneSettings_getProp('displayName'): SwimlaneSettingsModel = { keyField: '${this.getProp('swimlaneSettingsKeyField')}', allowDragAndDrop: ${this.getProp('swimlaneSettingsAllowDragAndDrop')} };

  public columns_getProp('displayName'): any[] = ${JSON.stringify(this.columns)};
  public transitionColumnsForColumns_getProp('displayName'): string[][] = ${JSON.stringify(this.transitionColumnsForColumns)};
  public allowDragForColumns_getProp('displayName'): boolean[] = ${JSON.stringify(this.allowDragForColumns)};
  public allowDropForColumns_getProp('displayName'): boolean[] = ${JSON.stringify(this.allowDropForColumns)};

  public triggers_getProp('displayName'): string[] = [];

  ${apiCall ? apiCallGenerator.snippet : ''}

  public setColumnAndDataArray_getProp('displayName')(columnsArrayFromApiCall: any[], dataArrayFromApiCall: any[]) {
    if (!dataArrayFromApiCall[0]) { return; }

    let data: any = [];
    for (const d of dataArrayFromApiCall) {
      const newData: any = {};

      for (const key in d) {
        const newKey = key.startsWith('properties.') ? key.split('.')[1] : key[0].toUpperCase() + key.slice(1);
        newData[newKey] = d[key];
      }

      data.push(newData);
    }

    this.dataSource_getProp('displayName') = data;

    Object.keys(data[0]).forEach((column, i) => {
      this.columns_getProp('displayName')[i] = { headerText: column, keyField: column }
    });
  }

  public onKanbanDragStop_getProp('displayName')(event: any) {
    const match: any = this.dataSource_getProp('displayName').find((d: any) => d[this.cardSettings_getProp('displayName').headerField!] === event.data[0][this.cardSettings_getProp('displayName').headerField!]);
    if (match) {
      match[this.keyField_getProp('displayName')] = event.data[0][this.keyField_getProp('displayName')];

      this.triggers_getProp('displayName').forEach((triggerId: string) => {
        const trigger = this.automationsService.automations.find(automation => automation.trigger?.id === triggerId)?.trigger;
        if (trigger) {
          const kanbanCardDroppedTrigger = trigger as KanbanCardDroppedTrigger;
          kanbanCardDroppedTrigger.input = event.data;
          kanbanCardDroppedTrigger.triggerAction();
        }
      });
    }
  }

  public addTrigger_getProp('displayName')(kanbanCardDroppedTrigger: KanbanCardDroppedTrigger): void {
    if (!this.triggers_getProp('displayName').includes(kanbanCardDroppedTrigger.id)) {
      this.triggers_getProp('displayName').push(kanbanCardDroppedTrigger.id);
    }
  }

  public removeTrigger_getProp('displayName')(kanbanCardDroppedTrigger: KanbanCardDroppedTrigger): void {
    const index = this.triggers_getProp('displayName').indexOf(kanbanCardDroppedTrigger.id);
    if (index > -1) {
      this.triggers_getProp('displayName').splice(index, 1);
    }
  }
`;
    return code;
  }
}

export interface KanbanColumn {
  headerText: string;
  keyField: string;
}
