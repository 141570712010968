import { Automation } from './automation';

export abstract class Action {
  public id: string;
  public name: string;

  public previousAction?: Action;
  public nextAction?: Action;

  public automation?: Automation;

  public input: any;
  public output: any;

  public executeAction(test?: boolean): void {
    if (this.previousAction && test) {
      this.previousAction.executeAction(true);
      this.input = this.previousAction.output;
    }

    console.log('START ' + this.name);
    this.action();
    console.log('FINISHED ' + this.name + ' WITH ' + this.output);

    if (!this.output) {
      this.output = this.input;
    }

    if (this.nextAction && !test) {
      this.nextAction.input = this.output;
      this.nextAction.executeAction();
    }
  }

  public abstract action(): void;

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name
    }
  }
}
