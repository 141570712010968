import { SaveLayout } from './entities/save-layout.entity';
import { SaveLayoutsService } from './save-layouts.service';

export class SaveLayoutIterator {
  private readonly service: SaveLayoutsService;
  private readonly root: SaveLayout;

  private children: SaveLayout[];
  private counter: number = 0;

  public constructor(service: SaveLayoutsService, root: SaveLayout) {
    this.service = service;
    this.root = root;
  }

  public [Symbol.iterator]() {
    return {
      next: () => {
        if (!this.children) {
          this.children = this.service.cacheService.saveLayouts.filter(sl => sl.parent?.id === this.root.id);
          this.children.sort((a, b) => a.orderNumber - b.orderNumber);
        }
        const child = this.children[this.counter++];
        return { done: this.counter > this.children.length, value: child };
      }
    };
  }

  public forEach(callback: (value: SaveLayout, index: number, array: SaveLayout[]) => void): void {
    const children = this.service.cacheService.saveLayouts.filter(sl => sl.parent?.id === this.root.id);
    children.sort((a, b) => a.orderNumber - b.orderNumber);
    for (let index = 0; index < children.length; index++) {
      const child = children[index];
      if (child) {
        callback(child, index, children);
      }
    }
  }
}
