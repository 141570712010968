import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { elementCodeDialog } from '../../Dialogs/Dialog Element Code/elementCodeDialog';

@Component({
	selector: 'app-matDatepicker',
	templateUrl: './matDatepicker.component.html',
})
export class MatDatepickerComponent extends LayoutComponent {
	public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService)
    this.componentType = 'MatDatepickerComponent'
  }

	setInitialProps(){

		console.log('setInitialProps() of matDatepicker.component.ts')
		if(this.getProp("Label") == "")							this.setPropAsHelper("Label", "Neues Eingabefeld")
		if(this.getProp("Platzhalter") == "")				this.setPropAsHelper("Platzhalter", "Neuer Platzhalter")
		if(this.getProp("Typ") == "")								this.setPropAsHelper("Typ", "text")
		if(this.getProp("Erscheinungsbild") == "")	this.setPropAsHelper("Erscheinungsbild", "fill")
		if(this.getProp("color") == "")							this.setPropAsHelper("color", "primary")

		this.elementService.changeDetector.detectChanges()
	}

	getAppearance() {
		let appearance: MatFormFieldAppearance = "fill"

		switch (this.getProp("Erscheinungsbild")) {
			case "fill":
				appearance = "fill"
				break
			case "outline":
				appearance = "outline"
				break
		}

		return appearance
	}

	public getColor(): ThemePalette {
		let color: ThemePalette = 'primary'

		switch (this.getProp('color')) {
			case 'primary':
				color = 'primary'
				break
			case 'accent':
				color = 'accent'
				break
			case 'warn':
				color = 'warn'
				break
			default:
				color = undefined;
				break
		}

		return color
	}

/*
████████╗███████╗███╗   ███╗██████╗ ██╗      █████╗ ████████╗███████╗    ███████╗████████╗██████╗ ██╗███╗   ██╗ ██████╗
╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝
	 ██║   █████╗  ██╔████╔██║██████╔╝██║     ███████║   ██║   █████╗      ███████╗   ██║   ██████╔╝██║██╔██╗ ██║██║  ███╗
	 ██║   ██╔══╝  ██║╚██╔╝██║██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝      ╚════██║   ██║   ██╔══██╗██║██║╚██╗██║██║   ██║
	 ██║   ███████╗██║ ╚═╝ ██║██║     ███████╗██║  ██║   ██║   ███████╗    ███████║   ██║   ██║  ██║██║██║ ╚████║╚██████╔╝
	 ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝

*/
	public getTemplateString(tabs: string): string {

		let string = this.dataService.codeGenerationService.generateFinalHtml(this.getHtmlTemplate(), this, tabs)

		return string
	}

	getHtmlTemplate(): string {

		let string =
`<mat-form-field appearance="$Erscheinungsbild" color="$color" id="$displayName">

	<mat-label>$Label</mat-label>

	<input id="$displayName+Input" [matDatepicker]="$displayName" value="$prefill" matInput type="$Typ" if§$maxLength, maxLength="getProp('maxLength')"§ placeholder="$Platzhalter" if§$disabled, disabled§>

	<mat-hint *ngIf="getProp('startHint')!=''" align="start">{{getProp("startHint")}}</mat-hint>
	<mat-hint *ngIf="getProp('endHint')!=''" align="end">{{getProp("endHint")}}</mat-hint>

	<span *ngIf="getProp('prefixText')!=''" matPrefix style="margin-right: 10px;">{{getProp("prefixText")}}</span>
	<span *ngIf="getProp('suffixText')!=''" matSuffix style="margin-right: 10px;">{{getProp("suffixText")}}</span>

	<mat-icon *ngIf="getProp('prefixIcon')!=''" matPrefix>{{getProp("prefixIcon")}}</mat-icon>
	<mat-icon *ngIf="getProp('suffixIcon')!=''" matSuffix>{{getProp("suffixIcon")}}</mat-icon>

	<button *ngIf="getProp('deletable')=='1'" matSuffix mat-icon-button>
		<mat-icon>close</mat-icon>
	</button>

  <mat-datepicker-toggle matIconSuffix [for]="$displayName"></mat-datepicker-toggle>
  <mat-datepicker #$displayName></mat-datepicker>

</mat-form-field>`
		return string
	}
}
