<table style="margin-top: 10px; width: 100%; border-collapse: collapse; border: 1px solid black;">

  <!-- Filters -->
  <tr>
    <td *ngFor="let key of getKeys(dataData ? dataData[0] : {}); let i = index">
			<label>Filter for {{key}}</label>
			<input matInput type="text" class="filterField"
				[value]="getFilterValue(key)"
				(input)="addToFilters(key, filterInput.value)" #filterInput
				(focus)="   elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)" >
    </td>
  </tr>

  <!-- Headings -->
  <tr>
    <th *ngFor="let column of columnData; let i = index" style="border: 1px solid black;">{{column}}</th>
  </tr>

  <!-- Data -->
  <ng-container *ngFor="let dataRow of filteredData; let i = index">
    <ng-container *ngIf="i == 0 && filteredData.length > 0 && (!columnData || columnData.length == 0)">
      <tr>
        <th *ngFor="let key of getKeys(dataRow); let i = index"
            style="border: 1px solid black;">Column {{i}}</th>
      </tr>
    </ng-container>
    <tr *ngIf="i < 11">
      <td *ngFor="let data of getKeys(dataRow)" style="border: 1px solid black;">{{ dataRow[data] }}</td>
    </tr>
    <tr *ngIf="i == 11">
      <td *ngFor="let data of getKeys(dataRow)" style="border: 1px solid black;">...</td>
    </tr>
  </ng-container>

</table>
<table *ngIf="!dataData || dataData.length == 0"
                style="margin-top: 10px; width: 100%; border-collapse: collapse; border: 1px solid black;">

  <!-- Headings -->
  <tr>
    <th style="border: 1px solid black;">The result of the api call will be here</th>
  </tr>

  <!-- Data -->
  <tr>
    <td style="border: 1px solid black;">...</td>
  </tr>

</table>
<p style="font-weight: bold;">Amount of lines: {{filteredData.length}}</p>
