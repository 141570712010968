import { Component, Input } from '@angular/core';
import { menu } from 'src/app/menus/menu/menu';
import { ElementService } from 'src/app/element.service';

@Component({
  selector: 'chartJs-menu',
  templateUrl: 'chartJs.menu.html'
})
export class ChartJsComponentMenu extends menu {

  @Input() renderOnlyOuter = false
  @Input() isTailwind = false
  @Input() isHelper = true

  constructor(
    public elementService: ElementService
  ){

    super(elementService)
  }
}
