import { Component, Input } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-gap-menu',
  templateUrl: 'menu.html'
})
export class MatGapMenu extends menu {

  // @Input() shortName = ""
  @Input() width = "100%"

  renderOnlyOuter = false
  isTailwind = false
  isHelper = false

  gaps = [
    "0",
    "0.25",
    "0.5",
    "0.75",
    "1",
    "1.25",
    "1.5",
		"1.75",
		"2",
		"2.25",
		"2.5",
		"2.75",
		"3",
  ]
}
