<div class="overlay"></div>

<div class="dialog">
  <mat-card>
    <button mat-raised-button style="position: absolute; right: 10px; top: 10px; z-index: 3;" color="primary" (click)="close()"><span translate>general.close</span></button>
    <mat-tab-group>

      <mat-tab [label]="templateName">
        <button mat-icon-button color="primary" style="position: absolute; right: 10px; top: 10px;"
          (click)="copyText(HTMLdata)">
          <mat-icon>content_copy</mat-icon>
        </button>
        <mat-card-subtitle>
          <pre><code>{{HTMLdata}}</code></pre>
        </mat-card-subtitle>
      </mat-tab>

      <mat-tab [label]="scriptName">
        <button mat-icon-button color="primary" style="position: absolute; right: 10px; top: 10px;"
          (click)="copyText(tsCode)">
          <mat-icon>content_copy</mat-icon>
        </button>
        <mat-card-subtitle>
          <pre><code>{{tsCode}}</code></pre>
        </mat-card-subtitle>
      </mat-tab>

      <mat-tab [label]="cssName">
        <button mat-icon-button color="primary" style="position: absolute; right: 10px; top: 10px;"
          (click)="copyText(cssCode)">
          <mat-icon>content_copy</mat-icon>
        </button>
        <mat-card-subtitle>
          <pre><code>{{cssCode}}</code></pre>
        </mat-card-subtitle>
      </mat-tab>

    </mat-tab-group>

  </mat-card>
</div>
