import { Project, PropertiesService, PropertyIterator, SaveLayout } from './projects';
import { LayoutComponent } from './layout/layout.component';

export function createProject(): Project {
  return { id: '', owner: '', name: 'New App', pages: [] };
}

export class PageReference {
  public propertiesService: PropertiesService;
  public page: SaveLayout;

  public reference(propertiesService: PropertiesService, page: SaveLayout): void {
    this.propertiesService = propertiesService;
    this.page = page;
  }

  public get id(): string {
    const attributes = new PropertyIterator(this.propertiesService, this.page);
    return [ ...attributes ].find((prop) => prop.key === 'id')?.value ?? '';
  }

  public get name(): string {
    return this.page?.name ?? '';
  }
}

export class ProjectContext {
  /**
   * ### The Project State Table
   * projectComponentId -> ProjectState
   */
  public projectStateTable: Map<string, Project> = new Map<string, Project>();
  /**
   * ### The Current Pages Table
   * projectComponentId -> PageReference
   */
  public currentPagesTable: Map<string, PageReference> = new Map<string, PageReference>();

  /**
   * ### The Data Table
   * layoutComponentId -> SaveLayout
   */
  public dataTable: Map<string, SaveLayout> = new Map<string, SaveLayout>();
  /**
   * ### The Component Table
   * layoutComponentId -> LayoutComponent
   */
  public componentTable: Map<string, LayoutComponent> = new Map<string, LayoutComponent>();

  public append(anotherContext: ProjectContext): void {
    for (const [projectComponentId, projectState] of anotherContext.projectStateTable) {
      this.projectStateTable.set(projectComponentId, projectState);
    }
    for (const [projectComponentId, pageReference] of anotherContext.currentPagesTable) {
      this.currentPagesTable.set(projectComponentId, pageReference);
    }

    for (const [layoutComponentId, saveLayout] of anotherContext.dataTable) {
      this.dataTable.set(layoutComponentId, saveLayout);
    }
    for (const [layoutComponentId, layoutComponent] of anotherContext.componentTable) {
      this.componentTable.set(layoutComponentId, layoutComponent);
    }
  }

  public clear(): void {
    this.projectStateTable.clear();
    this.currentPagesTable.clear();

    this.dataTable.clear();
    this.componentTable.clear();
  }
}
