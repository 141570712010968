import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import _ from 'lodash'

/*
  The JsonViewer offers a view for JSON-Objects.
  It is used in the ApiCallDialog to show the response of the API-Call.
  The magic is, that it returns the path of the clicked element, wich
it simultaniously sets as the html-id of the clicked node, so it can be accessed.
*/
@Component({
  selector: 'jsonViewer',
  templateUrl: './jsonViewer.html'
})
export class JsonViewer {

  // If this is the highest level of jsonViewer in the recursive call stack
  @Input() isHighest = false

  @Input() body_Type: string

  @Input() selectionType = "multiple" // single or multiple

  // Will be less and less as you get recursively deeper into the json
  @Input() responseBody: any

  // Num is needed to make the html-id's unique.
  // Because all jsonViewers in an API call are using the same data, they need to be unique.
  @Input() jsonViewerNumber: number
  @Input() apiCallNumber: number
  @Input() path: string = ''

  @Output() tellApiCallAboutSelection: EventEmitter<string[]> = new EventEmitter<string[]>()

  getType(val: any): string {

    return Array.isArray(val) ? 'array' : typeof val
  }

  isObject(val: any): boolean {

    return typeof val === 'object' && !Array.isArray(val)
  }

  trackByFn(index: any, item: any): any {

    return index
  }

  getKeys(data: any): Array<string> {

    return data ? Object.keys(data) : []
  }

  /**
   * forwardLastSelected() is needed, because JSONViewer is recursive, so the
   * last selected node get's transferred to the apiCall
   * @param nodeId Whatever came in, it get's passed along to the parent
   */
  forwardLastSelected(nodeId: any){

    if(!this.isHighest) this.tellApiCallAboutSelection.emit(nodeId)
    else this.setLastSelectedColorizeAndReturn(nodeId)
  }

  /**
   * When a JSON-Field was clicked.
   * **Emits** the path of the clicked element to the parent, but ultimately to the highest level of jsonViewer,
   * through forwardLastSelected()
   * @param path The recursively accumulated path of the clicked element
   * @param node The clicked html-element
   */
  onClick(node: HTMLSpanElement) {

    this.forwardLastSelected(node.id)
  }

  /*
    We need to store the field paths, because we don't want to store the data itself, but handle the incoming
    data of the response after every call.
  */
  selectedFieldPaths: string[] = []
  lastSelectedDataElement: HTMLElement
  /**
   * Selection of data fields.
   * The Data is used for the data in charts, tables etc.
   * JSONViewer -> select JSON-Field -> event comes in.
   * We can have single or multiple selections of data fields.
   * @param pathAndNum pathAndNum = path + number of jsonViewer = the html-id of the selected JSON-Field
   */
  setLastSelectedColorizeAndReturn(pathAndNum: string){ // event = path + this.num

    if(!this.isHighest || this.body_Type == "Form" || this.body_Type == undefined) return // only the highest level of jsonViewer should handle the selection of fields

    // Get the element
    let newSelectedElement = document.getElementById(pathAndNum) as HTMLElement

    // Remove the num from jsonViewer
    let newSelectedPath = pathAndNum.slice(0, pathAndNum.length - 1)
    // Get the substring after 'call'+apiCallNumber
    let after = 'call'+this.apiCallNumber
    let arr = newSelectedPath.split(after)
    newSelectedPath = arr[arr.length-1]

    let isAlreadySelected = this.selectedFieldPaths.includes(newSelectedPath)
    if(isAlreadySelected){

      // Remove selection - works for single and multiple selection
      this.selectedFieldPaths.splice(this.selectedFieldPaths.findIndex(item => item === newSelectedPath), 1)
      if (newSelectedElement) newSelectedElement.style.backgroundColor = '#CDCDCD'
    }
    else { // Is not selected yet


      if(this.selectionType == "single"){

        // Remove selection for the last one
        this.selectedFieldPaths = []
        this.lastSelectedDataElement ? this.lastSelectedDataElement.style.backgroundColor = '#CDCDCD':null
      }

      // Select the new one
      this.selectedFieldPaths.push(newSelectedPath)
      newSelectedElement ? newSelectedElement.style.backgroundColor = '#C4EDC3' : ""
    }

    this.lastSelectedDataElement = newSelectedElement
    this.data = []
    this.storeData()
    this.tellApiCallAboutSelection.emit(this.selectedFieldPaths)
  }

  data: any[] = []
  /**
   * Stores the data of the selected fields in the data array and
   * **_emits_** it to the apiCall
   */
  storeData(){

    if( !this.responseBody || !this.selectedFieldPaths[0]) return

    let isAnArray = this.selectedFieldPaths[0].includes("[0]")
    if(!isAnArray){

      let resultObj: any = {}

      this.selectedFieldPaths.forEach(path => {

        // Get the value of this path inside of the item object
        let dataValue = _.get(this.responseBody, path)

        resultObj[path] = dataValue
      })
      this.data.push(resultObj)
      return
    }
    // The path includes an array
    // Get the prefix in the path
    // The prefix is the part of the path, that indicates the array in the json data
    // EXAMPLE: "objects[0].supplierNameAtSave.text" => "objects"
    // Man kann eigentlich nicht einfach selectedFieldPaths[0] nehmen, weil die anderen Felder
    // von einem anderen Array sein können
    let arrayPrefix = this.selectedFieldPaths[0].startsWith("[0]")?"":
                                this.selectedFieldPaths[0].split("[0]")[0];

    // Durchläuft alle Elemente im gefundenen Prefix
    (arrayPrefix?this.responseBody[arrayPrefix]:this.responseBody).forEach((item: any) => {

      // Any data object, wich represents a row in a table or a data point in a chart
      let resultObj: any = {}

      this.selectedFieldPaths.forEach(path => {

        // Nimm alles nach dem Arrayprefix
        // z.B. "data[0].attributes.first_name.value" => "attributes.first_name.value"
        let pathAfterPrefix = path.split(arrayPrefix+"[0].")[1]

        // Get the value of this path inside of the item object
        let dataValue = _.get(item, pathAfterPrefix)

        resultObj[pathAfterPrefix] = dataValue
      })

      this.data.push(resultObj)
    })
  }
}
