import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ElementService } from '../../element.service';
import { AutomationsService } from '../../automations';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   Automations Dialog                                |
           |   ===============================================   |
           |   * for Automations like in Budibase                |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'automationsDialog',
  templateUrl: './automationsDialog.html',
  styleUrls: ['./automationsDialog.scss']
})
export class AutomationsDialog implements OnInit {
  public columns: string[] = ['name', 'action'];

  @Output()
  public closed: EventEmitter<AutomationsDialog> = new EventEmitter<AutomationsDialog>();

  public constructor(public readonly elementService: ElementService, public readonly automationsService: AutomationsService){
  }

  public ngOnInit(): void {
  }

  public emitDataAndClose() {
    this.closed.emit(this);
  }
}
