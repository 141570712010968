import {Component, Input} from '@angular/core';
import { menu } from "../menu/menu";

@Component({
  selector: 'app-menu-background',
  templateUrl: './menu-background.component.html',
})
export class MenuBackgroundComponent extends menu{
  @Input() width = "100%"

  renderOnlyOuter = false
  isTailwind = false
  isHelper = false
}
