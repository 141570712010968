import { Action } from '../action';

export class KanbanCardDroppedAction extends Action {
  public headerField: string;
  public keyField: string;

  public action(): void {
    try {
      const pageId = this.input[0][this.headerField];
      const newStatus = this.input[0][this.keyField];

      const url = `https://api.notion.com/v1/pages/${pageId}`;
      const method = 'PATCH';

      const header =
`{
  "Authorization": "Bearer secret_IMATLOjGl6VziBz4sdC6XCn7bavijiybyhHyUH4M92P",
  "Notion-Version": "2022-02-22",
  "Content-Type": "application/json",
  "target-url": "${url}"
}
`;

      const body =
`{
  "properties": {
    "Status": {
      "select": {
        "name": "${newStatus}"
      }
    }
  }
}
`;

      fetch('https://general-backend.testing.photonic-codes.cloud/api/call', {
        method: method,
        headers: JSON.parse(header),
        body: body
      });
    } catch (error) {
      console.error('ERROR KanbanCardDRoppedAction: ' + error);
    }
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      headerField: this.headerField,
      keyField: this.keyField
    }
  }
}
