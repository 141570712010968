import { Component, Input } from '@angular/core'

import { ElementService } from "../../element.service"
import { menu } from "../menu/menu"

@Component({
  selector: 'app-menu-display-name-input',
  templateUrl: './menu-display-name-input.component.html'
})
export class MenuDisplayNameInputComponent extends menu {
  @Input()
  width = '100%';

  private _inputValue: string | undefined;

  public constructor(public readonly elementService: ElementService) {
    super(elementService)
  }

  public get inputValue(): string | undefined {
    const stringValue = this.elementService.getProp('displayName');
    this._inputValue = stringValue == '' ? undefined : stringValue;
    return this._inputValue;
  }
  public set inputValue(value: string | undefined) {
    if (!value) { return; }
    this.elementService.setProp({ key: 'displayName', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this._inputValue = value;
  }
}
