import { Component, Input, OnChanges } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-amount-menu',
  templateUrl: 'menu.html'
})
export class MatAmountMenu extends menu implements OnChanges {

  // @Input() shortName = ""
  @Input() width = "100%"
  @Input() maxAmount = 2

  renderOnlyOuter = false
  isTailwind = false
  isHelper = true

  ngOnChanges(): void {

    this.amounts = []
    for (let i = 1; i <= this.maxAmount; i++) {

      this.amounts.push(""+i)
    }
  }

  amounts = [
    "1",
    "2"
  ]

  getAmount(){

    return this.amounts.slice(0, Number(this.getProp('Menge')))
  }
}
