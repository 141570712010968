import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';

@Component({
  selector: 'matCheckbox-menu',
  templateUrl: 'menu.html'
})
export class MatCheckboxMenu extends menu {

  isHelper = true

}
