import { Trigger } from '../trigger';

export class KanbanCardDroppedTrigger extends Trigger {
  public input: any;

  public override triggerAction(): void {
    if (this.automation && this.automation.action) {
      this.automation.action.input = this.input;
    }

    super.triggerAction();
  }
}
