import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { ApiService, PeriodicElement } from '../../in-memory-data.service';
import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from "../../data.service";
import { matTableComponentMenu } from './matTable.menu';
import { ApiCallGenerator } from '../../code-generation/generators/snippets/api-call.generator';

@Component({
  selector: 'app-matTable',
  templateUrl: './matTable.component.html',
  styleUrls: ['./matTable.component.scss']
})
export class matTableComponent extends LayoutComponent implements AfterViewInit {
  chemicalElements: PeriodicElement[]

  columns: string[] = []
  dataSource: any[] = []

  currentEditorSelector: Element | null

  constructor(
    elementService: ElementService,
    renderer: Renderer2,
    private apiService: ApiService,
    public dataService: DataService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
  ) {
    super(elementService, renderer, dataService);
    this.componentType = 'matTableComponent';
  }

  /*
   *
   * ██╗     ██╗███████╗███████╗ ██████╗██╗   ██╗ ██████╗██╗     ███████╗
   * ██║     ██║██╔════╝██╔════╝██╔════╝╚██╗ ██╔╝██╔════╝██║     ██╔════╝
   * ██║     ██║█████╗  █████╗  ██║      ╚████╔╝ ██║     ██║     █████╗
   * ██║     ██║██╔══╝  ██╔══╝  ██║       ╚██╔╝  ██║     ██║     ██╔══╝
   * ███████╗██║██║     ███████╗╚██████╗   ██║   ╚██████╗███████╗███████╗
   * ╚══════╝╚═╝╚═╝     ╚══════╝ ╚═════╝   ╚═╝    ╚═════╝╚══════╝╚══════╝
   *
   */

  ngAfterViewInit(): void {
    this.initResizing()

    setTimeout(() => {
      this.initializeColumnOrder()

      setTimeout(() => {
        this.element = this

        this.initializeDatasource();

        setTimeout(() => {
          if (this.getProp("fieldNameSort1") == "") {
            this.createPeriodicElementsProperties();
          }
        }, 50)
      }, 50)
    }, 50)

    // Detect changes
    this.elementService.changeDetector.detectChanges()
  }

  /*
   *
   * ██╗███╗   ██╗██╗████████╗██╗ █████╗ ██╗     ██╗███████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   * ██║████╗  ██║██║╚══██╔══╝██║██╔══██╗██║     ██║╚══███╔╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   * ██║██╔██╗ ██║██║   ██║   ██║███████║██║     ██║  ███╔╝ ███████║   ██║   ██║██║   ██║██╔██╗ ██║
   * ██║██║╚██╗██║██║   ██║   ██║██╔══██║██║     ██║ ███╔╝  ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   * ██║██║ ╚████║██║   ██║   ██║██║  ██║███████╗██║███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   * ╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝   ╚═╝╚═╝  ╚═╝╚══════╝╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
   *
   */

  private initializeDatasource(): void {
    let dataAsString = this.getProp('data');

    if (dataAsString === '') {
      this.apiService.getPeriodicElements().subscribe({
        next: chemicalElements => {
          this.dataSource = chemicalElements;
          this.setPropAsHelper('data', JSON.stringify(this.dataSource));
          this.columns = Object.keys(this.dataSource[0]);
          this.initTable();
        },
        error: err => {
          console.error(err);
        }
      });
    } else {
      this.dataSource = JSON.parse(dataAsString);
      this.setPropAsHelper('data', JSON.stringify(this.dataSource));
      this.columns = Object.keys(this.dataSource[0]);
      this.initTable();
    }
  }

  private initTable(): void {
    setTimeout(() => {
      this.initializeColumns();
      this.setBordersHorizontal("border-bottom", this.getProp("horizontalBorder") == "1" ? 1 : 0);
      this.setBordersVertical("border-right", this.getProp("verticalBorder") == "1" ? 1 : 0);
    }, 50);
  }

  private createPeriodicElementsProperties(): void {
    if (this.getProp("border-color") == '') { this.setProp({ key:"border-single-width", value: "1", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp("margin") == '') { this.setProp({ key:"margin", value: "50", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true }); }
    if (this.getProp("horizontalBorder") == '') { this.setProp({ key:"horizontalBorder", value: "1", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp("columns") == '') { this.setProp({ key:"columns", value: "4", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp("fieldNameSort1") == '') { this.setProp({ key:"fieldNameSort1", value: "position", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("fieldNameSort2") == '') { this.setProp({ key:"fieldNameSort2", value: "name", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("fieldNameSort3") == '') { this.setProp({ key:"fieldNameSort3", value: "weight", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("fieldNameSort4") == '') { this.setProp({ key:"fieldNameSort4", value: "symbol", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp("Col1Text") == '') { this.setProp({ key:"Col1Text", value: "Spalte 1", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("Col2Text") == '') { this.setProp({ key:"Col2Text", value: "Spalte 2", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("Col3Text") == '') { this.setProp({ key:"Col3Text", value: "Spalte 3", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp("Col4Text") == '') { this.setProp({ key:"Col4Text", value: "Spalte 4", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp('Col1Code') == '') { this.setProp({ key:"Col1Code", value: this.getDefaultCode(), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('Col2Code') == '') { this.setProp({ key:"Col2Code", value: this.getDefaultCode(), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('Col3Code') == '') { this.setProp({ key:"Col3Code", value: this.getDefaultCode(), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('Col4Code') == '') { this.setProp({ key:"Col4Code", value: this.getDefaultCode(), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp('footer') == '') { this.setProp({ key:"footer", value: '0', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp('footerType1') == '') { this.setProp({ key:"footerType1", value: 'nothing', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('footerType2') == '') { this.setProp({ key:"footerType2", value: 'nothing', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('footerType3') == '') { this.setProp({ key:"footerType3", value: 'nothing', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('footerType4') == '') { this.setProp({ key:"footerType4", value: 'nothing', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
  }

  // Update after API call
  setColumnAndDataArray(columnsArrayFromApiCall: any[], dataArrayFromApiCall: any[]){
    if(!dataArrayFromApiCall[0]) return

    this.columns = Object.keys(dataArrayFromApiCall[0])
    this.dataSource = dataArrayFromApiCall

    this.setProp({key:"columns", value: this.columns.length+"", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.setProp({key:"data", value: JSON.stringify(this.dataSource), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    this.columns.forEach((column, i) => {

      this.setProp({key:"fieldNameSort"+(i+1), value: column, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    })

    // For each columnsArrayFromApiCall, set the column heading
    columnsArrayFromApiCall.forEach((column, i) => {

      this.setProp({key:"Col"+(i+1)+"Text", value: column, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    })
  }

  public getInputValue(field: HTMLElement, columnNum: number){
    let value = this.getProp("Col"+columnNum+"Text");

    let oldSize = (field.attributes as any).size.nodeValue
    let newSize = value.length

    if(oldSize != newSize) {
      (field.attributes as any).size.nodeValue = newSize > 0 ? newSize : 1
    }

    return value
  }

  setBordersVertical(borderName: string, px: number) {
    let headerRowCells = this.nativeElement.querySelectorAll('[mat-header-cell]')
    let colAmount = Number(this.getProp("columns"))

    let i = 0
    headerRowCells.forEach(headerRow => {
      i++
      if(i == colAmount) this.renderer.setStyle(headerRow, borderName, "solid 0px #0000001f")
      else this.renderer.setStyle(headerRow, borderName, "solid "+px+"px #0000001f")
    })

    let dataCells = this.nativeElement.querySelectorAll('[mat-cell]')

    i = 0
    dataCells.forEach(row => {
      i++
      // Don't set the border for the last cell
      if((i % colAmount) == 0) this.renderer.setStyle(row, borderName, "solid 0px #0000001f")
      else this.renderer.setStyle(row, borderName, "solid "+px+"px #0000001f")
    })

    let footerCells = this.nativeElement.querySelectorAll('[mat-footer-cell]')

    i = 0
    footerCells.forEach(row => {
      i++
      // Don't set the border for the last cell
      if((i % colAmount) == 0) this.renderer.setStyle(row, borderName, "solid 0px #0000001f")
      else this.renderer.setStyle(row, borderName, "solid "+px+"px #0000001f")
    })
  }

  setBordersHorizontal(borderName: string, px: number) {
    let headerRows = this.nativeElement.querySelectorAll('[mat-header-cell]')

    headerRows.forEach(headerRow => {
      this.renderer.setStyle(headerRow, borderName, "solid "+px+"px #0000001f")
    })

    let dataCells = this.nativeElement.querySelectorAll('[mat-cell]')

    let i = 1
    let colAmount = Number(this.getProp("columns")) - 1
    dataCells.forEach(row => {
      // Don't set the border for the last row, if the footer is deactivated
      if(i >= (dataCells.length-colAmount) && this.getProp("footer") == "0")
        return
      if(i >= (dataCells.length-colAmount) && this.getProp("footer") == "1")
        this.renderer.setStyle(row, borderName, "solid "+(px+1)+"px #0000001f")
      else
        this.renderer.setStyle(row, borderName, "solid "+px+"px #0000001f")
      i++
    })
  }

  /*
   *
   * ██╗   ██╗████████╗██╗██╗     ██╗     ██╗████████╗██╗███████╗███████╗
   * ██║   ██║╚══██╔══╝██║██║     ██║     ██║╚══██╔══╝██║██╔════╝██╔════╝
   * ██║   ██║   ██║   ██║██║     ██║     ██║   ██║   ██║█████╗  ███████╗
   * ██║   ██║   ██║   ██║██║     ██║     ██║   ██║   ██║██╔══╝  ╚════██║
   * ╚██████╔╝   ██║   ██║███████╗███████╗██║   ██║   ██║███████╗███████║
   *  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚══════╝╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝
   *
   */

  /**
   * Get the cells of the column, **including the header and footer cells**
   * @param colNum Starts with 1
   * @returns The cells of the column
   */
  getColumnCellsFromColNum(colNum: number) {
    let fieldName = this.getCleanFieldNameFromColNum(colNum);
    let columnCells = this.nativeElement.getElementsByClassName(`mat-column-${fieldName}`);
    return columnCells ? columnCells : [];
  }

  getCleanFieldNames(): string[] {
    const fieldNames = this.columns;
    const cleanFieldNames: string[] = [];

    fieldNames.forEach((fieldName, index) => {
      fieldName = fieldName.replace(/\./g, "-").replace(/ /g, "-");
      fieldName = fieldName.replace(/\[/g, "-").replace(/\]/g, "-");

      cleanFieldNames.push(fieldName);
    })

    return cleanFieldNames;
  }

  /**
   * Returns the cleaned fieldname of the column, to access the html id
   * **WARNING:** DO NOT USE FOR the dataSource
   * @param colNum Starts with 1
   * @returns the fieldname or "" if nothing found
   */
  getCleanFieldNameFromColNum(colNum: number): string {
    let fieldName: string = this.getFieldNameFromColNum(colNum);

    fieldName = fieldName.replace(/\./g, "-").replace(/ /g, "-");
    fieldName = fieldName.replace(/\[/g, "-").replace(/\]/g, "-");

    return fieldName;
  }

  /**
   * Returns the fieldname of the column for accessing the dataSource, **WARNING:** DO NOT USE FOR the html id
   * @param colNum Starts with 1
   * @returns the fieldname or "" if nothing found
   */
  getFieldNameFromColNum(colNum: number): string {
    let foundFieldname: string = "";
    this.columns.forEach((fieldName, index) => {
      if(index + 1 !== colNum) {
        return;
      }
      foundFieldname = fieldName;
    });
    return foundFieldname;
  }

  /**
   * Returns the column number of the column for the fieldname
   * @param fieldname2Search
   * @returns the column number or 0 if nothing found
   */
  getColNumFromFieldName(fieldname2Search: string): number {
    let foundColNum: number = 0;
    this.columns.forEach((fieldName, index) => {
      if (fieldname2Search === fieldName) {
        foundColNum = index + 1;
      }
    });
    return foundColNum;
  }

  isFooterCell(cell: HTMLElement): boolean {
    return cell.classList.contains("mat-mdc-footer-cell");
  }

  getDefaultCode(): string {
    return '// Also try col[2] or calcCol[2]' + '\r\n' + 'result = cell';
  }

  /*
   *
   *  ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗    ██╗███╗   ██╗██╗████████╗██╗ █████╗ ██╗     ██╗███████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   * ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║    ██║████╗  ██║██║╚══██╔══╝██║██╔══██╗██║     ██║╚══███╔╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   * ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║    ██║██╔██╗ ██║██║   ██║   ██║███████║██║     ██║  ███╔╝ ███████║   ██║   ██║██║   ██║██╔██╗ ██║
   * ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║    ██║██║╚██╗██║██║   ██║   ██║██╔══██║██║     ██║ ███╔╝  ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   * ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║    ██║██║ ╚████║██║   ██║   ██║██║  ██║███████╗██║███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   *  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝    ╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝   ╚═╝╚═╝  ╚═╝╚══════╝╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
   *
   */

  private initializeColumnOrder(): void {
    this.columns = [];
    let length = Number(this.getProp("columns"));
    for (let i = 1; i <= length; i++) {
      this.columns.push(this.getProp("fieldNameSort" + i));
    }
  }

  private initializeColumns(): void {
    this.getCleanFieldNames().forEach((fieldName, index) => this.processColumnsWidth(fieldName));

    /*
     * Without timeout, it doesn't work when cloning, otherwise yes. Has nothing to do with expressionChangedAfterChecked.
     * This has to be done at least 50ms after the this.columns are being set.
     * Without timeout, it doesn't work when importing json (also cloning?), otherwise yes. Has nothing to do with expressionChangedAfterChecked.
     */
    setTimeout(() => { this.processColumns(); }, 300);
  }

  private processColumnsWidth(fieldName: string): void {
    // mat-column-xyz's are in the table > thead > tr > th part, and all mat-cell's have it as a class
    let column = this.nativeElement.getElementsByClassName(`mat-column-${fieldName}`)[0] as HTMLElement;

    let width = this.getProp(`width${fieldName}`);
    if(width && width !== "") { this.renderer.setStyle(column, "width", `${width}px`); }
  }

  public processColumns(): void {
    this.evaluateTable();

    setTimeout(() => {
      this.columns.forEach((fieldName, index) => {
        const footer = this.getProp(`footer`);
        if (footer === '1') { this.setFooter(index + 1); }

        const format = this.getProp(`columnFormat${index + 1}`);
        if (format && format !== '') { this.setFormat(index + 1, format); }

        const align = this.getProp(`columnAlign${index + 1}`);
        if (align && align !== '') { this.setAlign(index + 1, align); }
      });
    }, 10);
  }

  private evaluateTable(): void {
    for (let index = 0; index < this.columns.length; index++) {
      this.evaluate(index + 1);
    }
  }

  private setFooter(columnNumber: number): void {
    const columnCells = this.getColumnCellsFromColNum(columnNumber);
    const lastCell = columnCells[columnCells.length - 1] as HTMLElement;

    if (this.isFooterCell(lastCell)) {
      const footerType = this.getProp(`footerType${columnNumber}`);
      if (footerType === 'sum') {
        lastCell.innerText = String(this.getSum(this.columns[columnNumber - 1]));
      } else if (footerType === 'count') {
        lastCell.innerText = String(this.dataSource.length);
      } else {
        lastCell.innerText = '';
      }
    }
  }

  /**
   * Get the sum of a column
   * Sums up cells and ignores currency
   * @param columnFieldName The fieldname of the column to get the sum of
   * @returns The sum of the column
   */
  private getSum(columnFieldName: string) {
    const columnNumber = this.columns.indexOf(columnFieldName) + 1;
    let columnLineCells = this.getColumnCellsFromColNum(columnNumber);

    let sum: number = 0;

    for (let index = 1; index < columnLineCells.length; index++) {
      let cell = columnLineCells[index] as HTMLElement;
      let isFooterCell = this.isFooterCell(cell);
      if (!isFooterCell) {
        sum += Number(cell.innerText.replace(',', '.').replace('€', ''));
      }
      if (isNaN(sum)) {
        return '';
      }
    }

    return sum;
  }

  private setFormat(columnNumber: number, format: string): void {
    const columnLineCells = this.getColumnCellsFromColNum(columnNumber);

    // Iterate over the lines in the column.
    // Must start with 1, because columnCells[0] is the header row.
    for (let index = 1; index < columnLineCells.length; index++) {
      const cell = columnLineCells[index] as HTMLElement;

      const isFooterCell = this.isFooterCell(cell);
      if (!this.dataSource[index - 1] && !isFooterCell) { continue; }

      const value = cell.innerText.replace(',', '.').replace('€', '');

      if (value === '') { continue; }

      switch (format) {
        case "date_de":
          if (isNaN(Date.parse(value))) { break; }
          const footerType = this.getProp(`footerType${columnNumber}`);
          cell.innerText = !isFooterCell ? String(this.datePipe.transform((value),'dd.MM.yyyy')) : (footerType === 'count' ? value : '');
          break;
        case "currency_de":
          if (isNaN(Number(value))) { break; }
          cell.innerText = String(this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2', 'de-DE'));
          break;
        case "number_de":
          if (isNaN(Number(value))) { break; }
          cell.innerText = String(this.decimalPipe.transform(value, '1.2-2', 'de-DE'));
          break;
        default:
          cell.innerText = value;
          break;
      }
    }
  }

  private setAlign(columnNumber: number, align: string): void {
    let columnCells = this.getColumnCellsFromColNum(columnNumber);

    for(let i = 0; i < columnCells.length; i++) {
      let cell = columnCells[i] as HTMLElement;
      this.renderer.setStyle(cell, "text-align", align);
    }
  }

  /*
   *
   * ███╗   ███╗███████╗███╗   ██╗██╗   ██╗
   * ████╗ ████║██╔════╝████╗  ██║██║   ██║
   * ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║
   * ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║
   * ██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝
   * ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝
   *
   */

  /**
   * To be called from matTable.menu.ts
   */
  public delete(colNum: string): void {
    this.deleteColumn(Number(colNum));
    this.removeProp("deleteColumn");
  }

  /*
   *
   * ███████╗██╗   ██╗ █████╗ ██╗     ██╗   ██╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   * ██╔════╝██║   ██║██╔══██╗██║     ██║   ██║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   * █████╗  ██║   ██║███████║██║     ██║   ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
   * ██╔══╝  ╚██╗ ██╔╝██╔══██║██║     ██║   ██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   * ███████╗ ╚████╔╝ ██║  ██║███████╗╚██████╔╝██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   * ╚══════╝  ╚═══╝  ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
   *
   */

  tooltip = document.getElementsByClassName("editor-tooltip")[0] as HTMLElement;

  /**
   * Evaluates the code in the editor field of the currently selected column.
   * @param code The code to evaluate
   */
  private evaluate(column: number): void {
    const code = this.getProp(`Col${column}Code`);

    const columnCells = this.getColumnCellsFromColNum(Number(column));
    if (!columnCells || columnCells.length == 0) { console.error("columnCells were not found!"); }

    // Iterate over the lines in the column.
    // Must start with 1, because columnCells[0] is the header row.
    // columnCells.length - 1, because the last cell is the footer row.
    for(let index = 1; index < columnCells.length - 1; index++) {
      const htmlCell = columnCells[index] as HTMLElement;

      // The original cell text
      const columnField = this.columns[column - 1];
      let cell = this.dataSource[index - 1][columnField];

      // Array of all cells in the current row, from this.dataSource
      const col: string[] = [];
      this.columns.forEach(column => {
         /*
            Don't add +"", if you need it, do it in the editorfield
            We want to be able to use the original format for example a number
         */
        col.push(this.dataSource[index - 1][column]);
      });

      // Array of all cells in the current row, from the html table
      const calcCol: string[] = [];
      this.columns.forEach(column => {
        const colCells = this.getColumnCellsFromColNum(this.getColNumFromFieldName(column));
        const colCell = colCells[index] as HTMLElement;
        calcCol.push(colCell.innerText);
      });

      cell = cell === 0 ? 0 : !cell ? '' : cell; // Convert to string if undefined

      let result = cell;
      try {
        eval(code);
        this.tooltip.style.display = "none";
      } catch (error) {
        this.tooltip.style.display = "revert";
        this.tooltip.innerText = String(error);
      }

      htmlCell.innerText = result === 0 ? 0 : result ? result : '';

      // console.log(`Datasource[${index -1},${columnField}] = ${this.dataSource[index - 1][columnField]}; InnerText[${index -1},${columnField}] = ${htmlCell.innerText}`);

      this.setPropAsHelper(`Col${column}Code`, code);
    }
  }

  /*
   *
   *  ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
   * ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
   * ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
   * ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
   * ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
   *  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝
   *
   */

  public toggleSelectedColumn(columnNum: number): void {
    if (this.getProp('selectedColumn') !== String(columnNum)) {
      this.setProp({ key:"selectedColumn", value: String(columnNum), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    } else if (this.getProp('selectedColumn') === String(columnNum)) {
      this.setProp({ key:"selectedColumn", value: "", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    }

    ElementService.monacoEditor.setValue(this.getProp(`Col${columnNum}Code`));
  }

  public renameExistingColumn(): void {
    let columnAmount = Number(this.getProp("columns"));
    for (let i = 0; i < columnAmount; i++) {
      let fieldName = this.getProp("fieldNameSort" + (i + 1));
      let indexOfThis = this.columns.indexOf(fieldName);
      if (indexOfThis < 0) this.columns[i] = fieldName;
      else if (i !== indexOfThis) {
        this.columns[i] = fieldName + "!exist@"+(indexOfThis + 1);
        this.setPropAsHelper("fieldNameSort" + (i + 1), this.columns[i]);
      }
    }
  }

  addColumn(newColNum: number): void {
    const columnAmount = Number(this.getProp("columns"));
    this.setProp({ key: "columns", value: String(columnAmount + 1), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

    let i = 1;
    let newFieldName = `fieldName${columnAmount + i}`;
    while (this.columns.indexOf(newFieldName) >= 0) {
      i++;
      newFieldName = `fieldName${columnAmount + i}`;
    }

    this.columns.splice(newColNum - 1, 0, newFieldName);

    // Move everything one to the right
    for (let i = columnAmount + 1; i > newColNum; i--) {
      const lastText = this.getProp(`Col${i - 1}Text`);
      this.setProp({ key: `Col${i}Text`, value: lastText, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const lastFieldNameSort = this.getProp(`fieldNameSort${i - 1}`);
      this.setProp({ key: `fieldNameSort${i}`, value: lastFieldNameSort, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const lastColumnFormat = this.getProp(`columnFormat${i - 1}`);
      this.setProp({ key: `columnFormat${i}`, value: lastColumnFormat, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const lastColumnAlign = this.getProp(`columnAlign${i - 1}`);
      this.setProp({ key: `columnAlign${i}`, value: lastColumnAlign, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const lastColumnCode = this.getProp(`Col${i - 1}Code`);
      this.setProp({ key: `Col${i}Code`, value: lastColumnCode, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const lastFooterType = this.getProp(`footerType${i - 1}`);
      this.setProp({ key: `footerType${i}`, value: lastFooterType, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    }

    // Insert column properties
    this.setProp({ key: `Col${newColNum}Text`, value: `Neue Spalte ${newColNum}`, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.setProp({ key: `fieldNameSort${newColNum}`, value: newFieldName, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.setProp({ key: `columnFormat${newColNum}`, value: '', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.setProp({ key: `columnAlign${newColNum}`, value: '', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.setProp({ key: `Col${newColNum}Code`, value: this.getDefaultCode(), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.setProp({ key: `footerType${newColNum}`, value: '', second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

    // Update data source
    for (let index = 0; index < this.dataSource.length; index++) {
      let newRow: any = {};
      const entries = Object.entries(this.dataSource[index]);

      for (let entry = 0; entry < newColNum - 1; entry++) {
        newRow[entries[entry][0]] = entries[entry][1];
      }
      newRow[newFieldName] = '';
      for (let entry = newColNum - 1; entry < entries.length; entry++) {
        newRow[entries[entry][0]] = entries[entry][1];
      }

      this.dataSource[index] = newRow;
    }

    this.setPropAsHelper('data', JSON.stringify(this.dataSource));

    // Update
    setTimeout(() => {
      this.initializeColumns();
      this.initResizing();
    }, 50);
  }

  deleteColumn(colToDelete: number): void {
    // Unselect the deleted column
    this.setProp({ key: "selectedColumn", value: "", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

    const columnField = this.getProp(`fieldNameSort${colToDelete}`)
    this.columns.splice(colToDelete - 1, 1);

    let columnAmount = Number(this.getProp("columns"));
    this.setProp({ key: "columns", value: String(columnAmount - 1), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false});

    // Remove column properties
    this.removeProp(`Col${colToDelete}Text`);
    this.removeProp(`fieldNameSort${colToDelete}`);
    this.removeProp(`columnFormat${colToDelete}`);
    this.removeProp(`columnAlign${colToDelete}`);
    this.removeProp(`Col${colToDelete}Code`);
    this.removeProp(`footerType${colToDelete}`);

    // Move everything one to the left
    for (let i = colToDelete; i < columnAmount; i++) {
      const nextColumnText = this.getProp(`Col${i + 1}Text`);
      this.setProp({ key: `Col${i}Text`, value: nextColumnText, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const nextFieldNameSort = this.getProp(`fieldNameSort${i + 1}`);
      this.setProp({ key: `fieldNameSort${i}`, value: nextFieldNameSort, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const nextColumnFormat = this.getProp(`columnFormat${i + 1}`);
      this.setProp({ key: `columnFormat${i}`, value: nextColumnFormat, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const nextColumnAlign = this.getProp(`columnAlign${i + 1}`);
      this.setProp({ key: `columnAlign${i}`, value: nextColumnAlign, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const nextColumnCode = this.getProp(`Col${i + 1}Code`);
      this.setProp({ key: `Col${i}Code`, value: nextColumnCode, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      const nextFooterType = this.getProp(`footerType${i + 1}`);
      this.setProp({ key: `footerType${i}`, value: nextFooterType, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    }

    // Remove column properties of the last column
    this.removeProp(`Col${columnAmount}Text`);
    this.removeProp(`fieldNameSort${columnAmount}`);
    this.removeProp(`columnFormat${columnAmount}`);
    this.removeProp(`columnAlign${columnAmount}`);
    this.removeProp(`Col${columnAmount}Code`);
    this.removeProp(`footerType${columnAmount}`);

    // Update data source
    for (const data of this.dataSource) {
      delete data[columnField];
    }

    this.setPropAsHelper('data', JSON.stringify(this.dataSource));

    // Update
    setTimeout(() => {
      this.initializeColumns();
      this.initResizing();
    }, 50);
  }

  /*
   *
   * ██████╗ ██████╗  █████╗  ██████╗      █████╗ ███╗   ██╗██████╗     ██████╗ ██████╗  ██████╗ ██████╗
   * ██╔══██╗██╔══██╗██╔══██╗██╔════╝     ██╔══██╗████╗  ██║██╔══██╗    ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
   * ██║  ██║██████╔╝███████║██║  ███╗    ███████║██╔██╗ ██║██║  ██║    ██║  ██║██████╔╝██║   ██║██████╔╝
   * ██║  ██║██╔══██╗██╔══██║██║   ██║    ██╔══██║██║╚██╗██║██║  ██║    ██║  ██║██╔══██╗██║   ██║██╔═══╝
   * ██████╔╝██║  ██║██║  ██║╚██████╔╝    ██║  ██║██║ ╚████║██████╔╝    ██████╔╝██║  ██║╚██████╔╝██║
   * ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝     ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝     ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝
   *
   */

  public drop(event: CdkDragDrop<string[]>): void {
    let previousIndex = event.previousIndex;
    let currentIndex = event.currentIndex;

    let areSameIndices = previousIndex === currentIndex;
    const columnAmount = Number(this.getProp("columns"));

    this.setProp({ key: "selectedColumn", value: "", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

    if (areSameIndices) {
      if (previousIndex === columnAmount - 1) {
        [ this.columns[previousIndex], this.columns[previousIndex - 1] ] = [ this.columns[previousIndex - 1], this.columns[previousIndex] ];
      } else {
        [ this.columns[previousIndex], this.columns[previousIndex + 1] ] = [ this.columns[previousIndex + 1], this.columns[previousIndex] ];
      }
    } else {
      // Swap columns
      [ this.columns[previousIndex], this.columns[currentIndex] ] = [ this.columns[currentIndex], this.columns[previousIndex] ];

      // Exchange column text
      const previousColText = this.getPropObj(`Col${previousIndex + 1}Text`);
      const currentColText = this.getPropObj(`Col${currentIndex + 1}Text`);
      this.setProp({ key: `Col${previousIndex + 1}Text`, value: currentColText!.value, second: currentColText!.second, isTailwind: currentColText!.isTailwind, isHelper: currentColText!.isHelper, renderOnlyOuter: currentColText!.renderOnlyOuter });
      this.setProp({ key: `Col${currentIndex + 1}Text`, value: previousColText!.value, second: previousColText!.second, isTailwind: previousColText!.isTailwind, isHelper: previousColText!.isHelper, renderOnlyOuter: previousColText!.renderOnlyOuter });

      // Exchange field name
      const previousFieldName = this.getProp(`fieldNameSort${previousIndex + 1}`);
      const currentFieldName = this.getProp(`fieldNameSort${currentIndex + 1}`);
      this.setProp({ key: `fieldNameSort${previousIndex + 1}`, value: currentFieldName, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
      this.setProp({ key: `fieldNameSort${currentIndex + 1}`, value: previousFieldName, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });

      // Exchange column format
      const previousColumnFormat = this.getProp(`columnFormat${previousIndex + 1}`);
      const currentColumnFormat = this.getProp(`columnFormat${currentIndex + 1}`);
      this.setProp({ key: `columnFormat${previousIndex + 1}`, value: currentColumnFormat, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
      this.setProp({ key: `columnFormat${currentIndex + 1}`, value: previousColumnFormat, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

      // Exchange column alignment
      const previousColumnAlignment = this.getProp(`columnAlign${previousIndex + 1}`);
      const currentColumnAlignment = this.getProp(`columnAlign${currentIndex + 1}`);
      this.setProp({ key: `columnAlign${previousIndex + 1}`, value: currentColumnAlignment, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
      this.setProp({ key: `columnAlign${currentIndex + 1}`, value: previousColumnAlignment, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

      // Exchange column code
      const previousColumnCode = this.getProp(`Col${previousIndex + 1}Code`);
      const currentColumnCode = this.getProp(`Col${currentIndex + 1}Code`);
      this.setProp({ key: `Col${previousIndex + 1}Code`, value: currentColumnCode, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
      this.setProp({ key: `Col${currentIndex + 1}Code`, value: previousColumnCode, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

      // Exchange footer type
      const previousFooterType = this.getProp(`footerType${previousIndex + 1}`);
      const currentFooterType = this.getProp(`footerType${currentIndex + 1}`);
      this.setProp({ key: `footerType${previousIndex + 1}`, value: currentFooterType, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
      this.setProp({ key: `footerType${currentIndex + 1}`, value: previousFooterType, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

      // Update data source
      const minIndex = Math.min(previousIndex, currentIndex);
      const maxIndex = Math.max(previousIndex, currentIndex);

      for (let index = 0; index < this.dataSource.length; index++) {
        let newRow: any = {};
        const entries = Object.entries(this.dataSource[index]);

        for (let entry = 0; entry < entries.length; entry++) {
          if (entry === minIndex) {
            newRow[entries[maxIndex][0]] = entries[maxIndex][1];
          } else if (entry === maxIndex) {
            newRow[entries[minIndex][0]] = entries[minIndex][1];
          } else {
            newRow[entries[entry][0]] = entries[entry][1];
          }
        }

        this.dataSource[index] = newRow;
      }

      this.setPropAsHelper('data', JSON.stringify(this.dataSource));
    }

    // Update
    setTimeout(() => {
      if (areSameIndices) {
        if (previousIndex === columnAmount - 1) {
          [ this.columns[previousIndex], this.columns[previousIndex - 1] ] = [ this.columns[previousIndex - 1], this.columns[previousIndex] ];
        } else {
          [ this.columns[previousIndex], this.columns[previousIndex + 1] ] = [ this.columns[previousIndex + 1], this.columns[previousIndex] ];
        }
      }
      this.initializeColumns();
      this.initResizing();
      this.setProp({ key: "selectedColumn", value: String(currentIndex + 1), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
    }, 50);
  }

  /*
   *
   *  ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗    ██╗    ██╗██╗██████╗ ████████╗██╗  ██╗    ██████╗ ██████╗  █████╗  ██████╗
   * ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║    ██║    ██║██║██╔══██╗╚══██╔══╝██║  ██║    ██╔══██╗██╔══██╗██╔══██╗██╔════╝
   * ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║    ██║ █╗ ██║██║██║  ██║   ██║   ███████║    ██║  ██║██████╔╝███████║██║  ███╗
   * ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║    ██║███╗██║██║██║  ██║   ██║   ██╔══██║    ██║  ██║██╔══██╗██╔══██║██║   ██║
   * ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║    ╚███╔███╔╝██║██████╔╝   ██║   ██║  ██║    ██████╔╝██║  ██║██║  ██║╚██████╔╝
   *  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝     ╚══╝╚══╝ ╚═╝╚═════╝    ╚═╝   ╚═╝  ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝
   *
   */

  resizeWidth = false;
  startX!: number
  startWidth!: number
  column!: HTMLElement
  currCol: string = ""

  initResizing() {
    this.renderer.listen("document", "mousemove", this.onMouseMove);
    this.renderer.listen("document", "mouseup", this.onMouseUp);

    let headerRow = this.nativeElement.querySelector('[mat-header-row]')
    let heigth = getComputedStyle(headerRow!).height

    let widthChanges = this.nativeElement.getElementsByClassName("widthChange")

    if(!widthChanges) return
    for(let i = 0 ; i < widthChanges.length ; i++) {

      this.renderer.setStyle(widthChanges.item(i), "height", heigth)
    }
  }

  mouseDown(event: MouseEvent, name: string) {
    this.resizeWidth = true

    this.startX = event.pageX;

    let locColumn = this.nativeElement.getElementsByClassName(`mat-column-${name}`)[0]
    this.currCol = name

    this.column = locColumn as HTMLElement

    this.startWidth = Number(getComputedStyle(locColumn!).width.replace("px", ""))
  }

  onMouseMove = (event: MouseEvent) => {
    if (this.resizeWidth){

      let newWidth = this.startWidth + (this.startX - event.pageX)*-1
      this.renderer.setStyle(this.column, "width", `${newWidth}px`)
    }
  }

  onMouseUp = (event: MouseEvent) => {
    if (this.resizeWidth) {
      this.resizeWidth = false;
      let newWidth = (this.startWidth + (this.startX - event.pageX) * -1);
      this.setPropAsHelper("width" + this.currCol, newWidth + "");
    }
  };

  /*
   *
   *  ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   * ██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   * ██║     ██║   ██║██║  ██║█████╗      ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
   * ██║     ██║   ██║██║  ██║██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   * ╚██████╗╚██████╔╝██████╔╝███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   *  ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
   *
   */

  public getTemplateString(tabs: string): string {
    let attrMap: Map<string, string> = new Map<string, string>()
    let children = []
    let childrenTabs = "\t"+tabs

    /*
      <table mat-table [dataSource]="dataSource">

        <ng-container *ngFor="let columnFieldName of columns; let i = index" matColumnDef="columnFieldName">
          <th mat-header-cell *matHeaderCellDef>TEXT</th>
          <td mat-cell *matCellDef="let element"> {{element[columnFieldName]}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>
    */
    let childrenL2: any[] = []
    let childrenL2Tabs = "\t"+childrenTabs

    const cols = JSON.parse(this.getProp('columns')) as number;
    Array.from(Array(cols).keys()).forEach((col, index) => {
      const columnFieldName = this.getProp(`fieldNameSort${index + 1}`);

      let colNum = index + 1

      const selectedDataPathsCall1 = this.getProp('selectedDataPathsCall1').replaceAll('results[0].', '').split(', ');

      // <th mat-header-cell *matHeaderCellDef>TEXT</th>
      attrMap.clear()
      attrMap.set("mat-header-cell", "")
      attrMap.set("*matHeaderCellDef", "")
      childrenL2.push(this.buildLeafTag(childrenL2Tabs, "th", attrMap, this.getProp("Col"+colNum+"Text")))

      // this.nativeElement.getElementsByClassName(`mat-column-${name}`)[0]

      // <td mat-cell *matCellDef="let element"> {{element[columnFieldName]}} </td>
      let currentPipe = this.getProp("columnFormat"+colNum)
      let hasCode = this.getProp(`Col${index+1}Code`) != ""
      attrMap.clear()
      attrMap.set("mat-cell", "")
      attrMap.set("*matCellDef", "let element")
      childrenL2.push(this.buildLeafTag(childrenL2Tabs, "td", attrMap,
        `{{${hasCode?"processCol"+colNum+'_'+this.getProp('displayName')+"(":""}element["${selectedDataPathsCall1[index] ?? columnFieldName}"]${hasCode?")":""}${currentPipe=='currency_de' ? " | currency:'EUR'":
                                               currentPipe=='number_de'   ? " | number:'1.2-2'":
                                               currentPipe=='date_de'     ? " | date:'dd.MM.yyyy'":""}}}`))

      // END CHILDRENL2

      // <ng-container *ngFor="let columnFieldName of columns; let i = index" [matColumnDef]="columnFieldName">

      attrMap.clear()
      attrMap.set("matColumnDef", selectedDataPathsCall1[index] ?? columnFieldName)
      children.push(this.buildNormalTag(childrenTabs, "ng-container", attrMap, childrenL2, ""))

      childrenL2 = []
    })

    // <tr mat-header-row *matHeaderRowDef="columns"></tr>
    attrMap.clear()
    attrMap.set("mat-header-row", "")
    attrMap.set("*matHeaderRowDef", `columns_${this.getProp('displayName')}`)
    children.push(this.buildLeafTag(childrenTabs, "tr", attrMap, ""))

    // <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    attrMap.clear()
    attrMap.set("mat-row", "")
    attrMap.set("*matRowDef", `let row; columns: columns_${this.getProp('displayName')};`)
    children.push(this.buildLeafTag(childrenTabs, "tr", attrMap, ""))

    // END CHILDREN
                                                attrMap.clear()
                                                attrMap.set("mat-table", "")
                                                attrMap.set("[dataSource]", `dataSource_${this.getProp('displayName')}`)
                                                attrMap.set("class", `${this.getProp('displayName')}`)
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())

    let result = this.buildNormalTag(tabs, "table", attrMap, children, "")
    return result
  }

  public getAtomicCode(): string {
    const apiCallGenerator = new ApiCallGenerator(this.dataService);
    apiCallGenerator.generate(this.dataService.projectContext.dataTable.get(this.id)!);

    let apiCall = +this.getProp("apiCalls") <= 0 ? false : true

    if(apiCall) this.elementService.addAboveCodeForApiCall()
    this.elementService.addAboveCodeForGermanPipeLocale()

    const fetchCall = `this.fetchCall_${this.getProp('displayName')}().then((result) => this.update_${this.getProp('displayName')}(this.columns_${this.getProp('displayName')}, result?.data ?? []));`;
    const ngOnInitCode =
`${apiCall ? `${this.getProp("preCall") === 'true' ? 'this.fetchPreCall()' : fetchCall }` : '' }`;

    if (!this.elementService.ngOnInitCode.includes(ngOnInitCode)) {
      this.elementService.ngOnInitCode += '\n    ' + ngOnInitCode;
    }

    let columnsF = [];
    for (let i = 0; i < +this.getProp('columns'); i++) {
      columnsF.push(this.getProp('fieldNameSort' + (i + 1)));
    }

    if (this.getProp('selectedDataPathsCall1')) {
      const selectedDataPathsCall1 = this.getProp('selectedDataPathsCall1').replaceAll('results[0].', '').split(', ');
      columnsF = [];
      for (let i = 0; i < selectedDataPathsCall1.length; i++) {
        columnsF.push(selectedDataPathsCall1[i]);
      }
    }

    let code =
`
  columns_${this.getProp('displayName')}: string[] = ['${columnsF.join("', '")}'];

  dataSource_${this.getProp('displayName')}: any[] = ${apiCall?"[]":JSON.stringify(this.getProp('data'))
                                                                  .replace(/\[\{/g, "[\n    {")
                                                                  .replace(/\}\,/g, "},\n    ")
                                                                  .replace(/\}\]/g, "}\n  ]")}
  ${apiCall ? `${apiCallGenerator.snippet}

  update_${this.getProp('displayName')}(columns: string[], dataArrayFromApiCall: any[]){

    if(!dataArrayFromApiCall[0]) return

    this.columns_${this.getProp('displayName')} = Object.keys(dataArrayFromApiCall[0])
    this.dataSource_${this.getProp('displayName')} = dataArrayFromApiCall
  }`:``}`

  const cols = JSON.parse(this.getProp('columns')) as number;
  Array.from(Array(cols).keys()).forEach( (column, index) => {

      if(this.getProp(`Col${index+1}Code`) != "") code += `
  processCol${index+1}_${this.getProp('displayName')}(cell: any) {
    cell = cell === 0 ? 0 : !cell ? '' : cell
    let result = cell
    ${this.getProp(`Col${index+1}Code`)}
    return result
  }
`
    })

    if(apiCall) this.elementService.addBelowCodeForApiCall()

    return code
  }

  public getAtomicStyle(styles?: string): string {
    let style = ""

    // Horizontal borders
    let hB = this.getProp("horizontalBorder") != '' && this.getProp("horizontalBorder") != "0"
    if(hB) style = style +
    `
    .${this.getProp('displayName')} tr.mat-row:not(:last-of-type) > td.mat-cell {
      border-bottom: solid 1px #0000001f;
    }
    `

    // Vertical borders
    let vB = this.getProp("verticalBorder")
    if(vB != '' && vB != "0") style = style +
    `
    .${this.getProp('displayName')} th.mat-header-cell:not(:last-of-type) {
      border-right: solid 1px #0000001f;
    }

    .${this.getProp('displayName')} td.mat-cell:not(:last-of-type) {
      border-right: solid 1px #0000001f;
    }
    `

    // Column widths and text aligns
    this.columns.forEach( (column, index) => {

      let widthString = ""
      let colWidth = this.getProp("width"+column)
      if(colWidth != ""){

        widthString = `\n      width: ${colWidth}px;`
      }

      let alignString = ""
      let colAlign = this.getProp("columnAlign"+(index+1))
      if(colAlign != ""){

        alignString = `\n      text-align: ${colAlign};`
      }

      if(colWidth == "" && colAlign == "") return

      style = style + `
    .${this.getProp('displayName')} .mat-column-${column} {
      ${widthString}${alignString}
    }
    `
    })

    // Cell paddings
    let leftPadding = "10"
    let rightPadding = "10"

    style = style + `
    // Header cells
    .${this.getProp('displayName')} th.mat-header-cell {
      padding-left: ${leftPadding}px;
      padding-right: ${rightPadding}px;
      ${hB?"border-bottom: solid 1px #0000001f;":"border-bottom: 0px;"}
    }
    `

    if(this.getStyleAttributesForInner() != "") {

      let innerStyles = this.getStyleAttributesForInner().split(';').join(`;\n\t`)
      style = style + `
.${this.getProp('displayName')} {

	${innerStyles.substring(0, innerStyles.length-2)}
}

`
    }

    // Standard-Styles
    let standardStyles = `

    th.mat-header-cell:first-of-type {
      padding-left: ${leftPadding}px;
    }

    th.mat-header-cell:last-of-type {
      padding-right: ${rightPadding}px;
    }

    // Data cells
    td.mat-cell {
      padding-left: ${leftPadding}px;
      padding-right: ${rightPadding}px;
      border-bottom: 0px;
    }

    .mdc-data-table__table {
      min-width: auto !important;
    }
    `
    if (!styles?.includes(standardStyles)) style = style + standardStyles

    return style
  }
}
