import { Component } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-disabled-menu',
  templateUrl: 'mat.disabled.menu.html'
})
export class MatDisabledMenu extends menu {

  renderOnlyOuter = false
  isTailwind = false
  isHelper = true
}
