import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-matChips',
  templateUrl: './matChips.component.html'
})
export class MatChipsComponent extends LayoutComponent {
  currentChipsAmount: number

  amounts = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6"
  ]

  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'MatChipsComponent';
  }

	setInitialProps(){

    this.ensureProp({key:"color", value: "basic", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Menge", value: "2", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label1", value: "A-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label2", value: "B-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label3", value: "C-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label4", value: "E-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label5", value: "S-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label6", value: "Maybach", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    // Detect changes
    this.elementService.changeDetector.detectChanges()
  }

  remove(i: any): void {
    let amountsIndex = this.amounts.indexOf(i)
    this.amounts.splice(amountsIndex,1)

    this.currentChipsAmount = Number(this.getProp('Menge')) - 1

    this.setProp({key:"Menge", value: String(this.currentChipsAmount), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
  }

  // =================
  // CODE
  // =================
  getAtomicCode(){
    if (this.getProp('removeButton')!=='1') return ''

    let chipsArray: string[] = []
    this.getAmount().forEach((i) => {
      chipsArray.push(this.getProp("Label" + i))
    })

    let code = `
    chipsArray_${this.getProp('displayName')}: string[] = ['${chipsArray.join("', '")}'];

    remove_${this.getProp('displayName')}(chipName: string): void {
      const index = this.chipsArray_${this.getProp('displayName')}.indexOf(chipName);

      if (index >= 0) {
        this.chipsArray_${this.getProp('displayName')}.splice(index, 1);
      }
  }`

    return code
  }

  // =================
  // STYLE
  // =================
  getAtomicStyle(styles?: string){
    let style = ""
    const chipStyle = `
    mat-chip {
      justify-content: space-between;
      display: flex;
    }
    `

    if (this.getProp('removeButton')=='1' && !styles?.includes(chipStyle)) {
      style = style + chipStyle
    }

    if(this.getStyleAttributesForInner() != "") {

      let innerStyles = this.getStyleAttributesForInner().split(';').join(`;\n\t`)
      style = style + `
.${this.getProp('displayName')} {

	${innerStyles.substring(0, innerStyles.length-2)}
}

`
    }

    return style
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getTemplateString(tabs: string): string {
    let attrMap: Map<string, string> = new Map<string, string>()
    let children: any[] = []
    let childrenTwo: any[] = []
    let childrenThree: any[] = []
    let childrenTabs = "\t"+tabs
    let childrenTwoTabs = "\t"+childrenTabs
    let childrenThreeTabs = "\t"+childrenTwoTabs

    if (this.getProp('removeButton') === '1') {
      attrMap.clear()
      childrenThree.push(this.buildNormalTag(childrenThreeTabs, "mat-icon", attrMap, [], 'cancel'))

      attrMap.clear()
      attrMap.set("matChipRemove", '')
      childrenTwo.push(this.buildNormalTag(childrenTwoTabs, "button", attrMap, childrenThree))

      attrMap.clear()
      attrMap.set("color", this.getProp('color'))
      attrMap.set("selected", '')
      attrMap.set("*ngFor", `let chip of chipsArray_${this.getProp('displayName')}`)
      attrMap.set("(removed)", `remove_${this.getProp('displayName')}(chip)`)

      if (this.getProp('disabled') === '1') {
        attrMap.set("disabled", "")
      }

      children.push(this.buildTagRightChildren(childrenTabs, "mat-chip", attrMap, [], '{{ chip }}', childrenTwo))
    } else {
      this.getAmount().forEach((i) => {
        attrMap.clear()
        attrMap.set("color", this.getProp('color'))
        attrMap.set("selected", '')
        if (this.getProp('disabled')=='1') {
          attrMap.set("disabled", "")
        }
        children.push(this.buildTagRightChildren(childrenTabs, "mat-chip", attrMap, [], this.getProp("Label"+i), childrenTwo))
      })
    }

    attrMap.clear()
    if (this.getProp('stacked') === '1') {
      attrMap.set("class", 'mat-chip-list-stacked')
    }
    if (this.getStyleAttributesForInner() !== "") {
      attrMap.set("class", this.getProp('displayName'))
    }

    let result = this.buildNormalTag(tabs, "mat-chip-listbox", attrMap, children, "")

    return result
  }

  getAmount(){
    if (this.currentChipsAmount < Number(this.getProp('Menge'))) {
      this.amounts = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ]
      this.currentChipsAmount = Number(this.getProp('Menge'));
    }

    return this.amounts.slice(0, Number(this.getProp('Menge')))
  }
}
