<div class="overlay"></div>

<div class="dialog">
	<mat-card style="display: flex; flex-direction: column; align-items: flex-start; padding: 20px; overflow: hidden;">

		<div style="width: 100%; display: flex; justify-content: space-between;">

			<h1 id="Heading_left" class="text-black">Add Trigger to {{automationsService.currentAutomation.name}}</h1>

			<!-- Close button -->
			<a (click)="emitDataAndClose()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
				<span class="mat-button-span">
					Schliessen
				</span>
			</a>

		</div>

		<div class="actionsArea">

			<div class="richText">
				<p><strong>Triggers</strong></p>
			</div>

			<div class="Layout column">

				<ng-container *ngFor="let triggerType of getTypes()">

					<div class="actionField" (click)="createTrigger(triggerType)">
						<div class="richText2">
							<pre><span style="font-family: arial, helvetica, sans-serif;">{{ getName(triggerType) }}</span></pre>
						</div>
					</div>

				</ng-container>

			</div>

		</div>

	</mat-card>
</div>
