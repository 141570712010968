import { SaveLayout } from '../projects';

export abstract class Generator {
  public abstract generate(component: SaveLayout): void;
}

export abstract class ComponentGenerator extends Generator {
  public template: string;
  public styles: string;
  public component: string;
}

export abstract class SnippetGenerator extends Generator {
  public snippet: string;
}

export abstract class FileGenerator {
  public abstract getFilename(): string;
  public abstract generate(): string;
}
