<div id="langchainAnker" [ngStyle]="{'display': !showImg ? 'revert': 'none'}"></div>

<div class="image-container" *ngIf="showImg">
	<div style="position: relative;">
		<img src="../../assets/magic.png" #placeholderImage alt="">
		<div class="rotation-container">
				<div class="glowing-ball"></div>
		</div>
	</div>
</div>

