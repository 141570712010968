import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'richText',
  templateUrl: 'richText.html',
})
export class richText extends LayoutComponent implements AfterViewInit {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService, public readonly sanitizer: DomSanitizer) {
    super(elementService, renderer, dataService);
    this.componentType = 'richText';
  }

  editor: any
  deselectAllowed = false
  isFocused = false

  public content: SafeHtml;

	private _code = ''
	public get code() {

		return this.getProp('code')
	}
	public set code(value) {

		this.setPropAsHelper('code', ''+value)
    this.content = this.sanitizer.bypassSecurityTrustStyle(this.getProp('code'))
    this.elementService.changeDetector.detectChanges()
	}

  public get style(): string { return this.getProp("style") }
  public set style(value: string) {

    if(value == "") return

    this.setProp(
      {key: 'style', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )

		this.renderStyle()
  }

  public ngAfterViewInit(): void {
    this.dataService.propertiesAreLoaded ? this.initProperties() : this.dataService.propertiesLoaded.subscribe(() => this.initProperties());
  }

  public initProperties(): void {
    if (this.getProp('code') === '') { this.setPropAsHelper('code', '<div>Hello World</div>') }

    this.content = this.sanitizer.bypassSecurityTrustHtml(this.getProp('code'))

    this.elementService.changeDetector.detectChanges()
  }

  renderStyle() {

		// Get the element with class mce-content-body
		const mce = document.querySelector('.mce-content-body') as HTMLElement

		const style = this.getProp('style')
  }

  /**
	 * # Listen to focus event
	 * @param event an event containing two properties:
	 * * event - The TinyMCE event object.
	 * * editor - A reference to the editor.
	 */
  public onFocus(event: any) {

		// For the del key. But maybe we need to ignore the richText alltogether
		// For example in a rich text dialog
		this.isFocused = true
		this.removeWarningMessage()
  }
	dismissedWarningMessage = false
	warningMessageLoops = 0

	removeWarningMessage(){

		if(this.dismissedWarningMessage || this.warningMessageLoops > 20) return

		setTimeout(() => {

			const element = (document.querySelector('.tox-promotion') as HTMLElement)

			if(element){

				this.dismissedWarningMessage = true;
				element.remove()
			}
			else {

				this.warningMessageLoops++
				this.removeWarningMessage()
			}
		}
		, 50)

	}

  /**
	 * # Listen to blur event
	 * @param event an event containing two properties:
	 * * event - The TinyMCE event object.
	 * * editor - A reference to the editor.
	 */
  public onBlur(event: any) {

		this.isFocused = false
  }

  handleBeingSelected(){

  }

  handleBeingDeSelected(){

  }

  // =================
  // CODE
  // =================
  getCodeTemplate() {
    const template =
``;
    return template
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getHtmlTemplate(tabs?: string): string {

		const template =
`<div class="$displayName">
	getProp("code")
</div>`

    return tabs ? tabs + template : template;
  }

}
