import { Injectable } from '@angular/core';
import { IpcRenderer, IpcRendererEvent } from 'electron';

@Injectable({
  providedIn: 'root'
})
export class IpcService {

  private _ipc: any;

  public isElectron = false;

  constructor() {

    if (!window.require) return

    this._ipc = (window as any).require('electron')?.ipcRenderer;

    if(this._ipc) this.isElectron = true;

    // console.log("ipc renderer: ")
    // console.log(this._ipc)

    // if (window.require) {

    //   try {

    //     this._ipc = window.require('electron').ipcRenderer;
    //   }
    //   catch (e) {

    //     throw e;
    //   }
    // } else {

    //   console.warn('Electron\'s IPC was not loaded');
    // }
  }

  public on(channel: string, listener: (event: Electron.IpcRendererEvent, ...args: any[]) => void): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.on(channel, listener);
  }

  public send(channel: string, ...args: any[]): void {

    if (!this._ipc) return

    this._ipc.send(channel, ...args);
  }
}
