import { Action } from '../action';

export class ApiCallAction extends Action {
  public url: string = '';
  public method: string = 'GET';
  public body: string = '{}';
  public headers: string = '{}';

  public action(): void {
    try {
      fetch(this.url, {
        method: this.method,
        headers: JSON.parse(this.headers),
        body: this.body
      });
    } catch (error) {
      console.error('ERROR ApiCallAction: ' + error);
    }
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      method: this.method,
      body: this.body,
      headers: this.headers
    }
  }
}
