<!-- SetProp(key, value, second, isTailwind, isHelper, RenderOnlyOuter) -->
<mat-form-field appearance="fill" [style]="'width: '+ width +';'">

  <mat-label translate>rightMenu.cursor</mat-label>
  <mat-select #cursorSelect
    (selectionChange)="setProp('cursor', cursorSelect.value, '', false, false, false)"
    [value]="getProp('cursor')"
  >
    <mat-option *ngFor="let cursor of cursors" [value]="cursor">{{cursor}}</mat-option>
  </mat-select>

  <button
    matPrefix
    mat-icon-button
    (click)="	elementService.removeProp('cursor');
    					cursorSelect.value = undefined"
  >
    <mat-icon>close</mat-icon>
  </button>

</mat-form-field>
