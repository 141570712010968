<action style="width: 100%;" [name]="'Download File'" [action]="action">

  <div class="element-selection-area" *ngIf="fileName !== undefined">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>File Name</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>File Name</mat-label>
      <input matInput type="text" [(ngModel)]="fileName">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="fileExtension !== undefined">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Final Extension</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>File Extension</mat-label>
      <input matInput type="text" [(ngModel)]="fileExtension">
    </mat-form-field>
  </div>

</action>
