    <!-- Amount -->
    <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
      <mat-label translate>rightMenu.quantity</mat-label>
      <mat-select #amountSelect
      (selectionChange)="setProp('Menge', amountSelect.value, '', false, true, false)"
      [value]="getProp('Menge')"
      >
        <mat-option *ngFor="let amount of amounts" [value]="amount">{{amount}}</mat-option>
      </mat-select>
    </mat-form-field>
