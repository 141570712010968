import { Component, Input } from '@angular/core';

import { ElementService } from '../../element.service';

@Component({
  selector: 'app-menu-slider',
  templateUrl: './menu-slider.component.html',
  styleUrls: ['./menu-slider.component.scss']
})
export class MenuSliderComponent {
  @Input() public width: string = '100%';
  @Input() public name: string = '';
  @Input() public second: string = '';

  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;

  public constructor(private readonly elementService: ElementService) {
  }

  public get inputValue(): string | undefined {
    const value = this.elementService.getProp(this.name);
    return value === '' ? undefined : value;
  }

  public set inputValue(value: string | undefined) {
    if (!value) { return; }
    this.elementService.setProp({ key: this.name, value: value, second: this.second, isHelper: false, isTailwind: false, renderOnlyOuter: false });
  }
}
