import { Component, Input } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, Trigger } from '../../../../automations';
import { MatButtonComponent } from '../../../../Angular Material/matButton/matButton.component';
import { CssButtonComponent } from '../../../../css-js Elements/cssButton/cssButton.component';

@Component({
  selector: 'button-clicked-trigger',
  templateUrl: './button-clicked.component.html',
  styleUrls: ['./button-clicked.component.scss']
})
export class ButtonClickedTriggerComponent {
  @Input()
  public trigger: Trigger;

  public buttons: (MatButtonComponent | CssButtonComponent)[] = [];

  public constructor(private readonly elementService: ElementService, private readonly automationsService: AutomationsService) {
  }

  public ngOnInit(): void {
    this.buttons = [ ...this.elementService.findElementsByType('MatButtonComponent'), ...this.elementService.findElementsByType('CssButtonComponent') ] as (MatButtonComponent | CssButtonComponent)[];
  }

  public get selectedButton(): MatButtonComponent | CssButtonComponent | undefined {
    const id = this.automationsService.currentAutomation.trigger?.saveLayoutId;
    if (!id) { return undefined; }
    const button = this.elementService.findElementById(id)[0] as MatButtonComponent | CssButtonComponent;
    return button;
  }

  public set selectedButton(button: MatButtonComponent | CssButtonComponent | undefined) {
    if (!button) { return; }
    if (!this.automationsService.currentAutomation.trigger) { return; }
    this.automationsService.currentAutomation.trigger.saveLayoutId = button.saveLayout.id;
    this.automationsService.saveAutomations(this.automationsService.currentLayout);
  }
}
