import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, SendEmailAction } from '../../../../automations';

@Component({
  selector: 'send-email-action',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailActionComponent {
  @Input()
  public action: SendEmailAction;

	@ViewChild('subjectField', { static: false })
	public richEditContainer: ElementRef;

	public richEditWidth: number;

  public constructor(public readonly automationsService: AutomationsService, public readonly elementService: ElementService) {
  }

	public ngAfterViewInit() {
		this.richEditWidth = this.richEditContainer.nativeElement.offsetWidth;
		this.elementService.changeDetector.detectChanges();
	}

  private serviceTimeout: NodeJS.Timeout;
  public get service(): string {
		return this.action.service;
	}
	public set service(value: string) {
		this.action.service = value;

		if (this.serviceTimeout) {
			clearTimeout(this.serviceTimeout);
		}
		this.serviceTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private hostTimeout: NodeJS.Timeout;
  public get host(): string {
		return this.action.host;
	}
	public set host(value: string) {
		this.action.host = value;

		if (this.hostTimeout) {
			clearTimeout(this.hostTimeout);
		}
		this.hostTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private portTimeout: NodeJS.Timeout;
  public get port(): string {
		return ''+this.action.port;
	}
	public set port(value: string) {
		this.action.port = +value;

		if (this.portTimeout) {
			clearTimeout(this.portTimeout);
		}
		this.portTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private secureTimeout: NodeJS.Timeout;
  public get secure(): string {
		return ''+this.action.secure;
	}
	public set secure(value: string) {
		this.action.secure = !!value;

		if (this.secureTimeout) {
			clearTimeout(this.secureTimeout);
		}
		this.secureTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private userTimeout: NodeJS.Timeout;
  public get user(): string {
		return this.action.user;
	}
	public set user(value: string) {
		this.action.user = value;

		if (this.userTimeout) {
			clearTimeout(this.userTimeout);
		}
		this.userTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private passwordTimeout: NodeJS.Timeout;
  public get password(): string {
		return this.action.password;
	}
	public set password(value: string) {
		this.action.password = value;

		if (this.passwordTimeout) {
			clearTimeout(this.passwordTimeout);
		}
		this.passwordTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private fromTimeout: NodeJS.Timeout;
  public get from(): string {
		return this.action.from;
	}
	public set from(value: string) {
		this.action.from = value;

		if (this.fromTimeout) {
			clearTimeout(this.fromTimeout);
		}
		this.fromTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private toTimeout: NodeJS.Timeout;
  public get to(): string {
		return this.action.to;
	}
	public set to(value: string) {
		this.action.to = value;

		if (this.toTimeout) {
			clearTimeout(this.toTimeout);
		}
		this.toTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private subjectTimeout: NodeJS.Timeout;
  public get subject(): string {
		return this.action.subject;
	}
	public set subject(value: string) {
		this.action.subject = value;

		if (this.subjectTimeout) {
			clearTimeout(this.subjectTimeout);
		}
		this.subjectTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private textTimeout: NodeJS.Timeout;
  public get text(): string {
		return this.action.text;
	}
	public set text(value: string) {
		this.action.text = value;

		if (this.textTimeout) {
			clearTimeout(this.textTimeout);
		}
		this.textTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

	public onFocus() {
		this.removeWarningMessage();
  }

	public dismissedWarningMessage = false;
	public warningMessageLoops = 0;

	public removeWarningMessage() {
		if (this.dismissedWarningMessage || this.warningMessageLoops > 20) { return; }

		setTimeout(() => {
			const element = (document.querySelector('.tox-notification__dismiss') as HTMLElement);

			if (element) {
				this.dismissedWarningMessage = true;
				element.click();
			} else {
				this.warningMessageLoops++;
				this.removeWarningMessage();
			}
		}, 50);
	}
}
