import { Component, Input } from '@angular/core';

import { AutomationsService, FileUploadedTrigger, SplitAndTrimAction } from '../../../../automations';
import { ElementService } from '../../../../element.service';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   Split and Trim                                    |
           |   ===============================================   |
           |   * an action											                 |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'split-and-trim-action',
  templateUrl: './split-and-trim.component.html',
  styleUrls: ['./split-and-trim.component.scss']
})
export class SplitAndTrimActionComponent {

	private _action: SplitAndTrimAction;
	public get action(): SplitAndTrimAction {
		return this._action;
	}
	@Input()
	public set action(value: SplitAndTrimAction) {
		this._action = value;
		this._action.uiClass = this;
		console.log('action and component now know each other')
	}

  public constructor(
		public readonly automationsService: AutomationsService,
		public readonly elementService: ElementService,) {
  }

	ngOnInit(): void {

		const trigger = this.automationsService.currentAutomation.trigger as FileUploadedTrigger
		if(!trigger) return

		this.action.getAllStuff()

		// Get all the sheets, every time the trigger element is selected
		trigger.elementSelected.subscribe((element) => {

			this.action.getAllStuff()
		})
	}

}
