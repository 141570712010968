import { Component, Input } from '@angular/core';
import { menu } from '../menu/menu';
import { ElementService } from '../../element.service';

@Component({
  selector: 'app-menu-unit-input',
  templateUrl: './menu-unit-input.component.html'
})
export class MenuUnitInputComponent extends menu {

  @Input() renderOnlyOuter = false
  @Input() isHelper = false
  @Input() disabled = false

  constructor(
    public elementService: ElementService
  ){

    super(elementService)
  }

  @Input() width = "0"
  @Input() name = ""
  @Input() shortName = ""

  private _inputValue: any
  public get inputValue(): number {

    let stringValue = this.elementService.getProp(this.name)

    this._inputValue = stringValue

    return this._inputValue;
  }
  public set inputValue(value: number) {

    let stringValue = ""
    if(value) stringValue = value.toString()

    this.elementService.setProp({
      key: this.name,
      value: stringValue,
      second: this.unit,
      isTailwind: false,
      isHelper: false,
      renderOnlyOuter: this.renderOnlyOuter
    })

    this._inputValue = value;
  }

  unit = "px"

  public switchUnitProperty(){

    this.unit=='px'?this.unit='%':this.unit='px'

    this.elementService.setProp({
      key: this.name,
      value: this.inputValue.toString(),
      second: this.unit,
      isTailwind: false,
      isHelper: false,
      renderOnlyOuter: this.renderOnlyOuter
    })
  }

  getUnitProperty(): string {

    let prop = this.elementService.getPropObj(this.name)

    if (prop && prop.second != ""){

      this.unit = prop.second
      return this.unit
    }

    // prop == undefined || prop.unit == ""
    else if(!this.disabled) {

      // console.log("Setting Unit Property for " + this.name + " to 'px'")
      this.elementService.setProp({
        key: this.name,
        value: this.inputValue.toString(),
        second: this.unit,
        isTailwind: false,
        isHelper: false,
        renderOnlyOuter: this.renderOnlyOuter
      })

      return this.unit
    }
		else return ""
  }
}
