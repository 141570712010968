<div *ngIf="elementService?.allOfType(name)">

  <h2>Slide Toggle {{'general.menu' | translate}}:</h2>

  <!-- TEXT -->
  <app-menu-input [width]="'100%'" [name]="'text'" [isHelper]="true"></app-menu-input>

  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- COLOR -->
    <mat-color-menu style="width: 49%;"></mat-color-menu>

    <!-- Labelposition -->
    <mat-LabelPosition-menu style="width: 49%;"></mat-LabelPosition-menu>
  </div>

  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- DISABLED? -->
    <mat-disabled-menu style="width: 49%;"></mat-disabled-menu>

    <!-- Checked? -->
    <mat-toggle-menu [name]="'checked'"></mat-toggle-menu>
  </div>

  <!-- OnChange -->
  <app-menu-input [width]="'100%'" [name]="'onChange'" [isHelper]="true"></app-menu-input>
</div>
