<div *ngIf="elementService?.allOfType('richText')">

	<h2>Rich-Text-Menü:</h2>

	<!-- HTML -->
	<div class="editor-tooltip"></div>
	<ngx-monaco-editor
		id="monacoEditor"
		*ngIf="elementService.selectedObjects[0] as selected"
		class="editor-container"
		[options]="editorOptions1"
		[(ngModel)]="inputValueCode"
		(onInit)="onInit($event, selected)"
	>
	</ngx-monaco-editor>

	<!-- Style -->
	<mat-form-field
		formula
		appearance="fill"
		style="width: 99%; position: relative; z-index: 1; left: 3px"
	>
		<button
			matPrefix
			mat-icon-button
			(click)="elementService.removeProp('style'); inputValueStyle = undefined"
		>
			<mat-icon>close</mat-icon>
		</button>

		<mat-label translate>Style</mat-label>

		<input
			matInput
			type="text"
			[(ngModel)]="inputValueStyle"
			(focus)="   elementService.tfFocussed(true)"
			(focusout)="elementService.tfFocussed(false)"
		/>
	</mat-form-field>
</div>
