import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-matRadio',
  templateUrl: './matRadio.component.html'
})
export class MatRadioComponent extends LayoutComponent {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'MatRadioComponent';
  }

	setInitialProps(){

    this.ensureProp({key:"Menge", value: "2", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"display", value: "flex", second: "", isTailwind: false, isHelper: false, renderOnlyOuter: false})
    this.ensureProp({key:"gap", value: "gap", second: "1", isTailwind: true, isHelper: false, renderOnlyOuter: false})
    this.ensureProp({key:"Labelposition", value: "dahinter", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label1", value: "A-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label2", value: "B-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label3", value: "C-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label4", value: "E-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label5", value: "S-Klasse", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.ensureProp({key:"Label6", value: "Maybach", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    this.elementService.changeDetector.detectChanges()
  }

  amounts = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6"
  ]

  getAmount(){

    return this.amounts.slice(0, Number(this.getProp('Menge')))
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getTemplateString(tabs: string): string {

    let attrMap: Map<string, string> = new Map<string, string>()
    let children: any[] = []
    let childrenTabs = "\t"+tabs

    /*
      <mat-radio-group [labelPosition]="getLabelposition()" [disabled]="getProp('disabled')=='1'">
        <mat-radio-button *ngFor="let amount of getAmount(); let i = index" [value]="amount">
          {{getValue(i)}}
        </mat-radio-button>
      </mat-radio-group>
    */
    /*
      <mat-radio-button *ngFor="let amount of getAmount(); let i = index" [value]="amount">
        {{getValue(i)}}
      </mat-radio-button>
      getProp('flex-direction') == 'column'
    */
    this.getAmount().forEach((i) => {

      attrMap.clear()
      attrMap.set("value", i)
      children.push(this.buildLeafTag(childrenTabs, "mat-radio-button", attrMap, this.getProp("Label"+i)))
    })

    // END CHILDREN
    // <mat-radio-group [labelPosition]="getLabelposition()" [disabled]="getProp('disabled')=='1'">
                                                attrMap.clear()
                                                attrMap.set("labelPosition", this.getLabelPos())
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())
    if(this.getProp('disabled')=='1')           attrMap.set("disabled", "")
    if(this.getStyleAttributesForInner() != "")     attrMap.set("class", this.getProp('displayName'))

    let result = this.buildNormalTag(tabs, "mat-radio-group", attrMap, children, "")
    return result
  }

  getLabelPos(): "before" | "after" {

    return this.getProp("Labelposition") == "davor" ? "before" : "after"
  }
}

