import { SaveLayout } from './entities/save-layout.entity';
import { Property } from './entities/property.entity';
import { PropertiesService } from './properties.service';

export class PropertyIterator {
  private readonly service: PropertiesService;
  private readonly saveLayout: SaveLayout;

  private properties: Property[];
  private counter: number = 0;

  public constructor(service: PropertiesService, saveLayout: SaveLayout) {
    this.service = service;
    this.saveLayout = saveLayout;
  }

  public [Symbol.iterator]() {
    return {
      next: () => {
        if (!this.properties) {
          this.properties = this.service.cacheService.properties.filter(property => property.saveLayout.id === this.saveLayout.id);
        }
        const property = this.properties[this.counter++];
        return { done: this.counter > this.properties.length, value: property };
      }
    };
  }

  public forEach(callback: (value: Property, index: number, array: Property[]) => void): void {
    const properties = this.service.cacheService.properties.filter(property => property.saveLayout.id === this.saveLayout.id);
    for (let index = 0; index < properties.length; index++) {
      const property = properties[index];
      if (property) {
        callback(property, index, properties);
      }
    }
  }
}
