<div *ngIf="elementService?.allOfType('xSpreadsheet')">

  <h2>Chart Menü:</h2>

  <!-- Title of the chart -->
  <app-menu-input [width]="'100%'" [name]="'label'" [isHelper]="true"></app-menu-input>

  <!-- Type -->
  <mat-form-field appearance="fill" style="width: 49%;">
    <mat-label>Type</mat-label>
    <mat-select #typeSelect
      (selectionChange)="setProp('type', typeSelect.value, '', true)"
      [value]="getProp('type')"
    >
      <mat-option value="bar">Balken</mat-option>
      <mat-option value="pie">Kuchen</mat-option>
      <mat-option value="doughnut">Donut</mat-option>
      <mat-option value="line">Linie</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Column names -->
  <app-menu-input [width]="'100%'" [name]="'xAxisLabels'" [isHelper]="true"></app-menu-input>

  <!-- Data -->
  <app-menu-input [width]="'100%'" [name]="'data'" [isHelper]="true"></app-menu-input>

  <!-- border width -->
  <mat-form-field appearance="fill" [style]="'width: '+ getProp('borderWidth') +';'">
    <mat-label translate>rightMenu.borderWidth</mat-label>
    <input
      #borderWidth
      type="number"
      matInput
      [value]="getProp('borderWidth')"
      (input)="setProp('borderWidth', borderWidth.value)">
  </mat-form-field>

  <!-- (HOVER)- Text Color -->

  <!-- API Call -->
  <a mat-raised-button color="primary" (click)="showApiCallDialog = true" style="margin-left: 5px">
		<span class="mat-button-span">API Call</span>
	</a>
  <apiCallDialog *ngIf="showApiCallDialog"
                  [elementType]="getType()"
                  (returnColumns)="columnsArrayFromApiCall = $event"
                  (returnData)="dataArrayFromApiCall = $event"
                  (closed)="closeApiCallDialog($event)"></apiCallDialog>

  <!--  BORDER  -->
  <app-menu-border></app-menu-border>
</div>
