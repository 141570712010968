<div class="overlay"></div>

<div class="dialog">
  <mat-card>
    <button mat-raised-button style="position: absolute; right: 10px; top: 10px; z-index: 3;" color="primary" (click)="close()"><span translate>general.close</span></button>
    <div style="display: flex; flex-direction: column; padding: 10px;">
      <p class="text-black" translate>
        download.downloadJSON
      </p>
      <div style="display: flex; align-items: flex-start;">
        <mat-form-field appearance="fill">
          <mat-label translate>
            download.fileName
          </mat-label>
          <input matInput type="text" placeholder="Cool Layout" [(ngModel)]="fileName">
          <mat-hint align="start">
            {{'download.withoutName' | translate}} -> "Cool Layout.json"
          </mat-hint>
          <span matSuffix>
            .json
          </span>
        </mat-form-field>
        <a style="margin-left: 20px; margin-top: 5px; width: 40px;" mat-mini-fab color="warn" (click)="triggerDownload()">
          <mat-icon>
            download
          </mat-icon>
        </a>
      </div>
    </div>
  </mat-card>
</div>
