import { Component, Renderer2 } from '@angular/core'
import Dropzone, { DropzoneFile } from 'dropzone'

import { LayoutComponent } from '../layout/layout.component'
import { ElementService } from '../element.service'
import { DataService } from '../data.service'
import { FileUploadedTrigger } from '../automations'
import { ExcelService } from '../excel.service'

@Component({

  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends LayoutComponent {

  private dropzone: Dropzone

	showLoadingDialog = false
  public uploads: any[] = []

  public constructor(
		public readonly elementService: ElementService,
		public readonly renderer: Renderer2,
		public readonly dataService: DataService,
		private excelService: ExcelService
		) {

    super(elementService, renderer, dataService)
    this.componentType = 'fileUpload'
  }

  public ngAfterViewInit(): void {

    this.dropzone = new Dropzone(`div#${this.getProp('displayName')}_dropzone`, {

      url: '/dummy/url',
      thumbnailWidth: 80,
      thumbnailHeight: 80,
      dictDefaultMessage: '',
      autoProcessQueue: false,
      previewTemplate: `
        <div
          class="dz-preview dz-file-preview"
          style="color: #000000 background-color: #cccccc border-radius: 4px margin: 8px padding: 16px display: flex flex-direction: row align-items: center justify-content: center gap: 4px"
        >
          <div class="dz-details">
            <div class="dz-filename">
              <span data-dz-name></span>
            </div>
          </div>
          <div>
            <button
              class="dz-remove"
              style="border-radius: 25px font-size: 0.75em"
            >
              X
            </button>
          </div>
        </div>`
    })

    this.dropzone.on('addedfile', (file: DropzoneFile) => {

			this.showLoadingDialog = true

			// Gib dem Inhalt der Datei in der Konsole aus
			// const reader = new FileReader();
			// reader.onload = (e) => {

			// 	const data = e.target?.result;
			// 	console.log(data);
			// };
			// reader.readAsText(file);

      this.uploads.push(file)
      this.setProp({ key: 'files', value: JSON.stringify(this.uploads), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false })

			const dzRemove = file.previewElement.querySelector('.dz-remove')
      dzRemove ? (dzRemove as HTMLElement).onclick = () => { this.dropzone.removeFile(file) } : null

			console.log('file', file, "was added")

			// Übergebe den Inhalt der Datei an den ExcelService
			this.excelService.onSelectFile(file, this, (excelData) => {
        this.triggers.forEach((triggerId: string) => {

          const trigger = this.findTrigger(triggerId);
          if (trigger) {

            const fileUploadedTrigger = trigger as FileUploadedTrigger
            fileUploadedTrigger.input = excelData
            fileUploadedTrigger.triggerAction()
          }
        })
      })
    })

    this.dropzone.on('removedfile', (file: any) => {

      const index = this.uploads.indexOf(file)
      if (index > -1) {
        this.uploads.splice(index, 1);
      }
    })
  }

  public getHtmlTemplate(): string {
    const code =
`<div class="app-dropzone-wrapper">
  <div [id]="'getProp('displayName')' + '_dropzone'" class="app-dropzone-container">
    <p>Drag 'n' Drop</p>
  </div>
</div>`
    return code;
  }

  public getCssTemplate(): string {

		const styles = this.getStyleAttributesForInner().split(';').join(';\n\t')

    const code =
`#${this.getProp('displayName')}_dropzone {

  ${styles.substring(0, styles.length-2)}}
}

.app-dropzone-wrapper {
  padding: 16px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 3px;
  width: 100%;
  display: inline-block;
  background-color: #ffffff;
}

.app-dropzone-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}`;
    return code;
  }

  public getCodeTemplate(): string {
    const importCode =
`import Dropzone, { DropzoneFile } from 'dropzone'

import { FileUploadedTrigger } from './automations.service';`;

    if (!this.elementService.aboveCode.includes(importCode)) {
      this.elementService.aboveCode += importCode;
    }

    const code =
`private dropzone!: Dropzone;

public uploads: any[] = [];
public triggers: string[] = [];

public ngAfterViewInit(): void {
  this.dropzone = new Dropzone(\`div#${this.getProp('displayName')}_dropzone\`, {

    url: '/dummy/url',
    thumbnailWidth: 80,
    thumbnailHeight: 80,
    dictDefaultMessage: '',
    autoProcessQueue: false,
    previewTemplate: \`
      <div
        class="dz-preview dz-file-preview"
        style="color: #000000 background-color: #cccccc border-radius: 4px margin: 8px padding: 16px display: flex flex-direction: row align-items: center justify-content: center gap: 4px"
      >
        <div class="dz-details">
          <div class="dz-filename">
            <span data-dz-name></span>
          </div>
        </div>
        <div>
          <button
            class="dz-remove"
            style="border-radius: 25px font-size: 0.75em"
          >
            X
          </button>
        </div>
      </div>\`
  });

  this.dropzone.on('addedfile', (file: DropzoneFile) => {
    this.uploads.push(file)

    const dzRemove = file.previewElement.querySelector('.dz-remove')
    dzRemove ? (dzRemove as HTMLElement).onclick = () => { this.dropzone.removeFile(file) } : null

    this.excelService.onSelectFile(file, this, (excelData) => {
      this.triggers.forEach((triggerId: string) => {
        const trigger = this.automationsService.automations.find(automation => automation.trigger?.id === triggerId)?.trigger
        if (trigger) {
          const fileUploadedTrigger = trigger as FileUploadedTrigger
          fileUploadedTrigger.input = excelData
          fileUploadedTrigger.triggerAction()
        }
      });
    });
  });

  this.dropzone.on('removedfile', (file: any) => {
    const index = this.uploads.indexOf(file)
    if (index > -1) {
      this.uploads.splice(index, 1);
    }
  });

  this.automationsService.reRegisterTriggers(this);
}

public addTrigger(fileUploadedTrigger: FileUploadedTrigger): void {
  if (!this.triggers.includes(fileUploadedTrigger.id)) {
    this.triggers.push(fileUploadedTrigger.id);
  }
}

public removeTrigger(fileUploadedTrigger: FileUploadedTrigger): void {
  const index = this.triggers.indexOf(fileUploadedTrigger.id);
  if (index > -1) {
    this.triggers.splice(index, 1);
  }
}`;

    return code;
  }
}
