import { DataService } from '../../../data.service';
import { FileGenerator } from '../../code-generation.model';

export class StylesGenerator extends FileGenerator {
  public constructor(private readonly dataService: DataService) {
    super();
  }

  public getFilename(): string {
    return 'src/styles.scss';
  }

  public generate(): string {
    return this.dataService.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban')
      ? this.generateContent()
      :
`html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}`
  }

  public generateContent(): string {
    const styles =
`@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
`;

    return styles;
  }
}
