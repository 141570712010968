import { SnippetGenerator } from '../../code-generation.model';
import { SaveLayout } from '../../../projects';
import { DataService } from '../../../data.service';

export class ApiCallGenerator extends SnippetGenerator {
  public constructor(private readonly dataService: DataService) {
    super();
  }

  public generate(component: SaveLayout): void {
    let result =
`fetchCall_${this.dataService.treeSearchService.getProp(component, 'displayName')}() {
  let result: { columns: string[], data: string[] } = { columns: [], data: [] };
  
  `;

    for (let index = 1; index <= +this.dataService.treeSearchService.getProp(component, 'apiCalls'); index++) {
      result += this.generateFetchDataForChart(component, index);
    }

    result += `
}
`;

    this.snippet = result;
  }

  private generateFetchDataForChart(component: SaveLayout, index: number): string {
    const callType = this.dataService.treeSearchService.getProp(component, `call_type${index}`) ?? 'GET';
    const url = this.dataService.treeSearchService.getProp(component, `url${index}`);
    const headers = this.dataService.treeSearchService.getProp(component, `call${index}Headers`) ?? 'undefined';
    const body = this.dataService.treeSearchService.getProp(component, `body${index}`) ?? 'undefined';
    const selectedDataPathsCall = this.dataService.treeSearchService.getProp(component, `selectedDataPathsCall${index}`);
    const selectedColumnPathsCall = this.dataService.treeSearchService.getProp(component, `selectedColumnPathsCall${index}`);

    const result =
`return fetchDataForChart('${callType}', '${url}', '${headers.split('#header#')[0]}', '${headers.split('#header#')[1]}', '${body}').then((responseData) => {
  if (!responseData) { return; }

  let columns: string[] = [];
  let data: string[] = [];

  ${selectedDataPathsCall.includes('[0]') && selectedDataPathsCall.split('[0]')[0] !== '' ? `responseData["${selectedDataPathsCall.split('[0]')[0]}"]` : 'responseData.results'}.forEach((item: any) => {${
    // For selected column fields, see if a selectedColumnPrefix exists
    // -> selectedColumnPrefix = this.getProp('selectedColumnPathsCall1').split("[0]")[0]
    selectedColumnPathsCall !== '' ? `
    let columnValue = _.get(item, '${selectedColumnPathsCall.split(selectedColumnPathsCall.split('[0]')[0] + '[0].')[1]}');
    columns.push(columnValue);` : ''
  }
    let selectedDataFieldPaths = [
      '${selectedDataPathsCall.split(', ').join(`',\n      '`)}'
    ];
    let resultObj: any = {};

    selectedDataFieldPaths.forEach(path => {
      let pathAfterPrefixForData = path.split('${selectedDataPathsCall.split('[0]')[0]}[0].')[1];
      let dataValue = _.get(item, pathAfterPrefixForData);
      resultObj[pathAfterPrefixForData] = dataValue;
    });

    data.push(resultObj);
  });

  result = { columns: columns, data: data };
  return result;
});
`;
    return result;
  }
}
