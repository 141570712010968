<div *ngIf="elementService?.allOfType('sf-button')">

  <!-- text -->
  <app-menu-input [width]="'100%'" [name]="'text'" [isHelper]="true"></app-menu-input>

  <!-- is primary? -->
  <!-- logic: The (change) event is fired after the toggle is already changed
      Example: It is currently checked and the user clicks on it, then is is not checked anymore
              Then the (change) event fires and sees that itself is currently NOT CHECKED
              and calls the setProp() accordingly. -->
  <p style="margin-left: 10px;">
    <mat-slide-toggle
      #primaryToggle
      (change)="setProp('isPrimary', primaryToggle.checked?'1':'0')"
      [checked]="getProp('isPrimary')=='1'"
    >
      Primary?
    </mat-slide-toggle>
  </p>
	<!-- [disabled]="typeSelect.value == 'icon' || typeSelect.value == 'fab' || typeSelect.value == 'mini-fab'" -->

  <!-- Type -->
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.type</mat-label>
    <mat-select #typeSelect
      (selectionChange)="setProp('type', typeSelect.value)"
      [value]="getProp('type')"
    >
      <mat-option value="normal">		<button ejs-button [isPrimary]="getProp('isPrimary') == '1'">Normal</button>												</mat-option>
      <mat-option value="outline">	<button ejs-button [isPrimary]="getProp('isPrimary') == '1'" cssClass="e-outline">Outline</button>	</mat-option>
      <mat-option value="flat">			<button ejs-button [isPrimary]="getProp('isPrimary') == '1'" cssClass="e-flat">Flat</button>				</mat-option>
      <mat-option value="success">	<button ejs-button cssClass="e-success">Success</button>	</mat-option>
      <mat-option value="warning">	<button ejs-button cssClass="e-warning">Warning</button>	</mat-option>
      <mat-option value="danger">		<button ejs-button cssClass="e-danger">Danger</button>		</mat-option>
      <mat-option value="info">			<button ejs-button cssClass="e-info">Info</button>				</mat-option>
    </mat-select>
  </mat-form-field>
</div>