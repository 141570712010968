import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import _ from 'lodash'

/*
  The htmlViewer offers a view and selection for HTML-Responses from API-Calls.
  It is used in the ApiCallDialog to show the response of the API-Call.
  The magic is, that it returns the path of the clicked element.
*/
@Component({
  selector: 'htmlViewer',
  templateUrl: './htmlViewer.html',
  styleUrls: ['./htmlViewer.scss']
})
export class htmlViewer implements OnChanges {

  @Input() responseBody: any
  @Input() htmlViewerNumber: number
  @Input() highlightColor: string
  @Input() lengthRestriction: number = 0

  @Output() setSelectedPaths: EventEmitter<string[]> = new EventEmitter<string[]>()

  constructor(
      private sanitizer: DomSanitizer,
    ) {}


  /*
██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ███████╗
██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██╔════╝
███████║███████║██╔██╗ ██║██║  ██║██║     █████╗
██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██╔══╝
██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗███████╗
╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚══════╝

██╗  ██╗████████╗███╗   ███╗██╗         ██████╗ ███████╗███████╗██████╗  ██████╗ ███╗   ██╗███████╗███████╗
██║  ██║╚══██╔══╝████╗ ████║██║         ██╔══██╗██╔════╝██╔════╝██╔══██╗██╔═══██╗████╗  ██║██╔════╝██╔════╝
███████║   ██║   ██╔████╔██║██║         ██████╔╝█████╗  ███████╗██████╔╝██║   ██║██╔██╗ ██║███████╗█████╗
██╔══██║   ██║   ██║╚██╔╝██║██║         ██╔══██╗██╔══╝  ╚════██║██╔═══╝ ██║   ██║██║╚██╗██║╚════██║██╔══╝
██║  ██║   ██║   ██║ ╚═╝ ██║███████╗    ██║  ██║███████╗███████║██║     ╚██████╔╝██║ ╚████║███████║███████╗
╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚══════╝
  */

  safeHtml: SafeHtml | undefined = undefined

  ngOnChanges() {

    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.responseBody)
  }

  selectedFieldPaths: string[] = []
  data: string[] = []
  /**
   * ### Handle click
   * * store the clicked Paths
   * * store the data of the selected element
   *
   * **_emits_** both to the apiCall
   * @param event The click event
   */
  handleClickInHtmlResponse(event: MouseEvent): void {

    let highlighting = '2px dashed ' + this.highlightColor

    // Get the clicked element
    const target = event.target as HTMLElement

    // Get the Path of the clicked element
    let path = this.getJsPath(target)
    this.selectedFieldPaths.push(path)

    // Get and Trim the text
    let text = target.innerText.trim()

    // It was already clicked
    if(target.style.border == highlighting){

      // Remove the green/blue dashed border
      target.style.border = 'none'

      // Remove the clicked element from the data array
      this.data.splice(this.data.findIndex(item => item === text), 1)
    }
    // It was not clicked -> save data and style
    else
    {
      this.data.push(text)
      target.style.border = highlighting
    }

    this.setSelectedPaths.emit(this.selectedFieldPaths)
  }

  // END HANDLE HTML RESPONSE

  /**
   * ### Get's the JavaScript Path of an element.
   * Later you can retrieve the HTML element by evaluating the JS Path in your code:
   *
   * `let element = eval(jsPath);`
   * @param element The element to get the path from
   * @returns Something like `document.body.children[1].children[0].children[0]`
   */
  getJsPath(element: Element): string {

    if (element.id !== '') return 'document.getElementById("' + element.id + '")'

    if (element === document.body) return element.tagName

    let ix = 0
    const siblings = element.parentNode ? element.parentNode.childNodes : []
    for (let i = 0; i < siblings.length; i++) {

        const sibling = siblings[i]

        if (sibling === element) return this.getJsPath(element.parentNode as HTMLElement) + '.children[' + ix + ']'

        if (sibling.nodeType === 1 && (sibling as HTMLElement).tagName === element.tagName) ix++
    }

    return ''
  }

}
