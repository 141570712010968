<div class="overlay"></div>

<div class="dialog">
  <mat-card>
    <button mat-raised-button style="position: absolute; right: 10px; top: 10px; z-index: 3;" color="primary" (click)="close()"><span translate>general.close</span></button>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">

      <mat-tab *ngFor="let label of ['Template', 'Script', 'Stylesheet']; let i = index" [label]="label">
        <mat-card-subtitle style="display: flex; flex-direction: column;">
          <ng-container *ngTemplateOutlet="editor; context: { index: i }"></ng-container>
        </mat-card-subtitle>
      </mat-tab>

    </mat-tab-group>
  </mat-card>
</div>

<ng-template #editor let-index="index">
  <div class="upside">
    <a
      *ngFor="let property of elementService.selectedObjects[0]?.properties"
      style="cursor: pointer; background-color: rgb(184, 255, 184); border-radius: 7px; border-color: rgb(125, 205, 125); border-style: solid; border-width: 1px; padding: 3px;"
      (click)="insertProperty(property.key)"
    >
      {{property.key}}: {{property.value}}{{property.second?'-'+property.second:''}}
    </a>
  </div>

  <div style="display: flex;">

    <!-- Left Side -->
    <div style="display: flex; width: 50%; flex-direction: column;">
      <div style="display: flex;">
        <a style="font-size: x-large; margin-bottom: 5px;">Template:</a>
        <svg (click)="copyText(sourceCodes[index])" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M15 1.25h-4.056c-1.838 0-3.294 0-4.433.153c-1.172.158-2.121.49-2.87 1.238c-.748.749-1.08 1.698-1.238 2.87c-.153 1.14-.153 2.595-.153 4.433V16a3.751 3.751 0 0 0 3.166 3.705c.137.764.402 1.416.932 1.947c.602.602 1.36.86 2.26.982c.867.116 1.97.116 3.337.116h3.11c1.367 0 2.47 0 3.337-.116c.9-.122 1.658-.38 2.26-.982c.602-.602.86-1.36.982-2.26c.116-.867.116-1.97.116-3.337v-5.11c0-1.367 0-2.47-.116-3.337c-.122-.9-.38-1.658-.982-2.26c-.531-.53-1.183-.795-1.947-.932A3.751 3.751 0 0 0 15 1.25Zm2.13 3.021A2.25 2.25 0 0 0 15 2.75h-4c-1.907 0-3.261.002-4.29.14c-1.005.135-1.585.389-2.008.812c-.423.423-.677 1.003-.812 2.009c-.138 1.028-.14 2.382-.14 4.289v6a2.25 2.25 0 0 0 1.521 2.13c-.021-.61-.021-1.3-.021-2.075v-5.11c0-1.367 0-2.47.117-3.337c.12-.9.38-1.658.981-2.26c.602-.602 1.36-.86 2.26-.981c.867-.117 1.97-.117 3.337-.117h3.11c.775 0 1.464 0 2.074.021ZM7.408 6.41c.277-.277.665-.457 1.4-.556c.754-.101 1.756-.103 3.191-.103h3c1.435 0 2.436.002 3.192.103c.734.099 1.122.28 1.399.556c.277.277.457.665.556 1.4c.101.754.103 1.756.103 3.191v5c0 1.435-.002 2.436-.103 3.192c-.099.734-.28 1.122-.556 1.399c-.277.277-.665.457-1.4.556c-.755.101-1.756.103-3.191.103h-3c-1.435 0-2.437-.002-3.192-.103c-.734-.099-1.122-.28-1.399-.556c-.277-.277-.457-.665-.556-1.4c-.101-.755-.103-1.756-.103-3.191v-5c0-1.435.002-2.437.103-3.192c.099-.734.28-1.122.556-1.399Z" clip-rule="evenodd"/></svg>
      </div>
      <ngx-monaco-editor [options]="editorLeftOptions" [(ngModel)]="sourceCodes[index]" (onInit)="onInit($event)" (ngModelChange)="onCodeChange($event, index)" style="height: 80vh; width: 100%;"></ngx-monaco-editor>
    </div>

    <!-- Right Side -->
    <div style="display: flex; width: 50%; flex-direction: column;">
      <div style="display: flex;">
        <a style="font-size: x-large; margin-bottom: 5px;">Result:</a>
        <svg (click)="copyText(generatedCodes[index])" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M15 1.25h-4.056c-1.838 0-3.294 0-4.433.153c-1.172.158-2.121.49-2.87 1.238c-.748.749-1.08 1.698-1.238 2.87c-.153 1.14-.153 2.595-.153 4.433V16a3.751 3.751 0 0 0 3.166 3.705c.137.764.402 1.416.932 1.947c.602.602 1.36.86 2.26.982c.867.116 1.97.116 3.337.116h3.11c1.367 0 2.47 0 3.337-.116c.9-.122 1.658-.38 2.26-.982c.602-.602.86-1.36.982-2.26c.116-.867.116-1.97.116-3.337v-5.11c0-1.367 0-2.47-.116-3.337c-.122-.9-.38-1.658-.982-2.26c-.531-.53-1.183-.795-1.947-.932A3.751 3.751 0 0 0 15 1.25Zm2.13 3.021A2.25 2.25 0 0 0 15 2.75h-4c-1.907 0-3.261.002-4.29.14c-1.005.135-1.585.389-2.008.812c-.423.423-.677 1.003-.812 2.009c-.138 1.028-.14 2.382-.14 4.289v6a2.25 2.25 0 0 0 1.521 2.13c-.021-.61-.021-1.3-.021-2.075v-5.11c0-1.367 0-2.47.117-3.337c.12-.9.38-1.658.981-2.26c.602-.602 1.36-.86 2.26-.981c.867-.117 1.97-.117 3.337-.117h3.11c.775 0 1.464 0 2.074.021ZM7.408 6.41c.277-.277.665-.457 1.4-.556c.754-.101 1.756-.103 3.191-.103h3c1.435 0 2.436.002 3.192.103c.734.099 1.122.28 1.399.556c.277.277.457.665.556 1.4c.101.754.103 1.756.103 3.191v5c0 1.435-.002 2.436-.103 3.192c-.099.734-.28 1.122-.556 1.399c-.277.277-.665.457-1.4.556c-.755.101-1.756.103-3.191.103h-3c-1.435 0-2.437-.002-3.192-.103c-.734-.099-1.122-.28-1.399-.556c-.277-.277-.457-.665-.556-1.4c-.101-.755-.103-1.756-.103-3.191v-5c0-1.435.002-2.437.103-3.192c.099-.734.28-1.122.556-1.399Z" clip-rule="evenodd"/></svg>
      </div>
      <ngx-monaco-editor [options]="editorRightOptions" [(ngModel)]="generatedCodes[index]" style="height: 80vh; width: 100%;"></ngx-monaco-editor>
    </div>

  </div>

</ng-template>
