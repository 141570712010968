<div style="width: 100%;">

	<!-- header -->
	<div class="header-area">
		<div class="left-side">
			<div class="trigger-icon">
				<p><br><img src="https://api.iconify.design/fluent-mdl2/trigger-auto.svg?color=%23777" alt="" width="52"
						height="52"></p>
			</div>
			<div class="heading-texts">
				<div class="trigger-text">
					<p>Action</p>
				</div>
				<div class="trigger-type-text">
					<p><span style="font-size: 14pt;"><strong>{{action.name}}</strong></span></p>
				</div>
			</div>
		</div>
		<div class="richText"
			(click)="automationsService.deleteAction(this.automationsService.currentLayout, automationsService.currentAutomation, action)"
			style="cursor: pointer;">
			<p><br><img src="https://api.iconify.design/ic/baseline-delete.svg?color=%23777" alt="" width="32"
					height="32"></p>
		</div>
	</div>

	<!-- specific action implementation -->
	<ng-content></ng-content>

</div>
