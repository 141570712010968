<div class="overlay"></div>

<div class="dialog">
  <mat-card style="display: flex; flex-direction: column; align-items: flex-start; padding: 20px;">

		<div style="width: 100%; display: flex; justify-content: space-between;">

			<h1 id="Heading_left" class="text-black">Automations Dialog</h1>

			<!-- Close button -->
			<a (click)="emitDataAndClose()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
				<span class="mat-button-span">
					Schliessen
				</span>
			</a>
		</div>

		<ng-container *ngIf="!automationsService.automations.get(elementService.rootNode.saveLayout.id)">
			<div style="width: 98%; text-align: center; font-size: medium; margin: 1%; padding-top: 50px; padding-bottom: 50px; border: 1px solid black;">
				<span>Keine Automations vorhanden. :-(</span>
			</div>
		</ng-container>

		<div *ngFor="let automation of automationsService.automations.get(elementService.rootNode.saveLayout.id) ?? []; let i = index" class="automation-table">
			<input #nameField
				type="text"
				class="focus:outline-0 bg-transparent"
				style="color: inherit; width: 90%;"
				[value]="automation.name"
				(blur)="automation.name = nameField.value"
				class="automation-name-input"
			/>
			<!-- Open -->
			<button mat-mini-fab color="primary" (click)="automationsService.openAutomation(elementService.rootNode, i)">
				<mat-icon>open_in_new</mat-icon>
			</button>
			<button mat-mini-fab (click)="automationsService.deleteAutomation(elementService.rootNode, automation)">
				<mat-icon>delete</mat-icon>
			</button>
		</div>

		<!-- Add automation -->
		<a (click)="automationsService.createAutomation(elementService.rootNode)" class="mat-button-wrapper-0-0-7" mat-raised-button color="primary">
			<span class="mat-button-span">
				Automation hinzufügen
			</span>
		</a>

  </mat-card>
</div>

<automationDialog
	*ngIf="automationsService.showAutomationDialog"
	(closed)="automationsService.showAutomationDialog = false"
></automationDialog>
