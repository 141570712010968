import { Component, Input } from '@angular/core';

import { AutomationsService, ExportExcelAction } from '../../../../automations';

@Component({
  selector: "export-excel-action",
  templateUrl: "./export-excel.component.html",
  styleUrls: ["./export-excel.component.scss"]
})
export class ExportExcelActionComponent {
  @Input()
  public action: ExportExcelAction;

  public constructor(private readonly automationsService: AutomationsService) {
  }

  private fileNameTimeout: NodeJS.Timeout;
  public get fileName(): string {
		return this.action.fileName;
	}
	public set fileName(value: string) {
		this.action.fileName = value;

		if (this.fileNameTimeout) {
			clearTimeout(this.fileNameTimeout);
		}
		this.fileNameTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}
}
