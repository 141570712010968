<div *ngIf="elementService?.allOfType('matSelect')">

  <h2 translate>{{'elementNames.selectionField' | translate}} {{'general.menu' | translate}}:</h2>

  <!-- Label -->
  <app-menu-input [width]="'100%'" [name]="'Label'" [isHelper]="true"></app-menu-input>

  <div style="display: flex; justify-content: space-between;">

    <!-- Appearance -->
    <mat-form-field appearance="fill" style="width: 49%;">
      <mat-label translate>rightMenu.appearance</mat-label>
      <mat-select #fuellungSelect
        (selectionChange)="setProp('Erscheinungsbild', fuellungSelect.value)"
        [value]="getProp('Erscheinungsbild')"
      >
        <mat-option *ngFor="let appearance of appearances" [value]="appearance">{{appearance}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- DISABLED? -->
    <mat-disabled-menu></mat-disabled-menu>
  </div>

  <div style="display: flex; justify-content: space-between;">

    <!-- Amount -->
    <mat-amount-menu style="width: 49%" [maxAmount]="11"></mat-amount-menu>

    <mat-toggle-menu style="width: 49%" [name]="'iconRight'" [shortName]="'Icon rechts?'"></mat-toggle-menu>

  </div>

  <div style="display: flex; justify-content: space-between;" *ngFor="let amount of getRange()">

    <!-- TEXT -->
    <app-menu-input style="width: 49%" [name]="'Label' + amount" [isHelper]="true"></app-menu-input>

    <!-- Icon -->
    <app-menu-input style="width: 49%" [name]="'Icon' + amount" [isHelper]="true"></app-menu-input>

  </div>

  <!-- ngModel -->
  <app-menu-input [width]="'100%'" [name]="'ngModel'" [isHelper]="true"></app-menu-input>
</div>
