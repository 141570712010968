<div class="overlay"></div>

<div class="dialog">
  <mat-card class="card">

    <div class="content">

      <div style="margin: 1%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <div style="flex: 1; text-align: center; font-size: x-large; font-weight: bold;">
          <span>Projekte</span>
        </div>

        <button
          mat-raised-button
          color="primary"
          (click)="close()"
          [disabled]="projectsService.currentProject === undefined"
        >
          <span translate>general.close</span>
        </button>
      </div>

      <ng-container *ngIf="projectsService.cacheService.projects.length === 0">
        <div style="width: 98%; text-align: center; font-size: medium; margin: 1%; padding-top: 50px; padding-bottom: 50px; border: 1px solid black;">
          <span>Keine Projekte vorhanden. :-(</span>
        </div>
      </ng-container>

      <div *ngIf="projectsService.cacheService.projects.length > 0" class="project-table">
        <table mat-table #projectsTable [dataSource]="projectsService.cacheService.projects">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let project" (click)="open(project)">
              <input #nameField
                type="text"
                style="color: inherit;"
                [value]="projectsService.getName(project)"
                (blur)="unfocus(project); setName(project, nameField.value)"
                (focus)="focus(project)"
                class="project-name-input"
                [ngClass]="{ 'project-name-input-focused': isFocused(project) }"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 64px;">Aktion</th>
            <td mat-cell *matCellDef="let project">
              <button mat-mini-fab (click)="delete(project)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;" [class.row-is-clicked]="isSelected(row)"></tr>
        </table>
      </div>

      <div style="width: 98%; margin: 1%; display: flex; align-items: center;">
        <button
          mat-raised-button
          color="primary"
          (click)="create()"
        >
          <span>Neues Projekt anlegen</span>
        </button>
					<!-- file upload -->
			<input
				type="file"
				name="files[]"
				style="display: none"
				(change)="dataService.onFileSelected($event)"
				#fileUpload
				accept="*.json"
			/>
			<a
				title="Upload JSON"
				style="
					width: 40px;
					margin-left: 10px;
					background-color: white;
					color: black;
				"
				mat-mini-fab
				(click)="fileUpload.click()"
				><mat-icon>upload</mat-icon></a>
      </div>

    </div>

  </mat-card>
</div>
