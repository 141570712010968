import { ProjectsService } from 'src/app/projects'
import { FileGenerator } from '../../code-generation.model';
import { getCorrectKeycloakClientName } from './environment.generator'

export class EnvironmentProdGenerator extends FileGenerator {

	constructor(private projectsService: ProjectsService) {

		super()
	}

  public getFilename(): string {

		return 'src/environments/environment.prod.ts'
	}

  public generate(): string {

		const result =
`export const environment = {

  production: true,
  protocol: 'https',
  domain: '${getCorrectKeycloakClientName(this.projectsService.currentProject?.name)}.testing.photonic-codes.cloud',
  port: '42080',
  apiPrefix: 'api',
  keycloakClient: '${getCorrectKeycloakClientName(this.projectsService.currentProject?.name)}-client'
}`
		return result
  }
}
