import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';

@Component({
  selector: 'matRadio-menu',
  templateUrl: 'matRadio.menu.html'
})
export class MatRadioComponentMenu extends menu {

  isHelper = true

  positions = [
    "davor",
    "dahinter",
  ]

	getRange(): number[] {

    let begin = 1
    let end = Number(this.getProp('Menge'))

		const nums: number[] = [];
		for (let i = begin; i <= end; i++) {

			nums.push(i);
		}

		return nums;
	}
}
