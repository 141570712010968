import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-Slider',
  templateUrl: './matSlider.component.html'
})
export class MatSliderComponent extends LayoutComponent {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'MatSliderComponent';
  }

	setInitialProps(){

    if(this.getProp("padding") == "")	this.setProp({key:"padding", value: "20", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: false})
    if(this.getProp("width") == "")	this.setProp({key:"width", value: "128", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: false})
    if(this.getProp("min") == "")	this.setProp({key:"min", value: "0", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("max") == "")	this.setProp({key:"max", value: "100", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("step") == "")	this.setProp({key:"step", value: "1", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    // Detect changes
    this.elementService.changeDetector.detectChanges()
  }

  setPropShort(key: string, value: string): void {
    this.setProp({key: key, value: value, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
  }

  private _value: string | undefined

  public get value(): string | undefined {
    let stringValue = this.elementService.getProp("value")
    this._value = stringValue == "" ? undefined : stringValue
    return this._value
  }

  public set value(value: string | undefined) {
    if(!value) return
    this.elementService.setProp(
      {key: "value", value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
    this._value = value;
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getHtmlTemplate(): string {
    const template =
`<div>
<mat-slider
  style="margin: 0; padding: 0; width: 100%;"
  color="getProp('color')"
  if § $disableRipple, disableRipple="getProp('disableRipple')" §
  if § $disabled, disabled="getProp('disabled')" §
  if § $discrete, discrete="getProp('discrete')" §
  if § $max, max="getProp('max')" §
  if § $min, min="getProp('min')" §
  if § $showTickMarks, showTickMarks="getProp('showTickMarks')" §
  if § $step, step="getProp('step')" §
>
  <input matSliderThumb #thissliderthumb
    [value]="value"
    (valueChange)="value = '' + thissliderthumb.value"
  >
</mat-slider>
</div>`;
    return template;
  }

  public getCodeTemplate(): string {
    const code =
`public value: string = 'getProp('value')';
`;
    return code;
  }

  public getTemplateString(tabs: string): string {
    let attrMap: Map<string, string> = new Map<string, string>()
    let children = []
    let childrenTabs = "\t"+tabs

    /*
      <mat-slider
        [color]="getProp('color')"
        [disableRipple]="getProp('disableRipple') == '1'"
        [disabled]="getProp('disabled') == '1'"
        [discrete]="getProp('discrete') == '1'"
        [max]="getProp('max')"
        [min]="getProp('min')"
        [showTickMarks]="getProp('showTickMarks') == '1'"
        [step]="getProp('step')"
      >
        <input #thisSliderThumb matSliderThumb
          [value]="getProp('value')"
          (valueChange)="setPropShort('value', ''+thisSliderThumb.value)"
          [step]="getProp('innerStep')"
        >
      </mat-slider>
    */
                                                attrMap.clear()
                                                attrMap.set("color", this.getProp('color'))
    if (this.getProp('disableRipple') == '1')   attrMap.set("disableRipple", "true")
    if (this.getProp('disabled') == '1')        attrMap.set("disabled", "true")
    if (this.getProp('discrete') == '1')        attrMap.set("discrete", "true")
                                                attrMap.set("max", this.getProp('max'))
                                                attrMap.set("min", this.getProp('min'))
    if (this.getProp('showTickMarks') == '1')   attrMap.set("showTickMarks", "true")
                                                attrMap.set("step", this.getProp('step'))
                                                attrMap.set("value", this.getProp('value'))
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())
    if(this.getStyleAttributesForInner() != "") attrMap.set("class", this.getProp('displayName'))

    let result = this.buildLeafTag(tabs, "mat-slider", attrMap, "")
    return result
  }
}
