<mat-form-field [appearance]="getAppearance()" [color]="getColor()">

	<mat-label>{{getProp('Label')}}</mat-label>

	<input *ngIf="getProp('maxLength')==''" [id]="getProp('displayName')+'Input'" [value]="getProp('prefill')" matInput [type]="getProp('Typ')"                                    [placeholder]="getProp('Platzhalter')" [disabled]="getProp('disabled')=='1'">
	<input *ngIf="getProp('maxLength')!=''" [id]="getProp('displayName')+'Input'" [value]="getProp('prefill')" matInput [type]="getProp('Typ')" [maxLength]="getProp('maxLength')" [placeholder]="getProp('Platzhalter')" [disabled]="getProp('disabled')=='1'">

	<mat-hint *ngIf="getProp('startHint')!=''" align="start">{{getProp("startHint")}}</mat-hint>
	<mat-hint *ngIf="getProp('endHint')!=''" align="end">{{getProp("endHint")}}</mat-hint>

	<span *ngIf="getProp('prefixText')!=''" matPrefix style="margin-right: 10px;">{{getProp("prefixText")}}</span>
	<span *ngIf="getProp('suffixText')!=''" matSuffix style="margin-right: 10px;">{{getProp("suffixText")}}</span>

	<mat-icon *ngIf="getProp('prefixIcon')!=''" matPrefix>{{getProp("prefixIcon")}}</mat-icon>
	<mat-icon *ngIf="getProp('suffixIcon')!=''" matSuffix>{{getProp("suffixIcon")}}</mat-icon>

	<button *ngIf="getProp('deletable')=='1'" matSuffix mat-icon-button>
		<mat-icon>close</mat-icon>
	</button>

</mat-form-field>
