import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ElementService } from '../../../element.service';
import { AutomationsService } from '../../../automations';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   Automations Dialog                                |
           |   ===============================================   |
           |   * for Automations like in Budibase                |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'addActionDialog',
  templateUrl: './addActionDialog.html',
  styleUrls: ['./addActionDialog.scss']
})
export class AddActionDialog implements OnInit {
  public columns: string[] = ['name', 'action'];

  @Output()
  public closed: EventEmitter<AddActionDialog> = new EventEmitter<AddActionDialog>();

  public constructor(public elementService: ElementService, public automationsService: AutomationsService) {
  }

  public ngOnInit(): void {
  }

  public getTypes() {
    return Array.from(this.automationsService.actionMap.keys());
  }

  public createAction(type: string) {
    this.automationsService.createAction(this.automationsService.currentLayout, this.automationsService.currentAutomation, type, this.automationsService.currentAction);
    this.emitDataAndClose();
  }

  public getName(type: string) {
    return type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  public emitDataAndClose() {
		this.automationsService.showAddActionDialog = false;
    this.automationsService.currentAction = undefined;
    this.closed.emit(this);
  }
}
