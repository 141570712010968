<trigger style="width: 100%;" [name]="'Kanban Card Dropped'" [trigger]="trigger">

  <div class="element-selection-area">
    <div class="richText2">
      <pre><span style="font-size: 14pt;"><strong>Kanbans</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Kanbans</mat-label>
      <mat-select #thisSelect (selectionChange)="selected = thisSelect.value" [value]="selected">
        <mat-option *ngFor="let kanban of kanbans" [value]="kanban">
          {{ kanban.getProp('displayName') ? kanban.getProp('displayName') : kanban.getProp('id') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</trigger>
