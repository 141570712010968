<div *ngIf="elementService?.allOfType('leafletJs')">

  <h2>Karten-Menü:</h2>

  <div style="display: flex; justify-content: space-between;">
    <mat-form-field appearance="fill" [style]="'width: 49%;'">
      <mat-label translate>rightMenu.latitude</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="latitude"
        (focus)="elementService.tfFocussed(true)"
        (focusout)="elementService.tfFocussed(false)"
      >
    </mat-form-field>

    <mat-form-field appearance="fill" [style]="'width: 49%;'">
      <mat-label translate>rightMenu.longitude</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="longitude"
        (focus)="elementService.tfFocussed(true)"
        (focusout)="elementService.tfFocussed(false)"
      >
    </mat-form-field>
  </div>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.zoomLevel</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="zoomLevel"
      (focus)="elementService.tfFocussed(true)"
      (focusout)="elementService.tfFocussed(false)"
    >
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.maxZoomLevel</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="maxZoomLevel"
      (focus)="elementService.tfFocussed(true)"
      (focusout)="elementService.tfFocussed(false)"
    >
  </mat-form-field>

  <div style="display: flex; justify-content: space-between; margin-bottom: 22px;">
    <a
      *ngIf="!this.elementService?.allLayouts()"
      mat-raised-button color="primary"
      (click)="addLayer()"
      style="width: 49%;"
    >
      <span class="mat-button-span">Layer hinzufügen</span>
    </a>
    <a
      *ngIf="!this.elementService?.allLayouts()"
      mat-raised-button color="primary"
      (click)="removeLayer()"
      style="width: 49%;"
    >
      <span class="mat-button-span">Layer entfernen</span>
    </a>
  </div>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Layer</mat-label>
    <mat-select #layerSelect
      (selectionChange)="currentLayerIndex = layerSelect.value"
      [value]="currentLayerIndex"
    >
      <mat-option
        *ngFor="let layer of countLayers;"
        [value]="layer"
      >
        {{ layer + 1 }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Farbe</mat-label>
    <input
      #layerColor
      type="color"
      matInput
      [value]="currentLayerColor || '#FFFFFF'"
      (input)="currentLayerColor = layerColor.value">
  </mat-form-field>

</div>
