<div *ngIf="elementService?.allOfType('matRadio')">

  <h2>Radiobutton Menü:</h2>

  <div style="display: flex; flex-direction: column; justify-content: space-between;"> <!-- Vertical Menu -->

    <!-- Flex direction -->
    <p style="margin-left: 10px;">
      <mat-slide-toggle
        #columnToggle
        (change)="setProp('flex-direction', getProp('flex-direction') == 'column'?'row':'column', '', false, false, false)"
        [checked]="getProp('flex-direction') == 'column'"
      >
      <span translate>rightMenu.flexDirection</span>
      </mat-slide-toggle>
    </p>

    <!-- DISABLED? -->
    <mat-disabled-menu></mat-disabled-menu>
  </div>

  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- Labelposition -->
    <mat-LabelPosition-menu style="width: 49%;"></mat-LabelPosition-menu>

    <!-- Gap -->
    <mat-gap-menu style="width: 49%;"></mat-gap-menu>
  </div>

  <!-- Amount -->
  <mat-amount-menu [maxAmount]="6"></mat-amount-menu>

  <!-- TEXT -->
  <app-menu-input *ngFor="let amount of getRange()" [width]="'100%'" [name]="'Label' + amount" [isHelper]="true"></app-menu-input>
</div>
