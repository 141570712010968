import { Action } from '../action';

export class CustomScriptAction extends Action {
  public code: string = '';

  public action(): void {
    try {
      eval(this.code);
    } catch (error) {
      console.error('ERROR CustomScriptAction: ' + error);
    }
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      code: this.code
    }
  }
}
