import { EventEmitter } from '@angular/core';

import { Trigger } from '../trigger';
import { LayoutComponent } from '../../layout/layout.component';

export class FileUploadedTrigger extends Trigger {
  public input: any;

  public element?: LayoutComponent;
  public elementSelected: EventEmitter<LayoutComponent> = new EventEmitter<LayoutComponent>();

  public triggerAction(): void {
    if (super.automation && super.automation.action) {
      super.automation.action.input = this.input;
    }

    super.triggerAction();

    console.log('Triggering elementSelected.emit()');
    // Tell the listening actions that the element has been selected
    this.elementSelected.emit(this.element);
  }
}
