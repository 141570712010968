<div *ngIf="elementService?.allOfType('cssInput')">
  <h2>Input Menü:</h2>

  <mat-slide-toggle
    #cacheDataToggle
    (change)="setProp('cache-data', cacheDataToggle.checked ? '1' : '0')"
    [checked]="getProp('cache-data') == '1'"
  >
    Cache Data
  </mat-slide-toggle>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.type</mat-label>
    <mat-select #typeField
      (selectionChange)="setProp('type', typeField.value)"
      [value]="getProp('type')"
    >
      <mat-option value="button"><span translate>button</span></mat-option>
      <mat-option value="checkbox"><span translate>checkbox</span></mat-option>
      <mat-option value="color"><span translate>color</span></mat-option>
      <mat-option value="date"><span translate>date</span></mat-option>
      <mat-option value="datetime-local"><span translate>datetime-local</span></mat-option>
      <mat-option value="email"><span translate>email</span></mat-option>
      <mat-option value="file"><span translate>file</span></mat-option>
      <mat-option value="hidden"><span translate>hidden</span></mat-option>
      <mat-option value="image"><span translate>image</span></mat-option>
      <mat-option value="month"><span translate>month</span></mat-option>
      <mat-option value="number"><span translate>number</span></mat-option>
      <mat-option value="password"><span translate>password</span></mat-option>
      <mat-option value="radio"><span translate>radio</span></mat-option>
      <mat-option value="range"><span translate>range</span></mat-option>
      <mat-option value="reset"><span translate>reset</span></mat-option>
      <mat-option value="search"><span translate>search</span></mat-option>
      <mat-option value="submit"><span translate>submit</span></mat-option>
      <mat-option value="tel"><span translate>tel</span></mat-option>
      <mat-option value="text"><span translate>text</span></mat-option>
      <mat-option value="time"><span translate>time</span></mat-option>
      <mat-option value="url"><span translate>url</span></mat-option>
      <mat-option value="week"><span translate>week</span></mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.value</mat-label>
    <input matInput #valueField
      [type]="getProp('type')"
      [value]="getProp('value')"
      (input)="setProp('value', valueField.value)">
  </mat-form-field>

  <mat-form-field *ngIf="getProp('type') === 'checkbox' || getProp('type') === 'radio'" appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.step</mat-label>
    <input matInput #stepField
      type="text"
      [value]="getProp('step')"
      (input)="setProp('step', stepField.value)">
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.color</mat-label>
    <input
      #color
      type="color"
      matInput
      [value]="getProp('normal-color')"
      (input)="setProp('normal-color', color.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.bgColor</mat-label>
    <input
      #backgroundColor
      type="color"
      matInput
      [value]="getProp('normal-background-color')"
      (input)="setProp('normal-background-color', backgroundColor.value)"
    />
  </mat-form-field>

  <div class="horizontalMenu">
		<mat-form-field appearance="fill" [style]="'width: 85%;'">
			<mat-label translate>rightMenu.hoverColor</mat-label>
			<input
				#hoverColor
				type="color"
				matInput
				[value]="getProp('hover-color')"
				(input)="setProp('hover-color', hoverColor.value)"
			/>
		</mat-form-field>
		<button title="Prop entfernen" class="removeButton" *ngIf="getProp('hover-color')"
						(click)="elementService.removeProp('hover-color')">
			X
		</button>
	</div>

  <div class="horizontalMenu">
		<mat-form-field appearance="fill" [style]="'width: 85%;'">
			<mat-label translate>rightMenu.hoverBackgroundColor</mat-label>
			<input
				#hoverBackgroundColor
				type="color"
				matInput

				(input)="setProp('hover-background-color', hoverBackgroundColor.value)"
			/>
		</mat-form-field>
		<button title="Prop entfernen" class="removeButton" *ngIf="getProp('hover-background-color')"
						(click)="elementService.removeProp('hover-background-color')">
			X
		</button>
	</div>
	<!-- [value]="getProp('hover-background-color')" -->

  <app-menu-border></app-menu-border>
</div>
