import { Component } from '@angular/core'

import { DataService } from '../data.service'
import { ElementService } from '../element.service'
import { menu } from '../menus/menu/menu'
import { PropertyIterator, SaveLayout } from '../projects'
import { TreeSearchService } from '../tree-search.service'
import { RouterOutletPlaceholderComponent } from './router-outlet-placeholder.component'

@Component({
  selector: 'router-outlet-placeholder-menu',
  templateUrl: 'router-outlet-placeholder.menu.html'
})
export class RouterOutletPlaceholderMenu extends menu {
  public constructor(
    public readonly elementService: ElementService,
    public readonly dataService: DataService,
    public readonly treeSearchService: TreeSearchService
  ) {
    super(elementService)
  }

  public get defaultPage(): string {
    return (this.elementService.selectedObjects[0] as RouterOutletPlaceholderComponent).defaultPage;
  }

  public set defaultPage(value: string) {
    (this.elementService.selectedObjects[0] as RouterOutletPlaceholderComponent).setDefaultPage(value);
  }

  public getId(page: SaveLayout): string {
    return [...(new PropertyIterator(this.dataService.propertiesService, page))].find((prop) => prop.key === 'id')?.value ?? '';
  }

  public get selectedPages(): string[] {
    return (this.elementService.selectedObjects[0] as RouterOutletPlaceholderComponent).selectedPages;
  }

  public set selectedPages(value: string[]) {
    (this.elementService.selectedObjects[0] as RouterOutletPlaceholderComponent).setSelectedPages(value);
  }

  public areSelectedPagesDisabled(page: SaveLayout): boolean {
    const appPage = this.dataService.projectsService.currentProject!.pages[0];

    const linkingDefaultPages = this.treeSearchService.getLinkingDefaultPages(this.dataService.projectsService.currentProject!, page);
    const linkingPages = this.treeSearchService.getLinkingPages(this.dataService.projectsService.currentProject!, page);

    return this.dataService.currentPage.name === appPage.name && (linkingDefaultPages.includes(appPage) || linkingPages.includes(appPage)) && linkingDefaultPages.length + linkingPages.length === 1;
  }

  public getPage(id: string): SaveLayout {
    return this.dataService.projectsService.currentProject!.pages.find((page) => [...(new PropertyIterator(this.dataService.propertiesService, page))].find((prop) => prop.key === 'id')?.value === id)!;
  }
}
