import { Component, Input } from '@angular/core';
import { ElementService } from '../../element.service';

@Component({
  selector: 'app-menu-input',
  templateUrl: './menu-input.component.html'
})
export class MenuInputComponent {

  @Input() width = "100%"
  @Input() name = ""
  @Input() shortName = ""
  @Input() isHelper = false
  @Input() type = "text"

  constructor(public elementService: ElementService) { }

  private _inputValue: string | undefined
  public get inputValue(): string | undefined {

    let stringValue = this.elementService.getProp(this.name)

    this._inputValue = stringValue == "" ? undefined : stringValue

    return this._inputValue
  }
  public set inputValue(value: string | undefined) {

    if(!value) return

    this.elementService.setProp(
      {key: this.name, value: value, second: "", isHelper: this.isHelper, isTailwind: false, renderOnlyOuter: false}
    )
    this._inputValue = value;
  }
}
