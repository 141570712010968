<action style="width: 100%;" [name]="'Custom Script'" [action]="action">

  <div class="element-selection-area" *ngIf="code !== undefined">

    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Code</strong></span></pre>
    </div>

    <div class="custom-script-editor-container">
      <ngx-monaco-editor
        id="custom-script-monaco-editor"
        class="custom-script-editor"
        [options]="editorOptions"
        [(ngModel)]="code"
        (onInit)="onInit($event)"
      >
      </ngx-monaco-editor>
    </div>

  </div>

	<!-- Test button -->
	<a (click)="testAction()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
		<span class="mat-button-span">
			Testen
		</span>
	</a>

</action>
