import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';
import {ignoreElements} from "rxjs/operators";

@Component({
  selector: 'matChips-menu',
  templateUrl: 'matChips.menu.html'
})
export class MatChipsComponentMenu extends menu {

  isHelper = true

  getRange(): number[] {
    let amounts = this.elementService.selectedObjects[0].element?.['amounts']
    if (!amounts) return []

    let nums: number[] = []
    amounts.forEach((index:string) => nums.push(Number(index)))

    nums = nums.slice(0, Number(this.getProp('Menge')))

    return nums;
  }
}
