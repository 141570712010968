import { Injectable } from '@angular/core';

import { Project } from './entities/project.entity';
import { SaveLayout } from './entities/save-layout.entity';
import { Property } from './entities/property.entity';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public projects: Project[] = [];
  public saveLayouts: SaveLayout[] = [];
  public properties: Property[] = [];

  public constructor() {
  }
}
