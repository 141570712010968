import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, concatMap, delay, finalize, from, mergeMap, retryWhen } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { apiCall } from './Dialogs/Dialog API Call/apiCall/apiCall';
import { DialogPageEditorComponent } from './Dialogs/dialog-page-editor/dialog-page-editor.component';
import { ElementService } from './element.service';
import { IpcService } from './ipc.service';
import { LayoutComponent } from './layout/layout.component';
import { PageReference, ProjectContext, createProject } from './data.model';
import { EditorComponent } from './editor/editor.component';
import { TreeSearchService } from './tree-search.service';
import { AutomationsService } from './automations/automations.service';
import { IProperty, Project, ProjectsService, PropertiesService, Property, PropertyIterator, SaveLayout, SaveLayoutIterator, SaveLayoutsService } from './projects';
import { Automation } from './automations';
import { HttpErrorResponse } from '@angular/common/http';
import { CodeGenerationService } from './code-generation/code-generation.service'

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   PROJECT STATE SERVICE                             |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS SERVICE IS TO               |
					 |   * STORE THE APP STATE                             |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Injectable({
	providedIn: 'root'
})
export class DataService {
	public constructor(
		public readonly ipc: IpcService,
		public readonly dialog: MatDialog,
		public readonly projectsService: ProjectsService,
		public readonly saveLayoutsService: SaveLayoutsService,
		public readonly propertiesService: PropertiesService,
		public readonly treeSearchService: TreeSearchService,
		public readonly automationsService: AutomationsService
	) {
	}

	public codeGenerationService: CodeGenerationService
	public apiCalls: apiCall[] = [];
	public appComponent: EditorComponent;
	public elementService: ElementService;

	public alignItemsTranslate = '';
	public justifyContentTranslate = '';

	public automationsForCodeGeneration: Map<string, Automation[]> = new Map<string, Automation[]>();

	/**
	 * ### The Current Page
	 * This is the current page.
	 */
	public currentPage: PageReference = new PageReference();

	/**
	 * ### The Project Context
	 * This is the given context for the project.
	 */
	public projectContext: ProjectContext = new ProjectContext();
	/**
	 * ### The Generation Context
	 * This is the given context for the code generation process.
	 */
	public generationContext: ProjectContext | undefined;

  /*
   * ██████╗  ██████╗ ████████╗███████╗
   * ██╔══██╗██╔═══██╗╚══██╔══╝██╔════╝
   * ██████╔╝██║   ██║   ██║   ███████╗
   * ██╔══██╗██║   ██║   ██║   ╚════██║
   * ██║  ██║╚██████╔╝   ██║   ███████║
   * ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚══════╝
   */

	public routerOutletPlaceholdersAreLoaded: Map<string, boolean> = new Map<string, boolean>();

	private checkIfAllRouterOutletPlaceholdersAreLoaded(): boolean {
		for (const [key, value] of this.routerOutletPlaceholdersAreLoaded) {
			if (!value) {
				return false;
			}
		}
		return true;
	}

  /*
   * ██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗███████╗
   * ██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝██╔════╝
   * ██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║   ███████╗
   * ██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║   ╚════██║
   * ██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║   ███████║
   * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝
   */

	public projectsAreLoaded: Map<string, boolean> = new Map<string, boolean>();

	private checkIfAllProjectsAreLoaded(): boolean {
		for (const [key, value] of this.projectsAreLoaded) {
			if (!value) {
				return false;
			}
		}
		return true;
	}

  /*
   * ███████╗███╗   ██╗████████╗██╗████████╗██╗███████╗███████╗
   * ██╔════╝████╗  ██║╚══██╔══╝██║╚══██╔══╝██║██╔════╝██╔════╝
   * █████╗  ██╔██╗ ██║   ██║   ██║   ██║   ██║█████╗  ███████╗
   * ██╔══╝  ██║╚██╗██║   ██║   ██║   ██║   ██║██╔══╝  ╚════██║
   * ███████╗██║ ╚████║   ██║   ██║   ██║   ██║███████╗███████║
   * ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝
   */
	public setProp(saveLayout: SaveLayout, property: IProperty): void {
		if (!this.projectsService.currentProject || !this.propertiesAreLoaded || !this.checkIfAllRouterOutletPlaceholdersAreLoaded() || !this.checkIfAllProjectsAreLoaded()) { return; }

		const attributes = new PropertyIterator(this.propertiesService, saveLayout);
		const properties = [ ...attributes ];
		const match = this.propertiesService.cacheService.properties.find(prop => prop.id === properties.find(p => p.key === property.key)?.id && prop.key === property.key)

		const template = match ? { ...match, ...property } : property;
				this.propertiesService.createOrUpdate(saveLayout, template);
	}

	public deleteProp(saveLayout: SaveLayout, property: IProperty): void {

		if (!this.propertiesAreLoaded || !this.projectsService.currentProject) return

		const attributes = new PropertyIterator(this.propertiesService, saveLayout)
		const properties = [ ...attributes ]
		const match = this.propertiesService.cacheService.properties.find(prop => prop.id === properties.find(p => p.key === property.key)?.id && prop.key === property.key)

		if (match) this.propertiesService.delete(match)
	}

	public addSaveLayout(parent: SaveLayout, type?: string, fn?: (saveLayout: SaveLayout) => void): void {
		if (!this.propertiesAreLoaded || !this.projectsService.currentProject) { return; }

		this.saveLayoutsService.create(this.projectsService.currentProject!, {
			projectId: this.projectsService.currentProject!.id,
			projectOwner: this.projectsService.currentProject!.owner,
			name: type ?? 'layout',
			parentId: parent.id,
			parentOwner: parent.owner,
			isLayout: type === 'layout',
			orderNumber: 0
		}, (saveLayout) => {
			if (fn) {
				fn(saveLayout);
			}
		});
	}

	public deleteSaveLayout(saveLayout: SaveLayout): void {
		if (!this.propertiesAreLoaded || !this.projectsService.currentProject) { return; }

		this.saveLayoutsService.delete(saveLayout);
	}

  /*
   * ██╗███╗   ██╗██╗████████╗     █████╗ ██████╗ ██████╗
   * ██║████╗  ██║██║╚══██╔══╝    ██╔══██╗██╔══██╗██╔══██╗
   * ██║██╔██╗ ██║██║   ██║       ███████║██████╔╝██████╔╝
   * ██║██║╚██╗██║██║   ██║       ██╔══██║██╔═══╝ ██╔═══╝
   * ██║██║ ╚████║██║   ██║       ██║  ██║██║     ██║
   * ╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝       ╚═╝  ╚═╝╚═╝     ╚═╝
   */

	public initApp(): void {
		this.projectsService.findAll().subscribe(() => {
			this.loadPersistedProject();

			if (this.projectsService.currentProject && this.projectsService.currentProject?.pages.length === 0) {
			  this.loadInitialProject();
			}
		});
	}

	public loadPersistedProject(): void {
		this.projectsService.loadCurrentProject();
		if (this.projectsService.currentProject) {
			this.loadProject(this.projectsService.currentProject);
		}
	}

	public loadInitialProject(): void {
		this.projectsService.create((project) => {
			this.changeProject(project);
		});
	}

	/*
   * ██████╗ ███████╗███████╗███████╗████████╗     █████╗ ██████╗ ██████╗
   * ██╔══██╗██╔════╝██╔════╝██╔════╝╚══██╔══╝    ██╔══██╗██╔══██╗██╔══██╗
   * ██████╔╝█████╗  ███████╗█████╗     ██║       ███████║██████╔╝██████╔╝
   * ██╔══██╗██╔══╝  ╚════██║██╔══╝     ██║       ██╔══██║██╔═══╝ ██╔═══╝
   * ██║  ██║███████╗███████║███████╗   ██║       ██║  ██║██║     ██║
   * ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝       ╚═╝  ╚═╝╚═╝     ╚═╝
   */

	/**
	 * Created once a clean app state. Now it is used to jump into a new project.
	 */
	public resetApp(fn?: () => void) {
		this.elementService.setProp({ key: 'apiCalls', value: '', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
		this.apiCalls = [];
		this.projectsService.create((project) => {
			this.changeProject(project, fn);
		});
	}

	public changeProject(project: Project, fn?: () => void) {
		this.projectsService.setCurrentProject(project);
		if (this.projectsService.currentProject) {
			this.loadProject(this.projectsService.currentProject, fn);
		}
	}

  /*
   * ██╗      ██████╗  █████╗ ██████╗     ██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗
   * ██║     ██╔═══██╗██╔══██╗██╔══██╗    ██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝
   * ██║     ██║   ██║███████║██║  ██║    ██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║
   * ██║     ██║   ██║██╔══██║██║  ██║    ██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║
   * ███████╗╚██████╔╝██║  ██║██████╔╝    ██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║
   * ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝
   */

	public loadProject(project: Project, fn?: () => void) {
		this.saveLayoutsService.findAll().subscribe(() => {
			this.propertiesService.findAll().subscribe(() => {
				this.elementService.rootNode?.removeMe();

				this.openedPath = '';
        this.elementService.selectedObjects = [];

				if (project && project.pages.length > 0) {
					this.loadDefaultPageIntoTheApp();
					if (fn) {
						fn();
					}
				}
			});
		});
	}

  /*
   * ██╗      ██████╗  █████╗ ██████╗     ██████╗  █████╗  ██████╗ ███████╗
   * ██║     ██╔═══██╗██╔══██╗██╔══██╗    ██╔══██╗██╔══██╗██╔════╝ ██╔════╝
   * ██║     ██║   ██║███████║██║  ██║    ██████╔╝███████║██║  ███╗█████╗
   * ██║     ██║   ██║██╔══██║██║  ██║    ██╔═══╝ ██╔══██║██║   ██║██╔══╝
   * ███████╗╚██████╔╝██║  ██║██████╔╝    ██║     ██║  ██║╚██████╔╝███████╗
   * ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝
   */

	// A page is always an Angular component, but not all Angular components are pages
	public propertiesLoaded: EventEmitter<string> = new EventEmitter<string>();
	public propertiesAreLoaded = false;

	public loadDefaultPageIntoTheApp() {
		this.loadPageIntoTheApp(this.projectsService.currentProject!.pages.find(p => p.name === 'App') ?? this.projectsService.currentProject!.pages[0]);
	}

	public loadPageIndexIntoTheApp(index: number) {
		this.loadPageIntoTheApp(this.projectsService.currentProject?.pages[index]!);
	}

	public loadPageIntoTheApp(page: SaveLayout) {
		// Remove current root, wich represented the previous page
		this.elementService.rootNode?.removeMe();

		this.currentPage.reference(this.propertiesService, page);

		this.propertiesAreLoaded = false;

		this.projectContext.clear();

		this.loadElement(undefined, page, { append: true, isEmbedded: false, prefix: undefined });
		this.elementService.tree?.updateDatasourceAndExpand();

		this.propertiesAreLoaded = true;
		this.propertiesLoaded.emit('All Properties are loaded');

		this.elementService.setApiCalls();

		this.automationsService.clearAutomations();
		this.automationsService.loadAutomationsForLayoutComponent(this.elementService.rootNode);
		this.automationsService.reRegisterTriggers(this.elementService.rootNode);
	}

	/**
	 * ### Recursive element loading
	 * from the SaveLayout object.
	 * 1. Creates an element for the given SaveLayout
	 * 2. Sets the props for the element
	 * 3. Repeat for all children of the element
	 *
	 * ⚠️ Attention: This does not add the generated elements into the parent object.
	 *
	 * @param parent The parent element to load the child into
	 * @param savedElement The element to load
	 * @param append If true, the element will be appended to the parent
	 * @returns The loaded element as LayoutComponent
	 */
	public loadElement(parent: LayoutComponent | undefined, savedElement: SaveLayout, options: { append: boolean, isEmbedded: boolean, prefix?: string }): LayoutComponent {
		let instance: LayoutComponent;
		let isRoot: boolean = false;

		if (!this.elementService.rootNode || this.elementService.rootNode.name == "Dead") {
			instance = this.elementService.createRootElement();
			isRoot = true;
		} else {
			instance = savedElement.isLayout
				? this.elementService.createElement(parent!, undefined, options.append)
				: this.elementService.createElement(parent!, savedElement.name, options.append);
		}

		if (!instance) {
			throw new Error('New instance was not created');
		}

		instance.name = savedElement.name;
		instance.isLayout = savedElement.isLayout;
		instance.saveLayout = savedElement;

		// Load properties
		const attributes = new PropertyIterator(this.propertiesService, savedElement)

		attributes.forEach((attribute) => {

			if (options.isEmbedded && (attribute.key === 'id' || attribute.key === 'displayName')) {

				instance.setProp({ key: attribute.key, value: `${options.prefix}!${attribute.value}`, second: attribute.second, isHelper: attribute.isHelper, isTailwind: attribute.isTailwind, renderOnlyOuter: attribute.renderOnlyOuter })
			}
			else {

				instance.setProp(attribute)
			}
		})

		const id = instance.getProp('id');
		if (id) {
			// TODO: Eventuell überprüfen ob die id bereits existiert
			this.projectContext.dataTable.set(id, savedElement);
			this.projectContext.componentTable.set(id, instance);
		}

		const componentType = instance.getProp('componentType');
		if (componentType === 'ProjectComponent') {
			this.projectContext.projectStateTable.set(id, createProject());
			this.projectContext.currentPagesTable.set(id, new PageReference());
		}

		this.elementService.changeDetector.detectChanges();

		// Load children
		const children = new SaveLayoutIterator(this.saveLayoutsService, savedElement);
		children.forEach((child) => {
			this.loadElement(instance, child, { append: true, prefix: options.prefix, isEmbedded: options.isEmbedded });
		});

		return instance;
	}

  /*
   * ██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗    ██████╗  █████╗ ████████╗ █████╗
   * ██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝    ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
   * ██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║       ██║  ██║███████║   ██║   ███████║
   * ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║       ██║  ██║██╔══██║   ██║   ██╔══██║
   * ██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║       ██████╔╝██║  ██║   ██║   ██║  ██║
   * ╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝       ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
   */

	/** Electron File Open */
	public openedPath: string;

	public openDialog(): void {
		let currentProjectState;

		this.ipc.on(
			'openDialogEvent-pathReply',
			(event: Electron.IpcRendererEvent, path: string) => {
				this.openedPath = path;
			}
		);

		this.ipc.on(
			'openDialogEvent-dataReply',
			(event: Electron.IpcRendererEvent, data: string) => {
				currentProjectState = JSON.parse(data);
				this.loadPageIntoTheApp(currentProjectState.pages[0]);
			}
		);

		this.ipc.send('openDialogEvent', '');
	}

	/** Browser Upload */
	public importedObject: any;

	public onFileSelected(event: any): void {
		this.import(event, (importedObject) => {
			// See if the parsed json file is a Project or ProjectState or Page (SaveLayout)
			if (importedObject?.project?.id) {
				const match = this.projectsService.cacheService.projects.find((project) => project.id === importedObject.project.id);
				if (match) {
					this.importedObject = importedObject;
					this.appComponent.showLoadingDialog = false;
					this.appComponent.showImportOptionsDialog = true;
				} else {
					this.importProject(importedObject);
				}
			} else if (importedObject.appName) {
				this.importProjectState(importedObject);
			} else {
				this.importPage(importedObject);
			}
		});
	}

	private import(event: any, fn: (importedObject: any) => void): void {
		this.appComponent.showLoadingDialog = true;

		const files = event.target.files;
		const file = files[0];
		const fileName = file?.name;

		const reader = new FileReader();

		reader.onload = (e) => {
			const data = reader.result as string;
			const importedObject = JSON.parse(data);

			fn(importedObject);

			this.openedPath = fileName;
			event.srcElement.value = '';
		}

		reader.readAsText(file, 'utf-8');
	}

	public importProject(importedObject: any): void {
		const failedImports: SaveLayout[] = [];

		from([importedObject.project] as Project[]).pipe(
			concatMap((project: Project) => this.projectsService.import(project).pipe(
				concatMap(() => from(importedObject.saveLayouts as SaveLayout[]).pipe(
					concatMap((saveLayout: SaveLayout) => this.saveLayoutsService.import(saveLayout).pipe(
						catchError((error) => {
							if (error instanceof HttpErrorResponse && error.status === 404) {
								console.error(`Failed to import SaveLayout with id ${saveLayout.id} because the parent was not found`);
								failedImports.push(saveLayout);
								return from([]);
							} else {
								throw error;
							}
						}),
						mergeMap(() => from((importedObject.properties as Property[]).filter((p: Property) => p.saveLayout.id === saveLayout.id)).pipe(
							mergeMap((property: Property) => this.propertiesService.import(property))
						))
					))
				))
			)),
			finalize(() => {
				from(failedImports).pipe(
					mergeMap((saveLayout: SaveLayout) => this.saveLayoutsService.import(saveLayout).pipe(
						retryWhen((errors) => errors.pipe(delay(10000))),
						mergeMap(() => from((importedObject.properties as Property[]).filter((p: Property) => p.saveLayout.id === saveLayout.id)).pipe(
							mergeMap((property: Property) => this.propertiesService.import(property).pipe(
								retryWhen((errors) => errors.pipe(delay(10000)))
							))
						))
					)),
					finalize(() => {
						this.projectsService.findOne(importedObject.project.id, (project) => {
							this.changeProject(project, () => {
								this.elementService.setProp({ key: 'apiCalls', value: '', second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
								this.apiCalls = [];
								this.appComponent.showLoadingDialog = false;
							});
						});
					})
				).subscribe();
			})
		).subscribe();
	}

	private importProjectState(importedObject: any): void {
		const dummyUserId = '00000000-0000-0000-0000-000000000000';

		let newImportObject: { version: string, project: Project, saveLayouts: SaveLayout[], properties: Property[] } = {
			version: '4',
			project: {
				id: uuid(),
				owner: dummyUserId,
				name: importedObject.appName,
				pages: []
			},
			saveLayouts: [],
			properties: []
		};

		const idsAreUnique = this.checkUniqnessForTheIdsForOldPages(importedObject.pages);
		if (!idsAreUnique) {
			this.createUniqueIdsForOldPages(importedObject.pages);
		}

		for (const page of importedObject.pages) {
			this.convertPage(dummyUserId, newImportObject, page);
		}

		this.importProject(newImportObject);
	}

	private importPage(importedObject: any): void {
		const dummyUserId = '00000000-0000-0000-0000-000000000000';

		let newImportObject: { version: string, project: Project, saveLayouts: SaveLayout[], properties: Property[] } = {
			version: '4',
			project: {
				id: uuid(),
				owner: dummyUserId,
				name: 'New App',
				pages: []
			},
			saveLayouts: [],
			properties: []
		};

		const idsAreUnique = this.checkUniqnessForTheIdsForOldPages([importedObject]);
		if (!idsAreUnique) {
			this.createUniqueIdsForOldPages([importedObject]);
		}

		this.convertPage(dummyUserId, newImportObject, importedObject);

		this.importProject(newImportObject);
	}

	private convertPage(dummyUserId: string, newImportObject: { version: string, project: Project, saveLayouts: SaveLayout[], properties: Property[] }, page: any) {
		const parentsTable = new Map<string, SaveLayout>();
		const orderNumberTable = new Map<string | undefined, number>();

		const stack = [page];

		while (stack.length > 0) {
			// Get the next saveLayout
			const saveLayout = stack.shift()!;

			// Get the needed id's
			const id: string = saveLayout.attributes.find((prop: any) => prop.key === 'id').value;
			const parent = parentsTable.get(id) ?? undefined;
			const parentId = parent?.id ?? undefined;

			// Update the order
			orderNumberTable.set(parentId, (orderNumberTable.get(parentId) ?? 0) + 1);

			// Add saveLayout to the new import object
			const newSaveLayout: SaveLayout = {
				id: id,
				owner: dummyUserId,
				name: saveLayout.name,
				isLayout: saveLayout.isLayout,
				orderNumber: orderNumberTable.get(parentId)!,
				project: newImportObject.project,
				parent: parent,
				children: []
			};
			newImportObject.saveLayouts.push(newSaveLayout);

			// Add saveLayout to the parent
			if (parent && parent.children) {
				parent.children.push({
					id: newSaveLayout.id,
					owner: dummyUserId,
					name: newSaveLayout.name,
					isLayout: newSaveLayout.isLayout,
					orderNumber: orderNumberTable.get(parentId)!
				});
			} else if (!parent) {
				newImportObject.project.pages.push({
					id: newSaveLayout.id,
					owner: dummyUserId,
					name: newSaveLayout.name,
					isLayout: newSaveLayout.isLayout,
					orderNumber: orderNumberTable.get(parentId)!
				});
			}

			// Add properties to the new import object
			if (saveLayout.attributes) {
				saveLayout.attributes.forEach((prop: any) => {
					const newProperty: Property = {
						id: uuid(),
						owner: dummyUserId,
						saveLayout: {
							id: newSaveLayout.id,
							owner: dummyUserId,
							name: newSaveLayout.name,
							isLayout: newSaveLayout.isLayout,
							orderNumber: orderNumberTable.get(parentId)!
						},
						key: prop.key,
						value: prop.value,
						second: prop.second,
						renderOnlyOuter: prop.renderOnlyOuter,
						isTailwind: prop.isTailwind,
						isHelper: prop.isHelper
					};
					newImportObject.properties.push(newProperty);
				});
			}

			// Add children to stack
			if (saveLayout.children) {
				saveLayout.children.forEach((child: any) => {
					const childId = child.attributes.find((prop: any) => prop.key === 'id').value;
					parentsTable.set(childId, newSaveLayout);
					stack.push(child);
				});
			}
		}
	}

	public checkUniqnessForTheIdsForOldPages(pages: any[]): boolean {
		let result = true;

		const ids = new Set<string>();

		for (const page of pages) {
			const stack = [page];

			while (stack.length > 0) {
				const saveLayout = stack.shift()!;

				const id: string = saveLayout.attributes.find((prop: any) => prop.key === 'id').value;
				if (ids.has(id)) {
					result = false;
					break;
				} else {
					ids.add(id);
				}

				if (saveLayout.children) {
					saveLayout.children.forEach((child: any) => stack.push(child));
				}
			}

			if (!result) {
				break;
			}
		}

		return result;
	}

	public createUniqueIdsForOldPages(pages: any[]): void {
		for (const page of pages) {
			const stack = [page];

			while (stack.length > 0) {
				const saveLayout = stack.shift()!;

				const idProp = saveLayout.attributes.find((prop: any) => prop.key === 'id');
				idProp.value = uuid();

				if (saveLayout.children) {
					saveLayout.children.forEach((child: any) => stack.push(child));
				}
			}
		}
	}

  /*
   * ███████╗██╗  ██╗██████╗  ██████╗ ██████╗ ████████╗    ██████╗  █████╗ ████████╗ █████╗
   * ██╔════╝╚██╗██╔╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝    ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
   * █████╗   ╚███╔╝ ██████╔╝██║   ██║██████╔╝   ██║       ██║  ██║███████║   ██║   ███████║
   * ██╔══╝   ██╔██╗ ██╔═══╝ ██║   ██║██╔══██╗   ██║       ██║  ██║██╔══██║   ██║   ██╔══██║
   * ███████╗██╔╝ ██╗██║     ╚██████╔╝██║  ██║   ██║       ██████╔╝██║  ██║   ██║   ██║  ██║
   * ╚══════╝╚═╝  ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝       ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
   */

	private createExportData(): string {
		const project = this.projectsService.currentProject;
		const saveLayouts = this.saveLayoutsService.cacheService.saveLayouts.filter(sl => sl.project!.id === this.projectsService.currentProject!.id);
		const properties = this.propertiesService.cacheService.properties.filter(p => this.saveLayoutsService.cacheService.saveLayouts.find(sl => sl.id === p.saveLayout.id)!.project!.id === this.projectsService.currentProject!.id);

		const sortedSaveLayouts = this.sortSaveLayoutsForExport(saveLayouts);

		const data = {
			version: '4',
			project: project,
			saveLayouts: sortedSaveLayouts,
			properties: properties
		};

		return JSON.stringify(data);
	}

	private sortSaveLayoutsForExport(saveLayouts: SaveLayout[]): SaveLayout[] {
		const sortedSaveLayouts: SaveLayout[] = [];

		const visited = new Set();

		for (const sl of saveLayouts) {
			if (visited.has(sl)) {
				continue;
			}

			const stack: SaveLayout[] = [sl];

			while (stack.length > 0) {
				const saveLayout = stack.pop();

				if (!saveLayout) {
					continue;
				}

				visited.add(saveLayout);
				sortedSaveLayouts.push(saveLayout);

				const saveLayoutIterator = new SaveLayoutIterator(this.saveLayoutsService, saveLayout);
				const children = [ ...saveLayoutIterator ];
				if (children.length > 0) {
					for (const child of children) {
						if (!visited.has(child)) {
							stack.push(child);
						}
					}
				}
			}
		}

		return sortedSaveLayouts;
	}

	/** Electron File Save */
	public writeFile = () => {
		const text = this.createExportData();
		this.ipc.send('writeFile', [this.openedPath, text]);
	}

	public downloadFile(fileName?: string): void {
		if (!this.projectsService.currentProject) {
			console.error('No project loaded');
			return;
		}

		if (!fileName) {
			fileName = 'cool-layout.json';
		} else if (!fileName?.endsWith('.json')) {
			fileName = fileName.concat('.json');
		}

		this.openedPath = fileName;

		const data = this.createExportData();
		const blob = new Blob([data], { type: 'application/json;charset=utf8' });

		const downloadLink = document.createElement('a');
		downloadLink.href = window.URL.createObjectURL(blob);
		downloadLink.setAttribute('download', fileName);
		document.body.appendChild(downloadLink);

		downloadLink.click();
		downloadLink.remove();
	}

	public openPagesDialog(): void {
		const dialogRef = this.dialog.open(DialogPageEditorComponent);
		dialogRef.afterClosed().subscribe((result) => {});
	}
}
