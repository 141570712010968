import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { CodeGenerationService } from '../../code-generation/code-generation.service';
import { LayoutComponent } from '../../layout/layout.component';

@Component({
  selector: 'cssButton',
  templateUrl: './cssButton.component.html',
	styleUrls: ['./cssButton.component.scss'],
})
export class CssButtonComponent extends LayoutComponent {
  public hovered: boolean = false;

  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService, public readonly codeGenerationService: CodeGenerationService) {
		super(elementService, renderer, dataService);
    this.componentType = 'CssButtonComponent';
	}

	setInitialProps(){

    this.ensureProp({ key: 'text', value: 'text', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'font-size', value: '16', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false })
    this.ensureProp({ key: 'cursor', value: 'pointer', second: '', isTailwind: false, isHelper: false, renderOnlyOuter: false })

    this.ensureProp({ key: 'border-width', value: '2', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'border-width-area', value: 'trlb', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'border-single-width', value: '2', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'border-style', value: 'solid', second: '', isTailwind: false, isHelper: false, renderOnlyOuter: false })
    this.ensureProp({ key: 'border-color', value: '#000000', second: '', isTailwind: false, isHelper: false, renderOnlyOuter: false })
    this.ensureProp({ key: 'border-radius', value: '9', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false })

    this.ensureProp({ key: 'normal-color', value: '#000000', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'normal-background-color', value: '#ffffff', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'hover-color', value: '#ffffff', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
    this.ensureProp({ key: 'hover-background-color', value: '#000000', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })

    this.ensureProp({ key: 'spacingSplit', value: 'true', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'padding-top', value: '10', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'padding-left', value: '30', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'padding-right', value: '30', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'padding-bottom', value: '10', second: 'px', isTailwind: false, isHelper: false, renderOnlyOuter: false });

    this.elementService.changeDetector.detectChanges()
  }

  public onButtonClicked(): void {
		this.trigger();
		this.routeToPage();
	}

  /*
   *  ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ███████╗███╗   ██╗
   * ██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝ ██╔════╝████╗  ██║
   * ██║     ██║   ██║██║  ██║█████╗      ██║  ███╗█████╗  ██╔██╗ ██║
   * ██║     ██║   ██║██║  ██║██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║
   * ╚██████╗╚██████╔╝██████╔╝███████╗    ╚██████╔╝███████╗██║ ╚████║
   *  ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝
   */

  public getHtmlTemplate(): string {
    const template =
`<button #thisbutton_${this.getProp('displayName').toLowerCase()}
  type="button"
  if § $disabled, [disabled]="getProp('disabled')" §
  ${this.dataService.automationsService.automations.size > 0 ? `(click)="onButtonClicked_${this.getProp('displayName')}()"` : ''}
  class="getProp('displayName')"
  ${this.getProp('pageRoute') ? `[routerLink]="${this.codeGenerationService.generateRoute(+this.getProp('pageRoute'))}"` : ''}
>
  ${this.getProp('image') ? `<img [src]="'${this.getProp('image')}' + '?color=%23' + ${this.getProp('image-color') ? `'${this.getProp('image-color').substring(1)}'` : '000000'}" style="margin-left: 8px; margin-right: 8px; width: 24px; height: 24px;">` : ''}

  <div
    class="focus:outline-0 bg-transparent"
  >
    <span>{{ getProp('text') }}</span>
  </div>

  ${this.getProp('image-2') ? `<img [src]="'${this.getProp('image-2')}' + '?color=%23' + ${this.getProp('image-color-2') ? `'${this.getProp('image-color-2').substring(1)}'` : '000000'}" style="margin-left: 8px; margin-right: 8px; width: 24px; height: 24px;">` : ''}
</button>`;
    return template;
  }

  public getCssTemplate(): string {
    let styles =
`.${this.getProp('displayName')} {
  ${this.getStyleAttributesForInner().replaceAll('; ', ';\n  ')}
  color: ${this.getProp('normal-color')};
  background-color: ${this.getProp('normal-background-color')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.${this.getProp('displayName')} > div {
  width: 100%;
  color: inherit;
  font: inherit;
  text-align: ${this.getProp('button-text-align') ? `${this.getProp('button-text-align')}` : 'center'};
  font-weight: ${this.getProp('button-font-weight') ? `${this.getProp('button-font-weight')}` : 'normal'}
}
`;

    if (this.getProp('hover-color') || this.getProp('hover-background-color')) {
      styles +=
`.${this.getProp('displayName')}:hover {
  ${this.getProp('hover-color') ? `color: ${this.getProp('hover-color')};` : ''}
  ${this.getProp('hover-background-color') ? `background-color: ${this.getProp('hover-background-color')};` : ''}
}`;
    }

    return styles;
  }

  public getCodeTemplate(): string {
    const importCode =
`${this.dataService.automationsService.automations.size > 0 ? `import { Trigger } from 'src/app/automations.service';\n` : ''}`;

    if (!this.elementService.aboveCode.includes(importCode)) {
      this.elementService.aboveCode += importCode;
    }

    const automationsCode =
`public triggers_getProp('displayName'): string[] = [];

public addTrigger_getProp('displayName')(trigger: Trigger): void {
  if (!this.triggers_getProp('displayName').includes(trigger.id)) {
    this.triggers_getProp('displayName').push(trigger.id);
  }
}

public removeTrigger_getProp('displayName')(trigger: Trigger): void {
  const index = this.triggers_getProp('displayName').indexOf(trigger.id);
  if (index > -1) {
    this.triggers_getProp('displayName').splice(index, 1);
  }
}

public trigger_getProp('displayName')(): void {
  if (!this.triggers_getProp('displayName')) { return; }
  for (const triggerId of this.triggers_getProp('displayName')) {
    const trigger = this.automationsService.automations.find(automation => automation.trigger?.id === triggerId)?.trigger;
    if (trigger) {
      trigger.triggerAction();
    }
  }
}

public onButtonClicked_getProp('displayName')(): void {
  this.trigger_getProp('displayName')();
}`;

    const code =
`${this.dataService.automationsService.automations.size > 0 ? automationsCode : ''}
`;
    return code;
  }
}
