import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { DataService } from '../../data.service';
import { ElementService } from '../../element.service';

@Component({
  selector: 'langchain',
  templateUrl: './langchain.html',
	styles: [`
		.image-container {
			position: relative;
			display: inline-block;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		.glowing-ball {
			width: 20px;
			height: 20px;
			background-color: orange;  /* Die Farbe der leuchtenden Kugel */
			border-radius: 50%;       /* Macht die Kugel rund */
			position: absolute;
			top: 0;
			left: 0;
			box-shadow: 0 0 20px orange; /* Der leuchtende Effekt */
			opacity: 0.8;
		}

		.rotation-container {
				width: 0;
				height: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				animation: rotateBall 8s infinite linear;
		}

		@keyframes rotateBall {
				from {
						transform: rotate(0deg);
				}
				to {
						transform: rotate(360deg);
				}
		}

		.glowing-ball {
				width: 20px;
				height: 20px;
				background-color: orange;
				border-radius: 50%;
				position: absolute;
				top: -10px; /* halbe Höhe der Kugel, um sie über dem Rotationspunkt zu zentrieren */
				left: -80px; /* 40px (Abstand von der Mitte) minus die halbe Breite der Kugel */
				box-shadow: 0 0 10px orange;
				opacity: 0.8;
		}
	`]
})
export class langchain extends LayoutComponent implements AfterViewInit {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'langchain';
  }

	private _promptElement: string
  public get promptElement(): string {

    return this.getProp('promptElement')
  }
  public set promptElement(value: string) {

    if(value == "") return
    this._promptElement = value

    this.setProp(
      {key: 'promptElement', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
  }

	private _promptStyle: string
  public get promptStyle(): string {

    return this.getProp('promptStyle')
  }
  public set promptStyle(value: string) {

    if(value == "") return
    this._promptStyle = value

    this.setProp(
      {key: 'promptStyle', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
  }

	showImg = true
	private _receivedCode: string
  public get receivedCode(): string {

    return this.getProp('receivedCode')
  }
  public set receivedCode(value: string) {

    if(value == "") return
    this._receivedCode = value

		this.showImg = false

		// Get element by id "langchainAnker"
		document.getElementById("langchainAnker")!.innerHTML = value

		this.setProp({key:"height", value: "", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true})
    this.setProp({key:"width", value: "", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true})

    this.setProp(
      {key: 'receivedCode', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
  }

  ngAfterViewInit(): void {

    this.dataService.propertiesAreLoaded ? this.init() :
       this.dataService.propertiesLoaded.subscribe(() => this.init())

    this.element = this

    this.elementService.changeDetector.detectChanges()
  }

  init(){

    this.setProp({key:"height", value: "256", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true})
    this.setProp({key:"width", value: "256", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true})
  }

  // =================
  // CODE
  // =================
  getCodeTemplate() {
    const template =
``;
    return template;
  }

  getAtomicCode(){

    return ""
  }


  // =================
  // TEMPLATE STRING
  // =================
  public getHtmlTemplate(): string {
    const template =
``;
    return template;
  }

  public getTemplateString(tabs: string): string {

    let attrMap: Map<string, string> = new Map<string, string>()
    let children = []
    let childrenTabs = "\t"+tabs

    attrMap.clear()
    attrMap.set("id", `${this.getProp("displayName")+'_canvas'}`)
    children.push(this.buildLeafTag(childrenTabs, "canvas", attrMap))

    attrMap.clear()
    attrMap.set("class", `${this.getProp("displayName")}`)

    return this.buildNormalTag(tabs, "div", attrMap, children)
  }
}
