<button #thisButton
  type="button"
  [disabled]="getProp('disabled') == '1'"[disabled]="getProp('disabled') == '1'"
  (click)="onButtonClicked()"
  (mouseover)="hovered = true"
  (mouseout)="hovered = false"
  style="display: flex; flex-direction: row; align-items: center; justify-content: center;"
  [ngStyle]="{
    'color': hovered ? getProp('hover-color') : getProp('normal-color'),
    'backgroundColor': hovered ? getProp('hover-background-color') : getProp('normal-background-color')
  }"
>
  <img *ngIf="getProp('iconURL')" [src]="getProp('iconURL') + '?color=%23' + (getProp('iconURL-color') ? getProp('iconURL-color').substring(1) : '000000')" style="margin-left: 8px; margin-right: 8px; width: 24px; height: 24px;">
  <ng-container *ngIf="elementService.isLiveMode; then liveBlock else editBlock"></ng-container>
  <img *ngIf="getProp('iconURL-2')" [src]="getProp('iconURL-2') + '?color=%23' + (getProp('iconURL-color-2') ? getProp('iconURL-color-2').substring(1) : '000000')" style="margin-left: 8px; margin-right: 8px; width: 24px; height: 24px;">
</button>

<ng-template #editBlock>
  <input
    type="text"
    #thisField
    [style]="{
      'width': '100%',
      'color': 'inherit',
      'font': 'inherit',
      'text-align': getProp('button-text-align') ? getProp('button-text-align') : 'center',
      'font-weight': getProp('button-font-weight') ? getProp('button-font-weight') : 'normal'
    }"
    class="buttonInput"
    [value]="getProp('text')"
    (input)="setPropAsHelper('text', thisField.value)"
    (focus)="elementService.tfFocussed(true)"
    (focusout)="elementService.tfFocussed(false)"
  />
</ng-template>

<ng-template #liveBlock>
  <div
    [style]="{
      'width': '100%',
      'color': 'inherit',
      'font': 'inherit',
      'text-align': getProp('button-text-align') ? getProp('button-text-align') : 'center',
      'font-weight': getProp('button-font-weight') ? getProp('button-font-weight') : 'normal'
    }"
    class="buttonInput"
  >
    <span>{{ getProp('text') }}</span>
  </div>
</ng-template>
