import { HttpClient } from '@angular/common/http';

import { Action } from '../action';
import { ProjectDataCacheService } from '../../project-data-cache.service';

export class SendEmailAction extends Action {
  public service: string = '';
  public host: string = '';
  public port: number = 0;
  public secure: boolean = false;
  public user: string = '';
  public password: string = '';

  public from: string = '';
  public to: string = '';
  public subject: string = '';
  public text: string = '';

  public http: HttpClient;
  public projectDataCacheService: ProjectDataCacheService;

  public action(): void {
    console.log('Sending email...');

    const resolvedText = this.text.replace(/\$([a-zA-Z0-9!\-]+)/g, (match, p1) => {
      const value = this.projectDataCacheService.getValue(p1);
      return value ? ''+value : match;
    });

    const message =
`<!DOCTYPE html>
<html>
<body>
${resolvedText}
</body>
</html>
`;

    this.http.post('https://general-backend.testing.photonic-codes.cloud/api/login', {
      service: this.service,
      host: this.host,
      port: this.port,
      secure: this.secure,
      user: this.user,
      password: this.password
    }).subscribe(() => {
      this.http.post('https://general-backend.testing.photonic-codes.cloud/api/send-email', {
        from: this.from,
        to: this.to,
        subject: this.subject,
        text: message
      }).subscribe(() => {
        console.log('Email sent!');
      });
    });
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      service: this.service,
      host: this.host,
      port: this.port,
      secure: this.secure,
      user: this.user,
      password: this.password,
      from: this.from,
      to: this.to,
      subject: this.subject,
      text: this.text
    }
  }
}
