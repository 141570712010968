import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'matTextarea',
  templateUrl: './component.html',
})
export class MatTextareaComponent extends LayoutComponent {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'MatTextareaComponent';
  }

	setInitialProps(){

    if(this.getProp("Label") == "")	this.setProp({key:"Label", value: "Neuer Textbereich", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Platzhalter") == "")	this.setProp({key:"Platzhalter", value: "Neuer Platzhalter", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Typ") == "")	this.setProp({key:"Typ", value: "text", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Erscheinungsbild") == "")	this.setProp({key:"Erscheinungsbild", value: "fill", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("color") == "")	this.setProp({key:"color", value: "Primary", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    // Detect changes
    this.elementService.changeDetector.detectChanges()
  }

  getAppearance() {
    let appearance: MatFormFieldAppearance = "fill"

    switch (this.getProp("Erscheinungsbild")) {
      case "fill":
        appearance = "fill"
        break
      case "outline":
        appearance = "outline"
        break
    }

    return appearance
  }

  public getColor(): ThemePalette {
    let color: ThemePalette = 'primary'

    switch (this.getProp('color')) {
      case 'primary':
        color = 'primary'
        break
      case 'accent':
        color = 'accent'
        break
      case 'warn':
        color = 'warn'
        break
      default:
        color = undefined;
        break
    }

    return color
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getTemplateString(tabs: string): string {

    let attrMap: Map<string, string> = new Map<string, string>()
    let children: any[] = []
    let childrenTabs = "\t"+tabs

    // <mat-label>{{getProp('Label')}}</mat-label>
    attrMap.clear()
    children.push(this.buildLeafTag(childrenTabs, "mat-label", attrMap, this.getProp("Label")))

    // <textarea matInput [placeholder]="getProp('Platzhalter')" [disabled]="getProp('disabled')=='1'"></textarea>
                                        attrMap.clear()
                                        attrMap.set("matInput", "")
                                        attrMap.set("id", this.getProp("displayName"))
                                        attrMap.set("placeholder", this.getProp("Platzhalter"))
    if(this.getProp("prefill") != "")   attrMap.set("value", this.getProp("prefill"))
    if(this.getProp("disabled") == "1") attrMap.set("disabled", "")
    children.push(this.buildLeafTag(childrenTabs, "textarea", attrMap))

    let color = this.getColor()
                                                attrMap.clear()
                                                attrMap.set("appearance", this.getAppearance())
    if(color)                                   attrMap.set("color", color)
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())
    if(this.getStyleAttributesForInner() != "") attrMap.set("class", this.getProp('displayName'))

    let result = this.buildNormalTag(tabs, "mat-form-field", attrMap, children, "")
    return result
  }
}
