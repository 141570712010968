<ng-container *ngIf="this.editable; then thenBlock else elseBlock"></ng-container>

<ng-template #thenBlock>
	<editor
		[disabled]="false"
		initialValue="<p>Hello World!</p>"
		[inline]="true"
		outputFormat="html"
		tagName="div"
		plugins="lists advlist link image table code wordcount charmap emoticons insertdatetime media"
		toolbar="blocks | bold italic underline | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist"
		[(ngModel)]="code"
		(onBlur)="onBlur($event)"
		(onFocus)="onFocus($event)"
	></editor>
</ng-template>

<ng-template #elseBlock>
	<div
		[innerHTML]="content"
	></div>
</ng-template>

<!-- undo redo |  -->
