import { Component } from '@angular/core'

import { LayoutComponent } from '../../layout/layout.component'

@Component({
  selector: 'sf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class SfButtonComponent extends LayoutComponent {

	ngAfterViewInit() {

		this.removeElement()
	}

	setInitialProps(){

		this.ensureProp({ key: 'isPrimary', value: '1', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })
		this.setProp({ key: 'text', value: 'button', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false })

		this.elementService.changeDetector.detectChanges()
	}

	removeElement(){

		var xpath = "//div[contains(@style, 'position: fixed') and contains(@style, 'z-index: 999999999') and //img[contains(@src, 'data:image/svg+xml')]]"
		var element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue

		if (!element) return

		(element as HTMLElement).remove()
	}
}