import { Component, EventEmitter, Output } from '@angular/core';

import { ElementService } from '../../../element.service';
import { Action, AutomationsService } from '../../../automations';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   Automations Dialog                                |
           |   ===============================================   |
           |   * for Automations like in Budibase                |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'automationDialog',
  templateUrl: './automationDialog.html',
  styleUrls: ['./automationDialog.scss']
})
export class AutomationDialog {
  public columns: string[] = ['name', 'action'];

  public iconColor1 = '%23777';
  public iconColor2 = '%23777';
  public iconColor3 = '%23777';

  @Output()
  public closed: EventEmitter<AutomationDialog> = new EventEmitter<AutomationDialog>();

  public constructor(
    public readonly elementService: ElementService,
		public readonly automationsService: AutomationsService
	) {
  }

  public openTriggerDialog() {
    this.automationsService.showAddTriggerDialog = true;
  }

  public openActionDialog(action?: Action) {
		this.automationsService.showAddActionDialog = true;
    this.automationsService.currentAction = action;
	}

  public emitDataAndClose() {
    this.automationsService.saveAutomations(this.automationsService.currentLayout);
    this.closed.emit(this);
  }
}
