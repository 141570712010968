import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import * as prettier from 'prettier'
import { AutomationsService } from '../automations'
import { ProjectContext } from '../data.model'
import { DataService } from '../data.service'
import { ElementService } from '../element.service'
import { LayoutComponent } from '../layout/layout.component'
import { ProjectComponent } from '../project/project.component'
import { PropertyIterator, SaveLayout } from '../projects'
import { TreeSearchService } from '../tree-search.service'
import { FileGenerator } from './code-generation.model'
import { AppRoutingModuleGenerator } from './generators/files/app-routing.generator'
import { AppModuleGenerator } from './generators/files/app.generator'
import { AutomationsServiceGenerator } from './generators/files/automations.service.generator'
import { ExcelServiceGenerator } from './generators/files/excel.service.generator'
import { FileServiceGenerator } from './generators/files/file.service.generator'
import { MainGenerator } from './generators/files/main.generator'
import { MaterialModuleGenerator } from './generators/files/material.generator'
import { PackageGenerator } from './generators/files/package.generator'
import { ProjectDataCacheServiceGenerator } from './generators/files/project-data-cache.service.generator'
import { StylesGenerator } from './generators/files/styles.generator'
import { TSConfigGenerator } from './generators/files/tsconfig.generator'
import { EnvironmentGenerator } from './generators/files/environment.generator'
import { EnvironmentProdGenerator } from './generators/files/environment.prod.generator'

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   CODE GENERATION SERVICE                           |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS SERVICE IS TO               |
					 |   * GENERATE THE CODE FOR THE APP                   |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Injectable({
  providedIn: 'root'
})
export class CodeGenerationService {
	private fileGenerators: FileGenerator[]

  public constructor(
		public readonly elementService: ElementService,
		public readonly dataService: DataService,
		public readonly treeSearchService: TreeSearchService,
		private readonly automationsService: AutomationsService
	) {

		dataService.codeGenerationService = this

		this.fileGenerators = [
			new AppModuleGenerator(this.dataService),
			new AppRoutingModuleGenerator(this.dataService, this.treeSearchService),
			new MaterialModuleGenerator(),
			new PackageGenerator(this.dataService, this.treeSearchService, this.automationsService),
			new TSConfigGenerator(),
			new FileServiceGenerator(this.dataService, this.automationsService),
			new ExcelServiceGenerator(this.dataService, this.automationsService),
			new AutomationsServiceGenerator(this.dataService, this.automationsService),
			new ProjectDataCacheServiceGenerator(),
			new MainGenerator(this.dataService),
			new StylesGenerator(this.dataService),
			new EnvironmentGenerator(this.dataService.projectsService),
			new EnvironmentProdGenerator(this.dataService.projectsService),
		]
  }

	/**
	 * ### Generates the entire code for the app
	 * 1. Generates the code for every page
	 * 2. Generates routing module
	 * 3. Creates a zip file
	 */
	public async generateCode(): Promise<void> {

		const zip = new JSZip();
		const files: { [filename: string]: string } = {};

		const context = new ProjectContext();
		this.dataService.generationContext = context;
		this.dataService.automationsForCodeGeneration.clear();

		for (const page of this.dataService.projectsService.currentProject!.pages) {
			this.loadAutomations(page);

			this.dataService.loadPageIntoTheApp(page);
			this.loadComponents();
			context.append(this.dataService.projectContext);

			const name = page.name.toLowerCase().replace(' ', '-');
			await this.generateForPage(files, page, name);

			const topLevelProjectState = this.dataService.projectsService.currentProject!;
			for (const [projectComponentId, projectState] of this.dataService.projectContext.projectStateTable) {
				for (const projectComponentPage of projectState.pages) {
					this.dataService.projectsService.currentProject! = projectState;

					this.dataService.loadPageIntoTheApp(projectComponentPage);
					this.loadComponents();
					context.append(this.dataService.projectContext);

					const name = this.computeName(context, projectComponentId, projectComponentPage);
					await this.generateForPage(files, projectComponentPage, name);
				}
			}
			this.dataService.projectsService.currentProject! = topLevelProjectState;
		}

		const attributes = new PropertyIterator(this.dataService.propertiesService, this.dataService.projectsService.currentProject!.pages[0]);
		if (this.dataService.currentPage.id !== [ ...attributes ].find((prop) => prop.key === 'id')?.value) {
			this.dataService.loadDefaultPageIntoTheApp();
			this.loadComponents();
		}

		for (const fileGenerator of this.fileGenerators) {
			const code = fileGenerator.generate();
			if (code) {
				files[fileGenerator.getFilename()] = code;
			}
		}

		for (const [filename, content] of Object.entries(files)) {
      zip.file(filename, content);
    }

		zip.generateAsync({ type: 'blob' }).then((blob) => {
			saveAs(blob, 'Frontend.zip');
		});
	}

	private loadAutomations(page: SaveLayout): void {
		this.automationsService.loadAutomationsForSaveLayout(page, this.dataService.automationsForCodeGeneration);

		this.treeSearchService.forEachElement(page, 'RouterOutletPlaceholderComponent', (routerOutletComponent) => {
			const subRootId = this.treeSearchService.getProp(routerOutletComponent, 'defaultPage');
			const subRoot = this.dataService.saveLayoutsService.cacheService.saveLayouts.find((saveLayout) => saveLayout.id === subRootId)!;
			this.automationsService.loadAutomationsForSaveLayout(subRoot, this.dataService.automationsForCodeGeneration);
		});

		this.treeSearchService.forEachElement(page, 'ProjectComponent', (projectComponent) => {
			const selectedProjectId = this.treeSearchService.getProp(projectComponent, 'selectedProject');
			const selectedProject = this.dataService.projectsService.getProject(selectedProjectId)!;

			const startPageId = this.treeSearchService.getProp(projectComponent, 'startPage');
			const page = startPageId
			  ? this.dataService.saveLayoutsService.cacheService.saveLayouts.find((saveLayout) => saveLayout.id === startPageId)!
				: selectedProject.pages[0]!;

			this.automationsService.loadAutomationsForSaveLayout(page, this.dataService.automationsForCodeGeneration);
		});
	}

	private loadComponents(): void {
		for (const [projectComponentId, projectState] of this.dataService.projectContext.projectStateTable) {
			const pc = this.dataService.projectContext.componentTable.get(projectComponentId) as ProjectComponent;
			pc?.ngOnInit();
			pc?.load();
		}
	}

	private computeName(context: ProjectContext, projectComponentId: string, projectComponentPage: SaveLayout): string {
		if (projectComponentPage.name === 'App') {
			const projectComponent = this.dataService.generationContext!.componentTable.get(projectComponentId)! as ProjectComponent;
			const projectId = projectComponent.selectedProject!;
			return this.dataService.projectsService.getSelectorName(projectId);
		}

		return projectComponentPage.name.toLowerCase().replace(' ', '-');
	}

	private async generateForPage(files: { [filename: string]: string }, page: SaveLayout, name: string): Promise<void> {

		const templateFilename = `src/app/${name !== 'app' ? `pages/${name}/` : ''}${name}.component.html`
		const styleFilename = `src/app/${name !== 'app' ? `pages/${name}/` : ''}${name}.component.scss`
		const componentFilename = `src/app/${name !== 'app' ? `pages/${name}/` : ''}${name}.component.ts`

		const templateContent = await this.formatHtml(this.elementService.rootNode.getTag(''))
		//

		const styleContent = this.elementService.rootNode.getCSS()
		const componentContent = this.elementService.rootNode.getTS()

		files[templateFilename] = templateContent
		files[styleFilename] = styleContent
		files[componentFilename] = componentContent
	}

	async formatHtml(html: string): Promise<string> {

		return await prettier.format(html, {

			parser: "html",
			plugins: window.prettierPlugins,
		})
	}

	/*
	 ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	██║     ██║   ██║██║  ██║█████╗      ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
	██║     ██║   ██║██║  ██║██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
	╚██████╗╚██████╔╝██████╔╝███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
	 ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	*/
	async generateAndFormatHtml(html: string): Promise<string> {

		return await this.formatHtml(this.generateHtml(html))
	}

  /**
   * ### Generates the html code
   * Recursively replaces the template syntax with the correct values
   * @param text The given html code with the special template syntax
   * @param selected The LayoutComponent to generate for.
   *
   * Must be **any**, because we want to access the methods and properties of the LayoutComponent dynamically
   * without stupid TypeScript crying all the time
   * @returns The generated html code
   */
	generateHtml(text: string, selectedElement?: any): string {

		const selected: LayoutComponent = selectedElement || this.elementService.selectedObjects[0]
		// Test the Regexes here: https://ihateregex.io/playground/
    // Check Regex Groups here: https://regex101.com/

		text = this.checkIf(text, selected)
		if (selected.componentType !== 'matTableComponent') { // Richtig fieser hack du...
    	text = this.checkNgIf(text, selected)
		}

    //text = this.executeMethods(text, selected)
    text = this.executeGetProp(text, selected)

    // Concatenate strings. For example matInput+Input => matInputInput
    text = text.replace(/([\w\d]{1,}) ?\+ ?([\w\d]{1,})/g, (match, p1, p2) => {
      return p1 + p2
    })

			// Remove {{ and }}
			.replace(/{{|}}/g, "")

			// Remove dead tabs
			.replace(/\t(?=\n)/g, "")

			// Remove unnecessary spaces
			.replace(/ {2,}/g, " ")

			// Remove unnecessary line feeds
      // 2 would still be normal, because sometimes I want to have space between tags
      .replace(/\n{3,}/g, "\n\n")

		return text
	}

	// Syntax: if § condition, code §. Can contain other template syntax.
	checkIf(text: string, selected?: any): string {
		return text.replace(/\t*if[\s]?\u00a7[\s]?([^\s]+?)(,[\s\t]*)([^\)]+?)(\)")?(["\)\s]{0,1})(\u00a7)/g, (match, p1, p2, p3, p4, p5) => {
			const checkInnerCondition = this.checkIf(p1)
			const propValue = this.executeGetProp(checkInnerCondition, selected)
			if (propValue && (propValue === 'true' || propValue !== '0')) {
				const content = p4 ? p3 + p4 : p3
				const evaluatedContent = this.executeGetProp(content, selected)
				if (evaluatedContent) { return evaluatedContent }
			}
			return ''
		})
	}

  executeMethods(text: string, selected: any) {
    return text.replace(/(this\.)?([\w]*)\(\)/g,
    (match, p1, p2) => {
      return (typeof selected[p2] === 'function') ? selected[p2]() : ""
    })
  }
  // Get a prop value. We can find for example: $displayName or getProp("displayName")
  executeGetProp(text: string, selected: LayoutComponent, surround?: string): string {

    if (!surround) surround = ''

    const result = text.replace(/\$([\w\d]*)|getProp\(["']{1,1}([\w\d]*)["']{1,1}\)/g, (match, p1, p2) => {

      // p1 is the $prop and p2 is the getProp("prop")-content
			const p = p1 ? p1 : p2
			const propObj = selected.getPropObj(p)
			const foundValue = propObj && propObj.value
			const result = surround + `${foundValue ? propObj.value : ''}${propObj && propObj.second ? propObj.second : ''}` + surround
      return result
    })

		return result
  }

	/*
		⚠️ ATTENTION ⚠️ The DOMParser changes camelCase to lowerCase, for example *ngIf to *ngif or displayName to displayname
		Also, the DOMParser adds ="" to all attributes without text, for example <matInput attr> to <matInput attr="">
	*/
  checkNgIf(text: string, selected: any) {

		const camelCaseWords = this.findAllCamelCase(text)
    let doc = new DOMParser().parseFromString(text, "text/html") // doc changes *ngIf to *ngif, lol
		let elementsWithNgIf = Array.from(doc.querySelectorAll("*")).filter(el => el.hasAttribute("*ngif"))

    elementsWithNgIf.forEach(el => {

      let ngIf = el.getAttribute("*ngif") + ""

      let executedMethods = this.executeMethods(ngIf, selected)
      let executedGetProp = this.executeGetProp(ngIf, selected, "\'")
      let showTag = eval(executedGetProp)
      el.removeAttribute("*ngif")

      // Gleich hier entscheiden, ob wir das Element entfernen oder nicht
      if (!showTag) el.parentNode?.removeChild(el)
    })

		text = doc.body.innerHTML.replace(/=\"\"/g, "")

		// Replace all lowercase words in the variable camelCaseWords and replace them with the correct camelCase
		camelCaseWords.forEach(camelCaseWord => {

			text = text.replaceAll(camelCaseWord.toLowerCase(), camelCaseWord)
		})

    return text
  }

	/**
	 * This will generate the final html code with the correct indentation
	 * @param text Template html string
	 * @param selected The LayoutComponent to generate for
	 * @param tabs The tabs to add before each line
	 * @returns The final html code
	 */
	generateFinalHtml(text: string, selected: any, tabs: string): string {

		return this.generateHtml(text, selected).split('\n').map(line => tabs + line).join('\n')
	}

	generateTypeScript(text: string, selected?: any): string {

		if (!selected) selected = this.elementService.selectedObjects[0]
    text = this.executeGetProp(text, selected)
		return text
	}

	generateCss(text: string, selected?: LayoutComponent): string {

		if (!selected) selected = this.elementService.selectedObjects[0]

    text = this.executeMethods(text, selected)
    text = this.executeGetProp(text, selected)
		return text
	}

  /*
	 * Helper functions
   */
	findAllCamelCase(text: string) {

		// Find all camelCase words
		let camelCaseWords = text.match(/[a-z]{1,}([A-Z][a-z]{1,}){1,}/g)

		// Store the found words in an array
		let foundWords: string[] = []
		camelCaseWords?.forEach(word => {

			// If the word is not already in the array, add it
			if (!foundWords.includes(word)) foundWords.push(word)
		})

		return foundWords
	}

	/**
	 * Generates the route for a given page
	 * @param index The index of the page to generate the route for
	 * @returns The generated route
	 */
	public generateRoute(index: number): string {

		const page = this.dataService.projectsService.currentProject!.pages[index]
		// route += this.searchRoute(page).split('/').map((part) => `'${part}'`).join(', ')
		let route = "\"" + this.searchRoute(page) + "\""
		return route
	}

	private searchRoute(page: SaveLayout) {
		let route = '';

		const paths = this.listPaths(this.dataService.projectsService.currentProject!.pages.find((page) => page.name === 'App')!, true, '');
		route = paths.find(path => path.endsWith(LayoutComponent.generateSelectorName(page.name))) ?? '';

		return route;
	}

	private listPaths(page: SaveLayout, isDefaultPage: boolean, path: string): string[] {
		const result: string[] = [];

		const nextPath = path + (isDefaultPage ? '' : `/${LayoutComponent.generateSelectorName(page.name)}`);
		result.push(nextPath);

		const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(this.dataService.projectsService.currentProject!, page);
		for (const linkedDefaultPage of linkedDefaultPages) {
			const innerResult = this.listPaths(linkedDefaultPage, true, nextPath);
			result.push(...innerResult);
		}

		const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(this.dataService.projectsService.currentProject!, page);
		for (const linkedPage of linkedPages) {
			const innerResult = this.listPaths(linkedPage, false, nextPath);
			result.push(...innerResult);
		}

		return result;
	}
}