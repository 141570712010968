<div class="overlay"></div>

<div class="rounded-lg bg-slate-100 border-solid border-black border gap-4" style="display: flex; flex-direction: column;
						justify-content: flex-start; align-items: center; position: absolute; left: 50%; top: 50%;
						transform: translate(-50%, -50%); padding: 10px; z-index: 98;">
	<a mat-icon-button color>
		<span style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
			<mat-icon>
				hourglass_empty
			</mat-icon>
		</span>
	</a>
	<div style="display: flex; position: relative; z-index: 99; justify-content: center; flex-direction: column; align-items: center;">
		<h1 class="text-black">
			wird geladen...
		</h1>
	</div>
</div>
