import { Component } from "@angular/core"
import { ElementService } from "../../element.service"

@Component({
  selector: 'menu',
  template: ''
})
export class menu {

  constructor(
    public elementService: ElementService
  ){ }

  // ===========
  // PROPERTIES
  // ===========
  getProp(key: string){

    this.value = this.elementService.getProp(key)
    return this.value
  }

  hasProp(key: string): boolean{

    return this.elementService.hasProp(key)
  }

  renderOnlyOuter = false
  isHelper = false
  value: string
  second = ""

  setProp(key: string, value: string, second?: string, isTailwind?: boolean, isHelper?: boolean, renderOnlyOuter?: boolean){

    this.value = value

    this.elementService.setProp({
      key: key,
      value: value,
      second: second!=undefined? second : this.second,
      isTailwind: false,
      isHelper: isHelper!=undefined? isHelper : this.isHelper,
      renderOnlyOuter: renderOnlyOuter!=undefined? renderOnlyOuter : this.renderOnlyOuter,
    })
  }

  /** Nullsafe */
  getPropSecond(key: string){

    let propObj = this.elementService.getPropObj(key)

    if(!propObj) return ""

    this.second = propObj.second

    return this.second
  }

  hasPropSecond(key: string): boolean{

    let propObj = this.elementService.getPropObj(key)?.second

    return propObj&&propObj!=""?true:false
  }

  setPropSecond(key: string, second: string){

    this.second = second

    this.elementService.setProp({
      key: key,
      value: this.value,
      second: second,
      renderOnlyOuter: this.renderOnlyOuter,
      isTailwind: false,
      isHelper: this.isHelper
    })
  }

  getClassesWithout(notProperty?: string){

    return this.elementService.selectedObjects[0].getClassesWithout(notProperty)
  }

  getProperties(){

    return this.elementService.selectedObjects[0].properties
  }

  showGeneralApiDialog = false
  closeGeneralApiCallDialog(event: any){

    this.showGeneralApiDialog = false
  }

  /*
    API CALL STUFF
  */
  showApiCallDialog = false
  closeApiCallDialog(event: any){

    let gotData = event.jsonData?true:false

    this.showApiCallDialog = false

    if(gotData) this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.setColumnAndDataArray(this.columnsArrayFromApiCall, this.dataArrayFromApiCall)
    })
  }
  columnsArrayFromApiCall: any[] = []
  dataArrayFromApiCall: any[] = []

  getType(){

    return this.elementService.selectedObjects[0].name
  }

  /*
    END API CALL STUFF
  */

	getLabelPosition(inputPos: string): "before" | "after" {

    let pos: "before" | "after" = "after"

    switch (inputPos) {
      case "davor":

      pos = "before"
        break;

      case "danach":

      pos = "after"
        break;
    }

    return pos
  }
}
