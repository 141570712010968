<ng-container *ngIf="selectedProject; then thenBlock else elseBlock"></ng-container>

<ng-template #thenBlock>
</ng-template>

<ng-template #elseBlock>
  <div style="background-color: lightseagreen; color: white; font-size: 22px; font-weight: bold; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
    <span>Project Component</span>
  </div>
</ng-template>
