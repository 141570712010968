import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';

@Component({
  selector: 'matSelect-menu',
  templateUrl: 'matSelect.menu.html'
})
export class MatSelectComponentMenu extends menu {

  isHelper = true

  appearances = [
    "standard",
    "fill",
    "outline"
  ]

  amounts = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
  ]

	getRange(): number[] {

    let begin = 1
    let end = Number(this.getProp('Menge'))

		const nums: number[] = [];
		for (let i = begin; i <= end; i++) {

			nums.push(i);
		}

		return nums;
	}
}
