import { Component, Input } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-cursor-menu',
  templateUrl: 'menu.html'
})
export class MatCursorMenu extends menu {

  // @Input() shortName = ""
  @Input() width = "100%"

  renderOnlyOuter = false
  isTailwind = false
  isHelper = false

  cursors = [
		"pointer",
		"alias",
		"all-scroll",
		"auto",
		"cell",
		"col-resize",
		"context-menu",
		"copy",
		"crosshair",
		"default",
		"e-resize",
		"ew-resize",
		"grab",
		"grabbing",
		"help",
		"move",
		"n-resize",
		"ne-resize",
		"nesw-resize",
		"ns-resize",
		"nw-resize",
		"nwse-resize",
		"no-drop",
		"none",
		"not-allowed",
		"progress",
		"row-resize",
		"s-resize",
		"se-resize",
		"sw-resize",
		"text",
		"url",
		"w-resize",
		"wait",
		"zoom-in",
		"zoom-out"
  ]
}
