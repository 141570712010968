<!-- SetProp(key, value, second, isTailwind, isHelper, RenderOnlyOuter) -->
<mat-form-field appearance="fill" [style]="'width: '+ width +';'">
  <mat-label translate>rightMenu.gap</mat-label>
  <mat-select #gapSelect
    (selectionChange)="setProp('gap', gapSelect.value, 'rem', false, false, false)"
    [value]="getProp('gap')"
  >
    <mat-option *ngFor="let gap of gaps" [value]="gap">{{gap}}</mat-option>
  </mat-select>
</mat-form-field>
