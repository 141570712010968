import { FileGenerator } from '../../code-generation.model';
import { DataService } from '../../../data.service';
import { TreeSearchService } from '../../../tree-search.service';
import { AutomationsService } from '../../../automations';

// TODO: Die Dependencies müssen anständig aufgelöst werden!

export class PackageGenerator extends FileGenerator {
  public constructor(private readonly dataService: DataService, private readonly treeSearchService: TreeSearchService, private readonly automationsService: AutomationsService) {
    super();
  }

  public getFilename(): string {
		return 'package.json';
	}

  public generate(): string {
		const result =
`{
  "name": "${this.dataService.projectsService.getNameForCurrentProject()}",
  "version": "0.0.1",
  "description": "The frontend for the ${this.dataService.projectsService.getNameForCurrentProject()} project",
  "author": "Photonic Codes GmbH",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^16.2.0",
    "@angular/cdk": "^16.2.7",
    "@angular/common": "^16.2.0",
    "@angular/compiler": "^16.2.0",
    "@angular/core": "^16.2.0",
    "@angular/forms": "^16.2.0",
    "@angular/material": "^16.2.7",
    "@angular/platform-browser": "^16.2.0",
    "@angular/platform-browser-dynamic": "^16.2.0",
    "@angular/router": "^16.2.0",${this.installSyncfusionKanban()}${this.installSyncfusionMaterial()}${this.installChartJs()}${this.installDropzone()}${this.installFileSaver()}${this.installLodash()}
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",${this.installXLSX()}
    "zone.js": "~0.13.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^16.2.5",
    "@angular/cli": "^16.2.5",
    "@angular/compiler-cli": "^16.2.0",${this.installChartJsTypes()}${this.installDropzoneTypes()}${this.installFileSaverTypes()}
    "@types/jasmine": "~4.3.0",${this.installLodashTypes()}
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.1.3"
  }
}
`;
		return result;
  }

  private fe(t: string): boolean {
    return this.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, t) ? true : false;
  }

  private installChartJs(): string {
		return this.fe('ChartJsComponent') ? '\r\n    "chart.js": "^4.4.0",' : '';
	}

	private installChartJsTypes(): string {
		return this.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'ChartJsComponent') ? '\r\n    "@types/chart.js": "^2.9.38",' : '';
	}

	private installLodash(): string {
		return this.fe('ChartJsComponent') || this.fe('matTableComponent') ? '\r\n    "lodash": "^4.17.21",' : '';
	}

	private installLodashTypes(): string {
		return this.fe('ChartJsComponent') || this.fe('matTableComponent') ? '\r\n    "@types/lodash": "^4.14.199",' : '';
	}

  private installDropzone(): string {
    return this.dataService.automationsForCodeGeneration.size > 0 ? '\r\n    "dropzone": "^6.0.0-beta.2",' : '';
  }

  private installDropzoneTypes(): string {
    return this.dataService.automationsForCodeGeneration.size > 0 ? '\r\n    "@types/dropzone": "^5.7.8",' : '';
  }

  private installXLSX(): string {
    return this.dataService.automationsForCodeGeneration.size > 0 ? '\r\n    "xlsx": "^0.18.5",' : '';
  }

  private installFileSaver(): string {
    return this.dataService.automationsForCodeGeneration.size > 0 ? '\r\n    "file-saver": "^2.0.5",' : '';
  }

  private installFileSaverTypes(): string {
    return this.dataService.automationsForCodeGeneration.size > 0 ? '\r\n    "@types/file-saver": "^2.0.5",' : '';
  }

  private installSyncfusionKanban(): string {
    return this.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban') ? '\r\n    "@syncfusion/ej2-angular-kanban": "^24.1.41",' : '';
  }

  private installSyncfusionMaterial(): string {
    return this.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban') ? '\r\n    "@syncfusion/ej2-material-theme": "^24.1.41",' : '';
  }
}
