import { Component, Input } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, ApiCallAction } from '../../../../automations';

@Component({
  selector: 'api-call-action',
  templateUrl: './api-call.component.html',
  styleUrls: ['./api-call.component.scss']
})
export class ApiCallActionComponent {
  @Input()
  public action: ApiCallAction;

  public constructor(public readonly automationsService: AutomationsService, public readonly elementService: ElementService) {
  }

  private urlTimeout: NodeJS.Timeout;
  public get url(): string {
    return this.action.url;
  }
  public set url(value: string) {
    this.action.url = value;

    if (this.urlTimeout) {
      clearTimeout(this.urlTimeout);
    }
    this.urlTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
  }

  private methodTimeout: NodeJS.Timeout;
  public get method(): string {
    return this.action.method;
  }
  public set method(value: string) {
    this.action.method = value;

    if (this.methodTimeout) {
      clearTimeout(this.methodTimeout);
    }
    this.methodTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
  }

  private headersTimeout: NodeJS.Timeout;
  public get headers(): string {
    return this.action.headers;
  }
  public set headers(value: string) {
    this.action.headers = value;

    if (this.headersTimeout) {
      clearTimeout(this.headersTimeout);
    }
    this.headersTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
  }

  private bodyTimeout: NodeJS.Timeout;
  public get body(): string {
    return this.action.body;
  }
  public set body(value: string) {
    this.action.body = value;

    if (this.bodyTimeout) {
      clearTimeout(this.bodyTimeout);
    }
    this.bodyTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
  }
}
