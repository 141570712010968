export class CreatePropertyDto {
  public readonly saveLayoutId: string;
  public readonly saveLayoutOwner: string;
  public readonly key: string;
  public readonly value: string;
  public readonly second: string;
  public readonly renderOnlyOuter: boolean;
  public readonly isTailwind: boolean;
  public readonly isHelper: boolean;
}
