<div *ngIf="elementService?.allOfType(name)">
  <h2>Slider {{'general.menu' | translate}}:</h2>

  <!-- TEXT -->
  <!-- <app-menu-input [width]="'100%'" [name]="'Label'" [isHelper]="true"></app-menu-input> -->

  <div style="display: flex; justify-content: space-between;">
    <mat-color-menu></mat-color-menu>
  </div>

  <!-- MIN / MAX -->
  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->
    <app-menu-input style="width: 49%" [width]="'100%'" [name]="'min'" [type]="'number'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%" [width]="'100%'" [name]="'max'" [type]="'number'" [isHelper]="true"></app-menu-input>
  </div>

  <!-- Step Size -->
  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->
    <app-menu-input style="width: 49%" [width]="'100%'" [name]="'step'" [type]="'number'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%" [width]="'100%'" [name]="'value'" [type]="'number'" [isHelper]="true"></app-menu-input>
  </div>

  <div style="display: flex; flex-direction: column; justify-content: space-between;"> <!-- Vertical Menu -->
    <mat-toggle-menu [name]="'disableRipple'"></mat-toggle-menu>
    <mat-disabled-menu></mat-disabled-menu>
    <mat-toggle-menu [name]="'discrete'"></mat-toggle-menu>
    <mat-toggle-menu [name]="'showTickMarks'"></mat-toggle-menu>
  </div>
</div>
