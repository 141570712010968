import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

import { ConfigService } from '../config.service';
import { CacheService } from './cache.service';
import { ErrorHandlingService } from './error-handling.service';
import { SaveLayoutsService } from './save-layouts.service';
import { PropertiesService } from './properties.service';
import { Project } from './entities/project.entity';
import { CreateProjectDto } from './dto/create-project.dto';
import { UpdateProjectDto } from './dto/update-project.dto';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private readonly url: string;

  public isProjectsDialogOpen: boolean = false;

  public currentProject?: Project;

  public constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly keycloakService: KeycloakService,
    public readonly cacheService: CacheService,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly saveLayoutsService: SaveLayoutsService,
    private readonly propertiesService: PropertiesService
  ) {
    this.url = `${this.configService.baseUrl}/projects`;
  }

  /*
   *  ██████╗██████╗ ██╗   ██╗██████╗      ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
   * ██╔════╝██╔══██╗██║   ██║██╔══██╗    ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
   * ██║     ██████╔╝██║   ██║██║  ██║    ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
   * ██║     ██╔══██╗██║   ██║██║  ██║    ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
   * ╚██████╗██║  ██║╚██████╔╝██████╔╝    ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
   *  ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝      ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
   */

  public findAll() {
    const request = this.http.get<Project[]>(this.url).pipe(shareReplay(1));
    request.subscribe((data) => this.cacheService.projects = data);
    return request;
  }

  public findOne(id: string, fn?: (project: Project) => void) {
    this.http.get<Project>(`${this.url}/${id}`).subscribe((data) => {
      if (data) {
        const index = this.cacheService.projects.findIndex((p) => p.id === data.id);

        if (index >= 0) {
          this.cacheService.projects[index] = data;
        } else {
          this.cacheService.projects.push(data);
        }

        if (data.id === this.currentProject?.id) {
          this.currentProject = data;
        }

        if (fn) {
          fn(data);
        }
      }
    });
  }

  public create(fn?: (project: Project) => void) {
    const newProject: CreateProjectDto = {
      name: 'New App'
    };

    this.http.post<Project>(this.url, newProject).subscribe((data) => {
      if (data) {
        this.cacheService.projects.push(data);
        if (fn) {
          fn(data);
        }
      }
    });
  }

  public import(importProject: Project) {
    const newProject: CreateProjectDto = {
      name: 'New App'
    };

    return this.http.post<Project>(`${this.url}/${importProject.id}`, newProject);
  }

  public update(project: Project, fn?: (project: Project) => void) {
    const editedProject: UpdateProjectDto = {
      name: project.name
    };

    this.http.put<Project>(`${this.url}/${project.id}`, editedProject).subscribe((data) => {
      if (data) {
        const index = this.cacheService.projects.findIndex((p) => p.id === data.id);

        if (index >= 0) {
          this.cacheService.projects[index] = data;
        } else {
          return;
        }

        if (data.id === this.currentProject?.id) {
          this.currentProject = data;
        }

        if (fn) {
          fn(data);
        }
      }
    });
  }

  public delete(project: Project) {
    this.http.delete<Project>(`${this.url}/${project.id}`).subscribe((receivedProject) => {
      if (receivedProject) {
        this.cacheService.projects = this.cacheService.projects.filter((p) => p.id !== receivedProject.id);
        this.unloadCurrentProject(receivedProject);
        this.saveLayoutsService.findAll().subscribe(); // TODO: Remove only the save layouts of the deleted project
        this.propertiesService.findAll().subscribe(); // TODO: Remove only the properties of the deleted project
      }
    });
  }

  /*
   * ██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗    ███████╗████████╗ █████╗ ████████╗███████╗
   * ██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝    ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
   * ██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║       ███████╗   ██║   ███████║   ██║   █████╗  
   * ██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║       ╚════██║   ██║   ██╔══██║   ██║   ██╔══╝  
   * ██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║       ███████║   ██║   ██║  ██║   ██║   ███████╗
   * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝       ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝
   */

  public setCurrentProject(project?: Project) {
    this.currentProject = project;
    localStorage.setItem('currentProjectId', project?.id.toString() ?? '');
    this.isProjectsDialogOpen = this.currentProject === undefined;
  }

  public unloadCurrentProject(project: Project) {
    if (this.currentProject?.id === project.id) {
      this.setCurrentProject(undefined);
    }
  }

  public loadCurrentProject() {
    const currentProjectId = localStorage.getItem('currentProjectId');
    if (!currentProjectId) { this.isProjectsDialogOpen = this.currentProject === undefined; return; }

    const currentProject = this.cacheService.projects.find((project) => project.id === currentProjectId);
    if (!currentProject) { this.isProjectsDialogOpen = this.currentProject === undefined; return; }

    this.currentProject = currentProject;
  }

  /*
   * ██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗███████╗
   * ██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝██╔════╝
   * ██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║   ███████╗
   * ██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║   ╚════██║
   * ██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║   ███████║
   * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝
   */

  public getName(project: Project): string {
    return project.name;
  }

  public setName(project: Project, name: string) {
    project.name = name;
    this.update(project);
  }

  public getNameForCurrentProject(): string {
    if (!this.currentProject) { return ''; }
    return this.getName(this.currentProject);
  }

  public getProject(id: string): Project | undefined {
    return this.cacheService.projects.find((project) => project.id === id);
  }

  public getSelectorName(id: string): string {
    const project = this.getProject(id);
    if (!project) { return ''; }

    const selectorName = this.getName(project).toLowerCase().replaceAll(' ', '-');
    return this.replaceUmlauts(selectorName);
  }

  public getComponentName(id: string): string {
    const project = this.getProject(id);
    if (!project) { return ''; }

    const componentName = this.getName(project).replaceAll(' ', '');
    return this.replaceUmlauts(componentName);
  }

  private replaceUmlauts(str: string): string {
    return str
      .replaceAll('ä', 'ae')
      .replaceAll('ö', 'oe')
      .replaceAll('ü', 'ue')
      .replaceAll('Ä', 'Ae')
      .replaceAll('Ö', 'Oe')
      .replaceAll('Ü', 'Ue')
      .replaceAll('ß', 'ss');
  }
}
