import { ProjectsService } from 'src/app/projects'
import { FileGenerator } from '../../code-generation.model';

export class EnvironmentGenerator extends FileGenerator {

	constructor(private projectsService: ProjectsService) {

		super()
	}
  public getFilename(): string {

		return 'src/environments/environment.ts'
	}

  public generate(): string {

		const result =
`export const environment = {

  production: false,
  protocol: 'http',
  domain: 'localhost',
  port: '3000',
  apiPrefix: 'api',
  keycloakClient: 'local-${getCorrectKeycloakClientName(this.projectsService.currentProject?.name)}-client'
}`
		return result
  }
}

export function getCorrectKeycloakClientName(name: string | undefined): string {

	return name ? name.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase().replace(/_/g, '-').replaceAll(" ", '-').replaceAll(".", '') : "ERROR-NAME-EMPTY"
}