import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';

import { Project, ProjectsService } from '../../projects';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-projects-dialog',
  templateUrl: './projects-dialog.component.html',
  styleUrls: ['./projects-dialog.component.scss']
})
export class ProjectsDialogComponent implements OnInit {
  public columns: string[] = ['name', 'action'];
  public focusedProjects: Map<string, boolean> = new Map<string, boolean>();

  @ViewChild('projectsTable') public table: MatTable<any>;

  @Output() public closed: EventEmitter<string> = new EventEmitter<string>();

  public constructor(
		public readonly projectsService: ProjectsService,
		public readonly dataService: DataService) {
  }

  public ngOnInit() {
    this.projectsService.findAll();
  }

  public create() {
    this.projectsService.create((project) => {
      this.table.renderRows();
    });
  }

  public open(project: Project) {
    if (this.projectsService.currentProject?.id !== project.id) {
      this.dataService.changeProject(project);
    }
  }

  public delete(project: Project) {
    this.dataService.elementService.selectedObjects = [];
    this.projectsService.unloadCurrentProject(project);
    this.dataService.elementService.changeDetector.detectChanges();

    this.projectsService.delete(project);
  }

  public focus(project: Project) {
    this.focusedProjects.set(project.id, true);
  }

  public unfocus(project: Project) {
    this.focusedProjects.set(project.id, false);
  }

  public setName(project: Project, name: string) {
    this.projectsService.setName(project, name);
  }

  public isFocused(project: Project): boolean {
    return this.focusedProjects.has(project.id) && (this.focusedProjects.get(project.id) ?? false);
  }

  public isSelected(project: Project): boolean {
    if (this.projectsService.currentProject === undefined) { return false; }
    return this.projectsService.currentProject.id === project.id;
  }

  public close() {
    this.closed.emit();
  }
}
