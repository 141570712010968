<div class="overlay"></div>

<div class="dialog">
	<mat-card style="display: flex; flex-direction: column; align-items: flex-start; padding: 20px; overflow: hidden;">


		<!-- first row (Heading & close button) -->
		<div style="width: 100%; display: flex; justify-content: space-between;">

			<h1 id="Heading_left" class="text-black">{{automationsService.currentAutomation.name}} Automation</h1>

			<!-- Close button -->
			<a (click)="emitDataAndClose()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
				<span class="mat-button-span">
					Schliessen
				</span>
			</a>
		</div>
		<!-- end of first row -->

		<!-- second row (content area) -->
		<div class="centered">

			<div *ngIf="!automationsService.currentAutomation?.trigger">
				<hr style="border: none; border-left: 1px dashed #777; height: 30px; width: 100%; margin: 0;">
			</div>

			<div *ngIf="!automationsService.currentAutomation?.trigger" class="richText23" style="cursor: pointer;" (click)="openTriggerDialog()">
				<p><img src="https://api.iconify.design/dashicons/plus-alt.svg?color={{iconColor1}}" alt="" width="32" height="32" (mouseover)="iconColor1 = '%23378ef0'" (mouseout)="iconColor1 = '%23777'"></p>
			</div>

			<div *ngIf="!automationsService.currentAutomation?.trigger && automationsService.currentAutomation.action">
				<hr style="border: none; border-left: 1px dashed #777; height: 30px; width: 100%; margin: 0;">
			</div>

			<!--
████████╗██████╗ ██╗ ██████╗  ██████╗ ███████╗██████╗
╚══██╔══╝██╔══██╗██║██╔════╝ ██╔════╝ ██╔════╝██╔══██╗
   ██║   ██████╔╝██║██║  ███╗██║  ███╗█████╗  ██████╔╝
   ██║   ██╔══██╗██║██║   ██║██║   ██║██╔══╝  ██╔══██╗
   ██║   ██║  ██║██║╚██████╔╝╚██████╔╝███████╗██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝
			-->

			<button-clicked-trigger class="karte" *ngIf="automationsService.currentAutomation?.trigger?.name === 'button-clicked'" [trigger]="automationsService.currentAutomation.trigger!"></button-clicked-trigger>
			<file-uploaded-trigger class="karte" *ngIf="automationsService.currentAutomation?.trigger?.name === 'file-uploaded'" [trigger]="automationsService.currentAutomation.trigger!"></file-uploaded-trigger>
			<app-kanban-card-dropped-trigger class="karte" *ngIf="automationsService.currentAutomation?.trigger?.name === 'kanban-card-dropped'" [trigger]="automationsService.currentAutomation.trigger!"></app-kanban-card-dropped-trigger>

			<!-- for each action -->
			<ng-container *ngFor="let action of automationsService.getActions(automationsService.currentAutomation)">

				<div>
					<hr style="border: none; border-left: 1px dashed #777; height: 30px; width: 100%; margin: 0;">
				</div>

				<div class="richText23" style="cursor: pointer;" (click)="openActionDialog(automationsService.getPreviousAction(automationsService.currentAutomation, action))">
					<p><img src="https://api.iconify.design/dashicons/plus-alt.svg?color={{iconColor2}}" alt="" width="32" height="32" (mouseover)="iconColor2 = '%23378ef0'" (mouseout)="iconColor2 = '%23777'"></p>
				</div>

				<div>
					<hr style="border: none; border-left: 1px dashed #777; height: 30px; width: 100%; margin: 0;">
				</div>

				<!--
	█████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗
	██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
	███████║██║        ██║   ██║██║   ██║██╔██╗ ██║
	██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║
	██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
	╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
				-->
				<split-and-trim-action class="karte" *ngIf="action.name === 'split-and-trim'" [action]="action"></split-and-trim-action>
				<download-file-action class="karte" *ngIf="action.name === 'download-file'" [action]="action"></download-file-action>
				<custom-script-action class="karte" *ngIf="action.name === 'custom-script'" [action]="action"></custom-script-action>
				<export-excel-action class="karte" *ngIf="action.name === 'export-excel'" [action]="action"></export-excel-action>
				<send-email-action class="karte" *ngIf="action.name === 'send-email'" [action]="action"></send-email-action>
				<api-call-action class="karte" *ngIf="action.name === 'api-call'" [action]="action"></api-call-action>
				<kanban-card-dropped-action class="karte" *ngIf="action.name === 'kanban-card-dropped'" [action]="action"></kanban-card-dropped-action>
				<!-- end of action card -->

			</ng-container>

			<div *ngIf="automationsService.currentAutomation?.trigger">
				<hr style="border: none; border-left: 1px dashed #777; height: 30px; width: 100%; margin: 0;">
			</div>

			<div *ngIf="automationsService.currentAutomation?.trigger" class="richText23" style="cursor: pointer;" (click)="openActionDialog(automationsService.getLastAction(automationsService.currentAutomation))">
				<p><img src="https://api.iconify.design/dashicons/plus-alt.svg?color={{iconColor3}}" alt="" width="32" height="32" (mouseover)="iconColor3 = '%23378ef0'" (mouseout)="iconColor3 = '%23777'"></p>
			</div>

		</div>
		<!-- end of second row -->

	</mat-card>
</div>

<addActionDialog *ngIf="automationsService.showAddActionDialog"></addActionDialog>
<add-trigger-dialog *ngIf="automationsService.showAddTriggerDialog"></add-trigger-dialog>
