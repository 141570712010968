<mat-chip-listbox [ngClass]="{'mat-mdc-chip-set-stacked': getProp('ariaOrientation') === 'horizontal'}">
  <mat-chip
    highlighted
    style="justify-content: space-between; display: flex;"
    [color]="getProp('color') !== '' ? getProp('color') : undefined"
    [disabled]="getProp('disabled') === '1'"
    *ngFor="let i of getAmount()" [value]="i"
    (removed)="remove(i)"
  >
    {{ getProp("Label"+i) }}
    <button matChipRemove *ngIf="getProp('removeButton') === '1'">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
</mat-chip-listbox>
