<!--
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██████╗  ██████╗ ██╗   ██╗███╗   ██╗██████╗      ██████╗ ██████╗ ██╗      ██████╗ ██████╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██╔══██╗██╔═══██╗██║   ██║████╗  ██║██╔══██╗    ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗
██████╔╝███████║██║     █████╔╝ ██║  ███╗██████╔╝██║   ██║██║   ██║██╔██╗ ██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██████╔╝
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██╔══██╗██║   ██║██║   ██║██║╚██╗██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██╔══██╗
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║ ╚████║██████╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝╚═════╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝

-->
<mat-form-field appearance="fill" [style]="'width: '+ width +';'">

  <button
    matPrefix
    mat-icon-button
    (click)="elementService.removeProp('background-color')"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-label translate>rightMenu.bgColor</mat-label>
  <input
    #backgroundColor
    type="color"
    matInput
    [value]="getProp('background-color') || '#FFFFFF'"
    (input)="setProp('background-color', backgroundColor.value)">
</mat-form-field>

<!--
██╗  ██╗ ██████╗ ██╗   ██╗███████╗██████╗     ██████╗  ██████╗      ██████╗ ██████╗ ██╗      ██████╗ ██████╗
██║  ██║██╔═══██╗██║   ██║██╔════╝██╔══██╗    ██╔══██╗██╔════╝     ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗
███████║██║   ██║██║   ██║█████╗  ██████╔╝    ██████╔╝██║  ███╗    ██║     ██║   ██║██║     ██║   ██║██████╔╝
██╔══██║██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██╗    ██╔══██╗██║   ██║    ██║     ██║   ██║██║     ██║   ██║██╔══██╗
██║  ██║╚██████╔╝ ╚████╔╝ ███████╗██║  ██║    ██████╔╝╚██████╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║
╚═╝  ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝╚═╝  ╚═╝    ╚═════╝  ╚═════╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝

-->
<mat-form-field appearance="fill" [style]="'width: '+ width +';'">

  <button
    matPrefix
    mat-icon-button
    (click)="elementService.removeProp('background-hover-color')"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-label translate>rightMenu.hoverBgColor</mat-label>

  <input
    #backgroundHoverColor
    type="color"
    matInput
    [value]="getProp('background-hover-color') || '#FFFFFF'"
    (input)="setProp('background-hover-color', backgroundHoverColor.value, '', false, true)">

</mat-form-field>

<!--
██████╗  ██████╗     ████████╗██████╗  █████╗ ███╗   ██╗███████╗██████╗  █████╗ ██████╗ ███████╗███╗   ██╗ ██████╗██╗   ██╗
██╔══██╗██╔════╝     ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝████╗  ██║██╔════╝╚██╗ ██╔╝
██████╔╝██║  ███╗       ██║   ██████╔╝███████║██╔██╗ ██║███████╗██████╔╝███████║██████╔╝█████╗  ██╔██╗ ██║██║      ╚████╔╝
██╔══██╗██║   ██║       ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔═══╝ ██╔══██║██╔══██╗██╔══╝  ██║╚██╗██║██║       ╚██╔╝
██████╔╝╚██████╔╝       ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║     ██║  ██║██║  ██║███████╗██║ ╚████║╚██████╗   ██║
╚═════╝  ╚═════╝        ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝   ╚═╝

-->
<div style="display: flex; flex-direction: column;">
	<label for="backgroundTransparencySlider">Transparenz der Hintergrundfarbe</label>
	<mat-slider style="width: 47%"
		id="backgroundTransparencySlider"
		max="100"
		min="0"
		step="1"
		thumbLabel="true"
		(mouseup)="setProp('background-transparency', '' + backgroundTransparency.value, '', false, true)"
	>
		<input #backgroundTransparency matSliderThumb
			[value]="getProp('background-transparency') !== '' ? +getProp('background-transparency') : 100"
		>
	</mat-slider>
</div>

<!--
██████╗  █████╗ ██████╗ ██╗██╗   ██╗███████╗
██╔══██╗██╔══██╗██╔══██╗██║██║   ██║██╔════╝
██████╔╝███████║██║  ██║██║██║   ██║███████╗
██╔══██╗██╔══██║██║  ██║██║██║   ██║╚════██║
██║  ██║██║  ██║██████╔╝██║╚██████╔╝███████║
╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝ ╚═════╝ ╚══════╝

-->
<mat-form-field appearance="fill" [style]="'width: '+ width +';'">
  <mat-label translate>rightMenu.borderRadius</mat-label>
  <mat-select #borderRadius
              (selectionChange)="setProp('border-radius', borderRadius.value)"
              [value]="getProp('border-radius')"
  >
    <mat-option value="0px"><span translate>borderRadius.square</span></mat-option>
    <mat-option value="5px"><span translate>borderRadius.rounded</span></mat-option>
    <mat-option value="15px"><span translate>borderRadius.fullyRounded</span></mat-option>
  </mat-select>
</mat-form-field>
