import { Component } from '@angular/core'
import { menu } from '../../menus/menu/menu'

@Component({
  selector: 'matTextareaMenu',
  templateUrl: 'menu.html'
})
export class MatTextareaComponentMenu extends menu {

  isHelper = true

  types = [
    "color",
    "date",
    "datetime-local",
    "email",
    "month",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]

  appearances = [
    "standard",
    "fill",
    "outline"
  ]
}
