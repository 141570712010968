<div class="overlay"></div>

<div class="dialog">
	<mat-card style="display: flex; flex-direction: column; align-items: flex-start; padding: 20px; overflow: hidden;">

		<div style="width: 100%; display: flex; justify-content: space-between;">

			<h1 id="Heading_left" class="text-black">Add Action to {{automationsService.currentAutomation.name}}</h1>

			<!-- Close button -->
			<a (click)="emitDataAndClose()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
				<span class="mat-button-span">
					Schliessen
				</span>
			</a>

		</div>

		<div class="actionsArea">

			<div class="richText">
				<p><strong>Actions</strong></p>
			</div>

			<div class="Layout column">

				<ng-container *ngFor="let actionType of getTypes()">

					<div class="actionField" (click)="createAction(actionType)">
						<img *ngIf="actionType === 'custom-script'" src="https://api.iconify.design/logos/javascript.svg?color=%23777" alt="" width="32" height="32">
						<div class="richText2">
							<pre><span style="font-family: arial, helvetica, sans-serif;">{{ getName(actionType) }}</span></pre>
						</div>
					</div>

				</ng-container>

			</div>

		</div>

	</mat-card>
</div>
