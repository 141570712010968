import { Component, Input } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, Trigger } from '../../../../automations';
import { KanbanComponent } from '../../../../syncfusion/kanban/kanban.component';

@Component({
  selector: 'app-kanban-card-dropped-trigger',
  templateUrl: './kanban-card-dropped.component.html',
  styleUrls: ['./kanban-card-dropped.component.scss']
})
export class KanbanCardDroppedTriggerComponent {
  @Input()
  public trigger: Trigger;

  public kanbans: KanbanComponent[] = [];

  public constructor(private readonly elementService: ElementService, private readonly automationsService: AutomationsService) {
  }

  public ngOnInit(): void {
    this.kanbans = this.elementService.findElementsByType('sf-kanban') as KanbanComponent[];
  }

  public get selected(): KanbanComponent | undefined {
    const id = this.automationsService.currentAutomation.trigger?.saveLayoutId;
    if (!id) { return undefined; }
    const element = this.elementService.findElementById(id)[0] as KanbanComponent;
    return element;
  }

  public set selected(element: KanbanComponent | undefined) {
    if (!element) { return; }
    if (!this.automationsService.currentAutomation.trigger) { return; }
    this.automationsService.currentAutomation.trigger.saveLayoutId = element.saveLayout.id;
    this.automationsService.saveAutomations(this.automationsService.currentLayout);
  }
}
