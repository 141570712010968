import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { CodeGenerationService } from '../../code-generation/code-generation.service';

@Component({
	selector: 'app-matButton',
	templateUrl: 'matButton.component.html',
  styleUrls: ['./matButton.component.scss']
})
export class MatButtonComponent extends LayoutComponent {
	public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService, public readonly codeGenerationService: CodeGenerationService) {
		super(elementService, renderer, dataService);
		this.componentType = 'MatButtonComponent';
	}

	setInitialProps(){

		this.ensureProp({ key: 'type', value: 'raised', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
		this.ensureProp({ key: 'color', value: 'primary', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
		this.ensureProp({ key: 'Text', value: 'Neuer Button', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
		this.ensureProp({ key: 'Icon Name', value: 'delete', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

		this.elementService.changeDetector.detectChanges()
	}

	public onButtonClicked(): void {
		this.trigger();
		this.routeToPage();
	}

	// =================
	// STYLE
	// =================
	getAtomicStyle(styles?: string) {

		let type = this.getProp('type')
		let hasText =
			type == 'simple' ||
			type == 'raised' ||
			type == 'stroked' ||
			type == 'flat'

		let style = ''
		let spanStyle =
`
.mat-button-span {

	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
`
		if (!styles?.includes(spanStyle)) style = style + spanStyle

		let iconStyle = `
.mat-button-icon {

	margin-right: 8px;
}
`
		if (
			this.getProp('hasIcon') == '1' &&
			hasText &&
			!styles?.includes(iconStyle)
		) {

			style = style + iconStyle
		}

		if (this.getStyleAttributesForInner() != '') {

			let innerStyles = this.getStyleAttributesForInner()
				.split(';')
				.join(`;\n\t`)
			style =
				style +
		`
.${this.getProp('displayName')} {

	${innerStyles.substring(0, innerStyles.length-2)}
}

`
		}
		return style
	}

	// =================
	// TEMPLATE STRING
	// =================
	public getTemplateString(tabs: string): string {

		let typeMap: Map<string, string> = new Map<string, string>()

		typeMap.set('simple', 'mat-button')
		typeMap.set('raised', 'mat-raised-button')
		typeMap.set('stroked', 'mat-stroked-button')
		typeMap.set('flat', 'mat-flat-button')
		typeMap.set('icon', 'mat-icon-button')
		typeMap.set('fab', 'mat-fab')
		typeMap.set('mini-fab', 'mat-mini-fab')

		let type = this.getProp('type')
		let hasText =
			type == 'simple' ||
			type == 'raised' ||
			type == 'stroked' ||
			type == 'flat'

		let attrMap: Map<string, string> = new Map<string, string>()

		let children = []
		let childrenTabs = '\t' + tabs

		let childrenL2 = []
		if (this.getProp('hasIcon') == '1') {

			let childrenL2Tabs = '\t' + childrenTabs
			if (hasText) attrMap.set('class', 'mat-button-icon')
			childrenL2.push(
				this.buildLeafTag(
					childrenL2Tabs,
					'mat-icon',
					attrMap,
					this.getProp('Icon Name')
				)
			)
		}

		attrMap.clear()
		attrMap.set('class', 'mat-button-span')
		children.push(
			this.buildNormalTag(
				childrenTabs,
				'span',
				attrMap,
				childrenL2,
				hasText ? this.getProp('Text') : ''
			)
		)

		// END CHILDREN
		attrMap.clear()
		if (this.getProp('onClick') != '')
			attrMap.set('(click)', this.getProp('onClick'))
		if (this.getStyleAttributesForInner() != '')
			attrMap.set('class', this.getProp('displayName'))
		attrMap.set(typeMap.get(type)!, '')
		attrMap.set('color', this.getProp('color'))
		if (this.getProp('disabled') == '1') attrMap.set('disabled', '')

		if (this.getProp('pageRoute') !== '') { attrMap.set('[routerLink]', `${this.codeGenerationService.generateRoute(+this.getProp('pageRoute'))}`) }

		attrMap.set('(click)', 'onButtonClicked()');

		return this.buildNormalTag(tabs, 'a', attrMap, children, '')
	}

  public getCodeTemplate(): string {
    const importCode = ""
// `import { AutomationsService, Trigger } from './automations.service';`;

    if (!this.elementService.aboveCode.includes(importCode)) {
      this.elementService.aboveCode += importCode;
    }

    const code = `

	public onButtonClicked(): void {

	}`
// `public constructor(private readonly automationsService: AutomationsService) {
//   this.automationsService.reRegisterTriggers(this);
// }

// public triggers: string[] = [];

// public addTrigger(trigger: Trigger): void {
//   if (!this.triggers.includes(trigger.id)) {
//     this.triggers.push(trigger.id);
//   }
// }

// public removeTrigger(trigger: Trigger): void {
//   const index = this.triggers.indexOf(trigger.id);
//   if (index > -1) {
//     this.triggers.splice(index, 1);
//   }
// }

// public trigger(): void {
//   if (!this.triggers) { return; }
//   for (const triggerId of this.triggers) {
//     const trigger = this.automationsService.automations.find(automation => automation.trigger?.id === triggerId)?.trigger;
//     if (trigger) {
//       trigger.triggerAction();
//     }
//   }
// }

// public onButtonClicked(): void {
//   this.trigger();
// }`;
    return code;
  }
}
