<div *ngIf="elementService?.allOfType('cssButton')">
  <h2>Button Menü:</h2>

  <pageRouting></pageRouting>

  <app-menu-input [width]="'100%'" [name]="'text'" [isHelper]="true"></app-menu-input>

  <app-menu-unit-input style="width: 49%" [width]="'100%'" [name]="'font-size'"></app-menu-unit-input>

  <!-- Rundung -->

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.color</mat-label>
    <input
      #color
      type="color"
      matInput
      [value]="getProp('normal-color')"
      (input)="setProp('normal-color', color.value); unhover()"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.bgColor</mat-label>
    <input
      #backgroundColor
      type="color"
      matInput
      [value]="getProp('normal-background-color')"
      (input)="setProp('normal-background-color', backgroundColor.value); unhover()"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.hoverColor</mat-label>
    <input
      #hoverColor
      type="color"
      matInput
      [value]="getProp('hover-color')"
      (input)="setProp('hover-color', hoverColor.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.hoverBackgroundColor</mat-label>
    <input
      #hoverBackgroundColor
      type="color"
      matInput
      [value]="getProp('hover-background-color')"
      (input)="setProp('hover-background-color', hoverBackgroundColor.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">

		<button
			matPrefix
			mat-icon-button
			(click)="elementService.removeProp('iconURL'); iconURL.value = ''"
		>
			<mat-icon>close</mat-icon>
		</button>

    <mat-label translate>Link des Icons</mat-label>
    <input
      #iconURL
      type="text"
      matInput
      [value]="getProp('iconURL')"
      (input)="setIcon(iconURL.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Farbe des Icons</mat-label>
    <input
      #iconURLColor
      type="color"
      matInput
      [value]="getProp('iconURL-color')"
      (input)="setProp('iconURL-color', iconURLColor.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Link des rechten Icons</mat-label>
    <input
      #iconURL2
      type="text"
      matInput
      [value]="getProp('iconURL-2')"
      (input)="setProp('iconURL-2', iconURL2.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Farbe des rechten Icons</mat-label>
    <input
      #iconURLColor2
      type="color"
      matInput
      [value]="getProp('iconURL-color-2')"
      (input)="setProp('iconURL-color-2', iconURLColor2.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>Textausrichtung</mat-label>
    <mat-select #textAlignmentSelect
      (selectionChange)="setProp('button-text-align', textAlignmentSelect.value)"
      [value]="getProp('button-text-align')"
    >
      <mat-option value="center"><button mat-button color="primary"><span translate>Center</span></button></mat-option>
      <mat-option value="end"><button mat-button color="primary"><span translate>End</span></button></mat-option>
      <mat-option value="justify"><button mat-button color="primary"><span translate>Justify</span></button></mat-option>
      <mat-option value="left"><button mat-button color="primary"><span translate>Left</span></button></mat-option>
      <mat-option value="right"><button mat-button color="primary"><span translate>Right</span></button></mat-option>
      <mat-option value="start"><button mat-button color="primary"><span translate>Start</span></button></mat-option>
      <mat-option value="-webkit-auto"><button mat-button color="primary"><span translate>WebKit Auto</span></button></mat-option>
      <mat-option value="-webkit-center"><button mat-button color="primary"><span translate>Webkit Center</span></button></mat-option>
      <mat-option value="-webkit-left"><button mat-button color="primary"><span translate>Webkit Left</span></button></mat-option>
      <mat-option value="-webkit-match-parent"><button mat-button color="primary"><span translate>Webkit Match Parent</span></button></mat-option>
      <mat-option value="-webkit-right"><button mat-button color="primary"><span translate>Webkit Right</span></button></mat-option>
      <mat-option value="inherit"><button mat-button color="primary"><span translate>Inherit</span></button></mat-option>
      <mat-option value="initial"><button mat-button color="primary"><span translate>Initial</span></button></mat-option>
      <mat-option value="revert"><button mat-button color="primary"><span translate>Revert</span></button></mat-option>
      <mat-option value="revert-layer"><button mat-button color="primary"><span translate>Revert Layer</span></button></mat-option>
      <mat-option value="unset"><button mat-button color="primary"><span translate>Unset</span></button></mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>Fett</mat-label>
    <mat-select #fontWeightSelect
      (selectionChange)="setProp('button-font-weight', fontWeightSelect.value)"
      [value]="getProp('button-font-weight')"
    >
      <mat-option value="bold"><button mat-button color="primary"><span translate>Fett</span></button></mat-option>
      <mat-option value="bolder"><button mat-button color="primary"><span translate>Fetter</span></button></mat-option>
      <mat-option value="lighter"><button mat-button color="primary"><span translate>Leichter</span></button></mat-option>
      <mat-option value="normal"><button mat-button color="primary"><span translate>Normal</span></button></mat-option>
    </mat-select>
  </mat-form-field>

  <app-menu-border></app-menu-border>
  <app-menu-box-shadow></app-menu-box-shadow>
</div>
