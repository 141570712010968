<div class="page-editor-wrapper">
	<h1>Page Editor</h1>

<!--
██████╗  █████╗  ██████╗ ███████╗    ██╗     ██╗███████╗████████╗
██╔══██╗██╔══██╗██╔════╝ ██╔════╝    ██║     ██║██╔════╝╚══██╔══╝
██████╔╝███████║██║  ███╗█████╗      ██║     ██║███████╗   ██║
██╔═══╝ ██╔══██║██║   ██║██╔══╝      ██║     ██║╚════██║   ██║
██║     ██║  ██║╚██████╔╝███████╗    ███████╗██║███████║   ██║
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝    ╚══════╝╚═╝╚══════╝   ╚═╝

-->
	<ng-container *ngFor="let page of pages; index as i; first as isFirst">
		<div class="page-editor-instance" [ngStyle]="{ 'margin-left': indents[i] + 'px' }">

			<!-- Page Name -->
			<div class="page-editor-instance-text">
				<input
					#pageName
					type="text"
					class="buttonInput"
					[value]="page.name"
					(change)="changePageName(pageName.value, i)"
				/>
			</div>

			<div class="page-editor-instance-button">

				<!-- Open -->
				<button
					mat-mini-fab
					color
					mat-dialog-close
					(click)="openPage(i)"
					[disabled]="isTyping"
				>
					<mat-icon>open_in_new</mat-icon>
				</button>

				<!-- Delete -->
				<button
					mat-mini-fab
					color
					(click)="deletePage(i)"
					[disabled]="isTyping || isFirst"
				>
					<mat-icon>delete_forever</mat-icon>
				</button>

				<button
				  mat-mini-fab
					color
					(click)="copyPage(i)"
					[disabled]="isTyping"
				>
					<mat-icon>content_copy</mat-icon>
				</button>
			</div>

		</div>
	</ng-container>

<!--
██╗      █████╗ ███████╗████████╗    ██████╗  ██████╗ ██╗    ██╗
██║     ██╔══██╗██╔════╝╚══██╔══╝    ██╔══██╗██╔═══██╗██║    ██║
██║     ███████║███████╗   ██║       ██████╔╝██║   ██║██║ █╗ ██║
██║     ██╔══██║╚════██║   ██║       ██╔══██╗██║   ██║██║███╗██║
███████╗██║  ██║███████║   ██║       ██║  ██║╚██████╔╝╚███╔███╔╝
╚══════╝╚═╝  ╚═╝╚══════╝   ╚═╝       ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝

-->
<div class="page-editor-instance">

	<div class="page-editor-instance-button">

		<!-- Add -->
		<button mat-mini-fab color (click)="addPage()" [disabled]="isTyping">
			<mat-icon>add_circle</mat-icon>
		</button>

	</div>
</div>
<!-- End last row -->

	<div class="page-editor-ok">
		<button mat-raised-button mat-dialog-close color="primary">OK</button>
	</div>
</div>
