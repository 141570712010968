import { Component, Input } from '@angular/core'

import { DataService } from "../../data.service"
import { ElementService } from "../../element.service"
import { SaveLayout } from '../../projects'
import { menu } from "../menu/menu"

@Component({
  selector: 'pageRouting',
  templateUrl: './pageRouting.menu.html',
})
export class PageRoutingMenu extends menu {
  // @Input() shortName = ""
  @Input() width = "100%"

  renderOnlyOuter = false
  isTailwind = false
  isHelper = true

  constructor(
    public elementService: ElementService,
    public dataService: DataService
  ) {
    super(elementService)
  }

  public getIndex(page: SaveLayout): string {
    return '' + this.dataService.projectsService.currentProject!.pages.findIndex((anotherPage) => this.getId(anotherPage) === this.getId(page));
  }

  private getId(page: SaveLayout): string {
    return this.dataService.treeSearchService.getId(page);
  }
}
