import { FileGenerator } from '../../code-generation.model';
import { DataService } from '../../../data.service';
import { LayoutComponent } from '../../../layout/layout.component';
import { SaveLayout } from '../../../projects';
import { ProjectComponent } from '../../../project/project.component';

export class AppModuleGenerator extends FileGenerator {
  public constructor(private readonly dataService: DataService) {
    super();
  }

	public getFilename(): string {
		return 'src/app/app.module.ts';
	}

  public generate(): string {
		const result =
`import { NgModule } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'${this.dataService.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban') ? `\r\nimport { KanbanModule } from '@syncfusion/ej2-angular-kanban'` : '' }

import { MaterialModule } from './material.module'
import { AppRoutingModule } from './app-routing.module'

import { AppComponent } from './app.component'${this.generateAppModuleImports()}

@NgModule({
  declarations: [
    AppComponent,${this.generateAppModuleDeclarations()}
  ],
  imports: [
		HttpClientModule,
    BrowserModule,
		BrowserAnimationsModule,
		MaterialModule,
    AppRoutingModule,${this.dataService.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban') ? '\r\n    KanbanModule,' : ''}
  ],
  providers: [
		HttpClient,
		{provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
	],
  bootstrap: [AppComponent]
})
export class AppModule {
}
`
		return result;
  }

  private generateAppModuleImports(): string {
		let result = '\r\n';

		for (const page of this.dataService.projectsService.currentProject!.pages.filter((page) => page.name !== 'App')) {
			result += this.generateAppModuleImport(page);
		}

		for (const [projectComponentId, projectState] of this.dataService.generationContext?.projectStateTable ?? []) {
			for (const page of projectState.pages) {
				const importStatement = this.generateAppModuleImport(page);
				if (!result.includes(importStatement)) {
					result += importStatement;
				}
			}
		}

		result = result.slice(0, -2);

		return result;
	}

	private generateAppModuleImport(page: SaveLayout): string {

		const id = this.dataService.treeSearchService.getId(page)
		if (page.name === 'App' && LayoutComponent.isComponent(this.dataService, id)) {

			const projectComponentId = LayoutComponent.getProjectComponent(this.dataService, id)!
			const projectComponent = this.dataService.generationContext!.componentTable.get(projectComponentId)! as ProjectComponent
			const projectId = projectComponent.selectedProject!
			return `import { ${this.dataService.projectsService.getComponentName(projectId)}Component } from './${this.dataService.projectsService.getSelectorName(projectId)}/${this.dataService.projectsService.getSelectorName(projectId)}.component';\r\n`
		}

		const selectorName = LayoutComponent.generateSelectorName(page.name)
		const componentName = LayoutComponent.generateComponentName(page.name)
		return `import { ${componentName}Component } from './pages/${selectorName}/${selectorName}.component';\r\n`
	}

	private generateAppModuleDeclarations(): string {
		let result = '\r\n';

		for (const page of this.dataService.projectsService.currentProject!.pages.filter((page) => page.name !== 'App')) {
			result += this.generateAppModuleDeclaration(page);
		}

		for (const [projectComponentId, projectState] of this.dataService.generationContext?.projectStateTable ?? []) {
			for (const page of projectState.pages) {
				const declarationStatement = this.generateAppModuleDeclaration(page);
				if (!result.includes(declarationStatement)) {
					result += declarationStatement;
				}
			}
		}

		result = result.slice(0, -3);

		return result;
	}

	private generateAppModuleDeclaration(page: SaveLayout): string {
		const id = this.dataService.treeSearchService.getId(page);
		if (page.name === 'App' && LayoutComponent.isComponent(this.dataService, id)) {
			const projectComponentId = LayoutComponent.getProjectComponent(this.dataService, id)!;
			const projectComponent = this.dataService.generationContext!.componentTable.get(projectComponentId)! as ProjectComponent;
			const projectId = projectComponent.selectedProject!;
			return `    ${this.dataService.projectsService.getComponentName(projectId)}Component,\r\n`;
		}

		const componentName = LayoutComponent.generateComponentName(page.name);
		return `    ${componentName}Component,\r\n`;
	}
}
