<div class="overlay"></div>

<div class="dialog">
  <mat-card class="card">

    <div class="content">

      <div style="margin: 1%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <div style="flex: 1; text-align: center; font-size: x-large; font-weight: bold;">
          <span>Import</span>
        </div>
  
        <button
          mat-raised-button
          color="primary"
          (click)="close()"
        >
          <span translate>general.close</span>
        </button>
      </div>

      <div style="margin: 4px;">
        <span>Das Projekt ist bereits vorhanden. Soll das gespeicherte Projekt mit dem importierten Projekt überschrieben werden?</span>
      </div>

      <div style="width: 100%; height: 100%; display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">
        <button
          mat-raised-button
          color="warn"
          style="width: 49%; height: 42px;"
          (click)="apply(dataService.importedObject)"
        >
          <span>Übernehmen</span>
        </button>

        <button
          mat-raised-button
          color="warn"
          style="width: 49%; height: 42px;"
          (click)="discard(dataService.importedObject.project.id)"
        >
          <span>Verwerfen</span>
        </button>
      </div>

    </div>

  </mat-card>
</div>
