<div *ngIf="elementService?.allOfType('matChips')">
  <h2>Chips {{'general.menu' | translate}}:</h2>

  <div style="display: flex; flex-direction: column; justify-content: space-between;">
    <mat-disabled-menu></mat-disabled-menu>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <mat-toggle-menu [name]="'removeButton'"></mat-toggle-menu>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <mat-color-menu></mat-color-menu>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <app-menu-mat-aria-orientation></app-menu-mat-aria-orientation>
  </div>

  <mat-amount-menu [maxAmount]="6"></mat-amount-menu>
  <app-menu-input *ngFor="let amount of getRange()" [width]="'100%'" [name]="'Label' + amount" [isHelper]="true"></app-menu-input>
</div>
