<div style="width: 100%;">

  <div class="header-area">
    <div class="left-side">
      <div class="trigger-icon">
        <p><br><img src="https://api.iconify.design/iconoir/system-restart.svg?color=%23777" alt="" width="52"
            height="52"></p>
      </div>
      <div class="heading-texts">
        <div class="trigger-text">
          <p>Trigger</p>
        </div>
        <div class="trigger-type-text">
          <p><span style="font-size: 14pt;"><strong>{{name}}</strong></span></p>
        </div>
      </div>
    </div>
    <div class="richText" (click)="automationsService.deleteTrigger(automationsService.currentLayout, automationsService.currentAutomation)"
      style="cursor: pointer;">
      <p><br><img src="https://api.iconify.design/ic/baseline-delete.svg?color=%23777" alt="" width="32"
          height="32"></p>
    </div>
  </div>

  <ng-content></ng-content>

</div>
