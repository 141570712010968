import { saveAs } from 'file-saver';

import { Action } from '../action';

export class DownloadFileAction extends Action {
  public fileName: string = '';
  public fileExtension: string = '';

  public action(): void {
    const blob = new Blob([this.input], { type: 'charset=utf-8' });
    saveAs(blob, `${this.fileName}.${this.fileExtension}`);
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      fileName: this.fileName,
      fileExtension: this.fileExtension
    }
  }
}
