import { Component, EventEmitter, Output } from '@angular/core';

import { DataService } from '../../data.service';
import { Project } from '../../projects';

@Component({
  selector: 'app-import-options-dialog',
  templateUrl: './import-options-dialog.component.html',
  styleUrls: ['./import-options-dialog.component.scss']
})
export class ImportOptionsDialogComponent {
  @Output()
  public closed: EventEmitter<string> = new EventEmitter<string>();

  public constructor(public readonly dataService: DataService) {
  }

  public apply(importingProject: Project): void {
    this.dataService.appComponent.showLoadingDialog = true;
    this.dataService.importProject(importingProject);
    this.close();
  }

  public discard(projectId: string): void {
    const existingProject = this.dataService.projectsService.cacheService.projects.find((project) => project.id === projectId);
    if (existingProject) {
      if (this.dataService.projectsService.currentProject?.id !== existingProject.id) {
        this.dataService.changeProject(existingProject);
      }
    }
    this.close();
  }

  public close(): void {
    this.closed.emit();
  }
}
