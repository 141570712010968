<div class="overlay"></div>

<div class="dialog">
  <mat-card>
    <button mat-raised-button style="position: absolute; right: 10px; top: 10px; z-index: 3;" color="primary" (click)="close()"><span translate>general.close</span></button>
    <div>
      <div id="x-spreadsheet-edit"></div>
    </div>
  </mat-card>
</div>
