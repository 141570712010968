<table  mat-table
        [dataSource]="dataSource"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
        [ngClass]="getClassesWithout()"
>

  <!-- For each Column, where the columns are the obj keys of the data structure -->
  <ng-container *ngFor="let columnFieldName of columns; let colIndex = index" [matColumnDef]="columnFieldName">

    <!-- The column header -->
    <th mat-header-cell *matHeaderCellDef style="position: relative;" cdkDrag [cdkDragDisabled]="resizeWidth">

      <div *ngIf="hasRedOutline">

        <!-- Drag handle -->
        <div cdkDragHandle style="position: absolute;top: 0px;right: 10px;color: #ccc;cursor: move;width: 12px; height: 12px; z-index: 3;"><svg width="12px" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></div>

        <!-- Add Column left -->
        <div *ngIf="colIndex == 0" (click)="addColumn(colIndex+1)" style="position: absolute; top: -35px; left: -15px; color: #ccc; cursor: pointer; width: 12px; height: 12px; z-index: 3;" DeSelectOff>
          <mat-icon DeSelectOff style="color: green;">add_circle_outline</mat-icon></div>

        <!-- Select Arrow and field name -->
        <div (click)="toggleSelectedColumn(colIndex+1)" style="position: absolute; transform: translate(-50%, -50%); left: 50%; top: 1px; z-index: 2; display: flex;">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="3em" height="3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200">
            <path DeSelectOff [style]="getProp('selectedColumn') == (colIndex+1)+'' ?'fill: green;':'fill: gray'" d="M600 1200L131.25 496.875h252.539V0h432.422v496.875h252.539L600 1200z"/>
          </svg>
        </div>

        <!-- Add Column right -->
        <div (click)="addColumn(colIndex+2)" style="position: absolute; top: -35px; right: 0px; color: #ccc; cursor: pointer; width: 12px; height: 12px; z-index: 3;" DeSelectOff>
          <mat-icon DeSelectOff style="color: green;">add_circle_outline</mat-icon></div>

        <!-- Column resize -->
        <div DeSelectOff style="cursor: col-resize; width: 10px; position: absolute; z-index: 2; display: flex; align-items: center; right: 0px" (mousedown)="mouseDown($event, columnFieldName)">
          <svg DeSelectOff height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path DeSelectOff d="m0 0h16v16h-16z" fill="none"/>
            <path DeSelectOff fill="gray" d="m6.999 16h2.002v-16h-2.002zm-1.585-10.586-1.414-1.414-4 4 4 4 1.414-1.414-1.586-1.586h2.172v-2h-2.172zm6.586-1.414-1.414 1.414 1.586 1.586h-2.172v2h2.172l-1.586 1.586 1.414 1.414 4-4z"/></svg></div>
      </div>

      <div class="superHeader">
        <div class="custom-placeholder" *cdkDragPlaceholder></div>

        <input
          type="text"
          DeSelectOff
          #thisField
          size="1"
          style="color: inherit; font: inherit; text-align: center;"
          class="focus:outline-0"
          [value]="getInputValue(thisField, colIndex + 1)"
          (input)="setPropAsHelper('Col' + (colIndex + 1) + 'Text', thisField.value)"
          (focus)="elementService.tfFocussed(true)"
          (focusout)="elementService.tfFocussed(false)"
        >
      </div>
    </th>

    <!-- The individual data cells -->
    <td mat-cell #thisCell *matCellDef="let element; let rowNum = index">
      {{element[columnFieldName]}} </td>

    <!-- The footer cells -->
    <td mat-footer-cell *matFooterCellDef [ngStyle]="{'visibility': getProp('footer')=='1' ? 'visible': 'hidden'}"></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  <tr mat-footer-row *matFooterRowDef="columns" [ngStyle]="{'display': getProp('footer')=='1' ? 'revert': 'none'}"></tr>
</table>
