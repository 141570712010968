import { NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { LayoutComponent } from '../../layout/layout.component';

/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'tree',
  templateUrl: 'tree.component.html',
  styleUrls: ['tree.component.scss'],
})
export class TreeComponent {
  treeControl = new NestedTreeControl<LayoutComponent>(node => node.children);
  dataSource = new MatTreeNestedDataSource<LayoutComponent>();

  constructor() { }

  hasChild = (_: number, node: LayoutComponent) => !!node.children && node.children.length > 0;

  update() {

    // this.dataSource.data = [this.elementService.rootNode]
    let _data = this.dataSource.data
    this.dataSource.data = []
    this.dataSource.data = _data
  }
}
