import { Component, Input } from '@angular/core';

import { Action, AutomationsService } from '../../../../automations';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   Action                                            |
           |   ===============================================   |
           |   * an action											                 |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent {

  @Input()
  public action: Action;

  @Input()
  public name: string = '';

  public constructor(public readonly automationsService: AutomationsService) {
  }
}
