import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { TreeSearchService } from '../../tree-search.service';
import { CreateSaveLayoutDto, ProjectsService, PropertyIterator, SaveLayout, SaveLayoutsService } from '../../projects';

@Component({
	selector: 'app-dialog-page-editor',
	templateUrl: './dialog-page-editor.component.html',
	styleUrls: ['./dialog-page-editor.component.scss'],
})
export class DialogPageEditorComponent implements OnInit {
	public pages: SaveLayout[] = [];
	public indices: number[] = [];
	public indents: number[] = [];

	public timeout: any = null;
	public isTyping = false;

	constructor(public readonly dataService: DataService, public readonly projectsService: ProjectsService, public readonly saveLayoutsService: SaveLayoutsService, public readonly treeSearchService: TreeSearchService) {
	}

	public ngOnInit(): void {
		this.getPages();
	}

	private makeNameUnique(pageName: string): string {
		let identicalNames = this.getPagesNames().filter((exisingPageName) => exisingPageName.startsWith(pageName))
		let identicalNamesCounter = 1
		let newPageName = pageName
		if (identicalNames && identicalNames.length > 0) {
			while (identicalNames.includes(newPageName)) {
				identicalNamesCounter++
				newPageName = `${pageName}(${identicalNamesCounter})`
			}
		}
		return newPageName
	}

	private getPagesNames(): string[] {
		return this.projectsService.currentProject?.pages.map((page) => page.name) ?? [];
	}

	/**
	 * ### Get Pages
	 * Looks up all pages and sorts them in the right order with the correct indentation.
	 */
	getPages(): void {
		// Listen leeren
		this.pages = [];
		this.indices = [];

		// Pages berechnen
		for (const page of this.projectsService.currentProject?.pages ?? []) {
			const linkingDefaultPages: SaveLayout[] = this.treeSearchService.getLinkingDefaultPages(this.projectsService.currentProject!, page);
			const linkingPages: SaveLayout[] = this.treeSearchService.getLinkingPages(this.projectsService.currentProject!, page);
			if (linkingDefaultPages.length + linkingPages.length === 0) {
				this.orderPages(page);
			}
		}

		// Indents berechnen
		this.indents = Array(this.pages.length).fill(0);
		for (let index = 0; index < this.pages.length; index++) {
			this.indentPages(index);
		}
	}

	orderPages(page: SaveLayout): void {
		const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(this.projectsService.currentProject!, page);
		const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(this.projectsService.currentProject!, page);

		this.pages.push(page);
		this.indices.push(this.getOriginalIndex(page));

		if (linkedDefaultPages.length > 0) {
			for (let index = 0; index < linkedDefaultPages.length; index++) {
				const linkedDefaultPage = linkedDefaultPages[index];
				this.orderPages(linkedDefaultPage);
			}
		}

		if (linkedPages.length > 0) {
			for (let index = 0; index < linkedPages.length; index++) {
				const linkedPage = linkedPages[index];
				this.orderPages(linkedPage);
			}
		}
	}

	indentPages(startIndex: number): void {
		let stack: number[] = [ startIndex ];

		while (stack.length > 0) {
			const currentIndex = stack.pop();

			if (currentIndex) {
				const current = this.pages[currentIndex];
				for (let aboveIndex = 0; aboveIndex < currentIndex; aboveIndex++) {
					const above = this.pages[aboveIndex];
					if (above) {
						const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(this.projectsService.currentProject!, above);
						const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(this.projectsService.currentProject!, above);
						if (linkedDefaultPages.includes(current) || linkedPages.includes(current)) {
							this.indents[startIndex] += 100;
							stack.push(aboveIndex);
							break;
						}
					}
				}
			}
		}
	}

	getOriginalIndex(page: SaveLayout): number {
		return this.projectsService.currentProject!.pages.findIndex((p) => [...(new PropertyIterator(this.dataService.propertiesService, p))].find((prop) => prop.key === 'id' && prop.value === [...(new PropertyIterator(this.dataService.propertiesService, page))].find((prop) => prop.key === 'id')?.value));
	}

  /*
  *  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
  * ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
  * ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
  * ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
  * ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
  * ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
  */

	public addPage() {

		const uniquePageName = this.makeNameUnique('New Page')

		const newPage: CreateSaveLayoutDto = {
			projectId: this.projectsService.currentProject!.id,
			projectOwner: this.projectsService.currentProject!.owner,
			name: uniquePageName,
			isLayout: true,
			orderNumber: this.projectsService.currentProject!.pages.length
		}

		this.saveLayoutsService.create(this.projectsService.currentProject!, newPage, (page) => {

			this.projectsService.findOne(this.projectsService.currentProject!.id, (project) => {

				this.getPages()
			})
		})
	}

	public changePageName(pageName: string, index: number) {
		let uniquePageName = this.makeNameUnique(pageName);

		const id = this.projectsService.currentProject!.pages[this.indices[index]]!.id;
		const layout = this.dataService.saveLayoutsService.cacheService.saveLayouts.find((layout) => layout.id === id)!;
		layout.name = uniquePageName;

		this.saveLayoutsService.update(layout, () => {
			this.projectsService.findOne(this.projectsService.currentProject!.id, (project) => {
				this.getPages();
			});
		});
	}

	public openPage(index: number) {
		this.dataService.loadPageIndexIntoTheApp(this.indices[index]);
	}

	public deletePage(index: number) {
		let isCurrentPage = this.dataService.currentPage.name === this.projectsService.currentProject?.pages[this.indices[index]].name ?? '';
		const id = this.projectsService.currentProject!.pages[this.indices[index]]!.id;
		const layout = this.dataService.saveLayoutsService.cacheService.saveLayouts.find((layout) => layout.id === id)!;

		this.saveLayoutsService.delete(layout, () => {
			this.projectsService.findOne(this.projectsService.currentProject!.id, (project) => {
				if (isCurrentPage) {
					this.dataService.loadDefaultPageIntoTheApp();
				}
				this.getPages();
			});
		});
	}

	public copyPage(index: number) {
		const page = this.projectsService.currentProject!.pages[this.indices[index]]!;
		const randomNumber = Math.floor(Math.random() * 1000);
		this.dataService.elementService.clonePage(page, `${page.name}${randomNumber}`);
	}
}
