import { AfterViewInit, Component, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

import { LayoutComponent } from '../layout/layout.component';
import { ElementService } from '../element.service';
import { DataService } from '../data.service';
import { PageReference } from '../data.model';
import { SaveLayout } from '../projects';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends LayoutComponent implements AfterViewInit {
  @ViewChild('thenBlock', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  public selectedProject?: string;
  public startPage?: string;

  private loadedComponent?: LayoutComponent;

  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'ProjectComponent';
  }

  public ngAfterViewInit(): void {
    this.viewContainerAnker = this.container;

    this.ensureProp({ key: 'selectedProject', value: '', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'startPage', value: '', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

    this.selectedProject = this.getProp('selectedProject');
    this.startPage = this.getProp('startPage');

    setTimeout(() => {
      this.load();
    }, 10);
  }

  public load(): void {
    if (!this.viewContainerAnker) { return; }
    if (!this.selectedProject) { return; }

    const project = this.dataService.projectsService.getProject(this.selectedProject);
    if (!project) { return; }

    if (!project.pages) { return; }

    this.clearView();

    this.dataService.projectContext.projectStateTable.set(this.id, project);

    const page: SaveLayout = project.pages.find((projectStatePage: SaveLayout) => this.dataService.treeSearchService.getId(projectStatePage) === this.startPage) ?? project.pages[0];

    if (!this.dataService.projectContext.currentPagesTable.has(this.id)) {
      this.dataService.projectContext.currentPagesTable.set(this.id, new PageReference());
    }
    this.dataService.projectContext.currentPagesTable.get(this.id)!.reference(this.dataService.propertiesService, page);

    try {
      this.dataService.projectsAreLoaded.set(this.id, false);
      this.loadedComponent = this.dataService.loadElement(this, page, { append: false, isEmbedded: true, prefix: this.getProp('displayName') });
      this.dataService.projectsAreLoaded.set(this.id, true);
    } catch {
      return;
    }
    this.loadedComponent.setEditable(false);

    this.dataService.automationsService.loadAutomationsForLayoutComponent(this.loadedComponent);
		this.dataService.automationsService.reRegisterTriggers(this.loadedComponent);

    this.elementService.changeDetector.detectChanges();
  }

  private clearView() {
    if (this.loadedComponent) {
      this.loadedComponent.removeMe();
      this.loadedComponent = undefined;
    }

    this.children.forEach((child: LayoutComponent) => {
      child.removeMe();
    });

    if (this.container) {
      this.container.clear();
    }
  }

  public setSelectedProject(projectId?: string): void {
    this.setProp({ key: 'selectedProject', value: '' + projectId, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.selectedProject = projectId;
    this.load();
  }

  public setStartPage(pageId?: string): void {
    this.setProp({ key: 'startPage', value: pageId ?? '', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.startPage = pageId;
    this.load();
  }

  protected beforeRemoveMe(): void {
    this.clearView();
    this.dataService.projectContext.projectStateTable.delete(this.id);
    this.dataService.projectContext.currentPagesTable.delete(this.id);
  }

  public getHtmlTemplate(): string {
    const selector = this.selectedProject ? this.dataService.projectsService.getSelectorName(this.selectedProject) : '';

    const template = `<app-${selector} prefix="routeroutlet!${this.getProp('displayName')}!"></app-${selector}>`;
    return template;
  }

  public getCssTemplate(): string {
    return '';
  }

  public getCodeTemplate(): string {
    return '';
  }
}
