import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core'
import { ElementService } from '../../element.service'
import { resultPreviewTable } from './apiCall/resultPreviewTable/resultPreviewTable'
import { apiCall } from './apiCall/apiCall'

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   API Call Dialog                                   |
           |   ===============================================   |
           |   * uses one or more api calls                      |
           |   * stores the end result of the calling chain      |
           |   * returns the end result to the caller            |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'apiCallDialog',
  templateUrl: './apiDialog.html',
  styleUrls: ['./apiDialog.scss']
})
export class ApiCallDialog implements OnInit {

  @Input() elementType: string
  @Output() closed: EventEmitter<ApiCallDialog> = new EventEmitter<ApiCallDialog>()
  @Output() returnColumns: EventEmitter<string[]> = new EventEmitter<string[]>()
  @Output() returnData: EventEmitter<any[]> = new EventEmitter<any[]>()

  constructor(
    public elementService: ElementService,
    private changeDetector: ChangeDetectorRef
  ){ }

  apiCalls: apiCall[] = []
  @ViewChild('apiCall', { static: true }) apiCall: apiCall | undefined
  @ViewChild('apiCallContainer', { read: ViewContainerRef }) apiCallContainer: ViewContainerRef

  ngOnInit(): void {

    this.setSelectionType(this.elementType)
    let apiCalls = this.elementService.getProp("apiCalls")
    if(apiCalls == "") apiCalls = "1"

    for(let i = 0; i < +apiCalls; i++){

      this.addCountedApiCall()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.changeDetector.detectChanges()
  }

  /**
   * ### Adds an api call and counts it
   */
  addCountedApiCall(){

    this.addApiCall()
    this.elementService.setProp({
      key: "apiCalls",
      value: this.apiCalls.length+"",
      second: "",
      isHelper: true,
      isTailwind: false,
      renderOnlyOuter: false,
    })
  }


  /**
   * ### Adds an api call
   */
  private addApiCall(){

    let newApiCallRef = this.apiCallContainer.createComponent(apiCall)
    this.apiCalls.push(newApiCallRef.instance)

    // Get the hightest number of myNum in the apiCalls array
    let biggest = 0
    this.apiCalls.forEach((apiCall: apiCall) => {

      if(apiCall.myNum > biggest) biggest = apiCall.myNum
    })

    newApiCallRef.setInput("myNum", biggest + 1)

    newApiCallRef.instance.removeMeNow.subscribe((num: number) => {

      let arrayIndex = this.apiCalls.indexOf(newApiCallRef.instance)
      let viewIndex = this.apiCallContainer.indexOf(newApiCallRef.hostView)

      // Remove the apiCall from the array
      this.apiCalls.splice(arrayIndex, 1)

      // Remove the apiCall from the view
      this.apiCallContainer.remove(viewIndex)

      this.elementService.setProp({
        key: "apiCalls",
        value: this.apiCalls.length+"",
        second: "",
        isHelper: true,
        isTailwind: false,
        renderOnlyOuter: false,
      })
    })

		/**
		 * ### Notify the dialog, that the apiCall has selected data
		 * If the Notion Mode is activated, it is after receiving the data
		 * In jsonViewer or htmlViewer mode, it is after the user has selected the data
		 */
    newApiCallRef.instance.tellApiDialogAboutASelection.subscribe(() => {

      if(this.apiCalls.length == 1) {

        this.columnData = this.apiCalls[0].columnData
        this.originalDataData = this.apiCalls[0].dataData
      }
      else {

        this.columnData = []
        this.originalDataData = []

        this.apiCalls.forEach((apiCall: apiCall) => {

          apiCall.columnData.forEach((column: string) => {

            this.columnData.push(column)
          })

          this.originalDataData = this.mergeArrays(this.originalDataData, apiCall.dataData)
        })

      }
      this.elementService.changeDetector.detectChanges()

    })
  }

  /**
   * ### Merge the objects of multiple arrays
   * They are being merged into one array, where the objects are also
   * merged into one object, wich contains all the fields of the objects
   *
   * **EXAMPLE:**
   *
   * `const array1 = [{ key1: 'value1' }, { key2: 'value2' }, { key3: 'value3' }];`
   *
   * `const array2 = [{ keyA: 'valueA' }, { keyB: 'valueB' }];`
   *
   * `const array3 = [{ keyX: 'valueX' }];`
   *
   * becomes
   *
   * `[`
   *
   * `{ key1: 'value1', keyA: 'valueA', keyX: 'valueX' },`
   *
   * `{ key2: 'value2', keyB: 'valueB' },`
   *
   * `{ key3: 'value3' }`
   *
   * `[`
   *
   * @param arrays The arrays to be merged
   * @returns The merged array
   */
  mergeArrays(...arrays: Array<Array<object>>): Array<object> {

    const maxLength = Math.max(...arrays.map(arr => arr.length))

    return Array.from({ length: maxLength }).map((_, index) => {

      return arrays.reduce((acc, currentArray) => {

        return { ...acc, ...(currentArray[index] || {}) }
      }, {})
    })
  }

  originalDataData: any[] = []
  columnData: string[]

  @ViewChild('resultPreviewTable', { static: true }) resultPreviewTable: resultPreviewTable | undefined
  private _dataData: any[]
  public get dataData(): any[] {

    this._dataData = this.resultPreviewTable && this.resultPreviewTable.wasFiltered ?
                  this.resultPreviewTable.filteredData : this.originalDataData

    return this._dataData
  }
  public set dataData(value: any[]) {

    this._dataData = value
  }

	/**
	 * ### Emits the data and closes the dialog
	 * Make sure, that all apiCalls notified selected data with ```notifySelection.emit()```
	 */
  emitDataAndClose() {

    if(this.dataData && this.dataData.length > 0)
      this.elementService.selectedObjects.forEach((selectedObject: any) => {

        if (selectedObject.setColumnAndDataArray)
          selectedObject.setColumnAndDataArray(this.columnData, this.dataData)
      })

    this.closed.emit(this)
  }

  selectionType = "single"
  /**
   * This decides if the user can select multiple data fields or only one
   * @param elementType The type of the element that is selected
   */
  setSelectionType(elementType: string){

    switch (this.elementType) {
      case "matTable":
        this.selectionType = "multi"
        break;
      case "chartJs":
        this.selectionType = "single"
        break;
      default:
        this.selectionType = "single"
    }
  }

  /**
   * Handles filter selection in the resultPreviewTable
   * @param selectedFilter [key, filterInput]
   */
  storeFilter(selectedFilter: string[]){

    this.elementService.setProp(
      {key: "filter"+selectedFilter[0], value: selectedFilter[1], second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )

    this.changeDetector.detectChanges()
  }
}
