<ng-container [ngSwitch]="getProp('type')">

<!--
███████╗██╗███╗   ███╗██████╗ ██╗     ███████╗
██╔════╝██║████╗ ████║██╔══██╗██║     ██╔════╝
███████╗██║██╔████╔██║██████╔╝██║     █████╗
╚════██║██║██║╚██╔╝██║██╔═══╝ ██║     ██╔══╝
███████║██║██║ ╚═╝ ██║██║     ███████╗███████╗
╚══════╝╚═╝╚═╝     ╚═╝╚═╝     ╚══════╝╚══════╝

-->
	<a
		*ngSwitchCase="'simple'"
		mat-button
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><span
			style="
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			"
			><mat-icon style="margin-right: 8px" *ngIf="getProp('hasIcon') == '1'">{{
				getProp("Icon Name")
			}}</mat-icon>
			<input
				type="text"
				#thisField
				[size]="thisField.value.length > 3 ? thisField.value.length : 1"
				class="matButtonInput"
				[value]="getProp('Text')"
				(input)="setPropAsHelper('Text', thisField.value)"
				(focus)="elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)" /></span
	></a>

<!--
██████╗      █████╗     ██╗    ███████╗    ███████╗    ██████╗
██╔══██╗    ██╔══██╗    ██║    ██╔════╝    ██╔════╝    ██╔══██╗
██████╔╝    ███████║    ██║    ███████╗    █████╗      ██║  ██║
██╔══██╗    ██╔══██║    ██║    ╚════██║    ██╔══╝      ██║  ██║
██║  ██║    ██║  ██║    ██║    ███████║    ███████╗    ██████╔╝
╚═╝  ╚═╝    ╚═╝  ╚═╝    ╚═╝    ╚══════╝    ╚══════╝    ╚═════╝

-->
	<a
		*ngSwitchCase="'raised'"
		mat-raised-button
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><span
			style="
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			"
			><mat-icon style="margin-right: 8px" *ngIf="getProp('hasIcon') == '1'">{{
				getProp("Icon Name")
			}}</mat-icon>
			<input
				type="text"
				#thisField
				[size]="thisField.value.length > 3 ? thisField.value.length : 1"
				class="matButtonInput"
				[value]="getProp('Text')"
				(input)="setPropAsHelper('Text', thisField.value)"
				(focus)="elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)" /></span
	></a>

<!--
███████╗████████╗██████╗  ██████╗ ██╗  ██╗███████╗██████╗
██╔════╝╚══██╔══╝██╔══██╗██╔═══██╗██║ ██╔╝██╔════╝██╔══██╗
███████╗   ██║   ██████╔╝██║   ██║█████╔╝ █████╗  ██║  ██║
╚════██║   ██║   ██╔══██╗██║   ██║██╔═██╗ ██╔══╝  ██║  ██║
███████║   ██║   ██║  ██║╚██████╔╝██║  ██╗███████╗██████╔╝
╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═════╝

-->
	<a
		*ngSwitchCase="'stroked'"
		mat-stroked-button
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><span
			style="
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			"
			><mat-icon style="margin-right: 8px" *ngIf="getProp('hasIcon') == '1'">{{
				getProp("Icon Name")
			}}</mat-icon>
			<input
				type="text"
				#thisField
				[size]="thisField.value.length > 3 ? thisField.value.length : 1"
				class="matButtonInput"
				[value]="getProp('Text')"
				(input)="setPropAsHelper('Text', thisField.value)"
				(focus)="elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)" /></span
	></a>

<!--
███████╗██╗      █████╗ ████████╗
██╔════╝██║     ██╔══██╗╚══██╔══╝
█████╗  ██║     ███████║   ██║
██╔══╝  ██║     ██╔══██║   ██║
██║     ███████╗██║  ██║   ██║
╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

-->
	<a
		*ngSwitchCase="'flat'"
		mat-flat-button
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><span
			style="
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			"
			><mat-icon style="margin-right: 8px" *ngIf="getProp('hasIcon') == '1'">{{
				getProp("Icon Name")
			}}</mat-icon>
			<input
				type="text"
				#thisField
				[size]="thisField.value.length > 3 ? thisField.value.length : 1"
				class="matButtonInput"
				[value]="getProp('Text')"
				(input)="setPropAsHelper('Text', thisField.value)"
				(focus)="elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)" /></span
	></a>

<!--
██╗ ██████╗ ██████╗ ███╗   ██╗
██║██╔════╝██╔═══██╗████╗  ██║
██║██║     ██║   ██║██╔██╗ ██║
██║██║     ██║   ██║██║╚██╗██║
██║╚██████╗╚██████╔╝██║ ╚████║
╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝

-->
	<a
		*ngSwitchCase="'icon'"
		mat-icon-button
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><mat-icon>{{ getProp("Icon Name") }}</mat-icon></a
	>

<!--
███████╗     █████╗     ██████╗
██╔════╝    ██╔══██╗    ██╔══██╗
█████╗      ███████║    ██████╔╝
██╔══╝      ██╔══██║    ██╔══██╗
██║         ██║  ██║    ██████╔╝
╚═╝         ╚═╝  ╚═╝    ╚═════╝

-->
	<a
		*ngSwitchCase="'fab'"
		mat-fab
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><mat-icon>{{ getProp("Icon Name") }}</mat-icon></a
	>

<!--
███╗   ███╗    ██╗    ███╗   ██╗    ██╗              ███████╗     █████╗     ██████╗
████╗ ████║    ██║    ████╗  ██║    ██║              ██╔════╝    ██╔══██╗    ██╔══██╗
██╔████╔██║    ██║    ██╔██╗ ██║    ██║    █████╗    █████╗      ███████║    ██████╔╝
██║╚██╔╝██║    ██║    ██║╚██╗██║    ██║    ╚════╝    ██╔══╝      ██╔══██║    ██╔══██╗
██║ ╚═╝ ██║    ██║    ██║ ╚████║    ██║              ██║         ██║  ██║    ██████╔╝
╚═╝     ╚═╝    ╚═╝    ╚═╝  ╚═══╝    ╚═╝              ╚═╝         ╚═╝  ╚═╝    ╚═════╝

-->
	<a
		*ngSwitchCase="'mini-fab'"
		mat-mini-fab
		[color]="getProp('color')"
		[disabled]="getProp('disabled') == '1'"
		(click)="onButtonClicked()"
		><mat-icon>{{ getProp("Icon Name") }}</mat-icon></a
	>

</ng-container>
