import { Project } from './project.entity';

export interface ISaveLayout {
  name: string;
  isLayout: boolean;
  orderNumber: number;
}

export class SaveLayout implements ISaveLayout {
  public id: string;
  public owner: string;
  public project?: Project;
  public name: string;
  public parent?: SaveLayout;
  public children?: SaveLayout[];
  public isLayout: boolean;
  public orderNumber: number;
}
