<ejs-kanban
  [keyField]="keyField"
  [dataSource]="dataSource"
  [cardSettings]="cardSettings"
  [swimlaneSettings]="swimlaneSettings"
  (dragStop)="onKanbanDragStop($event)"
>
  <e-columns>

    <e-column *ngFor="let column of columns; let colIndex = index"
      [headerText]="column.headerText"
      [keyField]="column.keyField"
      [transitionColumns]="transitionColumnsForColumns[colIndex]"
      [allowDrag]="allowDragForColumns[colIndex]"
      [allowDrop]="allowDropForColumns[colIndex]"
    ></e-column>

    <ng-template #cardSettingsTemplate let-data>
      <div class="custom-card">
        <div>{{data[getProp('headerTextField')]}}</div>
        <div>{{data[getProp('contentField')]}}</div>
      </div>
    </ng-template>

  </e-columns>
</ejs-kanban>

<apiCallDialog
  #apiCallDialog
  [ngStyle]="{ 'display': 'none' }"
  [elementType]="'sf-kanban'"
>
</apiCallDialog>
