import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, from, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

import { ConfigService } from '../config.service';
import { CacheService } from './cache.service';
import { ErrorHandlingService } from './error-handling.service';
import { SaveLayout } from './entities/save-layout.entity';
import { IProperty, Property } from './entities/property.entity';
import { CreatePropertyDto } from './dto/create-property.dto';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private readonly url: string;

  private readonly queue: Map<string, Subject<Observable<any>>> = new Map<string, Subject<Observable<any>>>();

  public constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly keycloakService: KeycloakService,
    public readonly cacheService: CacheService,
    private readonly errorHandlingService: ErrorHandlingService
  ) {
    this.url = `${this.configService.baseUrl}/properties`;
  }

  public queueObservable(key: string, request: Observable<any>) {
    const hasNot = !this.queue.has(key);

    if (hasNot) {
      this.queue.set(key, new Subject<Observable<any>>());
    }

    const queue = this.queue.get(key)!;

    if (hasNot) {
      queue.pipe(
        concatMap((observable) => observable)
      ).subscribe();
    }

    queue.next(request);
  }

  /*
   *  ██████╗██████╗ ██╗   ██╗██████╗      ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
   * ██╔════╝██╔══██╗██║   ██║██╔══██╗    ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
   * ██║     ██████╔╝██║   ██║██║  ██║    ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
   * ██║     ██╔══██╗██║   ██║██║  ██║    ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
   * ╚██████╗██║  ██║╚██████╔╝██████╔╝    ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
   *  ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝      ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
   */

  public findAll() {
    const request = this.http.get<Property[]>(this.url).pipe(shareReplay(1));
    request.subscribe((data) => this.cacheService.properties = data);
    return request;
  }

  public findOne(id: string, fn?: (property: Property) => void) {
    this.http.get<Property>(`${this.url}/${id}`).subscribe((data) => { if (data && fn) { fn(data); } });
  }

  public createOrUpdate(saveLayout: SaveLayout, property: IProperty, fn?: (property: Property) => void) {
    const key = `${property.saveLayout?.id ?? saveLayout.id}-${property.key}`;

    const newProperty: CreatePropertyDto = {
      saveLayoutId: property.saveLayout?.id ?? saveLayout.id,
      saveLayoutOwner: property.saveLayout?.owner ?? saveLayout.owner,
      key: property.key,
      value: property.value,
      second: property.second,
      renderOnlyOuter: property.renderOnlyOuter,
      isTailwind: property.isTailwind,
      isHelper: property.isHelper
    };

    const createOrUpdateObservable = this.http.post<Property>(this.url, newProperty).pipe(
      catchError(error => {
        return throwError(() => {
          this.errorHandlingService.handleCreateOrUpdateProperty(newProperty, error);
          this.findAll();
        });
      }),
      tap((data) => {
        if (data) {
          const index = this.cacheService.properties.findIndex((p) => p.id === data.id);
          if (index >= 0) {
            this.cacheService.properties[index] = data;
          } else {
            this.cacheService.properties.push(data);
          }
          if (fn) {
            fn(data);
          }
        }
      })
    );

    this.queueObservable(key, createOrUpdateObservable);
  }

  public createLater(saveLayout: SaveLayout, property: IProperty) {
    const newProperty: CreatePropertyDto = {
      saveLayoutId: saveLayout.id,
      saveLayoutOwner: saveLayout.owner,
      key: property.key,
      value: property.value,
      second: property.second,
      renderOnlyOuter: property.renderOnlyOuter,
      isTailwind: property.isTailwind,
      isHelper: property.isHelper
    };

    return this.http.post<Property>(this.url, newProperty);
  }

  public import(importProperty: Property) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user) => {
        const newProperty: CreatePropertyDto = {
          saveLayoutId: importProperty.saveLayout?.id,
          saveLayoutOwner: user.id!,
          key: importProperty.key,
          value: importProperty.value,
          second: importProperty.second,
          renderOnlyOuter: importProperty.renderOnlyOuter,
          isTailwind: importProperty.isTailwind,
          isHelper: importProperty.isHelper
        };

        return this.http.post<Property>(`${this.url}/${importProperty.id}`, newProperty);
      }
    ));
  }

  public delete(property: Property) {
    const key = `${property.saveLayout.id}-${property.key}`;

    const deleteObservable = this.http.delete<Property>(`${this.url}/${property.id}`).pipe(
      catchError(error => {
        return throwError(() => {
          this.errorHandlingService.handleDeleteProperty(property, error);
          this.findAll();
        });
      }),
      tap((data) => {
        if (data) {
          this.cacheService.properties = this.cacheService.properties.filter((p) => p.id !== data.id);
        }
      })
    );

    this.queueObservable(key, deleteObservable);
  }
}
