<div *ngIf="isObject(responseBody)">
  <ul>
    <li *ngFor="let key of getKeys(responseBody); trackBy: trackByFn">
      <span #node style=" cursor: pointer; font-weight: bold; border-radius: 4px;
                          background-color: rgb(205, 205, 205); padding: 3px;"
            [id]="'call'+apiCallNumber+(path ? path + '.' + key : key) + jsonViewerNumber"
            (click)="onClick(node)">
        {{key}}
      </span>
      <jsonViewer (tellApiCallAboutSelection)="forwardLastSelected($event)"
        [jsonViewerNumber]="jsonViewerNumber"
        [responseBody]="responseBody[key]"
        [apiCallNumber]="apiCallNumber"
        [path]="path ? path + '.' + key : key"
      ></jsonViewer>
    </li>
  </ul>
</div>

<div *ngIf="getType(responseBody) === 'array'">
  <ul>
    <div *ngFor="let val of responseBody; index as i; trackBy: trackByFn">
      <li *ngIf="i === 0">
        <jsonViewer (tellApiCallAboutSelection)="forwardLastSelected($event)"
          [jsonViewerNumber]="jsonViewerNumber"
          [apiCallNumber]="apiCallNumber"
          [responseBody]="val"
          [path]="path + '[' + i + ']'"
        ></jsonViewer>
      </li>
    </div>
  </ul>
</div>
<span *ngIf="getType(responseBody) !== 'array' && !isObject(responseBody)"
      [id]="'call'+apiCallNumber+(path + '.value')">
      {{ responseBody }}
</span>
