<mat-form-field appearance="fill" style="width: 100%;">
  <mat-label translate>rightMenu.pageLink</mat-label>
  <mat-select #pageSelect
    (selectionChange)="setProp('pageRoute', pageSelect.value)"
    [value]="getProp('pageRoute')"
  >
	  <mat-option *ngFor="let page of dataService.projectsService.currentProject!.pages | pageFilter;" [value]="getIndex(page)">{{ page.name }}</mat-option>
  </mat-select>

  <button
    matPrefix
    mat-icon-button
    (click)="	elementService.removeProp('pageRoute');
    					pageSelect.value = undefined"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
