import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";

import { JsonViewer } from "src/app/Dialogs/Dialog API Call/apiCall/JsonViewer/jsonViewer";
import { htmlViewer } from "src/app/Dialogs/Dialog API Call/apiCall/htmlViewer/htmlViewer";
import { resultPreviewTable } from "src/app/Dialogs/Dialog API Call/apiCall/resultPreviewTable/resultPreviewTable";
import { ConfigService } from "src/app/config.service";
import { ElementService } from "src/app/element.service";

/*

         _______________________________________________________________
    ()==(                                                              (@==()
         '______________________________________________________________'|
           |                                                             |
           |   API CALL                                                  |
           |   =======================================================   |
           |   * makes API calls                                         |
           |   * stores all data of the call                             |
           |   * returns the selected data and/or columns                |
           |     * handles JSON responses                                |
           |     * handles HTTP responses                                |
           |   * this.notifySelection.emit() transfers data to apiDialog |
         __)_____________________________________________________________|
    ()==(                                                               (@==()
         '---------------------------------------------------------------'

*/

@Component({
  selector: 'apiCall',
  templateUrl: './apiCall.html',
  styleUrls: ['./apiCall.scss']
})
export class apiCall implements OnInit, AfterViewChecked {

  @Input() selectionType = "single"
  @Input() myNum = 0
  @Input() _call_type: string = "GET"

  constructor(
    public elementService: ElementService,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private readonly configService: ConfigService
  ){ }

  @HostBinding('style.width') get getIndex() { return "100%" }

  ngOnInit(): void {

		this.initHeaders()
  }

  ngOnChanges(changes: SimpleChanges) {

		this.changeDetector.detectChanges()
  }

  @Output() removeMeNow: EventEmitter<string> = new EventEmitter<string>()
  removeMe(){

    this.removeMeNow.emit(this.myNum.toString())
  }

  /*
██╗  ██╗████████╗████████╗██████╗      ██████╗ █████╗ ██╗     ██╗
██║  ██║╚══██╔══╝╚══██╔══╝██╔══██╗    ██╔════╝██╔══██╗██║     ██║
███████║   ██║      ██║   ██████╔╝    ██║     ███████║██║     ██║
██╔══██║   ██║      ██║   ██╔═══╝     ██║     ██╔══██║██║     ██║
██║  ██║   ██║      ██║   ██║         ╚██████╗██║  ██║███████╗███████╗
╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝          ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝
  */
  isHTML = false

  fetchApi(callback?: () => void){

    // Call type was already set in this.call_type

    // Get the url
    const url = this.url

    // Set the headers
    let headers = new HttpHeaders()

    headers = headers.set('target-url', url)
    headers = headers.set('Cache-Control', 'no-cache')

    this.body_Type == "Form" ?
              headers = headers.set('Content-Type', 'application/x-www-form-urlencoded') :
              headers = headers.set('Content-Type', 'application/json')

    this.uiHeaders.forEach(header => {

      if(header.key && header.value) headers = headers.set(header.key, header.value)
    })

    // Create options object
    let options = {

      headers: headers,
      responseType: 'text' as 'json',
      observe: 'response',
      redirect: 'follow'
    }

    // Body type was already set in this.body_Type

    // Get the Body
    let bodyString: string = this.body

    let body: any

    // If this.body_Type == "Form", then take the fields of the body and create a new URLSearchParams() object
    if(this.body_Type == "Form" && bodyString) {

      // Get the fields of the body object
      let bodyFields = Object.keys(JSON.parse(bodyString))

      body = new URLSearchParams()

      // Add the fields to the URLSearchParams object
      bodyFields.forEach(field => {

        body.set(field, JSON.parse(bodyString)[field])
      })
    }
    else body = bodyString

    // FIRE
    this.httpCall(this.call_type, options, body).subscribe({

      next: response => {

        this.isHTML = response.headers.get('Content-Type').includes('text/html')

        // The resultArea.textContent is set in the setter of this.responseBody
        this.responseBody = this.isHTML ? response.body : JSON.parse(response.body)

        // Find all forms and their fields
        if(this.isHTML && !body) this.findAllFormsAndTheirFields()

        this.weNeedToColorize = true

				if(this.notionCall) this.loadNotionDataAndSetPreviewTable(this.responseBody)

        if (callback) {

            callback()
        }
      },
      error: err => console.error(err)
    })

  }

  weNeedToColorize = false

  // If there was a selection already, then colorize it again
  // ngAfterViewChecked() is needed, because of the *ngIf's in the JsonViewers
  // If you would call this.colorizeSelectedJsonFields() this immediately, the recursive
  // JsonViewers would not yet be rendered and you would get "cannot read property of undefined"
  public ngAfterViewChecked(): void {
    if (this.notionCall) {
      return;
    }

    if (!this.weNeedToColorize) {
      return;
    }

    // This needs to come first, otherwise colorizeSelectedJsonFields() could be called multiple times
    // p.s. believe me, I tried
    this.weNeedToColorize = false;

    if (!this.notionCall) {
      this.colorizeSelectedJsonFields();
    }
  }

  private colorizeSelectedJsonFields() {

    let selectedColumnPaths = this.elementService.getProp("selectedColumnPathsCall" + this.myNum).split(`, `)
    selectedColumnPaths.forEach(path => {

      if (path == "") return
      let pathAndNum = path + "1"
      this.jsonViewer1?.setLastSelectedColorizeAndReturn("call" + this.myNum + pathAndNum)
    })

    let selectedDataPaths = this.elementService.getProp("selectedDataPathsCall" + this.myNum).split(`, `)

    selectedDataPaths.forEach(path => {

      if (path == "") return
      let pathAndNum = path + "2"
      this.jsonViewer2?.setLastSelectedColorizeAndReturn("call" + this.myNum + pathAndNum)
    })
  }

  httpCall(call_type: string, options: any, body: any): Observable<any>{

    let backendURL = `${this.configService.baseUrl}/call`

    if(call_type == 'POST') return this.http.post(backendURL, body==""?
                                                                  undefined:
                                                                  this.body_Type=="Form"?
                                                                        body:
                                                                        JSON.parse(body), options)

    else return this.http.get(backendURL, options)
  }

  // END HTTP CALL

  /*
███╗   ███╗██╗   ██╗    ██████╗ ███████╗███████╗██╗   ██╗██╗  ████████╗    ██████╗  █████╗ ████████╗ █████╗
████╗ ████║╚██╗ ██╔╝    ██╔══██╗██╔════╝██╔════╝██║   ██║██║  ╚══██╔══╝    ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
██╔████╔██║ ╚████╔╝     ██████╔╝█████╗  ███████╗██║   ██║██║     ██║       ██║  ██║███████║   ██║   ███████║
██║╚██╔╝██║  ╚██╔╝      ██╔══██╗██╔══╝  ╚════██║██║   ██║██║     ██║       ██║  ██║██╔══██║   ██║   ██╔══██║
██║ ╚═╝ ██║   ██║       ██║  ██║███████╗███████║╚██████╔╝███████╗██║       ██████╔╝██║  ██║   ██║   ██║  ██║
╚═╝     ╚═╝   ╚═╝       ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚═╝       ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
  */

	// _columnData is only used for cases, where whether the jsonViewer nor the htmlViewer is used
	_columnData: string[] = []
	/**
	 * This returns the column headings for the resultPreviewTable
	 */
  public get columnData(): string[] {

		if(this._columnData.length > 0) return this._columnData

    let columnData = this.isHTML ?
                        this.htmlViewer1 ? this.htmlViewer1.data : [] :
                        this.jsonViewer1 ? this.jsonViewer1.data : []

    // The jsonViewer only delivers objects arrays, so we convert it to a string array
    if(columnData.length > 0 && !this.isHTML) columnData = Object.values(columnData[0])
    return columnData
  }

	// _originalDataData is only used for cases, where whether the jsonViewer nor the htmlViewer is used
	private _originalDataData: any[] = []

  public get originalDataData(): any[] {

		return this._originalDataData
	}
	/**
	 * This sets the data for the resultPreviewTable, without the headings
	 */
  public setOriginalDataData() {

    let originalDataData: any = this.isHTML ?
                        (this.htmlViewer2 ? this.htmlViewer2.data : []) :
                        this.jsonViewer2 ? this.jsonViewer2.data : []

    // The htmlViewer only delivers string arrays, so we convert it to an object array
    // For each array element, add a key and value to an object

    let originalDataDataObject: any = {}
    if(originalDataData.length > 0 && this.isHTML) {

      originalDataData.forEach((element: any, i: number) => {

        // Add a key and value to the object
        originalDataDataObject[i] = element
      })
    }

    this._originalDataData = this.isHTML ? [originalDataDataObject] : originalDataData
  }

	/**
	 * This returns the data for the apiDialog
	 * Attention: only this.notifySelection.emit() will trigger this getter from the apiDialog
	 */
  public get dataData(): any[] {

    return this.resultPreviewTable && this.resultPreviewTable.wasFiltered ?
                              this.resultPreviewTable.filteredData : this.originalDataData
  }

	public notifyDialogThatIWasFiltered(){

		this.tellApiDialogAboutASelection.emit()
	}

  // END MY RESULT DATA

  /*
███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

███████╗██╗   ██╗███████╗███╗   ██╗████████╗██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ██╗███╗   ██╗ ██████╗
██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██║████╗  ██║██╔════╝
█████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████║███████║██╔██╗ ██║██║  ██║██║     ██║██╔██╗ ██║██║  ███╗
██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██║██║╚██╗██║██║   ██║
███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗██║██║ ╚████║╚██████╔╝
╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝

 */
	// Attention with @ViewChild -> if there is an *ngIf around it, then the ViewChild is undefined, even when true
  @ViewChild('resultPreviewTable', { static: true }) resultPreviewTable: resultPreviewTable | undefined
  @ViewChild('jsonViewer1', { static: true }) jsonViewer1: JsonViewer | undefined
  @ViewChild('jsonViewer2', { static: true }) jsonViewer2: JsonViewer | undefined
  @ViewChild('htmlViewer1', { static: true }) htmlViewer1: htmlViewer | undefined
  @ViewChild('htmlViewer2', { static: true }) htmlViewer2: htmlViewer | undefined
  @Output() tellApiDialogAboutASelection: EventEmitter<string> = new EventEmitter<string>()

  /**
   * Handles path selection clicks in the jsonViewer
	 * From (tellApiCallAboutSelection)="handleJsonViewerPathSelection($event, 'Data')"
   * @param path
   */
  handleJsonViewerPathSelection(setSelectedPaths: string[], type: string){

    this.elementService.setProp({

      key: "selected"+type+"PathsCall"+this.myNum,
      value: setSelectedPaths.join(`, `), second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false
    })

		this.setOriginalDataData()

    this.tellApiDialogAboutASelection.emit()
  }

  /**
   * Handles filter selection in the resultPreviewTable
   * @param selectedFilter [key, filterInput]
   */
  storeFilter(selectedFilter: string[]){

    this.elementService.setProp(
      {key: "filter"+this.myNum+selectedFilter[0], value: selectedFilter[1], second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )

    this.tellApiDialogAboutASelection.emit()
  }
  // END SELECTION EVENT HANDLING

  /*
███████╗██╗███╗   ██╗██████╗     ███████╗ ██████╗ ██████╗ ███╗   ███╗███████╗
██╔════╝██║████╗  ██║██╔══██╗    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║██╔════╝
█████╗  ██║██╔██╗ ██║██║  ██║    █████╗  ██║   ██║██████╔╝██╔████╔██║███████╗
██╔══╝  ██║██║╚██╗██║██║  ██║    ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║╚════██║
██║     ██║██║ ╚████║██████╔╝    ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║███████║
╚═╝     ╚═╝╚═╝  ╚═══╝╚═════╝     ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝
██╗███╗   ██╗    ██╗  ██╗████████╗███╗   ███╗██╗
██║████╗  ██║    ██║  ██║╚══██╔══╝████╗ ████║██║
██║██╔██╗ ██║    ███████║   ██║   ██╔████╔██║██║
██║██║╚██╗██║    ██╔══██║   ██║   ██║╚██╔╝██║██║
██║██║ ╚████║    ██║  ██║   ██║   ██║ ╚═╝ ██║███████╗
╚═╝╚═╝  ╚═══╝    ╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝

  */
  findAllFormsAndTheirFields(){

    // take the fist <form> tag and store all underlying name attributes in foundFormFields
    let parser = new DOMParser()
    let htmlDoc = parser.parseFromString(this.responseBody, 'text/html')
    let forms = htmlDoc.getElementsByTagName('form')

    // For each form, get all form fields
    for(let i = 0; i < forms.length; i++){

      this.foundForms.push({
                            formFields: []
                          })
      // Get all types of form fields
      let formFields = forms[i].querySelectorAll('input, select, textarea')

      for(let j = 0; j < formFields.length; j++){

        let nameAttribute = formFields[j].getAttribute('name')
        if(nameAttribute) this.foundForms[i].formFields.push(nameAttribute)
      }
    }

    let iframes = htmlDoc.getElementsByTagName('iframe')

    // Iterate over all iframes
    for(let i = 0; i < iframes.length; i++){

      // Get the html content of the iframe
      let iframeContent = iframes[i].contentDocument?.documentElement.outerHTML
    }
  }

  foundForms: {
                formFields: string[]
              }[] = []

  // END FIND FORMS IN HTML

  /*
███████╗███████╗████████╗    ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗ ███████╗
██╔════╝██╔════╝╚══██╔══╝    ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔════╝
███████╗█████╗     ██║       ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝███████╗
╚════██║██╔══╝     ██║       ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗╚════██║
███████║███████╗   ██║       ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║███████║
╚══════╝╚══════╝   ╚═╝       ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝
  */

  initHeaders(){

    // get prop "call"+this.myNum+"Headers" and store it in this.uiHeaders
    let headersString = this.elementService.getProp("call"+this.myNum+"Headers")
    if(!headersString) return

		let headers = headersString.split("#header#")

		headers.forEach(header => {

			this.uiHeaders.push(JSON.parse(header))
		})
  }

  private _uiHeaders: {
                        key: string
                        value: string
                      }[] = []

  public get uiHeaders() {

    return this._uiHeaders
  }

  setHeader(key: string, value: string, index: number){

    this.uiHeaders[index].key = key
    this.uiHeaders[index].value = value

    this.storeHeaders()
  }

  addHeader(key: string, value: string){

    this.uiHeaders.push({
      key: key,
      value: value
    })

    this.storeHeaders()
  }

  removeAllHeaders(){

		this._uiHeaders = []

		this.storeHeaders()
  }

  removeHeader(key: string, index: number){

    this.uiHeaders.splice(index, 1)

    this.storeHeaders()
  }

  storeHeaders(){

    let headersString = ""
    // For each header, stringify it
    this.uiHeaders.forEach((header, i) => {

      // add it to the headers string, but the last get's no #header# at the end
      headersString += JSON.stringify(header) + (i == this.uiHeaders.length-1 ? "" : "#header#")
    })

    this.elementService.setProp({
      key: "call"+this.myNum+"Headers",
      value: headersString, second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false})
  }

  /*
████████╗██╗    ██╗ ██████╗       ██╗    ██╗ █████╗ ██╗   ██╗    ██████╗ ██╗███╗   ██╗██████╗ ██╗███╗   ██╗ ██████╗
╚══██╔══╝██║    ██║██╔═══██╗      ██║    ██║██╔══██╗╚██╗ ██╔╝    ██╔══██╗██║████╗  ██║██╔══██╗██║████╗  ██║██╔════╝
   ██║   ██║ █╗ ██║██║   ██║█████╗██║ █╗ ██║███████║ ╚████╔╝     ██████╔╝██║██╔██╗ ██║██║  ██║██║██╔██╗ ██║██║  ███╗
   ██║   ██║███╗██║██║   ██║╚════╝██║███╗██║██╔══██║  ╚██╔╝      ██╔══██╗██║██║╚██╗██║██║  ██║██║██║╚██╗██║██║   ██║
   ██║   ╚███╔███╔╝╚██████╔╝      ╚███╔███╔╝██║  ██║   ██║       ██████╔╝██║██║ ╚████║██████╔╝██║██║ ╚████║╚██████╔╝
   ╚═╝    ╚══╝╚══╝  ╚═════╝        ╚══╝╚══╝ ╚═╝  ╚═╝   ╚═╝       ╚═════╝ ╚═╝╚═╝  ╚═══╝╚═════╝ ╚═╝╚═╝  ╚═══╝ ╚═════╝

███████╗██╗███████╗██╗     ██████╗ ███████╗
██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
█████╗  ██║█████╗  ██║     ██║  ██║███████╗
██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
██║     ██║███████╗███████╗██████╔╝███████║
╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝
  */

@Input() public get call_type(): string {

    this._call_type = this.elementService.getProp("call_type"+this.myNum) == "" ?
                                            "GET" :
                                            this.elementService.getProp("call_type"+this.myNum)
    return this._call_type
  }
  public set call_type(value: string | undefined) {

    if(!value) value = ""

    this.elementService.setProp({
          key: "call_type"+this.myNum,
          value: value, second: "",
          isHelper: true, isTailwind: false, renderOnlyOuter: false
        })
    this._call_type = value
  }

  //-------

  @Input() _url: string
  public get url(): string {

    this._url = this.elementService.getProp("url"+this.myNum)
    if(!this._url) this._url = "https://jsonplaceholder.typicode.com/todos/1"
    return this._url
  }
  public set url(value: string | undefined) {

    if(!value) value = ""

    this.elementService.setProp({
        key: "url"+this.myNum,
        value: value, second: "",
        isHelper: true, isTailwind: false, renderOnlyOuter: false
      })
    this._url = value

    this.elementService.setProp({
      key: 'selectedColumnPathsCall' + this.myNum,
      value : '',
      second: '',
      isHelper: true,
      isTailwind: false,
      renderOnlyOuter: false
    });

    this.elementService.setProp({
      key: 'selectedDataPathsCall' + this.myNum,
      value : '',
      second: '',
      isHelper: true,
      isTailwind: false,
      renderOnlyOuter: false
    });
  }

  //-------

  @Input() private _body_Type: string
  public get body_Type() {

    this._body_Type = this.elementService.getProp("body_Type"+this.myNum) == "" ? "JSON" : this.elementService.getProp("body_Type"+this.myNum)

    return this._body_Type
  }
  public set body_Type(value) {

    if(!value) value = ""

    this.elementService.setProp({
          key: "body_Type"+this.myNum,
          value: value, second: "",
          isHelper: true, isTailwind: false, renderOnlyOuter: false
        })

    this._body_Type = value
  }

  //-------

  @Input() _body: string
  public get body(): string {

    this._body = this.elementService.getProp("body"+this.myNum)
    return this._body
  }
  public set body(value: string | undefined) {

    if(!value) value = ""

    this.elementService.setProp({
      key: "body"+this.myNum,
      value: value, second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false
    })
    this._body = value
  }

  //-------

  /*
    We're not storing the response body, because we don't want to store the data itself, but handle the incoming
    data of the response after every call with the paths.
  */
  @Input() private _responseBody: any

  public get responseBody(): any {

    return this._responseBody
  }
  public set responseBody(value: any) {

    this._responseBody = value
    this.elementService.changeDetector.detectChanges()
  }

  public get responseBodyAsText(): string {
    return this.responseBody ? JSON.stringify(this.responseBody, undefined, 4) : 'Hier wird das Ergebnis stehen';
  }

  // End Two-Way Binding Fields

	/*
	███╗   ██╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗
	████╗  ██║██╔═══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
	██╔██╗ ██║██║   ██║   ██║   ██║██║   ██║██╔██╗ ██║
	██║╚██╗██║██║   ██║   ██║   ██║██║   ██║██║╚██╗██║
	██║ ╚████║╚██████╔╝   ██║   ██║╚██████╔╝██║ ╚████║
	╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
																										*/
	/*
		TODO: Alles, was mit Notion zu tun hat muss in eine Directive o.Ä. rausgezogen werden,
		damit es nicht den bestehenden Code verunstaltet.
	*/
	private _notionCall = false
	public get notionCall() {

    this._notionCall = this.elementService.getProp("notionCall"+this.myNum) == "true"
    return this._notionCall
	}
	public set notionCall(value) {

		this.elementService.setProp({
      key: "notionCall"+this.myNum,
      value: value + "", second: "",
      isHelper: true, isTailwind: false, renderOnlyOuter: false})

		this._notionCall = value
		this.setNotionCall()
	}

	setNotionCall(){

		if (this.notionCall) {

      this.call_type = "POST"
      this.url = "https://api.notion.com/v1/databases/1a0f6b9f-9f9a-4f9e-8f2a-0f2b8e8f9b9e/query"
      this.addHeader("Authorization", "")
      this.addHeader("Notion-Version", "2022-02-22")
    }
		else {

      this.call_type = "GET"
  		this.url = "https://jsonplaceholder.typicode.com/todos/1"
  		this.removeAllHeaders()
    }
	}

	loadNotionDataAndSetPreviewTable(responseBody: any){

		// Iterate over the list of results, wich will be table rows
		responseBody.results.forEach((result: any) => {

			if (!result.properties) return
			const objectWithValues: any = {}

      objectWithValues['Id'] = result.id;

			// Durch die "properties"-Objekte des aktuellen Ergebnisses iterieren
			for (const propertyName in result.properties) {

				const property = result.properties[propertyName]

				if (property.type === "number") {

					objectWithValues[propertyName] = property.number
				}
				else if (property.type === "date") {

					objectWithValues[propertyName] = property.date?.end ?
						property.date?.start + " - " + property.date?.end : property.date?.start
				}
				else if (property.type === "url") {

					objectWithValues[propertyName] = property.url
				}
				else if (property.type === "select") {

					objectWithValues[propertyName] = property.select?.name
				}
				else if (property.type === "multi_select") {

					objectWithValues[propertyName] = property.multi_select.map((item: any) => item.name).join(", ")
				}
				else if (property.type === "title") {

					objectWithValues[propertyName] = property.title[0]?.text?.content
				}
				else if (property.type === "checkbox") {

					objectWithValues[propertyName] = property.checkbox
				}
				else if (property.type === "phone_number") {

					objectWithValues[propertyName] = property.phone_number
				}
				else if (property.type === "rich_text") {

					objectWithValues[propertyName] = property.rich_text[0]?.plain_text
				}
				else if (property.type === "title") {

					objectWithValues[propertyName] = property.title?.plain_text
				}
				else if (property.type === "status") {

					objectWithValues[propertyName] = property.status?.name
				}
				else if (property.type === "formula") {

					if (property.formula.type === "number") {

						objectWithValues[propertyName] = property.formula?.number
					}
					if (property.formula.type === "date") {

						objectWithValues[propertyName] = property.formula?.date?.start
					}
					if (property.formula.type === "string") {

						objectWithValues[propertyName] = property.formula?.string
					}
				}
			}
			this._originalDataData.push(objectWithValues)
		})

		// Iterate over the keys of the first object of _originalDataData to set the column headings
		Object.keys(this._originalDataData[0]).forEach((key: string) => {

			this._columnData.push(key)
		})

    let dataPaths: string[] = [];
    dataPaths.push('results[0].id');
    for (const propertyName in responseBody.results[0].properties) {
      const property = responseBody.results[0].properties[propertyName];
      dataPaths.push('results[0].' + this.propertyTypeToDataPath(propertyName, property));
    }
    this.elementService.setProp({ key: 'selectedDataPathsCall' + this.myNum, value: dataPaths.join(', '), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

		this.tellApiDialogAboutASelection.emit()
	}

  private propertyTypeToDataPath(propertyName: string, property: any): string {
    let result = ''

    if (property.type === 'number') {
      result = `properties.${propertyName}.number`
    } else if (property.type === 'date') {
      result = `properties.${propertyName}.date.start`
    } else if (property.type === 'url') {
      result = `properties.${propertyName}.url`
    } else if (property.type === 'select') {
      result = `properties.${propertyName}.select.name`
    } else if (property.type === 'title') {
      result = `properties.${propertyName}.title[0].text.content`
    } else if (property.type === 'phone_number') {
      result = `properties.${propertyName}.phone_number`
    } else if (property.type === 'rich_text') {
      result = `properties.${propertyName}.rich_text[0].text.content`
    } else if (property.type === 'title') {
      result = `properties.${propertyName}.title[0].plain_text`
    } else if (property.type === 'status') {
      result = `properties.${propertyName}.status.name`
    } else if (property.type === 'formula') {
      if (property.formula.type === 'number') {
        result = `properties.${propertyName}.formula.number`
      }
    }

    return result
  }
}
