<action style="width: 100%;" [name]="'Send Email'" [action]="action">

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Service</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Service</mat-label>
      <input matInput type="text" [(ngModel)]="service">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Host</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Host</mat-label>
      <input matInput type="text" [(ngModel)]="host">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Port</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Port</mat-label>
      <input matInput type="text" [(ngModel)]="port">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Secure</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Secure</mat-label>
      <input matInput type="text" [(ngModel)]="secure">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>User</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>User</mat-label>
      <input matInput type="text" [(ngModel)]="user">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Password</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Password</mat-label>
      <input matInput type="password" [(ngModel)]="password">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>From</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>From</mat-label>
      <input matInput type="text" [(ngModel)]="from">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>To</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>To</mat-label>
      <input matInput type="text" [(ngModel)]="to">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Subject</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Subject</mat-label>
      <input matInput type="text" [(ngModel)]="subject">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Text</strong></span></pre>
    </div>
    <div #subjectField class="editor-con">
      <div>
        <editor
          [ngStyle]="{ 'width.px': richEditWidth }"
          [disabled]="false"
          initialValue="<p>Hallo!</p>"
          [inline]="true"
          outputFormat="html"
          tagName="div"
          plugins="lists advlist link image table code wordcount charmap emoticons insertdatetime media"
          toolbar="blocks | bold italic underline | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist"
          [(ngModel)]="text"
          (onFocus)="onFocus()"
        ></editor>
      </div>
    </div>
  </div>

</action>
