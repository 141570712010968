import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url = '/api/periodicElements';

  constructor (private http: HttpClient) { }

  getPeriodicElements(): Observable<PeriodicElement[]>{

    return this.http.get<PeriodicElement[]>(this.url);
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService{

  // parseRequestUrl override
  // Do this to manipulate the request URL or the parsed result
  // into something your data store can handle.
  // This example turns a request for `/foo/heroes` into just `/heroes`.
  // It leaves other URLs untouched and forwards to the default parser.
  // It also logs the result of the default parser.

  // parseRequestUrl(url: string, utils: RequestInfoUtilities) {

  //   console.log("Replacing " + url)

  //   let newUrl = url.replace('api/equipment/categories', 'api/categories');


  //   return utils.parseRequestUrl(newUrl);
  //   return
  // }

  createDb() {

    // {base}/categories
    const periodicElements = pE

    return {periodicElements};
  }
}

const pE = [
  {position: 1,  name: 'Hydrogen',   weight: 1.0079,  symbol: 'H'},
  {position: 2,  name: 'Helium',     weight: 4.0026,  symbol: 'He'},
  {position: 3,  name: 'Lithium',    weight: 6.941,   symbol: 'Li'},
  {position: 4,  name: 'Beryllium',  weight: 9.0122,  symbol: 'Be'},
  {position: 5,  name: 'Boron',      weight: 10.811,  symbol: 'B'},
  {position: 6,  name: 'Carbon',     weight: 12.0107, symbol: 'C'},
  {position: 7,  name: 'Nitrogen',   weight: 14.0067, symbol: 'N'},
  {position: 8,  name: 'Oxygen',     weight: 15.9994, symbol: 'O'},
  {position: 9,  name: 'Fluorine',   weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon',       weight: 20.1797, symbol: 'Ne'}
]
