import { Component, Input } from '@angular/core'
import { LayoutComponent } from 'src/app/layout/layout.component'
import { ElementService } from '../../element.service'
import { menu } from '../../menus/menu/menu'

@Component({
  selector: 'richTextMenu',
  templateUrl: 'richText.menu.html',
	styleUrls: ['richText.menu.scss']
})
export class richTextMenu extends menu {

  @Input() renderOnlyOuter = false
  @Input() isTailwind = false
  @Input() isHelper = true

  constructor(
    public elementService: ElementService
  ){

    super(elementService)
  }

  private _inputValueCode: string | undefined
  public get inputValueCode(): string | undefined {

    this._inputValueCode = this.elementService.selectedObjects[0].element.code

    return this._inputValueCode
  }
  public set inputValueCode(value: string | undefined) {

    if(!value) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.code = value
    })
    this._inputValueCode = value
  }

  private _inputValueStyle: string | undefined
  public get inputValueStyle(): string | undefined {

    this._inputValueStyle = this.elementService.selectedObjects[0].element.style

    return this._inputValueStyle
  }
  public set inputValueStyle(value: string | undefined) {

    if(!value) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.style = value
    })
    this._inputValueStyle = value
  }

  editorOptions1 = {
		theme: 'vs-dark',
		language: 'html',
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

	onInit(editor: any, selected: LayoutComponent) {

		ElementService.monacoEditor = editor
    const code = this.getProp(`code`)
    this.inputValueCode = code
	}
}
