<action style="width: 100%;" [name]="'Kanban Card Dropped'" [action]="action">
  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Header Field</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Header Field</mat-label>
      <input matInput type="text" [(ngModel)]="headerField">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Key Field</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Key Field</mat-label>
      <input matInput type="text" [(ngModel)]="keyField">
    </mat-form-field>
  </div>
</action>
