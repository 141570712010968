import { Clipboard } from '@angular/cdk/clipboard'
import { Component, EventEmitter, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTabChangeEvent } from '@angular/material/tabs'

import { CodeGenerationService } from 'src/app/code-generation/code-generation.service'
import { LayoutComponent } from 'src/app/layout/layout.component'
import { ElementService } from '../../element.service'

@Component({
	selector: 'elementCodeDialog',
	templateUrl: './elementCodeDialog.html',
	styleUrls: ['./elementCodeDialog.scss']
})
export class elementCodeDialog {
	@Output() closed: EventEmitter<string> = new EventEmitter<string>()

	language: 'html' | 'javascript' | 'typescript' | 'css' | 'markdown' = 'html'

	sourceCodes: string[] = [ 'No Html Template', 'No Code Template', 'No Css template' ]
	generatedCodes: string[] = [ '', '', '' ]

	constructor(
		private clipboard: Clipboard,
		private snackBar: MatSnackBar,
		public elementService: ElementService,
		public codeGenerationService: CodeGenerationService
	){
	}

	async ngAfterViewInit() {

		let element: LayoutComponent = this.elementService.selectedObjects[0]

		await this.setTemplateHtml(element.getTag(''))
		this.code = element.getTS()
		this.cssCode = element.getCSS()

		if ((window as any)['monaco']) {

			(window as any)['monaco'].languages.typescript.typescriptDefaults.setDiagnosticsOptions({

				noSyntaxValidation: true,
				noSemanticValidation: true,
				noSuggestionDiagnostics: true
			})
		}

		this.elementService.changeDetector.detectChanges()
	}

	currentTabIndex = 0
	onTabChanged(event: MatTabChangeEvent) {

		this.editors = []
		this.currentTabIndex = event.index

		this.language = this.currentTabIndex === 0 ? "html" : this.currentTabIndex === 1 ? "typescript" : "css"
		this.updateLanguage()
	}

	// Update the language with the two way binding (otherwise the language won't change)
	updateLanguage() {

		this.updateLeftLanguage()
		this.updateRightLanguage()
	}

	updateLeftLanguage(language?: 'html' | 'javascript' | 'typescript' | 'css' | 'markdown') {
		this.editorLeftOptions.language = language ? language: this.language
	}

	updateRightLanguage(language?: 'html' | 'javascript' | 'typescript' | 'css' | 'markdown') {
		this.editorRightOptions.language = language ? language: this.language
	}

	async onCodeChange(newValue: string, index: number) {

    if (index === 0) await this.setTemplateHtml(newValue)
		else if (index === 1) this.code = newValue
		else if (index === 2) this.cssCode = newValue
  }

	copyText(text: string) {
		this.clipboard.copy(text)
		this.snackBar.open("Code copied!", undefined, {duration: 5000})
	}

	close() {
		this.closed.emit('closed')
	}

	editors: any[] = []
	onInit(editor: any) {
		this.editors.push(editor)
	}

	insertProperty(property: string) {
		const editor = this.editors[this.currentTabIndex]

		const model = editor.getModel()
		const selection = editor.getSelection()

		const operation = {
			range: selection,
			text: '$' + property
		}

		model.pushEditOperations([], [operation], () => null)

		this.sourceCodes[this.currentTabIndex] = model.getValue()
	}

	editorLeftOptions = {
		theme: 'vs-dark',
		language: this.language,
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

	editorRightOptions = {
		theme: 'vs-dark',
		language: this.language,
		readOnly: true,
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
	}

  /*
   *
   * ██╗  ██╗████████╗███╗   ███╗██╗          ██████╗ ██████╗ ██████╗ ███████╗
   * ██║  ██║╚══██╔══╝████╗ ████║██║         ██╔════╝██╔═══██╗██╔══██╗██╔════╝
   * ███████║   ██║   ██╔████╔██║██║         ██║     ██║   ██║██║  ██║█████╗
   * ██╔══██║   ██║   ██║╚██╔╝██║██║         ██║     ██║   ██║██║  ██║██╔══╝
   * ██║  ██║   ██║   ██║ ╚═╝ ██║███████╗    ╚██████╗╚██████╔╝██████╔╝███████╗
   * ╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝     ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝
   *
   */
	public get templateHtml(): string {

		return this.sourceCodes[0]
	}

	private async setTemplateHtml(value: string) {

		this.sourceCodes[0] = value
		try {

			this.generatedCodes[0] = await this.codeGenerationService.generateAndFormatHtml(this.sourceCodes[0])
			this.updateRightLanguage('html')
		}
		catch (error) {

			console.error(error)
		}
	}

	static elementServiceLoc: ElementService

  /*
	 *
   *  ██████╗ ██████╗ ██████╗ ███████╗
   * ██╔════╝██╔═══██╗██╔══██╗██╔════╝
   * ██║     ██║   ██║██║  ██║█████╗
   * ██║     ██║   ██║██║  ██║██╔══╝
   * ╚██████╗╚██████╔╝██████╔╝███████╗
   *  ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝
   *
	 */
	public get code(): string {

		return this.sourceCodes[1]
	}

	public set code(value: string) {

		this.sourceCodes[1] = value

		try {

			this.generatedCodes[1] = this.codeGenerationService.generateTypeScript(this.sourceCodes[1])
			this.updateRightLanguage('typescript')
		}
		catch (error) {

			console.error(error)
		}
	}

  /*
	 *
   *  ██████╗███████╗███████╗     ██████╗ ██████╗ ██████╗ ███████╗
   * ██╔════╝██╔════╝██╔════╝    ██╔════╝██╔═══██╗██╔══██╗██╔════╝
   * ██║     ███████╗███████╗    ██║     ██║   ██║██║  ██║█████╗
   * ██║     ╚════██║╚════██║    ██║     ██║   ██║██║  ██║██╔══╝
   * ╚██████╗███████║███████║    ╚██████╗╚██████╔╝██████╔╝███████╗
   *  ╚═════╝╚══════╝╚══════╝     ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝
   *
	 */
  public get cssCode(): string {

    return this.sourceCodes[2]
  }
  public set cssCode(value: string) {

    this.sourceCodes[2] = value

    try {

			this.generatedCodes[2] = this.codeGenerationService.generateCss(this.sourceCodes[2])
			this.updateRightLanguage('css')
		}
		catch (error) {

			console.error(error)
		}
  }
}
