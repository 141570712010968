<action style="width: 100%;" [name]="'Split and Trim'" [action]="action">

	<!-- sheet selection -->
	<div class="element-selection-area">

		<div class="leftElementSelectionArea">
			<pre><span style="font-size: 14pt;"><strong>Sheet</strong></span></pre>
		</div>

		<mat-form-field appearance="fill" class="matSelect">

			<mat-label>Sheet</mat-label>
			<mat-select #sheetSelect [(ngModel)]="action.selectedSheet">
				<mat-option *ngFor="let sheetName of action.allSheets" [value]="sheetName">
					{{ sheetName }}
				</mat-option>
			</mat-select>

		</mat-form-field>

	</div>
	<!-- end sheet selection -->

	<div class="element-selection-area" *ngIf="action.column !== undefined">

		<div class="leftElementSelectionArea">
			<pre><span style="font-size: 14pt;"><strong>Column</strong></span></pre>
		</div>

		<mat-form-field appearance="fill" class="matSelect">
			<mat-label>Column</mat-label>
			<mat-select #columnSelect [(ngModel)]="action.column">
				<mat-option *ngFor="let columnName of action.allColumns" [value]="columnName">
					{{ columnName }}
				</mat-option>
			</mat-select>

		</mat-form-field>

	</div>

	<div class="element-selection-area" *ngIf="action.separator !== undefined">
		<div class="leftElementSelectionArea">
			<pre><span style="font-size: 14pt;"><strong>Separator</strong></span></pre>
		</div>
		<mat-form-field appearance="fill" id="matSelect" class="matSelect">
			<mat-label>Separator</mat-label>
			<input matInput type="text" [(ngModel)]="action.separator">
		</mat-form-field>
	</div>

	<!-- Test button -->
	<a (click)="action.executeAction(true)" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
		<span class="mat-button-span">
			Testen
		</span>
	</a>

</action>
