import { HttpClient } from "@angular/common/http"
import { Component, Input } from '@angular/core'

import { ConfigService } from "src/app/config.service"
import { ElementService } from "src/app/element.service"
import { menu } from "src/app/menus/menu/menu"

@Component({
  selector: 'langchainMenu',
  templateUrl: 'langchain.menu.html'
})
export class langchainMenu extends menu {
  @Input() renderOnlyOuter = false
  @Input() isTailwind = false
  @Input() isHelper = true

  public constructor(
    public readonly elementService: ElementService,
		private readonly http: HttpClient,
    private readonly configService: ConfigService
  ) {
    super(elementService)
  }

	promtLangchain(): void{

    let backendURL = `${this.configService.baseUrl}/prompt`

		let body = {
			element: this.promptElement,
			style: this.promptStyle,
		}

		let appComponent = this.elementService.dataService.appComponent
		appComponent.showLoadingDialog = true

    this.http.post(backendURL, body).subscribe({

			next: response => {

				console.log("response:\n", (response as any).responseText)
				this.elementService.selectedObjects[0].element.receivedCode = (response as any).responseText
				appComponent.showLoadingDialog = false
			},
			error: err => console.error(err)
		})
  }

	private _promptElement: string | undefined
  public get promptElement(): string | undefined {

    this._promptElement = this.elementService.selectedObjects[0].element.promptElement

    return this._promptElement
  }
  public set promptElement(value: string | undefined) {

    if(!value) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.promptElement = value
    })
    this._promptElement = value
  }

	private _promptStyle: string | undefined
  public get promptStyle(): string | undefined {

    this._promptStyle = this.elementService.selectedObjects[0].element.promptStyle

    return this._promptStyle
  }
  public set promptStyle(value: string | undefined) {

    if(!value) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.promptStyle = value
    })
    this._promptStyle = value
  }
}
