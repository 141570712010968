import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';
import { MatSlideToggleMenu } from './menu';

@Component({
  selector: 'app-'+MatSlideToggleMenu.name,
  templateUrl: './component.html'
})
export class MatSlideToggleComponent extends LayoutComponent {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'MatSlideToggleComponent';
  }

	setInitialProps(){

    if(this.getProp("text") == "")	this.setProp({key:"text", value: "aktiviere mich!", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    this.elementService.changeDetector.detectChanges()
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getTemplateString(tabs: string): string {

    let attrMap: Map<string, string> = new Map<string, string>()
    let children = []
    let childrenTabs = "\t"+tabs

    /*
      <mat-slide-toggle
        [color]="getProp('color')"
        [checked]="getProp('checked')=='1'"
        [disabled]="getProp('disabled')=='1'">
        {{getProp('text')}}
      </mat-slide-toggle>
    */
                                                attrMap.clear()
    if(this.getProp('checked')=='1')            attrMap.set("checked", "")
    if(this.getProp('onChange')!='')            attrMap.set("(change)", this.getProp('onChange'))
    if(this.getProp('disabled')=='1')           attrMap.set("disabled", "")
                                                attrMap.set("labelPosition", this.getLabelPos())
                                                attrMap.set("color", this.getProp('color'))
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())
    if(this.getStyleAttributesForInner() != "")     attrMap.set("class", this.getProp('displayName'))

    let result = this.buildLeafTag(tabs, "mat-slide-toggle", attrMap, this.getProp('text'))
    return result
  }

  getLabelPos(): "before" | "after" {

    return this.getProp("Labelposition") == "davor" ? "before" : "after"
  }
}
