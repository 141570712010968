<div *ngIf="elementService?.allOfType('langchain')">

  <h2>Langchain-Menü:</h2>
  <b>Prompt something</b>

  <div style="display: flex; flex-direction: column; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- prompt element -->
		<mat-form-field formula appearance="fill" style="width: 99%; position: relative; z-index: 1; left: 3px">

			<button
				matPrefix
				mat-icon-button
				(click)="elementService.removeProp('promptElement'); promptElement = ''"
			>
				<mat-icon>close</mat-icon>
			</button>

			<mat-label>Prompt welches Element</mat-label>

			<input
				matInput
				type="text"
				[(ngModel)]="promptElement"
				(focus)="   elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)"
			>

		</mat-form-field>

    <!-- prompt style -->
		<mat-form-field formula appearance="fill" style="width: 99%; position: relative; z-index: 1; left: 3px">

			<button
				matPrefix
				mat-icon-button
				(click)="elementService.removeProp('promptStyle'); promptStyle = ''"
			>
				<mat-icon>close</mat-icon>
			</button>

			<mat-label>Prompt welcher Style</mat-label>

			<input
				matInput
				type="text"
				[(ngModel)]="promptStyle"
				(focus)="   elementService.tfFocussed(true)"
				(focusout)="elementService.tfFocussed(false)"
			>

		</mat-form-field>
		<a mat-raised-button color="primary" (click)="promtLangchain()" style="margin-left: 5px">
			Send <mat-icon>send</mat-icon>
		</a>

  </div>

</div>
