<div *ngIf="elementService?.allOfType('text')">

  <h2>Custom Code Menü:</h2>

  <!-- HTML -->
	<div class="editor-tooltip"></div>
	<ngx-monaco-editor
		id="monacoEditor"
		*ngIf="elementService.selectedObjects[0] as selected"
		class="editor-container"
		[options]="editorOptions1"
		[(ngModel)]="inputValueHTML"
		(onInit)="onInit($event, selected)"
	>
	</ngx-monaco-editor>

  <!-- Style -->
	<div class="editor-tooltip"></div>
	<ngx-monaco-editor
		id="monacoEditor"
		*ngIf="elementService.selectedObjects[0] as selected"
		class="editor-container"
		[options]="editorOptions2"
		[(ngModel)]="inputValueStyle"
		(onInit)="onInit2($event, selected)"
	>
	</ngx-monaco-editor>
</div>
