import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatInputComponent } from './Angular Material/matInput/matInput.component';
import { LayoutComponent } from './layout/layout.component';
import { ElementService } from './element.service';
import { TreeComponent } from './Angular Material/matTree/tree.component';
import { MenuUnitInputComponent } from './menus/menu-unit-input/menu-unit-input.component';
import { TextComponent } from './css-js Elements/text/text.component';
import { TreeAppHierarchyComponent } from './tree-app-hierarchy/tree-app-hierarchy.component';
import { TextComponentMenu } from './css-js Elements/text/text.menu';
import { MatButtonComponentMenu } from './Angular Material/matButton/matButton.menu';
import { MatButtonComponent } from './Angular Material/matButton/matButton.component';
import { MenuInputComponent } from './menus/menu-input/menu-input.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatInputComponentMenu } from './Angular Material/matInput/matInput.menu';
import { menu } from './menus/menu/menu';
import { MatDisabledMenu } from './menus/menu-mat-disabled/mat.disabled.menu';
import { SelectComponent } from './Angular Material/matSelect/matSelect.component';
import { MatSelectComponentMenu } from './Angular Material/matSelect/matSelect.menu';
import { MatRadioComponentMenu } from './Angular Material/matRadio/matRadio.menu';
import { MatRadioComponent } from './Angular Material/matRadio/matRadio.component';
import { FileNameDialogComponent } from './Dialogs/Dialog FileName/fileName-dialog.component';
import { MatSliderComponentMenu } from './Angular Material/matSlider/matSlider.menu';
import { MatSliderComponent } from './Angular Material/matSlider/matSlider.component';
import { MatToggleMenu } from './menus/menu-mat-toggle/mat.toggle.menu';
import { MatSlideToggleComponent } from './Angular Material/matSlideToggle/component';
import { MatSlideToggleMenu } from './Angular Material/matSlideToggle/menu';
import { MatColorMenu } from './menus/menu-mat-color/mat.color.menu';
import { MatCheckboxMenu } from './Angular Material/matCheckbox/menu';
import { MatCheckboxComponent } from './Angular Material/matCheckbox/component';
import { MatLabelPositionMenu } from './menus/menu-mat-labelPosition/menu';
import { MatGapMenu } from './menus/menu-gap/menu';
import { MatAmountMenu } from './menus/menu-amount/menu';
import { LoadingDialogComponent } from './Dialogs/Dialog Loading/loading';
import { matTableComponentMenu } from './Angular Material/matTable/matTable.menu';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { environment } from '../environments/environment';
import { InMemoryDataService } from './in-memory-data.service';
import { AppComponentLeftMenu } from './menus/leftSideMenu/app.component.leftMenu';
import { MatChipsComponent } from "./Angular Material/matChips/matChips.component";
import { MatChipsComponentMenu } from "./Angular Material/matChips/matChips.menu";
import { MenuBorderComponent } from './menus/menu-border/menu-border.component';
import { MenuBackgroundComponent } from './menus/menu-background/menu-background.component';
import { DialogPageEditorComponent } from './Dialogs/dialog-page-editor/dialog-page-editor.component';
import { PageRoutingMenu } from './menus/menu-pages/pageRouting.menu';
import { TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CssButtonComponentMenu } from './css-js Elements/cssButton/cssButton.menu';
import { CssButtonComponent } from './css-js Elements/cssButton/cssButton.component';
import { MatTextareaComponent } from './Angular Material/matTextarea/component';
import { MatTextareaComponentMenu } from './Angular Material/matTextarea/menu';
import { ApiCallDialog } from './Dialogs/Dialog API Call/apiDialog';
import { JsonViewer } from './Dialogs/Dialog API Call/apiCall/JsonViewer/jsonViewer';
import { MenuMatAriaOrientationComponent } from './menus/menu-mat-aria-orientation/menu-mat-aria-orientation.component';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { generalApiDialog } from './Dialogs/Dialog general API Call/generalApiDialog';
import { CssInputComponent } from './css-js Elements/cssInput/css-input.component';
import { CssInputComponentMenu } from './css-js Elements/cssInput/css-input.menu';
import { htmlViewer } from './Dialogs/Dialog API Call/apiCall/htmlViewer/htmlViewer';
import { resultPreviewTable } from './Dialogs/Dialog API Call/apiCall/resultPreviewTable/resultPreviewTable';
import { FormulaDirective } from './formulaInput/formula.directive';
import { elementCodeDialog } from './Dialogs/Dialog Element Code/elementCodeDialog';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { xSpreadsheet } from './JS Libraries/xSpreadsheet/xSpreadsheet';
import { richText } from './JS Libraries/richText/richText';
import { richTextMenu } from './JS Libraries/richText/richText.menu';
import { matTableComponent } from './Angular Material/matTable/matTable.component';
import { CodeDialogComponent } from './Dialogs/Dialog Code/code-dialog.component';
import { apiCall } from './Dialogs/Dialog API Call/apiCall/apiCall';
import { ChartJsComponent } from './JS Libraries/chart.js/chartJs.component';
import { ChartJsComponentMenu } from './JS Libraries/chart.js/chartJs.menu';
import { langchain } from './JS Libraries/langchain/langchain';
import { langchainMenu } from './JS Libraries/langchain/langchain.menu';
import { leafletJsMenu } from './JS Libraries/leaflet.js/leafletJs.menu';
import { leafletJs } from './JS Libraries/leaflet.js/leafletJs.component';
import { xSpreadsheetMenu } from './JS Libraries/xSpreadsheet/xSpreadsheet.menu';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { EditTableDialogComponent } from './Dialogs/edit-table-dialog/edit-table-dialog.component';
import { RouterOutletPlaceholderComponent } from './router-outlet-placeholder/router-outlet-placeholder.component';
import { RouterOutletPlaceholderMenu } from './router-outlet-placeholder/router-outlet-placeholder.menu';
import { PageFilterPipe } from './page-filter.pipe';
import { SelectionFilterPipe } from './selection-filter.pipe';
import { ProjectsDialogComponent } from './Dialogs/projects-dialog/projects-dialog.component';
import { ProjectComponent } from './project/project.component';
import { ProjectMenuComponent } from './project/project.menu';
import { ProjectFilterPipe } from './project/project-filter.pipe';
import { MenuDisplayNameInputComponent } from './menus/menu-display-name-input/menu-display-name-input.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { EditorComponent } from './editor/editor.component';
import { ImportOptionsDialogComponent } from './Dialogs/import-options-dialog/import-options-dialog.component';
import { MenuSliderComponent } from './menus/menu-slider/menu-slider.component';
import { MenuBoxShadowComponent } from './menus/menu-box-shadow/menu-box-shadow.component';
import { AutomationsDialog } from './Dialogs/Dialog Automations/automationsDialog';
import { AutomationDialog } from './Dialogs/Dialog Automations/single automation dialog/automationDialog';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AddActionDialog } from './Dialogs/Dialog Automations/add action dialog/addActionDialog';
import { CustomScriptActionComponent } from './Dialogs/Dialog Automations/actions/custom-script/custom-script.component';
import { DownloadFileActionComponent } from './Dialogs/Dialog Automations/actions/download-file/download-file.component';
import { SplitAndTrimActionComponent } from './Dialogs/Dialog Automations/actions/split-and-trim/split-and-trim.component';
import { ActionComponent } from './Dialogs/Dialog Automations/actions/action/action.component';
import { ExportExcelActionComponent } from './Dialogs/Dialog Automations/actions/export-excel/export-excel.component';
import { SendEmailActionComponent } from './Dialogs/Dialog Automations/actions/send-email/send-email.component';
import { AddTriggerDialogComponent } from './Dialogs/Dialog Automations/add-trigger-dialog/add-trigger-dialog.component';
import { TriggerComponent } from './Dialogs/Dialog Automations/triggers/trigger/trigger.component';
import { ButtonClickedTriggerComponent } from './Dialogs/Dialog Automations/triggers/button-clicked/button-clicked.component';
import { FileUploadedTriggerComponent } from './Dialogs/Dialog Automations/triggers/file-uploaded/file-uploaded.component';
import { AuthInterceptor } from './auth.interceptor';
import { KanbanComponent } from './syncfusion/kanban/kanban.component';
import { KanbanComponentMenu } from './syncfusion/kanban/kanban.menu';
import { KanbanModule } from '@syncfusion/ej2-angular-kanban';
import { KanbanCardDroppedTriggerComponent } from './Dialogs/Dialog Automations/triggers/kanban-card-dropped/kanban-card-dropped.component';
import { ApiCallActionComponent } from './Dialogs/Dialog Automations/actions/api-call/api-call.component';
import { KanbanCardDroppedActionComponent } from './Dialogs/Dialog Automations/actions/kanban-card-dropped/kanban-card-dropped.component';
import { MatDatepickerComponentMenu } from './Angular Material/matDatepicker/matDatepicker.menu'
import { MatDatepickerComponent } from './Angular Material/matDatepicker/matDatepicker.component'
import { MatCursorMenu } from './menus/menu-cursor/menu'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { SfButtonComponent } from './syncfusion/buttons/button.component'
import { SfButtonComponentMenu } from './syncfusion/buttons/button.menu'
import { ButtonModule } from '@syncfusion/ej2-angular-buttons'

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://keycloak.testing.photonic-codes.cloud/auth',
        realm: 'ui-editor',
        clientId: environment.keycloakClient
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      shouldAddToken: (request) => {
        const acceptablePaths = ['/api/call'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => request.url.includes(path));
        return !isAcceptablePathMatch;
      },
      loadUserProfileAtStartUp: true
    });
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MatInputComponent,
    LayoutComponent,
    TreeComponent,
    matTableComponent,
    matTableComponentMenu,
    MenuUnitInputComponent,
    menu,
    TextComponent,
    TextComponentMenu,
    TreeAppHierarchyComponent,
    CodeDialogComponent,
    MatButtonComponentMenu,
    MatButtonComponent,
    MenuInputComponent,
    MatInputComponentMenu,
    MatDisabledMenu,
    SelectComponent,
    MatSelectComponentMenu,
    MatRadioComponent,
    MatRadioComponentMenu,
    FileNameDialogComponent,
    MatSliderComponentMenu,
    MatSliderComponent,
    MatToggleMenu,
    MatSlideToggleComponent,
    MatSlideToggleMenu,
    MatColorMenu,
    MatCheckboxComponent,
    MatCheckboxMenu,
    MatLabelPositionMenu,
    MatGapMenu,
    MatAmountMenu,
    LoadingDialogComponent,
    AppComponentLeftMenu,
    MatChipsComponent,
    MatChipsComponentMenu,
    MenuBorderComponent,
    MenuBackgroundComponent,
    DialogPageEditorComponent,
    PageRoutingMenu,
    CssButtonComponent,
    CssButtonComponentMenu,
    ChartJsComponent,
    ChartJsComponentMenu,
    MatTextareaComponent,
    MatTextareaComponentMenu,
    ApiCallDialog,
    JsonViewer,
    MenuMatAriaOrientationComponent,
    generalApiDialog,
    CssInputComponent,
    CssInputComponentMenu,
    apiCall,
    htmlViewer,
    resultPreviewTable,
    FormulaDirective,
    elementCodeDialog,
    xSpreadsheet,
    xSpreadsheetMenu,
    leafletJs,
    leafletJsMenu,
    richText,
    richTextMenu,
		langchain,
		langchainMenu,
    EditTableDialogComponent,
    RouterOutletPlaceholderComponent,
    RouterOutletPlaceholderMenu,
    PageFilterPipe,
    SelectionFilterPipe,
    ProjectsDialogComponent,
    ProjectComponent,
    ProjectMenuComponent,
    ProjectFilterPipe,
    MenuDisplayNameInputComponent,
    EditorComponent,
    ImportOptionsDialogComponent,
    MenuSliderComponent,
    MenuBoxShadowComponent,
		AutomationsDialog,
		AutomationDialog,
    FileUploadComponent,
		AddActionDialog,
    CustomScriptActionComponent,
    DownloadFileActionComponent,
    SplitAndTrimActionComponent,
    ActionComponent,
    ExportExcelActionComponent,
    SendEmailActionComponent,
    AddTriggerDialogComponent,
    TriggerComponent,
    ButtonClickedTriggerComponent,
    FileUploadedTriggerComponent,
    KanbanComponent,
    KanbanComponentMenu,
    KanbanCardDroppedTriggerComponent,
    ApiCallActionComponent,
    KanbanCardDroppedActionComponent,
		MatDatepickerComponent,
		MatDatepickerComponentMenu,
		MatCursorMenu,
		SfButtonComponent,
		SfButtonComponentMenu
  ],
  imports: [
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(
      InMemoryDataService, {
        dataEncapsulation: false,
        passThruUnknownUrl: true
      }
    ),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    MaterialModule,
    FormsModule,
    MonacoEditorModule.forRoot(),
    EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    KanbanModule,
		ButtonModule
  ],
  providers: [
    ElementService,
    HttpClient,
    DatePipe,
    DecimalPipe,
		{provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
