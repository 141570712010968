import { Component } from '@angular/core';

import { menu } from '../../menus/menu/menu';
import { ElementService } from '../../element.service';

@Component({
  selector: 'sf-button-menu',
  templateUrl: './button.menu.html',
  styleUrls: ['./button.component.scss'],
})
export class SfButtonComponentMenu extends menu {
  public constructor(public readonly elementService: ElementService) {
    super(elementService)
  }

}
