import { Component } from '@angular/core';

import { menu } from '../../menus/menu/menu';
import { ElementService } from '../../element.service';
import { KanbanColumn, KanbanComponent } from './kanban.component';

@Component({
  selector: 'sf-kanban-menu',
  templateUrl: './kanban.menu.html'
})
export class KanbanComponentMenu extends menu {
  public constructor(public readonly elementService: ElementService) {
    super(elementService)
  }

  public setKeyField(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setKeyField(value);
  }

  public setDataSource(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setDataSource(value);
  }

  public setHeaderField(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setHeaderField(value);
  }

  public setContentField(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setContentField(value);
  }

  public setHeaderTexts(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setHeaderTexts(value);
  }

  public setHeaderTextField(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setHeaderTextField(value);
  }

  public setKeyFields(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setKeyFields(value);
  }

  public setSwimlaneSettingsKeyField(value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setSwimlaneSettingsKeyField(value);
  }

  public setSwimlaneSettingsAllowDragAndDrop(value: boolean): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setSwimlaneSettingsAllowDragAndDrop(value);
  }

  public get columns(): KanbanColumn[] {
    return (this.elementService.selectedObjects[0] as KanbanComponent).columns;
  }

  public setTransitionColumns(index: number, key: string, value: string): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setTransitionColumns(index, key, value);
  }

  public setSwimlaneSettingsAllowDrag(index: number, key: string, value: boolean): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setSwimlaneSettingsAllowDrag(index, key, value);
  }

  public setSwimlaneSettingsAllowDrop(index: number, key: string, value: boolean): void {
    (this.elementService.selectedObjects[0] as KanbanComponent).setSwimlaneSettingsAllowDrop(index, key, value);
  }
}
