<mat-form-field [appearance]="getAppearance()" [color]="getColor()">

  <mat-label>{{getProp('Label')}}</mat-label>

  <input matInput [matDatepicker]="dp" [id]="getProp('displayName')+'Datepicker'" [disabled]="getProp('disabled')=='1'">

	<mat-hint *ngIf="getProp('startHint')!=''" align="start">{{getProp("startHint")}}</mat-hint>
	<mat-hint *ngIf="getProp('endHint')!=''" align="end">{{getProp("endHint")}}</mat-hint>

  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp></mat-datepicker>

</mat-form-field>
