<div *ngIf="elementService?.allOfType('ProjectComponent')">

  <mat-form-field appearance="fill" [style]="'width: 100%'">
    <mat-label translate>Selected Project</mat-label>
    <mat-select #projectSelect (valueChange)="selectedProject = projectSelect.value" [value]="selectedProject">
      <mat-option *ngFor="let project of dataService.projectsService.cacheService.projects | projectFilter" [value]="project">
        {{ getProjectName(project) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%'">
    <mat-label translate>Start Page</mat-label>
    <mat-select #startPageSelect [disabled]="pages.length === 0" (valueChange)="startPage = startPageSelect.value" [value]="startPage">
      <mat-option *ngFor="let page of pages" [value]="getPageId(page)">
        {{ page.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
