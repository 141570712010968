import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';

var name = "matSlider"

@Component({
  selector: name+'-menu',
  templateUrl: name+'.menu.html'
})
export class MatSliderComponentMenu extends menu {
  name = name
  isHelper = true
}
