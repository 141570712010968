<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">

  <!-- Expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="mat-nested-tree-node"

    style="cursor: pointer;"
    (mouseover)="node.setHover(true)"
    (mouseout)="node.setHover(false)"
    (click)="node.onClick($event)"
  >

      <!-- Expandable Node -->
      <div class="mat-expandable-node" [ngClass]="{selected: node.hasRedOutline}"

        [draggable]="!node.isRootNode"
        (dragstart)="drag(node)"
        (drop)="drop($event, node)"
        (dragover)="dragover($event, node)"
        (dragleave)="dragleave($event)"
        >

        <div style="display: flex; align-items: center;">

					<!-- Icon -->
          <button mat-icon-button matTreeNodeToggle (click)="updateDatasourceAndExpand()">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>

					<!-- Text of the node -->
          <div [ngClass]="{bold: node.hasBlueOutline}">{{node.getProp('displayName')}}</div>

        </div>
      </div>

      <!-- Node Group  -->
      <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>

      <div *ngIf="!node.isRootNode" class="below"
        (dragover)="dragover($event, node)"
        (dragleave)="dragleave($event)"
        (drop)="drop($event, node)">
      </div>

  </mat-nested-tree-node>

  <!-- Leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [ngClass]="{selected: node.hasRedOutline}"

    draggable="true" (dragstart)="drag(node)" (drop)="drop($event, node)" (dragover)="dragover($event, node)" (dragleave)="dragleave($event)"

    style="cursor: pointer;"
    (mouseover)="node.setHover(true)"
    (mouseout)="node.setHover(false)"
    (click)="node.onClick($event)"
  >

    <div class="above"></div>

    <div class="center" [ngClass]="{bold: node.hasBlueOutline}">{{node.getProp('displayName')}}</div>

    <div class="below"></div>
  </mat-tree-node>
</mat-tree>
