<mat-form-field appearance="fill" [style]="'width: '+ width +';'">

  <button
    matPrefix
    mat-icon-button
    (click)="	elementService.removeProp(name);
    					inputValue = undefined"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-label translate>menuInputLabels.{{shortName?shortName:name}}</mat-label>

  <input
    matInput
    [type]="type"
    [(ngModel)]="inputValue"
    (focus)="elementService.tfFocussed(true)"
    (focusout)="elementService.tfFocussed(false)"
  >

</mat-form-field>
