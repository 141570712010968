import { Automation } from './automation';

export abstract class Trigger {
  public id: string;
  public name: string;
  public saveLayoutId?: string;
  public automation?: Automation;

  public triggerAction(): void {
    if (this.automation && this.automation.action) {
      this.automation.action.executeAction();
    }
  }
}
