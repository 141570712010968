<mat-form-field appearance="fill" [style]="'width: '+ width +';'">

  <button
    matPrefix
    mat-icon-button
    (click)="elementService.removeProp(name);
    thisInput.value = ''"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-label>{{shortName?shortName:name}}</mat-label>

  <input
    #thisInput
    matInput [disabled]="disabled"
    type="number"
    [(ngModel)]="inputValue"
    (focus)="elementService.tfFocussed(true)"
    (focusout)="elementService.tfFocussed(false)"
  >
  <button
    mat-icon-button
    matSuffix
    (click)="switchUnitProperty()"
  >
    <span style="font-size: 16px;">{{ getUnitProperty() }}</span>
  </button>

</mat-form-field>
