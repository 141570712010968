<div class="overlay"></div>

<div class="dialog">
  <mat-card style="display: flex; flex-direction: column; align-items: flex-start;
                   padding: 10px;">

    <div style="width: 100%; display: flex; justify-content: space-between;">

      <h1 id="Heading_left" class="text-black">Api Call Dialog</h1>

      <!-- Close button -->
      <a (click)="emitDataAndClose()" class="mat-button-wrapper-0-0-8" mat-raised-button color="primary">
        <span class="mat-button-span">
          Schliessen
        </span>
      </a>
    </div>

    <ng-container #apiCallContainer></ng-container>

    <!-- Add api call -->
    <a (click)="addCountedApiCall()" class="mat-button-wrapper-0-0-7" mat-raised-button color="primary">
      <span class="mat-button-span">
        Api Call hinzufügen
      </span>
    </a>

    <!-- Preview of all api calls -->
    <div [ngStyle]="{'display': apiCalls.length > 1 ? 'revert': 'none'}" style="width: 100%;">
      <h1 id="Heading_left" style="margin-top: 10px;">Finales ergebnis</h1>
      <resultPreviewTable [columnData]="columnData" [dataData]="originalDataData" #resultPreviewTable
                                    (notifyNewFilter)="storeFilter($event)"></resultPreviewTable>
    </div>

    <!-- Close button -->
    <a (click)="emitDataAndClose()"
        class="mat-button-wrapper-0-0-7" mat-raised-button color="primary">
      <span class="mat-button-span">
        Fertigstellen
      </span>
    </a>

  </mat-card>
</div>
