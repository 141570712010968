import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.scss']
})
export class CodeDialogComponent {

  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ){ }

  copyText(text: string){

    this.clipboard.copy(text)

    this.snackBar.open("Code copied!", undefined, {duration: 5000})
  }

  close() {

    this.closed.emit('closed');
  }

  @Input()
  HTMLdata = "";

  @Input()
  templateName = "";

  @Input()
  cssName = "";

  @Input()
  scriptName = "";

  @Input()
  cssCode = "";

  @Input()
  tsCode =
`import {Component} from '@angular/core';

@Component({
  selector: 'xyz',
  templateUrl: 'xyz.component.html',
  styleUrls: ['xyz.component.scss'],
})
export class xyzComponent {}`
}
