import { Component, Input } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, Trigger } from '../../../../automations';
import { FileUploadComponent } from '../../../../file-upload/file-upload.component';

@Component({
  selector: 'file-uploaded-trigger',
  templateUrl: './file-uploaded.component.html',
  styleUrls: ['./file-uploaded.component.scss']
})
export class FileUploadedTriggerComponent {
  @Input()
  public trigger: Trigger;

  public fileUploads: FileUploadComponent[] = [];

  public constructor(private readonly elementService: ElementService, private readonly automationsService: AutomationsService) {
  }

  public ngOnInit(): void {
    this.fileUploads = this.elementService.findElementsByType('fileUpload') as FileUploadComponent[];
  }

  public get selectedFileUpload(): FileUploadComponent | undefined {
    const id = this.automationsService.currentAutomation.trigger?.saveLayoutId;
    if (!id) { return undefined; }
    const fileUpload = this.elementService.findElementById(id)[0] as FileUploadComponent;
    return fileUpload;
  }

  public set selectedFileUpload(fileUpload: FileUploadComponent | undefined) {
    if (!fileUpload) { return; }
    if (!this.automationsService.currentAutomation.trigger) { return; }
    this.automationsService.currentAutomation.trigger.saveLayoutId = fileUpload.saveLayout.id;
    this.automationsService.saveAutomations(this.automationsService.currentLayout);
  }
}
