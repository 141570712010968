import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import _ from 'lodash'
import { ElementService } from "src/app/element.service";

@Component({
  selector: 'resultPreviewTable',
  templateUrl: './resultPreviewTable.html',
  styleUrls: ['./resultPreviewTable.scss']
})
export class resultPreviewTable {

  constructor(
    public elementService: ElementService,
    private changeDetector: ChangeDetectorRef,
  ){ }

  @Input() columnData: string[] = []
  @Input() dataData: any[] = []
  @Input() apiNum: number
  @Output() notifyNewFilter: EventEmitter<string[]> = new EventEmitter<string[]>()
  @Output() iWasFiltered: EventEmitter<string> = new EventEmitter<string>()

  ngOnChanges(changes: SimpleChanges) {

    if(!this.dataData) return
    this.filteredData = this.dataData

		// Return if there are no changes
		if(changes.columnData && changes.dataData &&
			 _.isEqual(changes.columnData.previousValue, changes.columnData.currentValue) &&
			 _.isEqual(changes.dataData.previousValue, changes.dataData.currentValue)) return

    this.updateTheData()
  }
  wasFiltered = false
  filteredData: any[] = []
  /**
   * To get the field keys of an object
   * @param data One single object with keys
   * @returns The keys of the object, or an empty array
   */
  public getKeys(object: any): string[] {

    return object ? Object.keys(object) : []
  }

  public getFilterValue(key: string): string {

    let propName = "filter" + (this.apiNum ? this.apiNum : "") + key

    let filtervalue = this.elementService.getProp(propName)

    return filtervalue
  }

  public addToFilters(key: string, filterInputValue: string) {

    this.filteredData = this.dataData

    // Also sets the prop, so it can be used in updateTheData()
    this.notifyNewFilter.emit([key, filterInputValue])

    this.updateTheData()

  }

  private updateTheData(){

    // For all keys
    this.getKeys(this.dataData[0]).forEach((key: string) => {

      let filterInput = this.getFilterValue(key)

      if(!filterInput) return

      // Filter the dataData according to filterInput
      this.filteredData = this.filteredData.filter((dataRow: any) => {

        let result = true
        let cell = dataRow[key]
        let cellIsString = isNaN(cell)
        if(cellIsString) cell = "'" + cell + "'"

        try {

          result = eval(cell + filterInput)
          this.wasFiltered = true
        }
        catch (error) {

          result = true
        }

        return result
      })
    })

		if(this.wasFiltered) this.iWasFiltered.emit("")
  }
}
