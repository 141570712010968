import { Component } from '@angular/core'

import { DataService } from '../data.service'
import { ElementService } from '../element.service'
import { menu } from '../menus/menu/menu'
import { Project, PropertyIterator, SaveLayout } from '../projects'
import { ProjectComponent } from './project.component'

@Component({
  selector: 'project-menu',
  templateUrl: './project.menu.html'
})
export class ProjectMenuComponent extends menu {
  private cachedSelectedProject?: Project;

  public constructor(public readonly elementService: ElementService, public readonly dataService: DataService) {
    super(elementService)
  }

  public getProjectName(project: Project): string {
    return project.name;
  }

  public get selectedProject(): Project | undefined {
    const selection = (this.elementService.selectedObjects[0] as ProjectComponent).selectedProject;
    if (!this.cachedSelectedProject && selection) {
      this.cachedSelectedProject = this.dataService.projectsService.getProject(selection);
    }
    return this.cachedSelectedProject && this.cachedSelectedProject.id === selection ? this.cachedSelectedProject : undefined;
  }

  public set selectedProject(value: Project | undefined) {
    (this.elementService.selectedObjects[0] as ProjectComponent).setSelectedProject(value?.id);
    this.cachedSelectedProject = value;
  }

  public get pages(): SaveLayout[] {
    return this.dataService.projectsService.currentProject?.pages ?? [];
  }

  public getPageId(page: SaveLayout): string {
    return [...(new PropertyIterator(this.dataService.propertiesService, page))].find((prop) => prop.key === 'id')?.value ?? '';
  }

  public get startPage(): string | undefined {
    return (this.elementService.selectedObjects[0] as ProjectComponent).startPage;
  }

  public set startPage(value: string | undefined) {
    (this.elementService.selectedObjects[0] as ProjectComponent).setStartPage(value);
  }
}
