import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

import { DataService } from '../../data.service';
import { ElementService } from '../../element.service';
import { LayoutComponent } from '../../layout/layout.component';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
})
export class TextComponent extends LayoutComponent implements AfterViewInit {

  public constructor(public readonly elementService: ElementService,
										 public readonly renderer: Renderer2,
										 public readonly dataService: DataService)
	{
    super(elementService, renderer, dataService)
    this.componentType = 'TextComponent'
  }

	ngAfterViewInit(){

    /*
      This is needed, because formula.directive.ts calls
      this.elementService.elementInFormulaMode.element.deactivateFormulaMode()
      in onClick()
    */
    this.element = this

    /*
      This is needed, because the Properties are done loading after the View Init Hook
    */
    this.dataService.propertiesAreLoaded ? this.init() :
      this.dataService.propertiesLoaded.subscribe(() => {

        this.init()
      })
  }

  init(){

    if(this.getProp("html") == "")
							this.setProp({key:"html",
														value: "<div class='hello'>Hello world!</div>",
														second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    // if(this.getProp("style") == "")
		// 					this.setProp({key:"style",
		// 												value: ".hello{ font-style: italic; color: green; }",
		// 												second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    //

		this.renderHtmlContent()

    this.elementService.changeDetector.detectChanges()
  }

  @ViewChild('htmlContainer') htmlContainer: ElementRef
  renderHtmlContent() {

		this.renderer.setProperty(this.htmlContainer.nativeElement, 'innerHTML', '')

    const htmlContent = "<div>" + this.getProp("html") + "<style>" + this.getProp("style") + "</style></div>"
    const div = this.renderer.createElement('div')
    this.renderer.setProperty(div, 'innerHTML', htmlContent)
    const htmlContentElement = div.firstChild
    this.renderer.appendChild(this.htmlContainer.nativeElement, htmlContentElement)
  }

  public get htmlToShow(): string { return this.getProp("html") }
  public set htmlToShow(value: string) {

    this.setProp(
      {key: 'html', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )

		this.renderHtmlContent()
  }

  public get styleToShow(): string { return this.getProp("style") }
  public set styleToShow(value: string) {

    this.setProp(
      {key: 'style', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )

		this.renderHtmlContent()
  }

  ngOnChanges(changes: SimpleChanges) {

    this.elementService.changeDetector.detectChanges()
  }

  public getCssTemplate(): string {

    return this.getProp('style') ? this.getProp('style') + "\n\n" : ""
  }

  public getHtmlTemplate(): string {

    return this.getProp('html')
  }
}
