import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from "../../data.service";
import { ProjectDataCacheService } from '../../project-data-cache.service';

@Component({
  selector: 'css-input',
  templateUrl: './css-input.component.html',
  styleUrls: ['./css-input.component.scss']
})
export class CssInputComponent extends LayoutComponent {
  constructor(
    public readonly elementService: ElementService,
    public readonly renderer: Renderer2,
    public readonly dataService: DataService,
    public readonly projectDataCacheService: ProjectDataCacheService
  ) {
    super(elementService, renderer, dataService);
    this.componentType = 'CssInputComponent';
  }

	setInitialProps(){

    this.elementService.changeDetector.detectChanges();
  }

  /*
   *
   * ███████╗████████╗██╗   ██╗██╗     ███████╗
   * ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝
   * ███████╗   ██║    ╚████╔╝ ██║     █████╗
   * ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝
   * ███████║   ██║      ██║   ███████╗███████╗
   * ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝
   *
   */

  public getCssTemplate(): string {
    let styles =
`.${this.getProp('displayName')} {
  ${this.getStyleAttributesForInner().replaceAll('; ', ';\n  ')}
}`;

    if (this.getProp('hover-color') || this.getProp('hover-background-color')) {
      styles +=
`.${this.getProp('displayName')}:hover {
  ${this.getProp('hover-color') ? `color: ${this.getProp('hover-color')};` : ''}
  ${this.getProp('hover-background-color') ? `background-color: ${this.getProp('hover-background-color')};` : ''}
}`;
    }

    return styles;
  }

  /*
   *
   * ████████╗███████╗███╗   ███╗██████╗ ██╗      █████╗ ████████╗███████╗    ███████╗████████╗██████╗ ██╗███╗   ██╗ ██████╗
   * ╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝
   *    ██║   █████╗  ██╔████╔██║██████╔╝██║     ███████║   ██║   █████╗      ███████╗   ██║   ██████╔╝██║██╔██╗ ██║██║  ███╗
   *    ██║   ██╔══╝  ██║╚██╔╝██║██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝      ╚════██║   ██║   ██╔══██╗██║██║╚██╗██║██║   ██║
   *    ██║   ███████╗██║ ╚═╝ ██║██║     ███████╗██║  ██║   ██║   ███████╗    ███████║   ██║   ██║  ██║██║██║ ╚████║╚██████╔╝
   *    ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝
   *
   */
  public getTemplateString(tabs: string): string {
    let attrMap: Map<string, string> = new Map<string, string>();
    attrMap.set("id", this.getProp("displayName"));
    attrMap.set("class", this.getProp("displayName"));
    attrMap.set("type", this.getProp("type"));
    attrMap.set("value", this.getProp("value"));
    if (this.getProp("step") !== '') { attrMap.set("step", this.getProp("step")); }
    return this.buildLeafTagNoClosing(tabs, "input", attrMap);
  }

  public getHtmlTemplate() {
    const code =
`<input #thisinput_${this.getProp('displayName').toLowerCase()}
  [id]="prefix + 'getProp('displayName')'"
  class="getProp('displayName')"
  ${this.getProp('type') ? `type="getProp('type')"` : ''}
  [value]="${this.getProp('cache-data') === '1' ? `projectDataCacheService.getValue(prefix + 'getProp('displayName')')` : `'${this.getProp('value')}'`}"
  (input)="${this.getProp('cache-data') === '1' ? `projectDataCacheService.setValue(prefix + 'getProp('displayName')', thisinput_${this.getProp('displayName').toLowerCase()}.value)` : `thisinput_${this.getProp('displayName').toLowerCase()}.value`}"
  ${this.getProp('type') === 'checkbox' ? `checked="getProp('value')"` : ''}
  ${this.getProp('type') === 'range' ? `[step]="'getProp('step')'"` : ''}
  ${this.getProp('normal-color') ? `[style.color]="'${this.getProp('normal-color')}'"` : ''}
  ${this.getProp('normal-background-color') ? `[style.backgroundColor]="'${this.getProp('normal-background-color')}'"` : ''}
/>
`;
  return code;
  }
}
