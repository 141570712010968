import { Injectable } from '@angular/core';

import { Project, Property, PropertyIterator, PropertiesService, SaveLayout, SaveLayoutIterator, SaveLayoutsService } from './projects';
import { PageReference, ProjectContext } from './data.model';

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   TREE SEARCH SERVICE                               |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS SERVICE IS TO               |
					 |   * SEARCH LAYOUT COMPONENTS INSIDE THE TREE        |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Injectable({
  providedIn: 'root'
})
export class TreeSearchService {
  public constructor(private readonly saveLayoutsService: SaveLayoutsService, private readonly propertiesService: PropertiesService) {
  }

  /*
   *  ██████╗ ██╗   ██╗███████╗██████╗ ██╗███████╗███████╗
   * ██╔═══██╗██║   ██║██╔════╝██╔══██╗██║██╔════╝██╔════╝
   * ██║   ██║██║   ██║█████╗  ██████╔╝██║█████╗  ███████╗
   * ██║▄▄ ██║██║   ██║██╔══╝  ██╔══██╗██║██╔══╝  ╚════██║
   * ╚██████╔╝╚██████╔╝███████╗██║  ██║██║███████╗███████║
   *  ╚══▀▀═╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝╚═╝╚══════╝╚══════╝
   */

	public getProp(saveLayout: SaveLayout, name: string): string {
		const propertyIterator = new PropertyIterator(this.propertiesService, saveLayout);
		const attributes = [ ...propertyIterator ];
		return attributes.find((prop) => prop.key === name)?.value ?? '';
	}

	public getId(saveLayout: SaveLayout): string {
		return this.getProp(saveLayout, 'id');
	}

	public isProp(saveLayout: SaveLayout, name: string, value: string): Property | undefined {
		const propertyIterator = new PropertyIterator(this.propertiesService, saveLayout);
		const attributes = [ ...propertyIterator ];
		return attributes.find((prop) => prop.key === name && prop.value === value);
	}

	public findId(saveLayout: SaveLayout, id: string): Property | undefined {
		const propertyIterator = new PropertyIterator(this.propertiesService, saveLayout);
		const attributes = [ ...propertyIterator ];
		return attributes.find((prop) => prop.key === 'id' && prop.value === id);
	}

	public findPage(pages: SaveLayout[], id: string): SaveLayout | undefined {
		return pages.find((page) => this.findId(page, id));
	}

	/*
   * ██████╗  █████╗  ██████╗ ███████╗███████╗    ██████╗ ███████╗██╗      █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
   * ██╔══██╗██╔══██╗██╔════╝ ██╔════╝██╔════╝    ██╔══██╗██╔════╝██║     ██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
   * ██████╔╝███████║██║  ███╗█████╗  ███████╗    ██████╔╝█████╗  ██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
   * ██╔═══╝ ██╔══██║██║   ██║██╔══╝  ╚════██║    ██╔══██╗██╔══╝  ██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
   * ██║     ██║  ██║╚██████╔╝███████╗███████║    ██║  ██║███████╗███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
   * ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
	 */

	/**
	 * ### Linked Default Pages
	 * Finds all linked default pages of a given page.
	 * @param page The page to find the linked default pages for.
	 * @returns The linked default pages.
	 */
	getLinkedDefaultPages(project: Project, page: SaveLayout): SaveLayout[] {
		const result: SaveLayout[] = [];

		this.forEachRouterOutletPlaceholder(page, (routerOutletPlaceholder) => {
			const defaultPage = this.getProp(routerOutletPlaceholder, 'defaultPage');
			if (defaultPage) {
				const linkedPage = this.findPage(project.pages, defaultPage);
				if (linkedPage) {
					result.push(linkedPage);
				}
			}
		});

		return result;
	}

	/**
	 * ### Linked Pages
	 * Finds all linked pages of a given page.
	 * @param page The page to find the linked pages for.
	 * @returns The linked pages.
	 */
	getLinkedPages(project: Project, page: SaveLayout): SaveLayout[] {
		const result: SaveLayout[] = [];

		this.forEachRouterOutletPlaceholder(page, (routerOutletPlaceholder) => {
			const defaultPage = this.getProp(routerOutletPlaceholder, 'defaultPage');

			const selectedPages = this.getProp(routerOutletPlaceholder, 'selectedPages');
			if (selectedPages) {
				for (const selectedPage of JSON.parse(selectedPages)) {
					const linkedPage = this.findPage(project.pages, selectedPage);
					if (linkedPage && this.getId(linkedPage) !== defaultPage) {
						result.push(linkedPage);
					}
				}
			}
		});

		return result;
	}

	/**
	 * ### Linking Default Pages
	 * Finds all linking default pages of a given page.
	 * These are all pages that have the given page as the default page.
	 * @param page The page to find the linking default pages for.
	 * @returns The linking default pages.
	 */
	getLinkingDefaultPages(project: Project, page: SaveLayout): SaveLayout[] {
		const result: SaveLayout[] = [];

		for (const otherPage of project.pages) {
			this.forEachRouterOutletPlaceholder(otherPage, (routerOutletPlaceholder) => {
				const id = this.getId(page);
				if (id) {
					const defaultPage = this.getProp(routerOutletPlaceholder, 'defaultPage');
					if (defaultPage && defaultPage === id) {
						result.push(otherPage);
					}
				}
			});
		}

		return result;
	}

	/**
	 * ### Linking Pages
	 * Finds all linking pages of a given page.
	 * These are all pages that have the given page as a selected page.
	 * @param page The page to find the linking pages for.
	 * @returns The linking pages.
	 */
	getLinkingPages(project: Project, page: SaveLayout): SaveLayout[] {
		const result: SaveLayout[] = [];

		for (const otherPage of project.pages) {
			this.forEachRouterOutletPlaceholder(otherPage, (routerOutletPlaceholder) => {
				const id = this.getId(page);
				if (id) {
					const defaultPage = this.getProp(routerOutletPlaceholder, 'defaultPage');
					const selectedPages = this.getProp(routerOutletPlaceholder, 'selectedPages');
					if (selectedPages) {
						for (const selectedPage of JSON.parse(selectedPages)) {
							if (selectedPage === id && selectedPage !== defaultPage) {
								result.push(otherPage);
							}
						}
					}
				}
			});
		}

		return result;
	}

	/*
   * ██████╗  ██████╗ ██╗   ██╗████████╗███████╗██████╗      ██████╗ ██╗   ██╗████████╗██╗     ███████╗████████╗    ██████╗ ██╗      █████╗  ██████╗███████╗██╗  ██╗ ██████╗ ██╗     ██████╗ ███████╗██████╗     ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
   * ██╔══██╗██╔═══██╗██║   ██║╚══██╔══╝██╔════╝██╔══██╗    ██╔═══██╗██║   ██║╚══██╔══╝██║     ██╔════╝╚══██╔══╝    ██╔══██╗██║     ██╔══██╗██╔════╝██╔════╝██║  ██║██╔═══██╗██║     ██╔══██╗██╔════╝██╔══██╗    ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
   * ██████╔╝██║   ██║██║   ██║   ██║   █████╗  ██████╔╝    ██║   ██║██║   ██║   ██║   ██║     █████╗     ██║       ██████╔╝██║     ███████║██║     █████╗  ███████║██║   ██║██║     ██║  ██║█████╗  ██████╔╝    ███████╗█████╗  ███████║██████╔╝██║     ███████║
   * ██╔══██╗██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗    ██║   ██║██║   ██║   ██║   ██║     ██╔══╝     ██║       ██╔═══╝ ██║     ██╔══██║██║     ██╔══╝  ██╔══██║██║   ██║██║     ██║  ██║██╔══╝  ██╔══██╗    ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
   * ██║  ██║╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║    ╚██████╔╝╚██████╔╝   ██║   ███████╗███████╗   ██║       ██║     ███████╗██║  ██║╚██████╗███████╗██║  ██║╚██████╔╝███████╗██████╔╝███████╗██║  ██║    ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
   * ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝     ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝   ╚═╝       ╚═╝     ╚══════╝╚═╝  ╚═╝ ╚═════╝╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═════╝ ╚══════╝╚═╝  ╚═╝    ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
	 */

	public findFirstRouterOutletPlaceholder(page: SaveLayout): SaveLayout | undefined {
		return this.firstElement(page, 'RouterOutletPlaceholderComponent');
	}

	/**
	 * ### Für jedes RouterOutletPlaceholder Element
	 * Findet alle RouterOutletPlaceholder in einer Page und führt eine Funktion für jedes aus.
	 * @param page Die Seite innerhalb welcher gesucht wird.
	 * @param fn Die bei Treffer auszuführende Funktion.
	 */
	public forEachRouterOutletPlaceholder(page: SaveLayout, fn: (routerOutletPlaceholder: SaveLayout) => void): void {
		this.forEachElement(page, 'RouterOutletPlaceholderComponent', fn);
	}

	public isRouterOutletPlaceholderCreationDisabled(project: Project, pageReference: PageReference): boolean {
		let result = false;

		if (!project) {
			return result;
		}
		const currentPage = project.pages.find((page) => page.name === pageReference.name);
		if (currentPage) {
			this.forEachRouterOutletPlaceholder(currentPage, () => {
				result = true;
			});
		}

		return result;
	}

	/*
   * ██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗     ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗    ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
   * ██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝    ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
   * ██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║       ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║       ███████╗█████╗  ███████║██████╔╝██║     ███████║
   * ██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║       ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║       ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
   * ██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║       ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║       ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
   * ╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝        ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝       ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
   */

	public getProjectComponentForRouterOutletPlaceholder(projectContext: ProjectContext, routerOutletPlaceholderId: string): string | undefined {
		let result = undefined;

		for (const [projectComponentId, projectState] of projectContext.projectStateTable.entries()) {
			for (const projectPage of projectState.pages) {
				this.forEachRouterOutletPlaceholder(projectPage, (rop) => {
					const attributes = new PropertyIterator(this.propertiesService, rop);
					if (routerOutletPlaceholderId === [ ...attributes ].find((prop) => prop.key === 'id')?.value) {
						result = projectComponentId;
					}
				});
			}
		}

		return result;
	}

  /*
   * ████████╗██████╗ ███████╗███████╗    ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
   * ╚══██╔══╝██╔══██╗██╔════╝██╔════╝    ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
   *    ██║   ██████╔╝█████╗  █████╗      ███████╗█████╗  ███████║██████╔╝██║     ███████║
   *    ██║   ██╔══██╗██╔══╝  ██╔══╝      ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
   *    ██║   ██║  ██║███████╗███████╗    ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
   *    ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝    ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
   */

	public firstElementInProject(project: Project, type: string): SaveLayout | undefined {
		for (const page of project.pages) {
			const element = this.firstElement(page, type);
			if (element) {
				return element;
			}
		}
		return undefined;
	}

	public firstElement(saveLayout: SaveLayout, type: string): SaveLayout | undefined {
		// Stack mit den ersten Kindern der Page initialisieren
		let stack: SaveLayout[] = [ saveLayout ];

		// Rekusrive Simulation (Breitensuche)
		while (stack.length > 0) {
			// Entnehme ein Kind
			const child = stack.pop()!;

			// Inhalt der rekursiven Funktion
			const propertyIterator = new PropertyIterator(this.propertiesService, child);
			const attributes = [ ...propertyIterator ];
			const match = attributes.find((prop) => prop.key === 'componentType' && prop.value === type);
			if (match) {
				return child;
			}

			// Die nächsten Kinder unter der Wurzel in den Stack einreihen
			const saveLayoutIterator = new SaveLayoutIterator(this.saveLayoutsService, child);
			const children = [ ...saveLayoutIterator ];
			if (children.length > 0) {
				stack = [ ...stack, ...children ];
			}
		}

		return undefined;
	}

	public forEachElementInProject(project: Project, type: string, fn: (element: SaveLayout) => void): void {
		for (const page of project.pages) {
			this.forEachElement(page, type, fn);
		}
	}

	/**
	 * ### Für jedes Element
	 * Findet alle Elemente in einem SaveLayout und führt gegebenenfalls eine Funktion für jedes gefundene Element aus.
	 * @param saveLayout Für dieses Element und für alle passenden Kinder wird die Funktion ausgeführt.
	 * @param type Der Typ der Elemente, für welche die Funktion ausgeführt werden soll. Übergebe einen leeren String ('') um alle Typen zu akzeptieren.
	 * @param fn  Die Funktion, welche für jedes passende Element ausgeführt wird.
	 */
	public forEachElement(saveLayout: SaveLayout, type: string, fn: (element: SaveLayout) => void): void {
		// Stack mit den ersten Kindern der Page initialisieren
		let stack: SaveLayout[] = [ saveLayout ];

		// Rekusrive Simulation (Breitensuche)
		while (stack.length > 0) {
			// Entnehme ein Kind
			const child = stack.pop()!;

			// Inhalt der rekursiven Funktion
			if (type === '' || this.isProp(child, 'componentType', type)) {
				fn(child);
				continue;
			}

			// Die nächsten Kinder unter der Wurzel in den Stack einreihen
			const saveLayoutIterator = new SaveLayoutIterator(this.saveLayoutsService, child);
			const children = [ ...saveLayoutIterator ];
			if (children.length > 0) {
				stack = [ ...stack, ...children ];
			}
		}
	}
}
