import { Component, Input } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-color-menu',
  templateUrl: 'mat.color.menu.html'
})
export class MatColorMenu extends menu {

  // @Input() shortName = ""
  @Input() width = "100%"

  renderOnlyOuter = false
  isTailwind = false
  isHelper = true
}
