<trigger style="width: 100%;" [name]="'Button Clicked'" [trigger]="trigger">

  <div class="element-selection-area">
    <div class="richText2">
      <pre><span style="font-size: 14pt;"><strong>Button</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Buttons</mat-label>
      <mat-select #thisSelect (selectionChange)="selectedButton = thisSelect.value" [value]="selectedButton">
        <mat-option *ngFor="let button of buttons" [value]="button">
          {{ button.getProp('displayName') ? button.getProp('displayName') : button.getProp('id') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</trigger>
