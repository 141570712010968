import { FileGenerator } from '../../code-generation.model';
import { DataService } from '../../../data.service';
import { TreeSearchService } from '../../../tree-search.service';
import { LayoutComponent } from '../../../layout/layout.component';
import { Project, SaveLayout } from '../../../projects';

export class AppRoutingModuleGenerator extends FileGenerator {

  public constructor(private readonly dataService: DataService, private readonly treeSearchService: TreeSearchService) {

    super()
  }

	public getFilename(): string {

		return 'src/app/app-routing.module.ts'
	}

  public generate(): string {
		const result =
`import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'${this.generateRoutingModuleImports()}

const routes: Routes = [
${this.generateRoutesForApp()}]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
`
		return result;
  }

  private generateRoutingModuleImports(): string {
		const pages = this.dataService.projectsService.currentProject!.pages.filter((page) => page.name !== 'App');
		if (pages.length + (this.dataService.generationContext?.projectStateTable.size ?? 0) === 0) { return ''; }

		let result = '\r\n\r\n';

		for (const page of pages) {
			const selectorName = LayoutComponent.generateSelectorName(page.name);
			const componentName = LayoutComponent.generateComponentName(page.name);
			result += `import { ${componentName}Component } from './pages/${selectorName}/${selectorName}.component';\r\n`;
		}

		if (this.dataService.generationContext && this.dataService.generationContext.projectStateTable.size > 0) {
			for (const [projectComponentId, projectState] of this.dataService.generationContext.projectStateTable.entries()) {
				for (const projectPage of projectState.pages) {
					if (projectPage.name === 'App') { continue; }
					const selectorName = LayoutComponent.generateSelectorName(projectPage.name);
					const componentName = LayoutComponent.generateComponentName(projectPage.name);
					result += `import { ${componentName}Component } from './${selectorName}/${selectorName}.component';\r\n`;
				}
			}
		}

		result = result.slice(0, -2);

		return result;
	}

	private generateRoutesForApp(): string {
		let result = '';

		const appPage = this.dataService.projectsService.currentProject!.pages.find((page) => page.name === 'App')!;

		const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(this.dataService.projectsService.currentProject!, appPage);
		for (const linkedDefaultPage of linkedDefaultPages) {
			result += this.generateRoutesForPage(this.dataService.projectsService.currentProject!, linkedDefaultPage, true, 1);
		}

		const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(this.dataService.projectsService.currentProject!, appPage);
		for (const linkedPage of linkedPages) {
			result += this.generateRoutesForPage(this.dataService.projectsService.currentProject!, linkedPage, false, 1);
		}

		if (this.dataService.generationContext && this.dataService.generationContext.projectStateTable.size > 0) {
			for (const [projectComponentId, projectState] of this.dataService.generationContext.projectStateTable.entries()) {
				const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(projectState, projectState.pages[0]);
				for (const linkedDefaultPage of linkedDefaultPages) {
					result += this.generateRoutesForPage(projectState, linkedDefaultPage, true, 1);
				}

				const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(projectState, projectState.pages[0]);
				for (const linkedPage of linkedPages) {
					result += this.generateRoutesForPage(projectState, linkedPage, false, 1);
				}
			}
		}

		return result;
	}

	/**
	 * Generates the code for the routes for one single page
	 * @returns The code for the routes for one single page
	 */
	private generateRoutesForPage(project: Project, page: SaveLayout, isDefaultPage: boolean, depth: number): string {
		const linkedDefaultPages: SaveLayout[] = this.treeSearchService.getLinkedDefaultPages(project, page);
		const linkedPages: SaveLayout[] = this.treeSearchService.getLinkedPages(project, page);
		const tabs = '  '.repeat(depth);

		let result = tabs;

		result += `{ path: '${isDefaultPage ? '' : LayoutComponent.generateSelectorName(page.name)}', component: ${LayoutComponent.generateComponentName(page.name)}Component`;

		if (linkedDefaultPages.length + linkedPages.length > 0) {
			result += ', children: [\r\n';

			for (const linkedDefaultPage of linkedDefaultPages) {
				result += this.generateRoutesForPage(project, linkedDefaultPage, true, depth + 1);
			}

			for (const linkedPage of linkedPages) {
				result += this.generateRoutesForPage(project, linkedPage, false, depth + 1);
			}

			result += tabs + ']';
		}

		result += ' },\r\n';

		return result;
	}
}
