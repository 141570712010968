<!-- OBERES -->
<div>

<!--
██╗      █████╗ ██╗   ██╗ ██████╗ ██╗   ██╗████████╗     ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
██║     ██╔══██╗╚██╗ ██╔╝██╔═══██╗██║   ██║╚══██╔══╝    ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
██║     ███████║ ╚████╔╝ ██║   ██║██║   ██║   ██║       ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║███████╗
██║     ██╔══██║  ╚██╔╝  ██║   ██║██║   ██║   ██║       ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║╚════██║
███████╗██║  ██║   ██║   ╚██████╔╝╚██████╔╝   ██║       ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║███████║
╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝  ╚═════╝    ╚═╝        ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

-->
	<div style="display: flex; flex-direction: column; align-items: stretch">
		<button
			mat-raised-button
			color="primary"
			style="margin: 2px; height: 40px"
			(click)="elementService.dataService.openPagesDialog()"
		>
			<span translate>leftMenu.pageEditor</span>
		</button>
	</div>
	<div *ngIf="this.elementService?.allLayouts()">
		<div style="display: flex; flex-direction: column; align-items: stretch">
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected()"
			>
				<span translate>leftMenu.newLayout</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('text')"
			>
				<span translate>Custom Code</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('richText')"
			>
				<span>Rich Text</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('langchain')"
			>
				<span>AI Element</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				[disabled]="treeSearchService.isRouterOutletPlaceholderCreationDisabled(dataService.projectsService.currentProject!, dataService.currentPage)"
				(click)="elementService.createElementsIntoAllSelected('routerOutletPlaceholder')"
			>
				<span>Router Outlet</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('ProjectComponent')"
			>
				<span>Component</span>
			</button>
			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('fileUpload')"
			>
				<span>File Upload</span>
			</button>

			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('sf-kanban')"
			>
				<span>Kanban</span>
			</button>

			<button
				mat-raised-button
				color="primary"
				style="margin: 2px; height: 40px"
				(click)="elementService.createElementsIntoAllSelected('sf-button')"
			>
				<span>Button</span>
			</button>
		</div>
<!--
███╗   ███╗ █████╗ ████████╗    ████████╗ █████╗ ██████╗
████╗ ████║██╔══██╗╚══██╔══╝    ╚══██╔══╝██╔══██╗██╔══██╗
██╔████╔██║███████║   ██║          ██║   ███████║██████╔╝
██║╚██╔╝██║██╔══██║   ██║          ██║   ██╔══██║██╔══██╗
██║ ╚═╝ ██║██║  ██║   ██║          ██║   ██║  ██║██████╔╝
╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝          ╚═╝   ╚═╝  ╚═╝╚═════╝

 -->
		<mat-tab-group>
<!--
███╗   ███╗ █████╗ ████████╗███████╗██████╗ ██╗ █████╗ ██╗
████╗ ████║██╔══██╗╚══██╔══╝██╔════╝██╔══██╗██║██╔══██╗██║
██╔████╔██║███████║   ██║   █████╗  ██████╔╝██║███████║██║
██║╚██╔╝██║██╔══██║   ██║   ██╔══╝  ██╔══██╗██║██╔══██║██║
██║ ╚═╝ ██║██║  ██║   ██║   ███████╗██║  ██║██║██║  ██║███████╗
╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚══════╝

 -->
			<mat-tab label="Material">
				<div
					style="display: flex; flex-direction: column; align-items: stretch"
				>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matButton')"
					>
						<span translate>leftMenu.newButton</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matInput')"
					>
						<span translate>leftMenu.newInput</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matDatepicker')"
					>
						<span>Datepicker</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matTextarea')"
					>
						<span translate>leftMenu.newTextarea</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matSelect')"
					>
						<span translate>leftMenu.newSelect</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matRadio')"
					>
						<span translate>leftMenu.newRadioButtons</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matSlider')"
					>
						<span translate>leftMenu.newSlider</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matSlideToggle')"
					>
						<span translate>leftMenu.newSlideToggle</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matCheckbox')"
					>
						<span translate>leftMenu.newCheckbox</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matTable')"
					>
						<span translate>leftMenu.newTable</span>
					</button>
					<button
						mat-raised-button
						color="primary"
						style="margin: 2px; height: 40px"
						(click)="elementService.createElementsIntoAllSelected('matChips')"
					>
						<span translate>leftMenu.newChips</span>
					</button>
				</div>
			</mat-tab>
<!--
 ██████╗███████╗███████╗
██╔════╝██╔════╝██╔════╝
██║     ███████╗███████╗
██║     ╚════██║╚════██║
╚██████╗███████║███████║
 ╚═════╝╚══════╝╚══════╝

-->
			<mat-tab label="CSS">
				<div
					style="display: flex; flex-direction: column; align-items: stretch"
				>
					<a
						(click)="elementService.createElementsIntoAllSelected('cssButton')"
						style="
							color: #ffffff;
							background-color: #30c82d;
							border: 2px solid #3d963c;
							border-radius: 9px;
							padding: 10px 30px;
							cursor: pointer;
							margin: 2px;
						"
					>
						<span translate>leftMenu.newButton</span></a
					>
					<a
						(click)="elementService.createElementsIntoAllSelected('chartJs')"
						style="
							color: #ffffff;
							background-color: #30c82d;
							border: 2px solid #3d963c;
							border-radius: 9px;
							padding: 10px 30px;
							cursor: pointer;
							margin: 2px;
						"
					>
						<span translate>leftMenu.chartJs</span></a
					>
					<a
						(click)="elementService.createElementsIntoAllSelected('cssInput')"
						style="
							color: #ffffff;
							background-color: #30c82d;
							border: 2px solid #3d963c;
							border-radius: 9px;
							padding: 10px 30px;
							cursor: pointer;
							margin: 2px;
						"
					>
						<span translate>leftMenu.newInput</span></a
					>
					<a
						(click)="elementService.createElementsIntoAllSelected('xSpreadsheet')"
						style="
							color: #ffffff;
							background-color: #30c82d;
							border: 2px solid #3d963c;
							border-radius: 9px;
							padding: 10px 30px;
							cursor: pointer;
							margin: 2px;
						"
					>
						<span>xSpreadsheet</span></a
					>
					<a
						(click)="elementService.createElementsIntoAllSelected('leafletJs')"
						style="
							color: #ffffff;
							background-color: #30c82d;
							border: 2px solid #3d963c;
							border-radius: 9px;
							padding: 10px 30px;
							cursor: pointer;
							margin: 2px;
						"
					>
						<span>leafletJs</span></a
					>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>

	<mat-divider style="border-color: rgba(0, 0, 0, 0.12)"></mat-divider>

<!--
███████╗██╗     ███████╗███╗   ███╗███████╗███╗   ██╗████████╗     ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
██╔════╝██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝    ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
█████╗  ██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║       ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║███████╗
██╔══╝  ██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║       ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║╚════██║
███████╗███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║       ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║███████║
╚══════╝╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝        ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

-->
	<div
		style="display: flex; flex-direction: column; align-items: stretch"
		*ngIf="!elementService?.rootObjectSelected()"
	>
<!--
 ██████╗██╗      ██████╗ ███╗   ██╗███████╗
██╔════╝██║     ██╔═══██╗████╗  ██║██╔════╝
██║     ██║     ██║   ██║██╔██╗ ██║█████╗
██║     ██║     ██║   ██║██║╚██╗██║██╔══╝
╚██████╗███████╗╚██████╔╝██║ ╚████║███████╗
 ╚═════╝╚══════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

-->
		<a
			style="margin-top: 10px"
			mat-raised-button
			color="accent"
			(click)="elementService.clone()"
		>
			<span
				style="
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				"
				translate
			>
				<mat-icon style="margin-right: 8px"> control_point_duplicate </mat-icon>
				leftMenu.clone
			</span>
		</a>

<!--
██████╗ ███████╗██╗     ███████╗████████╗███████╗
██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
██║  ██║█████╗  ██║     █████╗     ██║   █████╗
██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
██████╔╝███████╗███████╗███████╗   ██║   ███████╗
╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

-->
		<a
			style="margin-top: 10px"
			mat-raised-button
			color="warn"
			(click)="elementService.removeAllSelectedElements()"
		>
			<span
				style="
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				"
				translate
			>
				<mat-icon style="margin-right: 8px"> delete </mat-icon>
				leftMenu.delete
			</span>
		</a>
	</div>
</div>

<!--
████████╗██████╗ ███████╗███████╗    ██╗   ██╗██╗███████╗██╗    ██╗
╚══██╔══╝██╔══██╗██╔════╝██╔════╝    ██║   ██║██║██╔════╝██║    ██║
   ██║   ██████╔╝█████╗  █████╗      ██║   ██║██║█████╗  ██║ █╗ ██║
   ██║   ██╔══██╗██╔══╝  ██╔══╝      ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
   ██║   ██║  ██║███████╗███████╗     ╚████╔╝ ██║███████╗╚███╔███╔╝
   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝      ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

-->
<div *ngIf="elementService?.rootNode">
	<tree-app-hierarchy></tree-app-hierarchy>
</div>
