<mat-form-field appearance="fill" [style]="'width: '+ width +';'">
  <mat-label translate>rightMenu.color</mat-label>
  <mat-select #colorSelect
    (selectionChange)="setProp('color', colorSelect.value)"
    [value]="getProp('color')"
  >
    <mat-option value="">       <button mat-raised-button color=""       >Basic  </button></mat-option>
    <mat-option value="primary"><button mat-raised-button color="primary">Primary</button></mat-option>
    <mat-option value="accent"> <button mat-raised-button color="accent" >Accent </button></mat-option>
    <mat-option value="warn">   <button mat-raised-button color="warn"   >Warn   </button></mat-option>
  </mat-select>
</mat-form-field>
