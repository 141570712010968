import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core'
import { ApiCallDialog } from '../Dialog API Call/apiDialog'
import { ElementService } from '../../element.service'
import { ChangeDetectorRef } from "@angular/core"
import { DataService } from '../../data.service'
import { apiCall } from '../Dialog API Call/apiCall/apiCall'


@Component({
  selector: 'generalApiDialog',
  templateUrl: 'generalApiDialog.html',
  styleUrls: ['generalApiDialog.scss'],
})
export class generalApiDialog implements OnChanges, AfterViewInit {

  @Input() elementType: string
  @Output() closed: EventEmitter<string> = new EventEmitter<string>()
  showApiCallDialog: boolean = false

  constructor(
    public dataService: DataService,
    public elementService: ElementService,
    private changeDetector: ChangeDetectorRef,
  ){}

  ngOnChanges(changes: SimpleChanges): void {

    this.changeDetector.detectChanges()
  }

  close(){

    this.closed.emit()
  }

  newCall(){

    this.selectedApiCall = null
    this.elementService.setProp({key: "preCall", value:"false", second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false})
    this.showApiCallDialog = true
  }

  /*
    CLOSE ApiCallDialog and HANDLE API CALL RESPONSE
  */
  columnsArrayFromApiCall: any[] = []
  dataArrayFromApiCall: any[] = []
  closedApiCallDialog(apiCall: ApiCallDialog){

    this.showApiCallDialog = false
    // let gotNewData = apiCall.jsonData && (this.selectedApiCall && apiCall.jsonData !== this.selectedApiCall.jsonData || !this.selectedApiCall)

    if(this.selectedApiCall && this.elementService.selectedObjects[0] != this.elementService.rootNode) this.elementService.selectedObjects.forEach(selectedObject => {

      if (selectedObject.element.setColumnAndDataArray)
        selectedObject.element.setColumnAndDataArray(this.columnsArrayFromApiCall, this.dataArrayFromApiCall)
    })

    // if(!gotNewData) return

    // Create new ApiCall object and set all attributes from apiCall
    // let newApiCall: apiCall = {

    //   url: apiCall.url,
    //   method: apiCall.call_type,
    //   headers: [
    //     {
    //       key: "authorization",
    //       value: apiCall.authorization
    //     },
    //     {
    //       key: apiCall.custom_header,
    //       value: apiCall.custom_header_content
    //     }
    //   ],
    //   body: apiCall.body,
    //   responseBody: apiCall.jsonData,
    // }

    this.changeDetector.detectChanges()

    // this.dataService.apiCalls.push(newApiCall)
    let apiCallsString = this.dataService.apiCalls.map(apiCall => JSON.stringify(apiCall)).join('###newApiCall###')

    this.elementService.setProp({key: "apiCalls", value:apiCallsString, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false})

    this.elementService.setProp({ key: 'columns', value: JSON.stringify(this.apiCallDialog.columnData), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.setProp({ key: 'data', value: JSON.stringify(this.apiCallDialog.dataData), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.changeDetector.detectChanges()
  }

  selectedApiCall: any
  @ViewChild('apiCallDialog') apiCallDialog: ApiCallDialog
  ngAfterViewInit() {

    this.changeDetector.detectChanges() // To prevent ExpressionChangedAfterItHasBeenCheckedError
  }
  openExistingApiCallDialog(apiCall: apiCall){

    this.selectedApiCall = apiCall

    if(!this.apiCallDialog) return

    this.apiCallDialog.elementType = 'general'

    // this.apiCallDialog.url = this.selectedApiCall?.url
    // this.apiCallDialog.call_type = this.selectedApiCall?.method
    // this.apiCallDialog.authorization = this.selectedApiCall?.headers[0].value
    // this.apiCallDialog.custom_header = this.selectedApiCall?.headers[1].key
    // this.apiCallDialog.custom_header_content = this.selectedApiCall?.headers[1].value
    // this.apiCallDialog.body = this.selectedApiCall?.body
    // this.apiCallDialog.jsonData = this.selectedApiCall?.jsonData

    this.showApiCallDialog = true
  }

  deleteApiCall(apiCall: apiCall){

    let index = this.dataService.apiCalls.indexOf(apiCall)
    if(index === -1) return

    this.dataService.apiCalls.splice(index, 1)
    let apiCallsString = this.dataService.apiCalls.map(apiCall => JSON.stringify(apiCall)).join('###newApiCall###')
    this.elementService.setProp({key: "apiCalls", value:apiCallsString, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false})
  }
}
