import { DataService } from '../../../data.service';
import { FileGenerator } from '../../code-generation.model';

export class MainGenerator extends FileGenerator {
  public constructor(private readonly dataService: DataService) {
    super();
  }

  public getFilename(): string {
    return 'src/main.ts';
  }

  public generate(): string {
    return this.dataService.treeSearchService.firstElementInProject(this.dataService.projectsService.currentProject!, 'sf-kanban')
      ? this.generateContent()
      : '';
  }

  public generateContent(): string {
    const code =
`import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';

import { AppModule } from './app/app.module';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH1feHRRQ2NfVEVyV0Q=');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
`;

    return code;
  }
}
