import { AfterViewInit, Component, Renderer2 } from '@angular/core'
import { Control, Draw, FeatureGroup, Map, geoJSON, map, tileLayer } from 'leaflet';
import 'leaflet-draw';

import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';
import { LayoutComponent } from 'src/app/layout/layout.component';

@Component({
  selector: 'leafletJs',
  templateUrl: './leafletJs.component.html',
})
export class leafletJs extends LayoutComponent implements AfterViewInit {
  private defaultLayerColor: string = '#3388ff';

  private map: Map;
  private featureGroups: FeatureGroup[] = [];
  private featureGroupColors: string[] = [];

  public constructor(
		public elementService: ElementService,
		public renderer: Renderer2,
		public dataService: DataService,
	) {
    super(elementService, renderer, dataService);
    this.componentType = 'leafletJs';
  }

  public ngAfterViewInit(): void {
    this.element = this;
    this.dataService.propertiesAreLoaded ? this.init() : this.dataService.propertiesLoaded.subscribe(() => this.init());
    this.elementService.onPropSet.subscribe(() => {
      if (this.elementService.selectedObjects.includes(this) && this.map) {
        this.map.invalidateSize();
        this.elementService.changeDetector.detectChanges();
      }
    });
  }

  private init() {
    if (this.getProp('width') === '') { this.setProp({ key:"width", value: "500", second: "px", isTailwind: false, isHelper: false, renderOnlyOuter: true }); }

    if (this.getProp('latitude') === '') { this.setProp({ key:"latitude", value: "51.325788", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('longitude') === '') { this.setProp({ key:"longitude", value: "9.505293", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('zoomLevel') === '') { this.setProp({ key:"zoomLevel", value: "19", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('maxZoomLevel') === '') { this.setProp({ key:"maxZoomLevel", value: "19", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    if (this.getProp('currentLayerIndex') === '') { this.setProp({ key:"currentLayerIndex", value: "0", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    this.latitude = +this.getProp('latitude');
    this.longitude = +this.getProp('longitude');
    this.zoomLevel = +this.getProp('zoomLevel');
    this.maxZoomLevel = +this.getProp('maxZoomLevel');

    this.currentLayerIndex = +this.getProp('currentLayerIndex');

    this.createMap();
  }

  private createMap() {
    // Skip if the map was already created
    if (this.map) { return; }

    // Create a map centered on the Science Park
    this.map = map('map').setView([this.latitude, this.longitude], this.zoomLevel);

    // Add a tile layer to the map from OpenStreetMap
    tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: this.maxZoomLevel, attribution: '© OpenStreetMap' }).addTo(this.map);

    // Read layers and add them to the map
    this.loadLayers();

    // Create options for the draw controls
    const drawOptions: Control.DrawConstructorOptions = {
      draw: {
        rectangle: false
      },
      edit: {
        featureGroup: this.featureGroups[0],
        edit: false,
        remove: false,
      }
    };

		// Add draw controls
		var drawControl = new Control.Draw(drawOptions);
		this.map.addControl(drawControl);

    // Add drawed items to map
    this.map.on(Draw.Event.CREATED, (event) => {
      this.featureGroups[this.currentLayerIndex].addLayer(event.layer);
      this.setProp({ key: 'layers', value: this.toJson(), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false } );
      this.updateCurrentLayersColor();
    });

    // Re-render the map size
    this.map.invalidateSize();
    this.elementService.changeDetector.detectChanges();
  }

  private loadLayers() {
    const savedLayers = this.getProp('layers');
    if (savedLayers) {
      const layers = JSON.parse(savedLayers);
      if (layers.length === 0) {
        this.featureGroups[0] = new FeatureGroup();
        this.map.addLayer(this.featureGroups[0]);
        this.setProp({ key: 'layers', value: this.toJson(), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false } );
        this.featureGroupColors[0] = this.defaultLayerColor;
        this.setProp({ key: 'layerColors', value: JSON.stringify(this.featureGroupColors), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
      } else {
        for (let index = 0; index < layers.length; index++) {
          const layer = layers[index];
          if (!this.featureGroups[index]) {
            this.featureGroups[index] = new FeatureGroup();
            this.map.addLayer(this.featureGroups[index]);
          }
          geoJSON(layer).eachLayer((layer) => this.featureGroups[index].addLayer(layer));
        }
      }
    } else {
      this.featureGroups[0] = new FeatureGroup();
      this.map.addLayer(this.featureGroups[0]);
      this.setProp({ key: 'layers', value: this.toJson(), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false } );
      this.featureGroupColors[0] = this.defaultLayerColor;
      this.setProp({ key: 'layerColors', value: JSON.stringify(this.featureGroupColors), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    }
    const savedLayerColors = this.getProp('layerColors');
    if (savedLayerColors) {
      this.featureGroupColors = JSON.parse(savedLayerColors);
      for (let index = 0; index < this.featureGroupColors.length; index++) {
        this.featureGroups[index].setStyle({ color: this.featureGroupColors[index] });
      }
    }
  }

  public addLayer() {
    this.featureGroups[this.featureGroups.length] = new FeatureGroup();
    this.map.addLayer(this.featureGroups[this.featureGroups.length - 1]);
    this.setProp({ key: 'layers', value: this.toJson(), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false } );

    this.featureGroupColors[this.featureGroups.length - 1] = this.defaultLayerColor;
    this.setProp({ key: 'layerColors', value: JSON.stringify(this.featureGroupColors), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

    this.currentLayerIndex = this.featureGroups.length - 1;
    this.setProp({ key: 'currentLayerIndex', value: ''+this.currentLayerIndex, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
  }

  public removeLayer() {
    if (this.featureGroups.length > 1) {
      this.map.removeLayer(this.featureGroups[this.featureGroups.length - 1]);
      this.featureGroups.pop();
      this.setProp({ key: 'layers', value: this.toJson(), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false } );

      this.featureGroupColors.pop();
      this.setProp({ key: 'layerColors', value: JSON.stringify(this.featureGroupColors), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

      if (this.currentLayerIndex >= this.featureGroups.length) {
        this.currentLayerIndex = this.featureGroups.length - 1;
        this.setProp({ key: 'currentLayerIndex', value: ''+this.currentLayerIndex, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
      }
    }
  }

  public countLayers() {
    return this.featureGroups.length;
  }

  public getCurrentLayersColor() {
    return this.featureGroupColors[this.currentLayerIndex];
  }

  private toJson(): string {
    const layers = [];
    for (const featureGroup of this.featureGroups) {
      layers.push(featureGroup.toGeoJSON());
    }
    return JSON.stringify(layers);
  }

  latitude: number;
  public updateLatitude() {
    this.latitude = +this.getProp('latitude');
    this.map.setView([this.latitude, this.longitude], this.zoomLevel);
  }

  longitude: number;
  public updateLongitude() {
    this.longitude = +this.getProp('longitude');
    this.map.setView([this.latitude, this.longitude], this.zoomLevel);
  }

  zoomLevel: number;
  public updateZoomLevel() {
    this.zoomLevel = +this.getProp('zoomLevel');
    this.map.setView([this.latitude, this.longitude], this.zoomLevel);
  }

  maxZoomLevel: number;
  public updateMaxZoomLevel() {
    this.maxZoomLevel = +this.getProp('maxZoomLevel');
    this.map.setMaxZoom(this.maxZoomLevel);
  }

  private currentLayerIndex: number = 0;
  public updateCurrentLayerIndex() {
    this.currentLayerIndex = +this.getProp('currentLayerIndex');
    this.updateCurrentLayersColor();
  }

  public updateCurrentLayersColor(color?: string) {
    this.featureGroupColors[this.currentLayerIndex] = color ?? this.featureGroupColors[this.currentLayerIndex];
    this.featureGroups[this.currentLayerIndex].setStyle({ color: this.featureGroupColors[this.currentLayerIndex] });
    this.setProp({ key: 'layerColors', value: JSON.stringify(this.featureGroupColors), second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
  }

  // =================
  // CODE
  // =================
  public getCodeTemplate() {
		const importCode =
`import { FeatureGroup, Map, geoJSON, map, tileLayer } from 'leaflet';
import { ChangeDetectorRef } from '@angular/core';`;

		if (!this.elementService.aboveCode.includes(importCode)) {
			this.elementService.aboveCode += importCode;
		}

    const code =
`private defaultLayerColor: string = '#3388ff';

public layers: string = 'getProp('layers')';
public layerColors: string = 'getProp('layerColors')';

private map!: Map;
private featureGroups: FeatureGroup[] = [];
private featureGroupColors: string[] = [];

private width: number = getProp('width');
private height: number = getProp('height');

private latitude: number = getProp('latitude');
private longitude: number = getProp('longitude');
private zoomLevel: number = getProp('zoomLevel');
private maxZoomLevel: number = getProp('maxZoomLevel');

public constructor(private changeDetector: ChangeDetectorRef) {
}

public ngAfterViewInit(): void {
  this.createMap();
}

private createMap() {
  // Skip if the map was already created
  if (this.map) { return; }

  // Create a map centered on the Science Park
  this.map = map('map').setView([this.latitude, this.longitude], this.zoomLevel);

  // Add a tile layer to the map from OpenStreetMap
  tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: this.maxZoomLevel, attribution: '© OpenStreetMap' }).addTo(this.map);

  // Read layers and add them to the map
  this.loadLayers();

  // Re-render the map size
  this.map.invalidateSize();
  this.changeDetector.detectChanges();
}

private loadLayers() {
  if (this.layers) {
    const layers = JSON.parse(this.layers);
    if (layers.length === 0) {
      this.featureGroups[0] = new FeatureGroup();
      this.map.addLayer(this.featureGroups[0]);
      this.featureGroupColors[0] = this.defaultLayerColor;
    } else {
      for (let index = 0; index < layers.length; index++) {
        const layer = layers[index];
        if (!this.featureGroups[index]) {
          this.featureGroups[index] = new FeatureGroup();
          this.map.addLayer(this.featureGroups[index]);
        }
        geoJSON(layer).eachLayer((layer) => this.featureGroups[index].addLayer(layer));
      }
    }
  } else {
    this.featureGroups[0] = new FeatureGroup();
    this.map.addLayer(this.featureGroups[0]);
    this.featureGroupColors[0] = this.defaultLayerColor;
  }
  if (this.layerColors) {
    this.featureGroupColors = JSON.parse(this.layerColors);
    for (let index = 0; index < this.featureGroupColors.length; index++) {
      this.featureGroups[index].setStyle({ color: this.featureGroupColors[index] });
    }
  }
}`;
    return code;
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getHtmlTemplate(): string {
    const template = `<div id="map" class="getProp('displayName')"></div>`;
    return template;
  }
}
