import { Component, ComponentRef, HostBinding, HostListener, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core'

import { CoolArray } from '../CoolArray/CoolArray'
import { Trigger } from '../automations'
import { DataService } from "../data.service"
import { ElementService } from '../element.service'
import { ProjectComponent } from '../project/project.component'
import { IProperty, SaveLayout } from '../projects'
import { RouterOutletPlaceholderComponent } from '../router-outlet-placeholder/router-outlet-placeholder.component'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
  protected id: string;
  public componentType: string;

  public saveLayout: SaveLayout;

  @ViewChild('viewContainerAnker', { read: ViewContainerRef }) viewContainerAnker: ViewContainerRef

  constructor(
    public readonly elementService: ElementService,
    public readonly renderer: Renderer2,
    public readonly dataService: DataService
  ) {
    this.element = this
    this.componentType = 'LayoutComponent'
  }

  public ngOnInit(): void {

    if (this.dataService.propertiesAreLoaded) {

      this.ensureStuff()
    }
		else {

      this.dataService.propertiesLoaded.subscribe(() => {

        this.ensureStuff()
      })
    }
  }

	ensureStuff(){

		this.ensureComponentType()
		this.ensureDisplayName()

		if(this.initialPropsNeedToBeSet && this.element && this.element.setInitialProps) this.element.setInitialProps()

		this.elementService.changeDetector.detectChanges()
	}

  public ensureComponentType(): void {
    this.ensureProp({ key: 'componentType', value: this.componentType, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
  }

  public ensureDisplayName(): void {
    this.ensureProp({ key: "displayName", value: this.name, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
  }

	/*
	███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
	██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
	███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
	╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
	███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
	╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

	*/

  editable: boolean = true;

	@HostListener('click', ['$event']) onClick(event: MouseEvent) {

		if(this.getProp('pageRoute') != "") this.routeToPage()

    if(!this.editable) return

		if(this.children.length == 0) this.childHasHover = false
		if(this.childHasHover || this.elementService.elementInFormulaMode) return

		let target = event.target as HTMLElement
		if(target?.getAttribute("DeSelectOff")!=null && this.hasRedOutline) return

		if(event.ctrlKey) this.elementService.addSelected(this)
		else this.elementService.setSelected(this)
	}
	@HostBinding('class.red-outline') get hasRedOutline() {

		return this.elementService.isSelected(this) && !this.elementService.isLiveMode
	}

  public setEditable(editable: boolean): void {
    this.editable = editable;

    for (const child of this.children) {
      child.setEditable(editable);
    }
  }

	/*
	██╗  ██╗ ██████╗ ██╗   ██╗███████╗██████╗     ███████╗████████╗██╗   ██╗███████╗███████╗
	██║  ██║██╔═══██╗██║   ██║██╔════╝██╔══██╗    ██╔════╝╚══██╔══╝██║   ██║██╔════╝██╔════╝
	███████║██║   ██║██║   ██║█████╗  ██████╔╝    ███████╗   ██║   ██║   ██║█████╗  █████╗
	██╔══██║██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██╗    ╚════██║   ██║   ██║   ██║██╔══╝  ██╔══╝
	██║  ██║╚██████╔╝ ╚████╔╝ ███████╗██║  ██║    ███████║   ██║   ╚██████╔╝██║     ██║
	╚═╝  ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝╚═╝  ╚═╝    ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝

	*/
	@HostListener('mouseover') onMouseOver() { this.setHover(true) }
	@HostListener('mouseout') onMouseOut() { this.setHover(false) }
	@HostListener('mouseleave') onMouseLeave() { this.setHover(false) }
	@HostBinding('class.blue-outline') get hasBlueOutline() {

		return this.elementService.hoveredElement == this && !this.elementService.isLiveMode
	}

	setHover(isHovered: boolean) {

		if(this.children.length == 0) this.childHasHover = false

		if(isHovered && !this.childHasHover) this.elementService.hoveredElement = this
		if(!isHovered && this.elementService.hoveredElement == this) this.elementService.hoveredElement = new LayoutComponent(this.elementService, this.renderer, this.dataService)

		if (!this.isRootNode && this.parent) this.parent.childHover(isHovered);
	}

	childHover(isHovered: boolean) {

		this.childHasHover = isHovered
	}

	/*
██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝

	*/

  /** Says if this is an element or a layout wich can have children */
  isLayout = true

  // ###########################
  //  IS A LAYOUT
  // ###########################
  @HostBinding('class.layout') get has_Hover() {
    if (this.getProp('background-hover-color') !== '' && this.elementService.hoveredElement == this){
      let BGcolor = this.hexToRgba(this.getProp('background-hover-color'))
      document.documentElement.style.setProperty('--background-color',BGcolor);
    }
    return this.elementService.hoveredElement == this && this.getProp('background-hover-color') !== ''
  }
  @HostBinding('class.inner') get is_a_Layout() { return this.isLayout }
  @HostBinding('class.helpLine') get hasHelpLine() { return (this.isLayout && !this.elementService.isLiveMode) }
  @HostBinding('class') get get_classes_without() {

    this.adjustInnerDimensionsIfNotLayout()
    return this.isLayout?this.getClassesWithout():""
  }
	// END - IS A LAYOUT

  @HostBinding('style.order') get getIndex() {

    let number = this.parent ? this.parent.children.findIndex((item: LayoutComponent) => item === this) : -42
    return number
  }

  element: any
	initialPropsNeedToBeSet = false
  componentRef: ComponentRef<LayoutComponent>
  parent: LayoutComponent
  children: CoolArray<LayoutComponent> = []
  childHasHover = false
  name = "Layout"
  nativeElement: HTMLElement
  isRootNode = false

  adjustInnerDimensionsIfNotLayout() {

    if(this.isLayout) return

    let child = this.nativeElement.children[0] as HTMLElement

    if(!child) return

    this.renderer.setStyle(child, 'height', "100%")
    this.renderer.setStyle(child, 'width', "-webkit-fill-available")

    let parentHeight = this.nativeElement.offsetHeight
    let childHeight = child.offsetHeight

    if(childHeight > parentHeight){

      let diff = childHeight - parentHeight
      this.renderer.setStyle(child, 'height', (this.nativeElement.offsetHeight-diff)+"px")
    }
  }

  hexToRgba(hex: string): string | null {
    const queryResult = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const transparency = this.getProp('background-transparency')

    if (!queryResult) { return null; }

    const red = parseInt(queryResult[1], 16)
    const green = parseInt(queryResult[2], 16)
    const blue = parseInt(queryResult[3], 16)
    const alpha = transparency !== '' ? Number(transparency) / 100 : 1

    const rgba = `rgba(${red},${green},${blue},${alpha})`
    return rgba
  }

	public routeToPage() {

		const pageRoute = this.getProp('pageRoute')

    if (this.elementService.isLiveMode && pageRoute) {
      const embeddingComponent = this.getCurrentRouterOutletPlaceholder();

      if (embeddingComponent) {
        embeddingComponent.route(+pageRoute);
      } else {
        this.dataService.loadPageIndexIntoTheApp(+pageRoute);
      }
    }
	}

  public getCurrentRouterOutletPlaceholder(): RouterOutletPlaceholderComponent | undefined {
    let routerOutletPlaceholderSaveLayout = this.elementService.treeSearchService.findFirstRouterOutletPlaceholder(this.dataService.currentPage.page);
    if (!routerOutletPlaceholderSaveLayout) {
      for (const entry of this.dataService.projectContext.projectStateTable.entries()) {
        const projectState = entry[1];
        for (const page of projectState.pages) {
          const result = this.elementService.treeSearchService.findFirstRouterOutletPlaceholder(page);
          if (result) {
            routerOutletPlaceholderSaveLayout = result;
          }
        }
      }
    }
    const id = routerOutletPlaceholderSaveLayout ? this.dataService.treeSearchService.getId(routerOutletPlaceholderSaveLayout) : '';
    return id ? this.dataService.projectContext.componentTable.get(id) as RouterOutletPlaceholderComponent : undefined;
  }

/*
██████╗ ██████╗  ██████╗ ██████╗ ███████╗██████╗ ████████╗██╗███████╗███████╗
██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔════╝██╔════╝
██████╔╝██████╔╝██║   ██║██████╔╝█████╗  ██████╔╝   ██║   ██║█████╗  ███████╗
██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗   ██║   ██║██╔══╝  ╚════██║
██║     ██║  ██║╚██████╔╝██║     ███████╗██║  ██║   ██║   ██║███████╗███████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

*/

  /*
   * ███████╗███████╗████████╗    ██████╗ ██████╗  ██████╗ ██████╗
   * ██╔════╝██╔════╝╚══██╔══╝    ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
   * ███████╗█████╗     ██║       ██████╔╝██████╔╝██║   ██║██████╔╝
   * ╚════██║██╔══╝     ██║       ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝
   * ███████║███████╗   ██║       ██║     ██║  ██║╚██████╔╝██║
   * ╚══════╝╚══════╝   ╚═╝       ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝
   */

  public properties: IProperty[] = [];

  /** Sets css properties and displayName */
  public setProp(dangerousPropObj: IProperty) {

		// Clone the prop
		let newPropObj = (JSON.parse(JSON.stringify(dangerousPropObj)) as IProperty)

		// Always save props with my own saveLayout id
		newPropObj.saveLayout = this.saveLayout

    if (!this.hasProp(newPropObj.key)) {

      this.properties.push(newPropObj)
    }
		else {

      this.properties[this.properties.findIndex(prop => prop.key === newPropObj.key)] = newPropObj
    }

    if (newPropObj.key === 'id') this.id = this.getProp('id')

    if (newPropObj.key === 'displayName') this.handleDisplayname(newPropObj)

    // NativeElement should be there to be able to style
    if (!this.nativeElement) return

    // See if this is a normal style property
    if (!newPropObj.isTailwind && !newPropObj.isHelper) {

      // If this is a layout, or if this is a renderOnlyOuter style,
      // then only the outer tag get's the style
      newPropObj.renderOnlyOuter = newPropObj.renderOnlyOuter || this.isLayout

      // Style the outer or the inner tag
      this.renderer.setStyle(newPropObj.renderOnlyOuter ?
                                  this.nativeElement: // The outer tag
                                  this.nativeElement.children[0] ? this.nativeElement.children[0] : this.nativeElement, // The inner tag if present, else the outer tag
                             newPropObj.key, newPropObj.value + (newPropObj.second ? newPropObj.second : ''))
    }

    this.adjustInnerDimensionsIfNotLayout()

    // When a child tag is absolute, the parent must be relative
    // Unsauber, kann bestende Parent Position überschreiben
    if(newPropObj.key === 'position' && newPropObj.value === 'absolute') this.parent?.setProp({
      key: 'position',
      value: 'relative',
      second: '',
      renderOnlyOuter: false,
      isTailwind: false,
      isHelper: false
    })
    if(newPropObj.key === 'position' && newPropObj.value === 'static') this.parent?.removeProp('position')

		// Persist the changes ⚠️ Warning: make sure, that the id of the savelayout is correct
		this.dataService.setProp(this.saveLayout, newPropObj)

		// Tell everybody!
    this.elementService.onPropSet.emit(newPropObj)
  }

  setPropAsStyle(key: string, value: string, second: string = '') {
    this.setProp({ key: key, value: value, second: second, isTailwind: false, isHelper: false, renderOnlyOuter: false });
  }

  setPropAsHelper(key: string, value: string) {
    this.setProp({ key: key, value: value, second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
  }

  ensureProp(prop: IProperty) {
    if (!this.getProp(prop.key)) {
      this.setProp(prop);
    }
  }

  handleDisplayname(newPropObj: IProperty){

    // See if this displayName already exists
    // If yes, add a higher number to the end
    let foundElements = this.elementService.findElementsByName(newPropObj.value)
    let i = foundElements.length
    while(foundElements.length > 1){

      newPropObj.value = newPropObj.value + i
      foundElements = this.elementService.findElementsByName(newPropObj.value)
      i++
    }

    // Write it into the displayName menu input field
    const element = document.getElementById("nameInput") as HTMLElement
    const inputElement = element.querySelector('input') as HTMLInputElement
    inputElement.value = newPropObj.value

    // Set the id of the element
    this.nativeElement.id = newPropObj.value
  }

	/*
 ██████╗ ███████╗████████╗        ██╗    ██╗  ██╗ █████╗ ███████╗    ██████╗ ██████╗  ██████╗ ██████╗
██╔════╝ ██╔════╝╚══██╔══╝       ██╔╝    ██║  ██║██╔══██╗██╔════╝    ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
██║  ███╗█████╗     ██║         ██╔╝     ███████║███████║███████╗    ██████╔╝██████╔╝██║   ██║██████╔╝
██║   ██║██╔══╝     ██║        ██╔╝      ██╔══██║██╔══██║╚════██║    ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝
╚██████╔╝███████╗   ██║       ██╔╝       ██║  ██║██║  ██║███████║    ██║     ██║  ██║╚██████╔╝██║
 ╚═════╝ ╚══════╝   ╚═╝       ╚═╝        ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝

	*/

  /** @returns Prop Value NULLSAFE */
  public getProp(key: string): string {
    const value = this.getPropObj(key)?.value;
    return value ? value : '';
  }

  public getPropObj(key: string) {
    return this.properties.find(prop => prop.key === key)
  }

  public hasProp(key: string): boolean {
    return this.properties.find(prop => prop.key === key) !== undefined
  }

	/*
██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗    ██████╗ ██████╗  ██████╗ ██████╗
██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝    ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗      ██████╔╝██████╔╝██║   ██║██████╔╝
██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝      ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝
██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗    ██║     ██║  ██║╚██████╔╝██║
╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝    ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝

	*/
  removeProp(key: string) {

    let prop = this.getPropObj(key)
    if(!prop) return

    this.properties.splice(this.properties.findIndex(prop => prop.key == key), 1)

    if(!prop.isTailwind && !prop.isHelper){

      prop.renderOnlyOuter = prop.renderOnlyOuter || this.isLayout
      let myElement = prop.renderOnlyOuter ? this.nativeElement : this.nativeElement.children[0]
      if(myElement) this.renderer.removeStyle(myElement, key)
    }

    this.adjustInnerDimensionsIfNotLayout()

		// Persist the changes
		this.dataService.deleteProp(this.saveLayout, prop)
  }

  /*
   * ██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗    ████████╗██╗  ██╗██╗███████╗
   * ██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝    ╚══██╔══╝██║  ██║██║██╔════╝
   * ██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗         ██║   ███████║██║███████╗
   * ██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝         ██║   ██╔══██║██║╚════██║
   * ██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗       ██║   ██║  ██║██║███████║
   * ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝       ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝
   */

  protected beforeRemoveMe(): void {
  }

  public removeMe() {
    this.beforeRemoveMe();

    if (this.parent) {
      const children = this.parent.children;
      children.splice(children.findIndex((item: LayoutComponent) => item === this), 1);
			const dummyObject = new LayoutComponent(this.elementService, this.renderer, this.dataService);
      this.parent = dummyObject;
    }

    if (this.children.length > 0) {
      this.children.forEach(child => {
        child.removeMe();
      });
    }

    this.isLayout = true;
    this.element = undefined;
    this.nativeElement.remove();
    this.name = "Dead";
    this.children = [];
    // this.componentRef.destroy() // Das killt auch Elemente, die schon unter einem anderen Parent hängen
  }

  public deleteMe() {
    this.removeMe();

    // Persist the changes
		this.dataService.deleteSaveLayout(this.saveLayout);
  }

  /*
   * ████████╗██████╗ ██╗ ██████╗  ██████╗ ███████╗██████╗ ███████╗
   * ╚══██╔══╝██╔══██╗██║██╔════╝ ██╔════╝ ██╔════╝██╔══██╗██╔════╝
   *    ██║   ██████╔╝██║██║  ███╗██║  ███╗█████╗  ██████╔╝███████╗
   *    ██║   ██╔══██╗██║██║   ██║██║   ██║██╔══╝  ██╔══██╗╚════██║
   *    ██║   ██║  ██║██║╚██████╔╝╚██████╔╝███████╗██║  ██║███████║
   *    ╚═╝   ╚═╝  ╚═╝╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝
   */

  public triggers: string[] = [];

  public addTrigger(trigger: Trigger): void {
    if (!this.triggers.includes(trigger.id)) {
      this.triggers.push(trigger.id);
    }
  }

  public removeTrigger(trigger: Trigger): void {
    const index = this.triggers.indexOf(trigger.id);
    if (index > -1) {
      this.triggers.splice(index, 1);
    }
  }

  public trigger(): void {
    if (!this.elementService.isLiveMode) { return; }
    if (!this.triggers) { return; }
    for (const triggerId of this.triggers) {
      const trigger = this.findTrigger(triggerId);
      if (trigger) {
        trigger.triggerAction();
      }
    }
  }

  public findTrigger(triggerId: string): Trigger | undefined {
    for (const [layout, automations] of this.dataService.automationsService.automations) {
      const match = automations.find(automation => automation.trigger?.id === triggerId)?.trigger;
      if (match) {
        return match;
      }
    }
    return undefined;
  }

  /*
   *  ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   * ██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   * ██║     ██║   ██║██║  ██║█████╗      ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
   * ██║     ██║   ██║██║  ██║██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   * ╚██████╗╚██████╔╝██████╔╝███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   *  ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
   */

  public static generateSelectorName(name: string): string {
    return name.toLowerCase().replace(' ', '-');
  }

  public static generateComponentName(name: string): string {
    return (name.charAt(0).toUpperCase() + name.slice(1)).replace(' ', '');
  }

  public static isComponent(dataService: DataService, id: string): boolean {
    for (const [projectComponentId, projectState] of dataService.generationContext?.projectStateTable ?? []) {
      const pageId = dataService.treeSearchService.getId(projectState.pages[0]);
      if (id === pageId) {
        return true;
      }
    }
    return false;
  }

  public static getProjectComponent(dataService: DataService, id: string): string | undefined {
    for (const [projectComponentId, projectState] of dataService.generationContext?.projectStateTable ?? []) {
      const pageId = dataService.treeSearchService.getId(projectState.pages[0]);
      if (id === pageId) {
        return projectComponentId;
      }
    }
    return undefined;
  }

  public getTS() {

    let componentName = this.elementService.rootNode.getProp('displayName')
    componentName = LayoutComponent.generateSelectorName(componentName);

    let componentClassName = this.elementService.rootNode.getProp('displayName')
    componentClassName = LayoutComponent.generateComponentName(componentClassName);

    if (componentName === 'app' && componentClassName === 'App' && LayoutComponent.isComponent(this.dataService, this.id)) {
      const projectComponentId = LayoutComponent.getProjectComponent(this.dataService, this.id)!;
      const projectComponent = this.dataService.generationContext!.componentTable.get(projectComponentId)! as ProjectComponent;
      const projectId = projectComponent.selectedProject!;

      componentName = this.dataService.projectsService.getSelectorName(projectId);
      componentClassName = this.dataService.projectsService.getComponentName(projectId);
    }

    if (this.dataService.automationsForCodeGeneration.size > 0) {
      this.elementService.aboveCode += `\nimport { AutomationsService } from 'src/app/automations.service';\n`
      this.elementService.aboveCode += `import { ExcelService } from 'src/app/excel.service';\n`;
    }

    let collectedCodes = this.getAllTypeScriptCodes()

    let code =
`import { Component, Input, OnInit } from '@angular/core';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';
${this.elementService.aboveCode}
@Component({
  selector: 'app-${componentName !== 'app' ? componentName : 'root'}',
  templateUrl: './${componentName}.component.html',
  styleUrls: ['./${componentName}.component.scss']
})
export class ${componentClassName}Component implements OnInit {
  @Input()
  public prefix: string = '';

  public constructor(public readonly projectDataCacheService: ProjectDataCacheService, ${this.dataService.automationsForCodeGeneration.size > 0 ? 'private readonly excelService: ExcelService, private readonly automationsService: AutomationsService' : ''}) {
    ${this.dataService.automationsForCodeGeneration.size > 0 ? 'this.automationsService.reRegisterTriggers(this);' : ''}
  }

  public ngOnInit(): void {${this.elementService.ngOnInitCode}
  }${collectedCodes}
}
${this.elementService.belowCode}`

    this.elementService.aboveCode = ''
    this.elementService.ngOnInitCode = ''
    this.elementService.belowCode = '';

    return code
  }

  getAllTypeScriptCodes(){

    let codes = this.getTypeScript()

    this.children.forEach((child: LayoutComponent, i) => {

      codes = codes.concat(child.getAllTypeScriptCodes())
    })

    return codes
  }

  getTypeScript(): string{

    if(!this.element) return ""

    return this.element.getCodeTemplate ?
				this.dataService.codeGenerationService.generateTypeScript(this.element.getCodeTemplate(), this.element) :
				this.element.getAtomicCode ? this.element.getAtomicCode() : ""
  }

  // ###################################
  //  END CODE GENERATION
  // ###################################

/*
███████╗████████╗██╗   ██╗██╗     ███████╗     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
███████╗   ██║    ╚████╔╝ ██║     █████╗      ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
███████║   ██║      ██║   ███████╗███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

*/
  public getCSS(){

		this.elementService.globalStylesToApply = []
		let allStyles = this.getAllStyles()

		this.elementService.globalStylesToApply.forEach(globalStyle => {

			let globalStylePropsInRoot = this.elementService.rootNode.getProp(globalStyle)
			let globalStylesArray: IProperty[] = JSON.parse(globalStylePropsInRoot)

			allStyles +=
`
.${globalStyle} {

	${this.getStyleAttributesForGlobal(globalStylesArray)}
}${this.getHoverClasses(globalStyle, globalStylesArray)}
`
		})

    return allStyles // 'background-hover-color'
  }

	getHoverClasses(globalStyle: string, globalStylesArray: IProperty[]){

		let hoverClasses = ``
		globalStylesArray.forEach((property) => {

			if(property.key == 'background-hover-color'){

				hoverClasses += `

.${globalStyle}:hover {

	background-color: ${this.hexToRgba(property.value)}
}`
			}
		})

		return hoverClasses
	}

  getStyleAttributesForGlobal(globalStylesArray: IProperty[]){

    let myAttributes = ``

		if (this.isLayout)
			myAttributes = myAttributes.concat(`display: flex;`)

    globalStylesArray.forEach((property) => {

      if(property.isTailwind || (property.isHelper)) return
      if(property.value == "") return

      let style = `${property.key}: ${property.value}${property.second!=''?property.second:''}`

      myAttributes = myAttributes.concat(`
	${style};`)
    })

    return myAttributes
  }

  getAllStyles(styles?: string){

    let allStyles = this.getStyle(styles)

    this.children.forEach((child: LayoutComponent, i) => {

      allStyles = allStyles.concat(child.getAllStyles(allStyles))
    })
    return allStyles
  }

  getStyle(styles?: string): string{

		if (this.isLayout) {

			let outerStyles = this.getStyleAttributesForLayout()

			if(!outerStyles) return ""

      let style =
`.${this.getProp("displayName")} {

	display: flex;${outerStyles}
}

`

      if (this.parent === undefined && this.dataService.projectsService.currentProject?.pages.find(page => page.id === this.saveLayout.id)) {
        style =
`:host, .${this.getProp("displayName")} {

	display: flex;
	width: -webkit-fill-available;${outerStyles}
}

`
      }

      if (this.getProp('background-hover-color')) {
        style +=
`.${this.getProp("displayName")}:hover {

  background-color: ${this.hexToRgba(this.getProp('background-hover-color'))}
}

`
      }

      return style
    }
    // If this is no layout, see if it offers some styles
    else {

			return this.element.getCssTemplate ?
									this.dataService.codeGenerationService.generateCss(this.element.getCssTemplate()) :
									this.element.getAtomicStyle ? this.element.getAtomicStyle() : this.getStandardStyle()
		}
  }

	getStandardStyle(): string{

		let hasNormalStyles = this.properties.find(prop => !prop.isHelper && prop.value != "")

		let style = ''

		if(!hasNormalStyles || this.getStyleAttributesForInner() == "") return style

		let innerStyles = this.getStyleAttributesForInner().split(';').join(`;\n\t`)
		style = style +
`
.${this.getProp('displayName')} {

	${innerStyles.substring(0, innerStyles.length-2)}
}
`
		return style
	}

  getStyleAttributesForLayout(){

		if (this.getStyleAttributesForInner() == '') return ''

		let result = "\n\t"+this.getStyleAttributesForInner().split(';').join(';\n\t')

		// Remove the last line break and tab
		return result.substring(0, result.length-2)
  }

	/**
	 * ### Get the style attributes
	 * Converts the style properties into a CSS string
	 * TODO: merge with getStyleAttributesForLayout() and make this prettier
	 * @returns a long string with all the style attributes, separated by a semicolon
	 */
  getStyleAttributesForInner(){

		let myGlobalStyle = this.getProp("globalStyle")
		let globalStylePropsInRoot = this.elementService.rootNode.getProp(myGlobalStyle)

    let myAttributes = ``
    this.properties.forEach((property) => {

      if(property.value == "" || property.isTailwind || property.isHelper) return

			// See if this is a global style
			if (myGlobalStyle && globalStylePropsInRoot) { // endInput

				let foundGlobalStyle = this.elementService.globalStylesToApply.find(globalStyle => globalStyle === myGlobalStyle)
				if(!foundGlobalStyle) this.elementService.globalStylesToApply.push(myGlobalStyle || "")

				let globalStylesArray: IProperty[] = JSON.parse(globalStylePropsInRoot)
				let globalStyle = globalStylesArray.find(globalStyle => globalStyle.key === property.key)
				if(globalStyle) return
			}

      let style = `${property.key}: ${property.value}${property.second!=''?property.second:''}`

      myAttributes = myAttributes.concat(`${style};`)
    })

    return myAttributes
  }

  /** @returns The Tailwind classes NULLSAFE */
  getClassesWithout(notProperty?: string): string{

    let classes = ""
    this.properties.forEach((prop) => {

      if(notProperty && prop.key == notProperty) return
      if(prop.isHelper) return
      if(!prop.isTailwind) return

      let secondString = prop.second == "" ? prop.second : "-" + prop.second
      classes = classes.concat(prop.value + secondString, " ")
    })

    return classes.trim()
  }

  // ###################################
  //  END STYLES GENERATION
  // ###################################

/*
██╗  ██╗████████╗███╗   ███╗██╗          ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
██║  ██║╚══██╔══╝████╗ ████║██║         ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
███████║   ██║   ██╔████╔██║██║         ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
██╔══██║   ██║   ██║╚██╔╝██║██║         ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
██║  ██║   ██║   ██║ ╚═╝ ██║███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

*/
/**
 * Recursively generates the HTML code for the layout
 * @param tabs
 * @returns Recursive HTML code
 */
  getTag(tabs: string) {

    let newTabs = tabs.concat(`\t`)
    let thisTag = ""

    if (this.isLayout) {

      let attrMap: Map<string, string> = new Map<string, string>()

      if(this.getProp("ngIf") != "")							attrMap.set("*ngIf", this.getProp("ngIf"))

      if(this.getStyleAttributesForLayout())			attrMap.set("class", this.getProp("displayName"))
			if(this.getProp("globalStyle"))							attrMap.set("class", this.getProp("globalStyle"))
			if(this.getProp("pageRoute"))               attrMap.set("routerLink", this.dataService.codeGenerationService.generateRoute(+this.getProp('pageRoute')).replaceAll('\"', ''))

      thisTag = this.buildNormalTag(tabs, "div", attrMap, this.getChildren(newTabs), "")
    }

    else {

			thisTag = (this as any).getHtmlTemplate ?
												this.dataService.codeGenerationService.generateHtml((this as any).getHtmlTemplate(tabs), this) :
												(this as any).getTemplateString(tabs)
			//

			if(!this.getProp("globalStyle")) return thisTag
			const classNameToAdd = this.getProp("globalStyle")

			// Globalstyle hinzufügen

			const camelCaseWords = this.dataService.codeGenerationService.findAllCamelCase(thisTag)

			// HTML-String in ein DOM-Element umwandeln
			const tempDiv = document.createElement('div')
			tempDiv.innerHTML = thisTag

			// Das äußerste Element extrahieren
			const outerElement = tempDiv.firstChild

			if (outerElement) {

				// Überprüfen, ob das Element bereits eine Klasse hat
				if (outerElement instanceof Element && outerElement.classList) {

					outerElement.classList.add(classNameToAdd)
				}
				else if (outerElement instanceof Element) {

					const existingClasses = outerElement.getAttribute('class')
					const newClassList = existingClasses ? `${existingClasses} ${classNameToAdd}` : classNameToAdd
					this.renderer.setAttribute(outerElement, 'class', newClassList)
				}

				// Das manipulierte HTML zurück in einen String umwandeln
				thisTag = tempDiv.innerHTML.replace(/=\"\"/g, "")

				// Replace all lowercase words in the variable camelCaseWords and replace them with the correct camelCase
				camelCaseWords.forEach(camelCaseWord => {

					thisTag = thisTag.replaceAll(camelCaseWord.toLowerCase(), camelCaseWord)
				})
			}
		}

    return thisTag
  }

  getChildren(tabs: string): any[]{

    let children: any[] = []
    this.children.forEach((child: LayoutComponent, i) => {

      // let newLine = i==0?"":"\n"
      children.push(child.getTag(tabs))
    })

    return children
  }

  /** TABS, TAGNAME, ATTRMAP, CONTENT */
  buildLeafTag(tabs: string, tagName: string, attrMap?: Map<string, string>, content?: string): string {

    return this.buildTag(tabs, tagName, true, attrMap, undefined, content)
  }

  /** TABS, TAGNAME, ATTRIMAP, CHILDREN, CONTENT */
  buildLeafTagOneLine(tabs: string, tagName: string, attrMap?: Map<string, string>, content?: string): string {

    return this.buildTag(tabs, tagName, true, attrMap, undefined, content, undefined, undefined, true)
  }

  /** TABS, TAGNAME, ATTRMAP */
  buildLeafTagNoClosing(tabs: string, tagName: string, attrMap?: Map<string, string>): string {

    return this.buildTag(tabs, tagName, false, attrMap, undefined, undefined)
  }

  /** TABS, TAGNAME, ATTRIMAP, CHILDREN, CONTENT */
  buildNormalTag(tabs: string, tagName: string, attrMap?: Map<string, string>, children?: any[], content?: string): string {

    return this.buildTag(tabs, tagName, true, attrMap, children, content)
  }

  /** TABS, TAGNAME, ATTRIMAP, CHILDREN, CONTENT, HASHTAG */
  buildNormalTagWithHashTag(tabs: string, tagName: string, attrMap?: Map<string, string>, children?: any[], content?: string, hashtag?: string): string {

    return this.buildTag(tabs, tagName, true, attrMap, children, content, undefined, hashtag)
  }

  /** TABS, TAGNAME, ATTRIMAP, CHILDRENLEFT, CONTENT, CHILDRENRIGHT */
  buildTagRightChildren(tabs: string, tagName: string, attrMap?: Map<string, string>, childrenLeft?: any[], content?: string, childrenRight?: any[]): string {

    return this.buildTag(tabs, tagName, true, attrMap, childrenLeft, content, childrenRight)
  }

  /** TABS, TAGNAME, HASCLOSETAG, ATTRMAP, CHILDREN, CONTENT, CHILDRENRIGHT, HASHTAG */
  buildTag(tabs: string, tagName: string, hasCloseTag: boolean, attrMap?: Map<string, string>, childrenLeft?: any[], content?: string, childrenRight?: any[], hashtag?: string, oneLine?: boolean): string {

    let attrString = ''
    if(attrMap) attrMap.forEach((value, key) => { attrString = attrString.concat(" "+ key + (value=="" ? "": value.includes('"')?"='"+value+"'":"=\""+value+"\"")) })

    let childrenLeftString = childrenLeft&&childrenLeft.length!=0?"\n"+childrenLeft.join("\n"):""

    let contentString = content?(oneLine?'':"\n\t"+tabs)+content:''

    let childrenRightString = childrenRight&&childrenRight.length!=0?"\n"+childrenRight.join("\n"):""

    let hashtagString = hashtag?" #"+hashtag:""
    let closeTag = hasCloseTag?(oneLine?'':"\n"+tabs)+"</"+tagName+">":""

    return `${tabs}<${tagName}${attrString}${hashtagString}>${childrenLeftString}${contentString}${childrenRightString}${closeTag}`
  }
}
