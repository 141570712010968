<div>
  <mat-slider
    style="margin: 0; padding: 0; width: 100%;"
    [color]="getProp('color')"
    [disableRipple]="getProp('disableRipple') == '1'"
    [disabled]="getProp('disabled') == '1'"
    [discrete]="getProp('discrete') == '1'"
    [max]="getProp('max')"
    [min]="getProp('min')"
    [showTickMarks]="getProp('showTickMarks') == '1'"
    [step]="getProp('step')"
  >
    <input matSliderThumb #thisSliderThumb
      [value]="getProp('value')"
      (valueChange)="setPropShort('value', ''+thisSliderThumb.value)"
    >
  </mat-slider>
</div>
