<div class="overlay"></div>

<div class="dialog">
  <mat-card>

    <div class="generalApiDialog">
      <div class="Layout">
        <h1 id="text" class="text-black">Existierende API Calls:</h1>
        <a (click)="close()" class="schliessen" mat-raised-button color="primary">
          <span class="mat-button-span">
            Schliessen
          </span>
        </a>
      </div>
      <div *ngFor="let apiCall of dataService.apiCalls" (click)="openExistingApiCallDialog(apiCall)" style="display: flex; align-items: center;">
        <div class="blue_round_area">
          <a mat-icon-button color="primary">
            <span class="mat-button-span">
              <mat-icon>
                swap_horiz
              </mat-icon>
            </span>
          </a>
          <!-- <a id="text" class="text-black">{{apiCall.method}} to {{apiCall.url}}</a> -->
        </div>
        <div (click)="deleteApiCall(apiCall)" style="cursor: pointer;">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="red" d="M15.854 12.854L11 8l4.854-4.854a.503.503 0 0 0 0-.707L13.561.146a.499.499 0 0 0-.707 0L8 5L3.146.146a.5.5 0 0 0-.707 0L.146 2.439a.499.499 0 0 0 0 .707L5 8L.146 12.854a.5.5 0 0 0 0 .707l2.293 2.293a.499.499 0 0 0 .707 0L8 11l4.854 4.854a.5.5 0 0 0 .707 0l2.293-2.293a.499.499 0 0 0 0-.707z"/></svg>
        </div>
      </div>
      <a *ngIf="!dataService.apiCalls || dataService.apiCalls.length == 0">Keine API Calls vorhanden</a>
      <a (click)="newCall()" class="newCall" mat-raised-button color="primary">
        <span class="mat-button-span">
          neuer Call
        </span>
      </a>
    </div>

  </mat-card>
</div>

<!-- API Call: the response is stored in this.jsonData -->
<apiCallDialog #apiCallDialog [ngStyle]="{'display': showApiCallDialog ? 'inline': 'none'}" [elementType]="elementType"
                (returnColumns)="columnsArrayFromApiCall = $event"
                (returnData)="dataArrayFromApiCall = $event"
                (closed)="closedApiCallDialog($event)"></apiCallDialog>

<!-- <apiCallDialog *ngIf="showApiCallDialog"
                [elementType]="'general'"
                (returnColumns)="columnsArrayFromApiCall = $event"
                (returnData)="dataArrayFromApiCall = $event"
                (closed)="closedApiCallDialog($event)"></apiCallDialog> -->
