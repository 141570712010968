import { SaveLayout } from './save-layout.entity';

export interface IProperty {
  saveLayout?: SaveLayout;
  key: string;
  value: string;
  second: string;
  renderOnlyOuter: boolean;
  isTailwind: boolean;
  isHelper: boolean;
}

export class Property implements IProperty {
  public id: string;
  public owner: string;
  public saveLayout: SaveLayout;
  public key: string;
  public value: string;
  public second: string;
  public renderOnlyOuter: boolean;
  public isTailwind: boolean;
  public isHelper: boolean;
}
