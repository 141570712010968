<div style="display: flex; flex-direction: column; padding: 10px;
            border: dotted 1px black; margin-top: 10px;">
	<div class="gap-3" style="display: flex; flex-direction: row; width: 100%;">

    <!--
██╗     ███████╗███████╗████████╗    ███████╗██╗██████╗ ███████╗
██║     ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██║██╔══██╗██╔════╝
██║     █████╗  █████╗     ██║       ███████╗██║██║  ██║█████╗
██║     ██╔══╝  ██╔══╝     ██║       ╚════██║██║██║  ██║██╔══╝
███████╗███████╗██║        ██║       ███████║██║██████╔╝███████╗
╚══════╝╚══════╝╚═╝        ╚═╝       ╚══════╝╚═╝╚═════╝ ╚══════╝

     -->
		<div style="display: flex; flex-direction: column; width: 50%; align-items: flex-start;">

      <!--
██╗  ██╗████████╗████████╗██████╗     ██████╗ ███████╗ ██████╗ ██╗   ██╗███████╗███████╗████████╗
██║  ██║╚══██╔══╝╚══██╔══╝██╔══██╗    ██╔══██╗██╔════╝██╔═══██╗██║   ██║██╔════╝██╔════╝╚══██╔══╝
███████║   ██║      ██║   ██████╔╝    ██████╔╝█████╗  ██║   ██║██║   ██║█████╗  ███████╗   ██║
██╔══██║   ██║      ██║   ██╔═══╝     ██╔══██╗██╔══╝  ██║▄▄ ██║██║   ██║██╔══╝  ╚════██║   ██║
██║  ██║   ██║      ██║   ██║         ██║  ██║███████╗╚██████╔╝╚██████╔╝███████╗███████║   ██║
╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝         ╚═╝  ╚═╝╚══════╝ ╚══▀▀═╝  ╚═════╝ ╚══════╝╚══════╝   ╚═╝

██████╗  █████╗ ████████╗ █████╗     ███████╗███╗   ██╗████████╗██████╗ ██╗   ██╗
██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗    ██╔════╝████╗  ██║╚══██╔══╝██╔══██╗╚██╗ ██╔╝
██║  ██║███████║   ██║   ███████║    █████╗  ██╔██╗ ██║   ██║   ██████╔╝ ╚████╔╝
██║  ██║██╔══██║   ██║   ██╔══██║    ██╔══╝  ██║╚██╗██║   ██║   ██╔══██╗  ╚██╔╝
██████╔╝██║  ██║   ██║   ██║  ██║    ███████╗██║ ╚████║   ██║   ██║  ██║   ██║
╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝

      -->
      <div class="apiCallHeading">

				<h1 class="text-black">{{"Api Call " + myNum}}</h1>

				<div	[class]="'notion-button ' + 'notion-button'+myNum" (click)="notionCall = !notionCall"
							[ngClass]="{'notion-button-active': notionCall}">
					<div class="notion-picture">
						<pre><img src="https://www.notion.so/images/favicon.ico" alt="" width="40" height="40"></pre>
					</div>
				</div>

			</div>

      <!-- Method selection -->
			<mat-form-field appearance="fill" [id]="'call_Type'+myNum">
				<mat-label>
					type
				</mat-label>
				<mat-select [(ngModel)]="call_type">
					<mat-option value="GET">
						GET
					</mat-option>
					<mat-option value="POST">
						POST
					</mat-option>
				</mat-select>
			</mat-form-field>

      <!-- URL -->
			<mat-form-field appearance="fill" color="primary" class="mat-input-wrapper-0-0-3">
				<mat-label>
					URL*
				</mat-label>
				<input  matInput [id]="'url'+myNum"
                [(ngModel)]="url"
                (focus)="elementService.tfFocussed(true)"
                (focusout)="elementService.tfFocussed(false)"
                type="text" placeholder="https://jsonplaceholder.typicode.com/todos/1"
                >
			</mat-form-field>

      <!-- Header -->
			<div style="display: flex; width: 100%; justify-content: space-between; align-items: center;"
           *ngFor="let header of uiHeaders; let i = index">

        <mat-form-field appearance="fill">
          <mat-label>
            Header key
          </mat-label>
          <input  matInput [id]="'headers' + i + 'key' + myNum"
                          [(ngModel)]="header.key"
                          (input)="setHeader(header.key, header.value, i)"
                          (focus)="elementService.tfFocussed(true)"
                          (focusout)="elementService.tfFocussed(false)" value="X-Personio-App-ID" type="text"
                          placeholder="...">
        </mat-form-field>

        <mat-form-field formula appearance="fill">
          <mat-label>
            Header value
          </mat-label>
          <input  matInput [id]="'headers' + i + 'value' + myNum"
                          [(ngModel)]="header.value"
                          (input)="setHeader(header.key, header.value, i)"
                          (focus)="elementService.tfFocussed(true)"
                          (focusout)="elementService.tfFocussed(false)" value="PHOTONIC_CODES" type="text"
                          placeholder="Platzhalter">
        </mat-form-field>

        <button title="Header entfernen" class="removeButton"
                (click)="removeHeader(header.key, i)">
            X
        </button>

        <button title="Header hinzufügen" class="addButton"
                [ngStyle]="{'visibility': i == uiHeaders.length - 1 ? 'visible': 'hidden'}"
                (click)="this.uiHeaders.push({key: '',value: ''})">
            +
        </button>

			</div>

      <button *ngIf="uiHeaders.length == 0" title="Header hinzufügen" class="addButton"
              (click)="this.uiHeaders.push({key: '',value: ''})">
          +
      </button>

      <!-- Body type -->
      <mat-form-field appearance="fill">
				<mat-label>
					Body type
				</mat-label>
				<mat-select [(ngModel)]="body_Type" [id]="'body_Type' + myNum">
					<mat-option value="JSON">
						JSON
					</mat-option>
					<mat-option value="Form">
						Formular
					</mat-option>
				</mat-select>
			</mat-form-field>

      <!-- Body -->
			<mat-form-field formula [user]="elementService.selectedObjects[0]"
                      appearance="fill" class="mat-textarea-wrapper-0-0-6">
				<mat-label>
					Body
				</mat-label>
				<textarea matInput placeholder="Neuer Platzhalter" [id]="'body' + myNum"
                  [(ngModel)]="body"
                  (focus)="elementService.tfFocussed(true)"
                  (focusout)="elementService.tfFocussed(false)"></textarea>
			</mat-form-field>

      <!-- Submit button -->
			<a (click)="fetchApi()" class="mat-button-wrapper-0-0-7" mat-raised-button color="primary">
				<span class="mat-button-span">
					Abschicken
				</span>
			</a>
      <!-- END REQUEST DATA ENTRY -->

		</div>
    <!-- END LEFT SIDE -->

    <!--
██████╗ ██╗ ██████╗ ██╗  ██╗████████╗    ███████╗██╗██████╗ ███████╗
██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝    ██╔════╝██║██╔══██╗██╔════╝
██████╔╝██║██║  ███╗███████║   ██║       ███████╗██║██║  ██║█████╗
██╔══██╗██║██║   ██║██╔══██║   ██║       ╚════██║██║██║  ██║██╔══╝
██║  ██║██║╚██████╔╝██║  ██║   ██║       ███████║██║██████╔╝███████╗
╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝       ╚══════╝╚═╝╚═════╝ ╚══════╝

     -->
		<div style="display: flex; width: 50%; border-width: 1px 1px 1px 1px; border-color: #383838; background-color: #ffffff; flex-direction: column; border-radius: 0px; border-style: none;">
			<div style="display: flex; justify-content: space-between; align-items: flex-start;">

				<h1 class="text-black">Ergebnis</h1>

        <!-- Remove api call button -->
        <a (click)="removeMe()" class="mat-button-wrapper-0-0-7" mat-raised-button color="primary">
          <span class="mat-button-span">
            Api Call Entfernen
          </span>
        </a>

			</div>

      <!-- Response prettyprint area -->
			<div style="display: flex; flex-direction: column; background-color: #e8e8e8; border-radius: 5px;
                  border-style: solid; border-width: 1px 1px 1px 1px; border-color: #383838; flex-grow: 1;
                  padding: 10px; align-items: flex-start; max-height: 422px; overflow: scroll;">

				<div>
          <pre [id]="'result'+myNum" class="text-gray-600">{{ responseBodyAsText }}</pre>
        </div>

			</div>
		</div>
    <!-- END RIGHT SIDE -->

	</div>

  <!--
██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ███████╗    ██████╗ ███████╗███████╗██████╗  ██████╗ ███╗   ██╗███████╗███████╗
██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██╔════╝    ██╔══██╗██╔════╝██╔════╝██╔══██╗██╔═══██╗████╗  ██║██╔════╝██╔════╝
███████║███████║██╔██╗ ██║██║  ██║██║     █████╗      ██████╔╝█████╗  ███████╗██████╔╝██║   ██║██╔██╗ ██║███████╗█████╗
██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██╔══╝      ██╔══██╗██╔══╝  ╚════██║██╔═══╝ ██║   ██║██║╚██╗██║╚════██║██╔══╝
██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗███████╗    ██║  ██║███████╗███████║██║     ╚██████╔╝██║ ╚████║███████║███████╗
╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚══════╝

  -->

    <div [ngStyle]="{'display': responseBody ? 'revert': 'none'}" style="width: 100%;">
      <!--
     ██╗███████╗ ██████╗ ███╗   ██╗    ██████╗ ███████╗███████╗██████╗  ██████╗ ███╗   ██╗███████╗███████╗
     ██║██╔════╝██╔═══██╗████╗  ██║    ██╔══██╗██╔════╝██╔════╝██╔══██╗██╔═══██╗████╗  ██║██╔════╝██╔════╝
     ██║███████╗██║   ██║██╔██╗ ██║    ██████╔╝█████╗  ███████╗██████╔╝██║   ██║██╔██╗ ██║███████╗█████╗
██   ██║╚════██║██║   ██║██║╚██╗██║    ██╔══██╗██╔══╝  ╚════██║██╔═══╝ ██║   ██║██║╚██╗██║╚════██║██╔══╝
╚█████╔╝███████║╚██████╔╝██║ ╚████║    ██║  ██║███████╗███████║██║     ╚██████╔╝██║ ╚████║███████║███████╗
 ╚════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═══╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚══════╝

██████╗  █████╗ ████████╗ █████╗     ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
██║  ██║███████║   ██║   ███████║    ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
██║  ██║██╔══██║   ██║   ██╔══██║    ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
██████╔╝██║  ██║   ██║   ██║  ██║    ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
      -->
      <div style="display: flex; flex-grow: 1;"
           [ngStyle]="{'display': body_Type == 'JSON' && !notionCall ? 'flex': 'none'}">

        <div style="width: 50%; overflow: hidden;">
          <!-- Column names -->
          <h2 class="text-black" style="margin-top: 10px;">Spaltenname auswählen:</h2>
					<!-- // Attention with @ViewChild -> if there is an *ngIf around it, then the ViewChild is undefined, even when true -->
          <!-- WARNING: IF YOU ADD INPUTS HERE, YOU NEED TO ADD THEM TO THE JSONVIEWER.HTML TOO -->
          <jsonViewer [isHighest]="true" [responseBody]="responseBody"
                      [jsonViewerNumber]="1" #jsonViewer1
                      [apiCallNumber]="myNum" [body_Type]="body_Type"
                      (tellApiCallAboutSelection)="handleJsonViewerPathSelection($event, 'Column')">
          </jsonViewer>
        </div>

        <div style="width: 50%; overflow: hidden;">
          <!-- Data -->
          <h2 class="text-black" style="margin-top: 10px;">Daten auswählen:</h2>
					<!-- // Attention with @ViewChild -> if there is an *ngIf around it, then the ViewChild is undefined, even when true -->
          <!-- WARNING: IF YOU ADD INPUTS HERE, YOU NEED TO ADD THEM TO THE JSONVIEWER.HTML TOO -->
          <jsonViewer [isHighest]="true" [responseBody]="responseBody"
                      [jsonViewerNumber]="2" #jsonViewer2
                      [apiCallNumber]="myNum" [body_Type]="body_Type"
                      (tellApiCallAboutSelection)="handleJsonViewerPathSelection($event, 'Data')">
          </jsonViewer>
        </div>

      </div>
      <!--
██╗  ██╗████████╗███╗   ███╗██╗         ██████╗ ███████╗███████╗██████╗  ██████╗ ███╗   ██╗███████╗███████╗
██║  ██║╚══██╔══╝████╗ ████║██║         ██╔══██╗██╔════╝██╔════╝██╔══██╗██╔═══██╗████╗  ██║██╔════╝██╔════╝
███████║   ██║   ██╔████╔██║██║         ██████╔╝█████╗  ███████╗██████╔╝██║   ██║██╔██╗ ██║███████╗█████╗
██╔══██║   ██║   ██║╚██╔╝██║██║         ██╔══██╗██╔══╝  ╚════██║██╔═══╝ ██║   ██║██║╚██╗██║╚════██║██╔══╝
██║  ██║   ██║   ██║ ╚═╝ ██║███████╗    ██║  ██║███████╗███████║██║     ╚██████╔╝██║ ╚████║███████║███████╗
╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚══════╝

██████╗  █████╗ ████████╗ █████╗     ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
██║  ██║███████║   ██║   ███████║    ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
██║  ██║██╔══██║   ██║   ██╔══██║    ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
██████╔╝██║  ██║   ██║   ██║  ██║    ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
        -->
        <!--
            SHOW ALL THE FORM FIELDS
        -->
      <div *ngIf="body_Type == 'Form' && !body && responseBody">
        <h2 class="text-black" style="margin-top: 10px;">
          Folgende Formularfelder wurden gefunden:</h2>

        <!--
          Iterate over all foundFormFields and display them as a list
          This is to help the user to select the correct formFields
          But you can also just submit a form, look into the network tab,
          copy the formFields and the values and paste them into the body of the request
        -->
        <ul>
          <li *ngFor="let forms of foundForms; let i = index">formField
            <ul *ngFor="let formField of forms.formFields">
              <li>{{formField}}</li>
            </ul>
          </li>
        </ul>

      </div>

      <div [ngStyle]="{'display': (body_Type == 'Form' && body) ? 'revert': 'none'}" style="width: 100%;">

        <h2 class="text-black" style="margin-top: 10px;">Überschriften auswählen:</h2>
        <htmlViewer [htmlViewerNumber]="1" #htmlViewer1
            [responseBody]="responseBody"
            [highlightColor]="'green'"
            (setSelectedPaths)="handleJsonViewerPathSelection($event, 'Column')"
        ></htmlViewer>

        <h2 class="text-black" style="margin-top: 10px;">Daten auswählen:</h2>
        <htmlViewer [htmlViewerNumber]="2" #htmlViewer2
            [responseBody]="responseBody"
            [lengthRestriction]="columnData.length"
            [highlightColor]="'blue'"
            (setSelectedPaths)="handleJsonViewerPathSelection($event, 'Data')"
        ></htmlViewer>

      </div>
      <!-- END HTML RESPONSE DATA SELECTION -->

    </div>

<!--
 █████╗ ██████╗ ██╗     ██████╗ █████╗ ██╗     ██╗         ██████╗ ███████╗███████╗██╗   ██╗██╗  ████████╗
██╔══██╗██╔══██╗██║    ██╔════╝██╔══██╗██║     ██║         ██╔══██╗██╔════╝██╔════╝██║   ██║██║  ╚══██╔══╝
███████║██████╔╝██║    ██║     ███████║██║     ██║         ██████╔╝█████╗  ███████╗██║   ██║██║     ██║
██╔══██║██╔═══╝ ██║    ██║     ██╔══██║██║     ██║         ██╔══██╗██╔══╝  ╚════██║██║   ██║██║     ██║
██║  ██║██║     ██║    ╚██████╗██║  ██║███████╗███████╗    ██║  ██║███████╗███████║╚██████╔╝███████╗██║
╚═╝  ╚═╝╚═╝     ╚═╝     ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚═╝

██╗   ██╗██╗███████╗██╗    ██╗     █████╗ ███████╗     █████╗     ████████╗ █████╗ ██████╗ ██╗     ███████╗
██║   ██║██║██╔════╝██║    ██║    ██╔══██╗██╔════╝    ██╔══██╗    ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝
██║   ██║██║█████╗  ██║ █╗ ██║    ███████║███████╗    ███████║       ██║   ███████║██████╔╝██║     █████╗
╚██╗ ██╔╝██║██╔══╝  ██║███╗██║    ██╔══██║╚════██║    ██╔══██║       ██║   ██╔══██║██╔══██╗██║     ██╔══╝
 ╚████╔╝ ██║███████╗╚███╔███╔╝    ██║  ██║███████║    ██║  ██║       ██║   ██║  ██║██████╔╝███████╗███████╗
  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝     ╚═╝  ╚═╝╚══════╝    ╚═╝  ╚═╝       ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝

-->
    <resultPreviewTable #resultPreviewTable [columnData]="columnData" [dataData]="originalDataData" [apiNum]="myNum"
                        (notifyNewFilter)="storeFilter($event)" (iWasFiltered)="notifyDialogThatIWasFiltered()">
    </resultPreviewTable>
    <!-- END API CALL RESULT VIEW AS A TABLE -->

</div>
