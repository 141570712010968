import { Component, Input } from '@angular/core';

import { menu } from '../../menus/menu/menu';
import { ElementService } from '../../element.service';

@Component({
  selector: 'cssButton-menu',
  templateUrl: 'cssButton.menu.html'
})
export class CssButtonComponentMenu extends menu {
  @Input() renderOnlyOuter = false;
  @Input() isTailwind = false;
  @Input() isHelper = true;

  constructor(
    public elementService: ElementService
  ) {
    super(elementService);
  }

  unhover(): void {
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.unhover();
    });
  }

	setIcon(iconURL: string): void {

		console.log('settin Icon', iconURL)
		if (!iconURL.includes(".svg")) {

			const regex = "https://icon-sets\.iconify\.design\/([^\/]+)\/([^\/]+)"
			const match = iconURL.match(regex)

			if (!match) console.log('no match')
			const [, group, icon] = match ? match : ["", "", ""]

			const baseURL = "https://api.iconify.design/"
			iconURL = baseURL+group+"/"+icon+".svg"
		}

		console.log('to ', iconURL)
		this.setProp('iconURL', iconURL)
	}
}
