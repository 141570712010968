import { Component } from '@angular/core';

import { menu } from '../menu/menu';

@Component({
  selector: 'app-menu-mat-aria-orientation',
  templateUrl: './menu-mat-aria-orientation.component.html',
  styleUrls: ['./menu-mat-aria-orientation.component.scss']
})
export class MenuMatAriaOrientationComponent extends menu {
}
