<div *ngIf="elementService?.allOfType('matButton')">

  <!-- Size -->
  <!-- <app-menu-unit-input style="width: 49%" [width]="'49%'" [name]="'font-size'"></app-menu-unit-input> -->

  <h2>Button {{'general.menu' | translate}}:</h2>
  <!-- Pages -->
  <pageRouting></pageRouting>

  <!-- OnClick -->
  <app-menu-input [width]="'100%'" [name]="'onClick'" [isHelper]="true"></app-menu-input>

  <!-- Type -->
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.type</mat-label>
    <mat-select #typeSelect
      (selectionChange)="setProp('type', typeSelect.value);(typeSelect.value == 'icon' || typeSelect.value == 'fab' || typeSelect.value == 'mini-fab')?setProp('hasIcon', '1'):''"
      [value]="getProp('type')"
    >
      <mat-option value="simple">   <button mat-button          color="primary"><span translate>buttonTypes.simple</span></button></mat-option>
      <mat-option value="raised">   <button mat-raised-button   color="primary"><span translate>buttonTypes.raised</span></button></mat-option>
      <mat-option value="stroked">  <button mat-stroked-button  color="primary"><span translate>buttonTypes.stroked</span></button></mat-option>
      <mat-option value="flat">     <button mat-flat-button     color="primary"><span translate>buttonTypes.flat</span></button></mat-option>
      <mat-option value="icon">     <button mat-icon-button     color="primary"><mat-icon>{{getProp('Icon Name')}}</mat-icon></button><span translate>buttonTypes.icon</span></mat-option>
      <mat-option value="fab"><span translate>buttonTypes.fab</span></mat-option>
      <mat-option value="mini-fab"><span translate>buttonTypes.miniFab</span></mat-option>
    </mat-select>
  </mat-form-field>

  <!-- COLOR -->
  <mat-color-menu></mat-color-menu>

  <!-- DISABLED? -->
  <mat-disabled-menu></mat-disabled-menu>

  <!-- HAS ICON? -->
  <!-- logic: The (change) event is fired after the toggle is already changed
      Example: It is currently checked and the user clicks on it, then is is not checked anymore
              Then the (change) event fires and sees that itself is currently NOT CHECKED
              and calls the setProp() accordingly. -->
  <p style="margin-left: 10px;">
    <mat-slide-toggle
      #iconToggle
      (change)="setProp('hasIcon', iconToggle.checked?'1':'0')"
      [checked]="getProp('hasIcon')=='1'"
      [disabled]="typeSelect.value == 'icon' || typeSelect.value == 'fab' || typeSelect.value == 'mini-fab'"
    >
      Icon?
    </mat-slide-toggle>
  </p>

  <app-menu-input [width]="'100%'" [name]="'Icon Name'" [isHelper]="true" *ngIf="getProp('hasIcon')=='1'"></app-menu-input>
</div>
