import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public config: Config;

  public baseUrl: string;

  public constructor() {
    this.config = {
      protocol: environment.protocol,
      domain: environment.domain,
      port: environment.port,
      apiPrefix: environment.apiPrefix
    };
    const usePort = this.config.domain === 'localhost' || this.config.domain.startsWith('1');
    const port = `:${this.config.port}`;
    this.baseUrl = `${this.config.protocol}://${this.config.domain}${usePort ? port : ''}/${this.config.apiPrefix}`;
  }
}

export interface Config {
  protocol: string;
  domain: string;
  port: string;
  apiPrefix: string;
}
