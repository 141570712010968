import { Component } from '@angular/core';

import { AuthService } from './auth.service';

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   APP COMPONENT                                     |
           |   ===============================================   |
           |   * THE ONLY PURPOSE OF THE APP COMPONENT MAY BE    |
					 |   		* to handle the main view of the app           |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public constructor(private readonly authService: AuthService) {
  }
}
