import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, from, of } from 'rxjs';
import { concatMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

import { ConfigService } from '../config.service';
import { CacheService } from './cache.service';
import { ErrorHandlingService } from './error-handling.service';
import { PropertiesService } from './properties.service';
import { Project } from './entities/project.entity';
import { SaveLayout } from './entities/save-layout.entity';
import { CreateSaveLayoutDto } from './dto/create-save-layout.dto';
import { UpdateSaveLayoutDto } from './dto/update-save-layout.dto';

@Injectable({
  providedIn: 'root'
})
export class SaveLayoutsService {
  private readonly url: string;

  private readonly queue: Map<string, Subject<Observable<any>>> = new Map<string, Subject<Observable<any>>>();

  public constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly keycloakService: KeycloakService,
    public readonly cacheService: CacheService,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly propertiesService: PropertiesService
  ) {
    this.url = `${this.configService.baseUrl}/save-layouts`;
  }

  public queueObservable(key: string, request: Observable<any>) {
    const hasNot = !this.queue.has(key);

    if (hasNot) {
      this.queue.set(key, new Subject<Observable<any>>());
    }

    const queue = this.queue.get(key)!;

    if (hasNot) {
      queue.pipe(
        concatMap((observable) => observable)
      ).subscribe();
    }

    queue.next(request);
  }

  /*
   *  ██████╗██████╗ ██╗   ██╗██████╗      ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
   * ██╔════╝██╔══██╗██║   ██║██╔══██╗    ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
   * ██║     ██████╔╝██║   ██║██║  ██║    ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
   * ██║     ██╔══██╗██║   ██║██║  ██║    ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
   * ╚██████╗██║  ██║╚██████╔╝██████╔╝    ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
   *  ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝      ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
   */

  public findAll() {
    const request = this.http.get<SaveLayout[]>(this.url).pipe(shareReplay(1));
    request.subscribe((data) => this.cacheService.saveLayouts = data);
    return request;
  }

  public findOne(id: string, fn?: (saveLayout: SaveLayout) => void) {
    this.http.get<SaveLayout>(`${this.url}/${id}`).subscribe((data) => { if (data && fn) { fn(data); } });
  }

  public create(project: Project, saveLayout: CreateSaveLayoutDto, fn?: (saveLayout: SaveLayout) => void) {

    const key = `${project.id}-${saveLayout.parentId}`

    const newSaveLayout: CreateSaveLayoutDto = {
      projectId: project.id,
      projectOwner: project.owner,
      name: saveLayout.name,
      parentId: saveLayout.parentId,
      parentOwner: saveLayout.parentOwner,
      isLayout: saveLayout.isLayout,
      orderNumber: saveLayout.orderNumber
    }

    const createObservable = this.http.post<SaveLayout>(this.url, newSaveLayout).pipe(

      tap((data) => {

        if (data) {

          this.cacheService.saveLayouts.push(data)
          this.propertiesService.findAll().subscribe(() => { // TODO: Reload only the properties of the new save layout

            if(fn) fn(data)
          })
        }
      })
    )

    this.queueObservable(key, createObservable);
  }

  public createLater(project: Project, saveLayout: CreateSaveLayoutDto) {
    const newSaveLayout: CreateSaveLayoutDto = {
      projectId: project.id,
      projectOwner: project.owner,
      name: saveLayout.name,
      parentId: saveLayout.parentId,
      parentOwner: saveLayout.parentOwner,
      isLayout: saveLayout.isLayout,
      orderNumber: saveLayout.orderNumber
    };

    return this.http.post<SaveLayout>(this.url, newSaveLayout);
  }

  public import(importSaveLayout: SaveLayout) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user) => {
        const newSaveLayout: CreateSaveLayoutDto = {
          projectId: importSaveLayout.project!.id,
          projectOwner: user.id!,
          name: importSaveLayout.name,
          parentId: importSaveLayout.parent?.id,
          parentOwner: importSaveLayout.parent ? user.id! : undefined,
          isLayout: importSaveLayout.isLayout,
          orderNumber: importSaveLayout.orderNumber
        };

        return this.http.post<SaveLayout>(`${this.url}/${importSaveLayout.id}`, newSaveLayout);
      }
    ));
  }

  public update(saveLayout: SaveLayout, fn?: (saveLayout: SaveLayout) => void) {
    const key = `${saveLayout.project!.id}-${saveLayout.parent?.id}`;

    const editedSaveLayout: UpdateSaveLayoutDto = {
      name: saveLayout.name,
      parentId: saveLayout.parent?.id,
      parentOwner: saveLayout.parent?.owner,
      isLayout: saveLayout.isLayout,
      orderNumber: saveLayout.orderNumber
    };

    const updateObservable = this.http.put<SaveLayout[]>(`${this.url}/${saveLayout.id}`, editedSaveLayout).pipe(
      tap((data) => {
        if (!data) {
          return;
        }

        for (const saveLayout of data) {
          const index = this.cacheService.saveLayouts.findIndex((s) => s.id === saveLayout.id);
          if (index >= 0) {
            this.cacheService.saveLayouts[index] = saveLayout;
          }
        }

        this.propertiesService.findAll().subscribe(() => { // TODO: Reload only the properties of the new save layout
          if (fn) {
            fn(data.find((s) => s.id === saveLayout.id)!);
          }
        });
      })
    );

    this.queueObservable(key, updateObservable);
  }

  public delete(saveLayout: SaveLayout, fn?: (saveLayout: SaveLayout) => void) {
    const key = `${saveLayout.project!.id}-${saveLayout.parent?.id}`;

    const deleteObservable = this.http.delete<SaveLayout>(`${this.url}/${saveLayout.id}`).pipe(
      tap((data) => {
        if (data) {
          this.cacheService.saveLayouts = this.cacheService.saveLayouts.filter((s) => s.id !== data.id);
          if (fn) {
            fn(data);
          }
        }
      })
    );

    this.queueObservable(key, deleteObservable);
  }
}
