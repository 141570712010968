<trigger style="width: 100%;" [name]="'File Uploaded'" [trigger]="trigger">

  <div class="element-selection-area">
    <div class="richText2">
      <pre><span style="font-size: 14pt;"><strong>Upload</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>File Uploads</mat-label>
      <mat-select #thisSelect (selectionChange)="selectedFileUpload = thisSelect.value" [value]="selectedFileUpload">
        <mat-option *ngFor="let fileUpload of fileUploads" [value]="fileUpload">
          {{ fileUpload.getProp('displayName') ? fileUpload.getProp('displayName') : fileUpload.getProp('id') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</trigger>
