import * as XLSX from 'xlsx';

import { Action } from '../action';

export class ExportExcelAction extends Action {
  public fileName: string = '';

  public action(): void {
    this.output = this.excelExport(Object.values(this.input));
  }

  public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
      fileName: this.fileName
    }
  }

  private excelExport(excelData: any, mountType?: string) {
    let finalExcelData;

    if (mountType) {
      const seperatedExcelData = this.seperateExcelData(excelData, mountType);
      finalExcelData = seperatedExcelData
    } else {
      finalExcelData = excelData;
    }

    var exportExcelData = XLSX.utils.json_to_sheet(finalExcelData) ;

    // A workbook is the name given to an Excel file
    var wb = XLSX.utils.book_new() // make Workbook of Excel

    // add Worksheet to Workbook
    // Workbook contains one or more worksheets
    let workSheetName: string = '';
    if (mountType === 'THROUGH') {
      workSheetName = 'THT_IMPORT'
    } else if (mountType === 'SMD') {
      workSheetName = 'SMT_IMPORT'
    }
    XLSX.utils.book_append_sheet(wb, exportExcelData, workSheetName) // name of Worksheet AND what data is inside

    // export Excel file
    XLSX.writeFile(wb, (this.fileName ?? 'Default') + '.xlsx') // name of the file is 'book.xlsx'
  }

  seperateExcelData(exportData: any, mountType:string) {

    const excelFinalOutputData: any[] = [];
    let result: any[] = [];

    let importType = '';
    if (mountType === 'SMD') {
      importType = 'SMT_IMPORT'
    } else if (mountType === 'THROUGH') {
      importType = 'THT_IMPORT'
    }

    exportData.forEach((element: any) => {
      if (element.MountTYPE !== mountType && element.MountTYPE) {
        return;
      }
      if (element['Reference'].isMismatched !== undefined && element['Reference'].isMismatched === true) return;
      let newObject: any;

      let foundObject = result.find((elem:any) => !elem.isFilled && elem.itemNumber === element.Item && elem.side === element.Side && elem.assyopt === element.ASSY_OPT.value);
      if (!foundObject) {
        newObject = {
          itemNumber: element.Item,
          cells: [],
          side: element.Side,
          isFilled: false,
          sortNum: 999,
          assyopt: element.ASSY_OPT.value,
          isSeparator: false,
          'Teile-Nr.': element['Teile-Nr.']
        }
        newObject.cells.push(element['Reference'] || element['Reference'].value);

        result.push(
          newObject
        )
      } else {
        newObject = foundObject;
        newObject.cells.push(element['Reference'] || element['Reference'].value);
        if(newObject.cells.length >= 10) {
          newObject.isFilled = true;
        }
      }

      if ((element.Side === "TOP" || "Top") && element.ASSY_OPT.value !== 'DNP') {
        newObject.sortNum = 1;
      } else if (element.Side === "TOP" || "Top") {
        newObject.sortNum = 2;
      } else if (element.Side === "BOTTOM" && element.ASSY_OPT.value !== 'DNP') {
        newObject.sortNum = 3;
      } else if (element.Side === "BOTTOM" ) {
        newObject.sortNum = 4;
      } else if (!element.Side && element.ASSY_OPT.value === 'DNP') {
        newObject.sortNum = 5;
      }

      result.sort(function(firstObj: any, secondObj: any) {
        return firstObj.sortNum-secondObj.sortNum;
      });
    });

    let counter = 0;
    this.nineSeparator(result, importType);
    result.forEach((element) => {
      // add this for testing: 'side': element.side, 'assyopt': element.assyopt
      excelFinalOutputData.push({
        'Designator': element.cells.join(','),
        'SachNr.': element.isSeparator ? 999999 : element['Teile-Nr.'] || ++counter,
        'X-Koord': 0,
        'Y-Koord': 0,
        'Rot': 0,
        'Anzahl Teile': element.cells.length,
        'BG': importType,
        'side': element.side,
        'assyopt': element.assyopt
      })
    });

    return excelFinalOutputData;
  }

  private nineSeparator( result: any, importType: string) {
    let sepArr = result.reduce((accumulator: any, currentValue: any) => {
      if (accumulator.every((item: any) => !(item.sortNum === currentValue.sortNum))) accumulator.push(currentValue);
      return accumulator;
    }, []);
    if (sepArr.length > 0) {
      sepArr.splice(0, 1);
    }
    sepArr.forEach((num: any) => {
      let inx = result.findIndex((elem: any) => elem.sortNum === num.sortNum);

      if (inx !== -1) {
        result.splice(inx, 0, {
          itemNumber: 0,
          cells: [],
          side: '',
          isFilled: true,
          sortNum: num.sortNum+ 0.1,
          assyopt: '',
          isSeparator: true
        });
      }
    })
  }
}
