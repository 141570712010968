import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { CacheService } from './cache.service';
import { CreatePropertyDto } from './dto/create-property.dto';
import { Property } from './entities/property.entity';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  public constructor(private readonly cacheService: CacheService) {
  }

  public handleCreateOrUpdateProperty(property: CreatePropertyDto, error: any) {
    const saveLayout = this.cacheService.saveLayouts.find((sl) => sl.id === property.saveLayoutId);
    console.error(`Property ${property.key} from ${saveLayout?.name ?? property.saveLayoutId}`, saveLayout, property);

    return throwError(() => error);
  }

  public handleDeleteProperty(property: Property, error: any) {
    const saveLayout = this.cacheService.saveLayouts.find((sl) => sl.id === property.saveLayout.id);
    console.error(`Property ${property.key} from ${saveLayout?.name ?? property.saveLayout.id}`, saveLayout, property);

    return throwError(() => error);
  }
}
