import { Component, Input } from '@angular/core';

import { menu } from 'src/app/menus/menu/menu';
import { ElementService } from 'src/app/element.service';
import { leafletJs } from './leafletJs.component';

@Component({
  selector: 'leafletJsMenu',
  templateUrl: 'leafletJs.menu.html'
})
export class leafletJsMenu extends menu {
  @Input() renderOnlyOuter = false;
  @Input() isTailwind = false;
  @Input() isHelper = true;

  constructor(
    public elementService: ElementService
  ){
    super(elementService)
  }

  public get latitude(): number {
    return +this.elementService.getProp('latitude');
  }

  public set latitude(value: number) {
    this.elementService.setProp({ key: 'latitude', value: ""+value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateLatitude();
    });
  }

  public get longitude(): number {
    return +this.elementService.getProp('longitude');
  }

  public set longitude(value: number) {
    this.elementService.setProp({ key: 'longitude', value: ""+value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateLongitude();
    });
  }

  public get zoomLevel(): number {
    return +this.elementService.getProp('zoomLevel');
  }

  public set zoomLevel(value: number) {
    this.elementService.setProp({ key: 'zoomLevel', value: ""+value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateZoomLevel();
    });
  }

  public get maxZoomLevel(): number {
    return +this.elementService.getProp('maxZoomLevel');
  }

  public set maxZoomLevel(value: number) {
    this.elementService.setProp({ key: 'maxZoomLevel', value: ""+value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateMaxZoomLevel();
    });
  }

  public addLayer() {
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.addLayer();
    });
  }

  public removeLayer() {
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.removeLayer();
    });
  }

  public get countLayers() {
    let countLayers = -1;
    this.elementService.selectedObjects.forEach(selectedObject => {
      countLayers = selectedObject.element.countLayers();
    });
    return Array.from({length: countLayers}, (_, i) => i);
  }

  public get currentLayerIndex(): number {
    return +this.elementService.getProp('currentLayerIndex');
  }

  public set currentLayerIndex(value: number) {
    this.elementService.setProp({ key: 'currentLayerIndex', value: ''+value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateCurrentLayerIndex();
    });
  }

  public get currentLayerColor(): string {
    return (this.elementService.selectedObjects[0] as leafletJs).getCurrentLayersColor();
  }

  public set currentLayerColor(value: string) {
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.updateCurrentLayersColor(value);
    });
  }
}
