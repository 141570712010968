<div *ngIf="elementService?.allOfType('routerOutletPlaceholder')">

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Default Page</mat-label>
    <mat-select #defaultPageSelect (valueChange)="defaultPage = defaultPageSelect.value" [value]="defaultPage">
      <mat-option *ngFor="let page of dataService.projectsService.currentProject!.pages | pageFilter | selectionFilter:selectedPages" [value]="getId(page)">
        {{ page.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>Pages</mat-label>
    <mat-select #pagesSelect multiple (valueChange)="selectedPages = pagesSelect.value" [value]="selectedPages">
      <mat-option *ngFor="let page of dataService.projectsService.currentProject!.pages | pageFilter" [value]="getId(page)" [disabled]="areSelectedPagesDisabled(page)">
        {{ page.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <a
    mat-raised-button
    color="primary"
    (click)="dataService.loadPageIntoTheApp(getPage(defaultPage))"
    style="margin-bottom: 10px;"
  >
    <span class="mat-button-span">Seite öffnen</span>
  </a>

</div>
