import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'loading-dialog',
  templateUrl: './loading-dialog.html',
  styleUrls: ['./loading-dialog.scss']
})
export class LoadingDialogComponent {

  @Output() closed: EventEmitter<string> = new EventEmitter<string>();
  @Output() download: EventEmitter<string> = new EventEmitter<string>();

  fileName = ""

  close() {

    this.closed.emit('closed')
  }

  triggerDownload() {

    this.download.emit(this.fileName)

    this.close()
  }

}
