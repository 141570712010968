import { AfterViewInit, Component, Input } from '@angular/core';
import { menu } from '../../menus/menu/menu';
import { LayoutComponent } from 'src/app/layout/layout.component'
import { ElementService } from 'src/app/element.service'

@Component({
  selector: 'text-menu',
  templateUrl: 'text.menu.html',
	styleUrls: ['text.menu.scss']
})
export class TextComponentMenu extends menu {

  @Input() renderOnlyOuter = false
  @Input() isTailwind = false
  @Input() isHelper = true

  private _inputValueHTML: string | undefined
  public get inputValueHTML(): string | undefined {

    this._inputValueHTML = this.elementService.selectedObjects[0].element.htmlToShow

    return this._inputValueHTML
  }
  public set inputValueHTML(value: string | undefined) {

		if(value === undefined) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.htmlToShow = value
    })
    this._inputValueHTML = value
  }

  private _inputValueStyle: string | undefined
  public get inputValueStyle(): string | undefined {

    this._inputValueStyle = this.elementService.selectedObjects[0].element.styleToShow

    return this._inputValueStyle
  }
  public set inputValueStyle(value: string | undefined) {

		if(value === undefined) return
    this.elementService.selectedObjects.forEach((selectedObject) => {

      selectedObject.element.styleToShow = value
    })
    this._inputValueStyle = value
  }

  editorOptions1 = {
		theme: 'vs-dark',
		language: 'html',
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

  editorOptions2 = {
		theme: 'vs-dark',
		language: 'css',
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

	onInit(editor: any, selected: LayoutComponent) {

		ElementService.monacoEditor = editor
    const code = this.getProp(`html`)
    this.inputValueHTML = code
	}

	onInit2(editor: any, selected: LayoutComponent) {

		ElementService.monacoEditor2 = editor
	}
}
