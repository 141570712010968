import { Injectable, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';

import { ElementService } from '../element.service';
import { ProjectDataCacheService } from '../project-data-cache.service';
import { LayoutComponent } from '../layout/layout.component';
import { Automation } from './automation';
import { ButtonClickedTrigger } from './triggers/button-clicked.trigger';
import { FileUploadedTrigger } from './triggers/file-uploaded.trigger';
import { Action } from './action';
import { SplitAndTrimAction } from './actions/split-and-trim.action';
import { DownloadFileAction } from './actions/download-file.action';
import { CustomScriptAction } from './actions/custom-script.action';
import { ExportExcelAction } from './actions/export-excel.action';
import { SendEmailAction } from './actions/send-email.action';
import { SaveLayout } from '../projects';
import { TreeSearchService } from '../tree-search.service';
import { ExcelService } from '../excel.service';
import { KanbanCardDroppedTrigger } from './triggers/kanban-card-dropped.trigger';
import { ApiCallAction } from './actions/api-call.action';
import { KanbanCardDroppedAction } from './actions/kanban-card-dropped.action';

@Injectable({
  providedIn: 'root'
})
export class AutomationsService {
  public triggerMap: Map<string, Type<unknown>> = new Map<string, Type<unknown>>();
  public actionMap: Map<string, Type<unknown>> = new Map<string, Type<unknown>>();

  public automations: Map<string, Automation[]> = new Map<string, Automation[]>();

  public currentLayout: LayoutComponent;
	public currentAutomation: Automation;

  public showAutomationsDialog: boolean = false;
  public showAutomationDialog: boolean = false;
  public showAddTriggerDialog: boolean = false;
  public showAddActionDialog: boolean = false;

  public currentAction?: Action;

  public constructor(
    private readonly elementService: ElementService,
    private readonly http: HttpClient,
    private readonly projectDataCacheService: ProjectDataCacheService,
    private readonly treeSearchService: TreeSearchService,
    private readonly excelService: ExcelService
  ) {
    this.triggerMap.set('file-uploaded', FileUploadedTrigger);
    this.triggerMap.set('button-clicked', ButtonClickedTrigger);
    this.triggerMap.set('kanban-card-dropped', KanbanCardDroppedTrigger);

    this.actionMap.set('split-and-trim', SplitAndTrimAction);
    this.actionMap.set('download-file', DownloadFileAction);
    this.actionMap.set('custom-script', CustomScriptAction);
    this.actionMap.set('export-excel', ExportExcelAction);
    this.actionMap.set('send-email', SendEmailAction);
    this.actionMap.set('api-call', ApiCallAction);
    this.actionMap.set('kanban-card-dropped', KanbanCardDroppedAction);
  }

  /*
   * ██████╗  █████╗ ████████╗ █████╗ 
   * ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
   * ██║  ██║███████║   ██║   ███████║
   * ██║  ██║██╔══██║   ██║   ██╔══██║
   * ██████╔╝██║  ██║   ██║   ██║  ██║
   * ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
   */

  public clearAutomations() {
    this.automations.clear();
  }

  public pushAutomation(id: string, createdAutomation: Automation, storage?: Map<string, Automation[]>) {
    if (storage) {
      storage.has(id) ? storage.get(id)!.push(createdAutomation) : storage.set(id, [createdAutomation]);
    } else {
      this.automations.has(id) ? this.automations.get(id)!.push(createdAutomation) : this.automations.set(id, [createdAutomation]);
    }
  }

  /*
   * ██╗      ██████╗  █████╗ ██████╗ 
   * ██║     ██╔═══██╗██╔══██╗██╔══██╗
   * ██║     ██║   ██║███████║██║  ██║
   * ██║     ██║   ██║██╔══██║██║  ██║
   * ███████╗╚██████╔╝██║  ██║██████╔╝
   * ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ 
   */

  public loadAutomationsForLayoutComponent(layout: LayoutComponent, storage?: Map<string, Automation[]>) {
    this.loadAutomationsForSaveLayout(layout.saveLayout, storage);
  }

  public loadAutomationsForSaveLayout(saveLayout: SaveLayout, storage?: Map<string, Automation[]>) {
    const id = this.treeSearchService.getProp(saveLayout, 'id');
    const automations = this.treeSearchService.getProp(saveLayout, 'automations');
    this.loadAutomations(id, automations, storage);
  }

  public loadAutomations(id: string, automations: string, storage?: Map<string, Automation[]>) {
    if (!id) { return; }
    if (!automations) { return; }

    const serializedAutomations = JSON.parse(automations);

    for (const serializedAutomation of serializedAutomations) {
      this.loadAutomation(id, serializedAutomation, storage);
    }
  }

  private loadAutomation(id: string, serializedAutomation: any, storage?: Map<string, Automation[]>): void {
    const createdAutomation = new Automation();
    createdAutomation.id = serializedAutomation.id;
    createdAutomation.name = serializedAutomation.name;

    if (serializedAutomation?.trigger?.name) {
      const createdTrigger = new (this.triggerMap.get(serializedAutomation.trigger.name)!)() as any;

      createdTrigger.id = serializedAutomation.trigger.id;
      createdTrigger.name = serializedAutomation.trigger.name;
      createdTrigger.saveLayoutId = serializedAutomation.trigger.saveLayoutId;
      createdTrigger.automation = createdAutomation;

      createdAutomation.trigger = createdTrigger;
    }

    if (serializedAutomation?.action?.name) {
      const createdAction = this.loadSaveAction(createdAutomation, serializedAutomation.action);
      createdAutomation.action = createdAction;
    }

    this.pushAutomation(id, createdAutomation, storage);
  }

  private loadSaveAction(automation: Automation, saveAction: any): Action {
    let firstAction: Action | undefined;

    let previousAction: Action | undefined;
    let currentAction: Action | undefined;

    let currentSaveAction: any = saveAction;

    while (currentSaveAction) {
      if (currentSaveAction) {
        currentAction = Object.assign(new (this.actionMap.get(currentSaveAction.name)!)() as any, currentSaveAction);
        currentAction!.automation = automation;

        if (!previousAction) {
          firstAction = currentAction!;
        }

        if (previousAction) {
          previousAction.nextAction = currentAction;
          currentAction!.previousAction = previousAction;
        }

        if (currentSaveAction.name === 'split-and-trim') {
          (currentAction! as SplitAndTrimAction).excelService = this.excelService;
        }

        if (currentSaveAction.name === 'send-email') {
          (currentAction! as SendEmailAction).http = this.http;
          (currentAction! as SendEmailAction).projectDataCacheService = this.projectDataCacheService;
        }
      }

      currentSaveAction = currentSaveAction.nextAction;

      previousAction = currentAction;
      currentAction = undefined;
    }

    return firstAction!;
  }

  /*
   * ███████╗ █████╗ ██╗   ██╗███████╗
   * ██╔════╝██╔══██╗██║   ██║██╔════╝
   * ███████╗███████║██║   ██║█████╗  
   * ╚════██║██╔══██║╚██╗ ██╔╝██╔══╝  
   * ███████║██║  ██║ ╚████╔╝ ███████╗
   * ╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝
   */

  public saveAutomations(layout: LayoutComponent) {

    if (!layout) return

    const value = this.automationsToString(this.treeSearchService.getId(layout.saveLayout));

    layout.setProp({ key: 'automations', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.reRegisterTriggers(layout);
  }

  public automationsToString(id: string, storage?: Map<string, Automation[]>): string {
    const serializedAutomations = []

    for (const automation of storage ? storage.get(id) ?? [] : this.automations.get(id) ?? []) {
			const serializedAutomation = {

        id: automation.id,
        name: automation.name,
        trigger: automation.trigger ? {
          id: automation.trigger?.id,
          name: automation.trigger?.name,
          saveLayoutId: automation.trigger?.saveLayoutId
        } : undefined,
        action: automation.action ? this.saveSaveAction(automation) : undefined
      }

      serializedAutomations.push(serializedAutomation)
    }

    return JSON.stringify(serializedAutomations)
  }

  private saveSaveAction(automation: Automation): any {

    let saveAction: any = undefined;

    let previousSaveAction: any = undefined;
    let currentSaveAction: any = undefined;

    let currentAction = automation.action;

    while (currentAction) {

      if (currentAction) {

        currentSaveAction = currentAction.getSaveAction();

        if (!previousSaveAction) saveAction = currentSaveAction

        if (previousSaveAction) previousSaveAction.nextAction = currentSaveAction
      }

      currentAction = currentAction.nextAction;

      previousSaveAction = currentSaveAction;
      currentSaveAction = undefined;
    }

    return saveAction;
  }

  /*
   * ████████╗██████╗ ██╗ ██████╗  ██████╗ ███████╗██████╗ 
   * ╚══██╔══╝██╔══██╗██║██╔════╝ ██╔════╝ ██╔════╝██╔══██╗
   *    ██║   ██████╔╝██║██║  ███╗██║  ███╗█████╗  ██████╔╝
   *    ██║   ██╔══██╗██║██║   ██║██║   ██║██╔══╝  ██╔══██╗
   *    ██║   ██║  ██║██║╚██████╔╝╚██████╔╝███████╗██║  ██║
   *    ╚═╝   ╚═╝  ╚═╝╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝
   */

  public reRegisterTriggers(layout: LayoutComponent) {
    for (const automation of this.automations.get(this.treeSearchService.getId(layout.saveLayout)) ?? []) {
      if (!automation.trigger || !automation.trigger.saveLayoutId) { continue; }

      const saveLayoutId = automation.trigger.saveLayoutId;
      if (!saveLayoutId) { continue; }

      const matches = this.elementService.findElementById(saveLayoutId);
      if (matches.length === 0) { continue; }

      const layoutComponent = matches[0];

      layoutComponent.removeTrigger(automation.trigger);
      layoutComponent.addTrigger(automation.trigger);
    }
  }

  /*
   *  ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
   * ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
   * ██║     ██████╔╝█████╗  ███████║   ██║   █████╗  
   * ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝  
   * ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   *  ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
   */

	public createAutomation(layout: LayoutComponent) {
		const createdAutomation = new Automation();
    createdAutomation.id = uuid();
    createdAutomation.name = 'New Automation';
		this.pushAutomation(this.treeSearchService.getId(layout.saveLayout), createdAutomation);
    this.saveAutomations(layout);
	}

  public createTrigger(layout: LayoutComponent, automation: Automation, trigger: string) {
    const createdTrigger = new (this.triggerMap.get(trigger)!)() as any;
    createdTrigger.id = uuid();
    createdTrigger.name = trigger;
    createdTrigger.automation = automation;
    automation.trigger = createdTrigger;
    this.saveAutomations(layout);
  }

  public createAction(layout: LayoutComponent, automation: Automation, action: string, previousAction?: Action) {
    const createdAction = new (this.actionMap.get(action)!)() as any;
    createdAction.id = uuid();
    createdAction.name = action;
    createdAction.automation = automation;

    if (previousAction) {
      createdAction.nextAction = previousAction.nextAction;
      previousAction.nextAction = createdAction;
    } else {
      createdAction.nextAction = automation.action;
      automation.action = createdAction;
    }

    if (action === 'split-and-trim') {
      (createdAction! as SplitAndTrimAction).excelService = this.excelService;
    }

    if (action === 'send-email') {
      createdAction.http = this.http;
      createdAction.projectDataCacheService = this.projectDataCacheService;
    }

    this.saveAutomations(layout);
  }

  /*
   *  ██████╗ ██████╗ ███████╗███╗   ██╗
   * ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
   * ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
   * ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
   * ╚██████╔╝██║     ███████╗██║ ╚████║
   *  ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝
   */

	public openAutomation(layout: LayoutComponent, index: number) {
    this.currentLayout = layout;
		this.currentAutomation = this.automations.get(this.treeSearchService.getId(layout.saveLayout))![index];
		this.showAutomationDialog = true;
	}

  /*
   * ██████╗ ███████╗██╗     ███████╗████████╗███████╗
   * ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
   * ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  
   * ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  
   * ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
   * ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
   */

	public deleteAutomation(layout: LayoutComponent, automation: Automation) {
    this.automations.get(this.treeSearchService.getId(layout.saveLayout))!.splice(this.automations.get(this.treeSearchService.getId(layout.saveLayout))!.indexOf(automation), 1);
    this.saveAutomations(layout);
	}

  public deleteTrigger(layout: LayoutComponent, automation: Automation) {
    automation.trigger!.automation = undefined;
    automation.trigger = undefined;
    this.saveAutomations(layout);
  }

  public deleteAction(layout: LayoutComponent, automation: Automation, action: Action) {
    if (automation.action?.id === action.id) {
      automation.action = action.nextAction;
    } else {
      let previousAction = automation.action;

      while (previousAction) {
        if (previousAction.nextAction?.id === action.id) {
          break;
        }
        previousAction = previousAction.nextAction;
      }

      if (previousAction) {
        previousAction.nextAction = action.nextAction;
      }
    }

    action.nextAction = undefined;
    action.automation = undefined;

    this.saveAutomations(layout);
  }

  /*
   * ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
   * ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
   * ███████╗█████╗  ███████║██████╔╝██║     ███████║
   * ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
   * ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
   * ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
   */

  public getActions(automation: Automation): any[] {
    const actions: Action[] = [];

    let currentAction = automation.action;

    while (currentAction) {
      actions.push(currentAction);
      currentAction = currentAction.nextAction;
    }

    return actions;
  }

  public getPreviousAction(automation: Automation, action: Action): Action | undefined {
    let currentAction = automation.action;

    while (currentAction) {
      if (currentAction.nextAction?.id === action.id) {
        return currentAction;
      }
      currentAction = currentAction.nextAction;
    }

    return undefined;
  }

  public getLastAction(automation: Automation): Action | undefined {
    let currentAction = automation.action;

    while (currentAction) {
      if (!currentAction.nextAction) {
        return currentAction;
      }
      currentAction = currentAction.nextAction;
    }

    return undefined;
  }
}
