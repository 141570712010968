<div *ngIf="elementService?.allOfType('matInput')">

  <h2>{{'elementNames.input' | translate}} {{'general.menu' | translate}}:</h2>

  <!-- Label -->
  <app-menu-input [width]="'100%'" [name]="'Label'" [isHelper]="true"></app-menu-input>

  <!-- PLACEHOLDER -->
  <app-menu-input [width]="'100%'" [name]="'Platzhalter'" [isHelper]="true"></app-menu-input>

  <!-- Prefill -->
  <app-menu-input [width]="'100%'" [name]="'prefill'" [isHelper]="true"></app-menu-input>

  <div style="display: flex; justify-content: space-between;">
    <!-- Clearable? -->
    <!-- logic: The (change) event is fired after the toggle is already changed
        Example: It is currently checked and the user clicks on it, then is is not checked anymore
                 Then the (change) event fires and sees that itself is currently NOT CHECKED
                 and calls the setProp() accordingly. -->
    <p style="margin-left: 10px;">
      <mat-slide-toggle
        #loeschbarToggle
        (change)="setProp('deletable', loeschbarToggle.checked?'1':'0')"
        [checked]="getProp('deletable')=='1'"
      >
        <span translate>rightMenu.deletable</span>
      </mat-slide-toggle>
    </p>

    <!-- DISABLED? -->
    <mat-disabled-menu></mat-disabled-menu>
  </div>

  <!-- Type -->
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label translate>rightMenu.type</mat-label>
    <mat-select #typeSelect
      (selectionChange)="setProp('Typ', typeSelect.value)"
      [value]="getProp('Typ')"
    >
      <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- Appearance -->
    <mat-form-field appearance="fill" style="width: 49%;">
      <mat-label translate>rightMenu.appearance</mat-label>
      <mat-select #fuellungSelect
        (selectionChange)="setProp('Erscheinungsbild', fuellungSelect.value)"
        [value]="getProp('Erscheinungsbild')"
      >
        <mat-option *ngFor="let appearance of appearances" [value]="appearance">{{appearance}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- COLOR -->
    <mat-color-menu style="width: 49%" [width]="'100%'"></mat-color-menu>
  </div>

  <!-- MAX LENGTH -->
  <app-menu-input [width]="'100%'" [name]="'maxLength'" [isHelper]="true" [type]="'number'"></app-menu-input>

  <div style="display: flex; justify-content: space-between;">
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'startHint'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'endHint'" [isHelper]="true"></app-menu-input>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'prefixText'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'suffixText'" [isHelper]="true"></app-menu-input>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'prefixIcon'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'suffixIcon'" [isHelper]="true"></app-menu-input>
  </div>
</div>
