import { Pipe, PipeTransform } from '@angular/core';

import { DataService } from './data.service';
import { PropertyIterator, SaveLayout } from './projects';

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   SELECTION FILTER PIPE                             |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS PIPE IS TO FILTER THE       |
					 |   * LIST OF PAGES. IT REMOVES EVERY PAGE NOT        |
           |   * LISTED IN THE SELECTED PAGES LIST CONTAINING.   |
           |   * THE IDS OF THE PAGES.                           |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Pipe({
  name: 'selectionFilter',
  pure: false
})
export class SelectionFilterPipe implements PipeTransform {
  public constructor(private dataService: DataService) {
  }

  transform(pages: SaveLayout[], selectedPages: string[]): SaveLayout[] {
    if (!pages) { return [] };
    return this.dataService.projectsService.currentProject!.pages.filter((page) => selectedPages.includes([...(new PropertyIterator(this.dataService.propertiesService, page))].find((prop) => prop.key === 'id')?.value ?? ''));
  }
}
