<div *ngIf="elementService?.allOfType('matDatepicker')">

  <h2>Datepicker {{'general.menu' | translate}}:</h2>

  <!-- Label -->
  <app-menu-input [width]="'100%'" [name]="'Label'" [isHelper]="true"></app-menu-input>

  <!-- PLACEHOLDER -->
  <app-menu-input [width]="'100%'" [name]="'Platzhalter'" [isHelper]="true"></app-menu-input>

  <!-- Prefill -->
  <app-menu-input [width]="'100%'" [name]="'prefill'" [isHelper]="true"></app-menu-input>

  <div style="display: flex; justify-content: space-between;">

    <!-- DISABLED? -->
    <mat-disabled-menu></mat-disabled-menu>
  </div>

  <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->

    <!-- Appearance -->
    <mat-form-field appearance="fill" style="width: 49%;">
      <mat-label translate>rightMenu.appearance</mat-label>
      <mat-select #fuellungSelect
        (selectionChange)="setProp('Erscheinungsbild', fuellungSelect.value)"
        [value]="getProp('Erscheinungsbild')"
      >
        <mat-option *ngFor="let appearance of appearances" [value]="appearance">{{appearance}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- COLOR -->
    <mat-color-menu style="width: 49%" [width]="'100%'"></mat-color-menu>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'startHint'" [isHelper]="true"></app-menu-input>
    <app-menu-input style="width: 49%;" [width]="'100%'" [name]="'endHint'" [isHelper]="true"></app-menu-input>
  </div>
</div>
