    <!-- Labelposition -->
    <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
      <mat-label translate>rightMenu.labelPosition</mat-label>
      <mat-select #positionSelect
        (selectionChange)="setProp('Labelposition', positionSelect.value, '', false, true, false)"
        [value]="getProp('Labelposition')"
      >
        <mat-option *ngFor="let position of positions" [value]="position"><span translate>rightMenu.{{position}}</span></mat-option>
      </mat-select>
    </mat-form-field>
