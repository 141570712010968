import { Component, Input } from '@angular/core'

import { AutomationsService, CustomScriptAction } from '../../../../automations'

@Component({
  selector: "custom-script-action",
  templateUrl: "./custom-script.component.html",
  styleUrls: ["./custom-script.component.scss"]
})
export class CustomScriptActionComponent {
  @Input()
  public action: CustomScriptAction

  public editorOptions = {
		theme: 'vs-dark',
		language: 'javascript',
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

  public editor: any

  public constructor(private readonly automationsService: AutomationsService) {
  }

  public onInit(editor: any) {

		this.editor = editor
	}

  private codeTimeout: NodeJS.Timeout
  public get code(): string {

    return this.action.code
  }
  public set code(value: string) {

    this.action.code = value
    if (this.codeTimeout) {

			clearTimeout(this.codeTimeout)
		}
		this.codeTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800)
  }

	testAction(){

		console.log('testing action')
		this.action.executeAction(true)
	}
}
