import { Component, Input } from '@angular/core';

import { ElementService } from '../../../../element.service';
import { AutomationsService, KanbanCardDroppedAction } from '../../../../automations';

@Component({
  selector: 'kanban-card-dropped-action',
  templateUrl: './kanban-card-dropped.component.html',
  styleUrls: ['./kanban-card-dropped.component.scss']
})
export class KanbanCardDroppedActionComponent {
  @Input()
  public action: KanbanCardDroppedAction;

  public constructor(public readonly automationsService: AutomationsService, public readonly elementService: ElementService) {
  }

  private headerFieldTimeout: NodeJS.Timeout;
  public get headerField(): string {
		return this.action.headerField;
	}
	public set headerField(value: string) {
		this.action.headerField = value;

		if (this.headerFieldTimeout) {
			clearTimeout(this.headerFieldTimeout);
		}
		this.headerFieldTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private keyFieldTimeout: NodeJS.Timeout;
  public get keyField(): string {
		return this.action.keyField;
	}
	public set keyField(value: string) {
		this.action.keyField = value;

		if (this.keyFieldTimeout) {
			clearTimeout(this.keyFieldTimeout);
		}
		this.keyFieldTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}
}
