import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: any;

  public constructor(private readonly keycloakService: KeycloakService) {
    setInterval(() => {
      this.checkAuthenticationStatus();
    }, 5000);
  }

  public checkAuthenticationStatus(): void {
    if (this.keycloakService.isTokenExpired()) {
      this.handleTokenExpired();
      return;
    }

    this.keycloakService.loadUserProfile()
      .then((user) => this.user = user)
      .catch(() => this.handleTokenExpired());
  }

  private handleTokenExpired() {
    location.reload();
  }
}
