import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';
import { ElementService } from '../../element.service';
import { MatButtonComponent } from './matButton.component';

@Component({
  selector: 'matButton-menu',
  templateUrl: 'matButton.menu.html'
})
export class MatButtonComponentMenu extends menu {

  element: MatButtonComponent

  constructor(
    public elementService: ElementService
  ){

    super(elementService)
  }

  renderOnlyOuter = false
  isTailwind = false
  isHelper = true
}
