import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	Renderer2,
	ViewChild,
	ViewContainerRef
} from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs/operators'
import { matTableComponentMenu } from '../Angular Material/matTable/matTable.menu'
import { elementCodeDialog } from '../Dialogs/Dialog Element Code/elementCodeDialog'
import { DataService } from '../data.service'
import { ElementService } from '../element.service'
import { CodeGenerationService } from '../code-generation/code-generation.service'
import { KeycloakService } from 'keycloak-angular'
import { AutomationsService } from '../automations/automations.service'
import { IProperty } from '../projects'

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements AfterViewInit {
	// isElectron = this.dataService._ipc.isElectron
	isElectron = false

	@ViewChild('viewContainerAnker', { read: ViewContainerRef })
	viewContainerAnker: ViewContainerRef

	constructor(
		public readonly dataService: DataService,
		public readonly elementService: ElementService,
		public readonly changeDetector: ChangeDetectorRef,
		public readonly renderer2: Renderer2,
		public readonly iconRegistry: MatIconRegistry,
		public readonly sanitizer: DomSanitizer,
		private readonly translateService: TranslateService,
		public readonly codegenerationService: CodeGenerationService,
		public readonly keycloakService: KeycloakService,
		public readonly automationsService: AutomationsService
	) {

		elementService.dataService = dataService
		elementService.renderer2 = renderer2
		elementService.changeDetector = changeDetector
		elementService.appComponent = this

		dataService.elementService = elementService
		dataService.appComponent = this

		elementCodeDialog.elementServiceLoc = elementService

		// Translation stuff
		this.translateService.setDefaultLang('en')
		this.translateService.use(localStorage.getItem('language') || 'en')
		this.setAlignmentsTranslation()

		iconRegistry.addSvgIcon(
			'center',
			sanitizer.bypassSecurityTrustResourceUrl(
				'../assets/img/add_black_24dp.svg'
			)
		)
		iconRegistry.addSvgIconSet(
			sanitizer.bypassSecurityTrustResourceUrl(
				'../assets/images/icons/sets/admin-client-icon-set.svg'
			)
		)
	}

	ngAfterViewInit(): void {

		this.dataService.initApp()

		this.changeDetector.detectChanges()
	}

  private _globalStyle: string | undefined
  public get globalStyle(): string | undefined {

    let stringValue = this.elementService.getProp('globalStyle')

    this._globalStyle = stringValue == "" ? undefined : stringValue

    return this._globalStyle
  }
  public set globalStyle(value: string | undefined) {

    if(!value) return

    this.elementService.setProp(
      {key: 'globalStyle', value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
    this._globalStyle = value

		this.elementService.checkIfThisStyleExistsThenApply(value)
  }

	/*
	██████╗ ███████╗ ██████╗ ██╗███████╗████████╗███████╗██████╗     ██╗  ██╗███████╗██╗   ██╗    ███████╗████████╗██████╗  ██████╗ ██╗  ██╗███████╗
	██╔══██╗██╔════╝██╔════╝ ██║██╔════╝╚══██╔══╝██╔════╝██╔══██╗    ██║ ██╔╝██╔════╝╚██╗ ██╔╝    ██╔════╝╚══██╔══╝██╔══██╗██╔═══██╗██║ ██╔╝██╔════╝
	██████╔╝█████╗  ██║  ███╗██║███████╗   ██║   █████╗  ██████╔╝    █████╔╝ █████╗   ╚████╔╝     ███████╗   ██║   ██████╔╝██║   ██║█████╔╝ █████╗
	██╔══██╗██╔══╝  ██║   ██║██║╚════██║   ██║   ██╔══╝  ██╔══██╗    ██╔═██╗ ██╔══╝    ╚██╔╝      ╚════██║   ██║   ██╔══██╗██║   ██║██╔═██╗ ██╔══╝
	██║  ██║███████╗╚██████╔╝██║███████║   ██║   ███████╗██║  ██║    ██║  ██╗███████╗   ██║       ███████║   ██║   ██║  ██║╚██████╔╝██║  ██╗███████╗
	╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝    ╚═╝  ╚═╝╚══════╝   ╚═╝       ╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

	*/
	// The root node may not be deleted
	@HostListener('document:keyup', ['$event']) handkeKey(event: KeyboardEvent) {
		let element = event.target

		if (event.key === 'Delete' && this.noRootAndTextFieldFocussed()) {

			this.elementService.removeAllSelectedElements()
		}

		// See if the user pressed ctrl + c
		if ((event.ctrlKey || event.metaKey) && event.key === 'c' && this.noTextFieldFocussed()
		) {

			this.elementService.copySelectedElements()
		}

		if ((event.ctrlKey || event.metaKey) && event.key === 'v' && this.noTextFieldFocussed()
		) {

			console.log('paste elements')
			this.elementService.pasteElements()
		}
	}

	noRootAndTextFieldFocussed(): boolean {

		return	!this.elementService.rootObjectSelected() && this.noTextFieldFocussed()
	}

	noTextFieldFocussed(): boolean {

		return	!this.elementService.textFieldFocussed &&
						!ElementService.monacoEditor?.hasTextFocus() && !ElementService.monacoEditor2?.hasTextFocus() &&
						!this.elementService.selectedObjects[0].element?.isFocused && // xSpreadsheet, jodit
						!this.elementCodeDialog // Not if there is a code dialog open
	}

	/*
██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ███████╗    ██╗   ██╗██╗    ███████╗████████╗██╗   ██╗███████╗███████╗
██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██╔════╝    ██║   ██║██║    ██╔════╝╚══██╔══╝██║   ██║██╔════╝██╔════╝
███████║███████║██╔██╗ ██║██║  ██║██║     █████╗      ██║   ██║██║    ███████╗   ██║   ██║   ██║█████╗  █████╗
██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██╔══╝      ██║   ██║██║    ╚════██║   ██║   ██║   ██║██╔══╝  ██╔══╝
██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗███████╗    ╚██████╔╝██║    ███████║   ██║   ╚██████╔╝██║     ██║
╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚══════╝     ╚═════╝ ╚═╝    ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝

*/
	deleteAllButtonClicked() {
		this.dataService.resetApp();
	}

	public toggleLiveMode() {
		this.elementService.rootNode.nativeElement.click()
		this.elementService.isLiveMode = !this.elementService.isLiveMode
	}

	showCodeDialog = false
	elementCodeDialog = false
	generatedHTML = ''
	generatedTS = ''
	generatedCSS = ''

	public async downloadCode() {

		await this.codegenerationService.generateCode()
	}

	public toggleProjectsDialog() {

		this.dataService.projectsService.isProjectsDialogOpen = true
	}

	showGeneralApiCallDialog = false
	openGeneralApiCallDialog() {
		this.elementService.rootNode.nativeElement.click()
		this.showGeneralApiCallDialog = true
	}

	openAutomationsDialog() {

		this.elementService.rootNode.nativeElement.click()
		this.automationsService.showAutomationsDialog = true
	}

	openAutomationDialog() {

		this.elementService.rootNode.nativeElement.click()
		this.automationsService.showAutomationDialog = true
	}

	showFileDialog = false
	public toggleFileDialog() {
		this.showFileDialog = !this.showFileDialog
	}

	showLoadingDialog = false
	showImportOptionsDialog = false

	public toggleLoadingDialog() {
		this.showLoadingDialog = !this.showLoadingDialog
	}

	switchCenter() {
		let isActive = this.elementService.hasProp('transform')

		if (isActive) {
			this.elementService.removeProp('transform')
		} else {
			this.setProp('left', '50', '%', true)
			this.setProp('top', '50', '%', true)
			this.elementService.removeProp('right')
			this.elementService.removeProp('bottom')
			this.setProp('transform', 'translate(-50%, -50%)', '', true)
		}
	}

	public get currentProjectName() {
		return this.dataService.projectsService.currentProject?.name ?? '';
	}

	public onProjectsDialogClosed(): void {
		this.dataService.projectsService.isProjectsDialogOpen = false;
		this.dataService.elementService.changeDetector.detectChanges();
	}

	/*
██████╗ ██████╗  ██████╗ ██████╗ ███████╗██████╗ ████████╗██╗███████╗███████╗
██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔════╝██╔════╝
██████╔╝██████╔╝██║   ██║██████╔╝█████╗  ██████╔╝   ██║   ██║█████╗  ███████╗
██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗   ██║   ██║██╔══╝  ╚════██║
██║     ██║  ██║╚██████╔╝██║     ███████╗██║  ██║   ██║   ██║███████╗███████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

*/
	getProp(key: string) {
		return this.elementService.getProp(key)
	}

	getClassesWithout(notProperty?: string) {
		if (!this.elementService.selectedObjects[0]) return ''
		return this.elementService.selectedObjects[0].getClassesWithout(notProperty)
	}

	setProp(
		key: string,
		value: string,
		unit?: string,
		renderOnlyOuter?: boolean,
		isHelper?: boolean
	) {
		this.elementService.setProp({
			key: key,
			value: value,
			second: unit ? unit : '',
			renderOnlyOuter: renderOnlyOuter ? renderOnlyOuter : false,
			isTailwind: false,
			isHelper: isHelper ? isHelper : false,
		})
	}

	/*
████████╗██████╗  █████╗ ███╗   ██╗███████╗██╗      █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██║     ██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
   ██║   ██████╔╝███████║██╔██╗ ██║███████╗██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
   ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
   ██║   ██║  ██║██║  ██║██║ ╚████║███████║███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

*/
	setLanguage(language: string) {
		localStorage.setItem('language', language)
		this.translateService.use(language)

		this.setAlignmentsTranslation()
	}

	getLanguage() {
		return localStorage.getItem('language') || 'en'
	}

	setAlignmentsTranslation() {
		this.translateService
			.getTranslation(this.translateService.currentLang)
			.pipe(take(1))
			.subscribe((transAsJson) => {
				let allKeysAsString = transAsJson
				this.dataService.alignItemsTranslate =
					allKeysAsString?.rightMenu?.alignItems
				this.dataService.justifyContentTranslate =
					allKeysAsString?.rightMenu?.justifyContent
			})
	}
}
