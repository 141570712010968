<ng-container *ngIf="defaultPage; then thenBlock else elseBlock"></ng-container>

<ng-template #thenBlock>
</ng-template>

<ng-template #elseBlock>
  <div style="background-color: red; color: white; font-size: 22px; font-weight: bold; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
    <span>Angular Router Outlet</span>
  </div>
</ng-template>
