<!-- HTML VIEWER -->
<div [innerHTML]="safeHtml" [style]="'max-height: 450px; border: '+highlightColor+' 1px solid; overflow: auto;'" (click)="handleClickInHtmlResponse($event)"></div>

<!-- SELECT THE DATA & TABLE PREVIEW -->
<div style="margin: 10px 0px;">
  <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
    <tr>
      <td *ngFor="let column of data; let i = index" style="border: 1px solid black;">{{column}}</td>
    </tr>
  </table>
</div>
<!-- End select data -->
