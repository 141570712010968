import { Component, EventEmitter, Output } from '@angular/core';

import { ElementService } from '../../../element.service';
import { AutomationsService } from '../../../automations';

@Component({
  selector: 'add-trigger-dialog',
  templateUrl: './add-trigger-dialog.component.html',
  styleUrls: ['./add-trigger-dialog.component.scss'],
})
export class AddTriggerDialogComponent {
  public columns: string[] = ['name', 'trigger'];

  @Output()
  public closed: EventEmitter<AddTriggerDialogComponent> = new EventEmitter<AddTriggerDialogComponent>();

  public constructor(public elementService: ElementService, public automationsService: AutomationsService) {
  }

  public ngOnInit(): void {
  }

  public getTypes() {
    return Array.from(this.automationsService.triggerMap.keys());
  }

  public createTrigger(type: string) {
    this.automationsService.createTrigger(this.automationsService.currentLayout, this.automationsService.currentAutomation, type);
    this.emitDataAndClose();
  }

  public getName(type: string) {
    return type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  public emitDataAndClose() {
		this.automationsService.showAddTriggerDialog = false;
    this.closed.emit(this);
  }
}
