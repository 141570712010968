<div [ngStyle]="{'display': elementService.allOfType('matTable') ? 'inline': 'none'}">

  <h2 translate>menuInputLabels.tableMenu</h2>

  <!--  BORDER  -->
  <a translate>rightMenu.outerBorder</a>
  <app-menu-border></app-menu-border>

<!--  <tailwind-border-menu></tailwind-border-menu>-->

  <!-- logic: The (change) event is fired after the toggle is already changed
  Example: It is currently checked and the user clicks on it, then is is not checked anymore
  Then the (change) event fires and sees that itself is currently NOT CHECKED
  and calls the setProp() accordingly. -->
  <p [style]="'width: 100%; margin-left: 10px;'">
    <mat-slide-toggle
      #thisToggle1
      (change)="setHorizontalBorder(thisToggle1.checked?'1':'0')"
      [checked]="getProp('horizontalBorder')=='1'"
    >
      <span translate>rightMenu.horizontalLines</span>
    </mat-slide-toggle>
  </p>

  <p [style]="'width: 100%; margin-left: 10px;'">
    <mat-slide-toggle
      #thisToggle2
      (change)="setVerticalBorder(thisToggle2.checked?'1':'0')"
      [checked]="getProp('verticalBorder')=='1'"
    >
      <span translate>rightMenu.verticalLines</span>
    </mat-slide-toggle>
  </p>

  <!-- EXISTING API Call -->
  <a mat-raised-button color="primary" (click)="showGeneralApiDialog = true" style="margin-left: 5px">
		<span class="mat-button-span">bestehender API Call</span>
	</a>
  <generalApiDialog *ngIf="showGeneralApiDialog" [elementType]="getType()"
                  (closed)="closeGeneralApiCallDialog($event)"></generalApiDialog>

  <!-- OWN API Call -->
  <a mat-raised-button color="primary" (click)="showApiCallDialog = true" style="margin-left: 5px">
		<span class="mat-button-span">eigener API Call</span>
	</a>
  <apiCallDialog *ngIf="showApiCallDialog"
                  [elementType]="getType()"
                  (returnColumns)="columnsArrayFromApiCall = $event"
                  (returnData)="dataArrayFromApiCall = $event"
                  (closed)="closeApiCallDialog($event)"></apiCallDialog>

  <!-- COLUMN MENU -->
  <div [ngStyle]="{'display': !this.getProp('selectedColumn') ? 'none': 'inline'}">

      <div style="display: flex; justify-content: space-between;"> <!-- HorizontalMenu -->
        <h2>{{'rightMenu.columnMenu' | translate}} {{this.getProp('selectedColumn')}}:</h2>

        <!-- DELETE BUTTON -->
        <div *ngIf="getProp('columns') !== '1'" style="margin: 10px" (click)="deleteColumn(this.getProp('selectedColumn'))">
          <button style="width: 40px;" mat-mini-fab color="warn"><mat-icon>delete</mat-icon></button>
        </div>
        <div *ngIf="getProp('columns') === '1'" style="margin: 10px">
          <button style="width: 40px;" mat-mini-fab disabled><mat-icon>delete</mat-icon></button>
        </div>
      </div>

      <!-- Name Field -->
      <mat-form-field appearance="fill" [style]="'width: 100%;'">
        <mat-label translate>rightMenu.fieldName</mat-label>
        <input
        matInput
        type="text"
        [(ngModel)]="inputValue"
        (focus)="elementService.tfFocussed(true)"
        (focusout)="elementService.tfFocussed(false)"
        >
      </mat-form-field>

      <!-- H4x0r Field -->
      <h2 translate title="In der Variable 'cell' steht der Zelleninhalt und in 'result' muss das Ergebnis rein.">rightMenu.customCode</h2>
      <div class="editor-tooltip"></div>
      <div *ngIf="elementService.allOfType('matTable')">
        <ngx-monaco-editor
          id="monacoEditor"
          *ngIf="elementService.selectedObjects[0] as selected"
          class="editor-container"
          [options]="editorOptions"
          [(ngModel)]="editorCode"
          (onInit)="onInit($event, selected)"
        >
        </ngx-monaco-editor>
      </div>

      <!-- PIPES -->
      <h2 translate>rightMenu.formats</h2>
      <div style="display: flex; border: 1px solid black; border-radius: 0.25rem; margin-bottom: 10px;"> <!-- HorizontalMenu -->

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnFormat'+this.getProp('selectedColumn'))=='currency_de'"
          (click)="setFormat(this.getProp('selectedColumn'), 'currency_de')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15 18.5c-2.5 0-4.68-1.42-5.76-3.5H15l1-2H8.58c-.05-.33-.08-.66-.08-1s.03-.67.08-1H15l1-2H9.24A6.491 6.491 0 0 1 15 5.5c1.61 0 3.09.59 4.23 1.57L21 5.3A8.955 8.955 0 0 0 15 3c-3.92 0-7.24 2.5-8.5 6H3l-1 2h4.06c-.06.33-.06.66-.06 1s0 .67.06 1H3l-1 2h4.5c1.26 3.5 4.58 6 8.5 6c2.31 0 4.41-.87 6-2.3l-1.78-1.77c-1.13.98-2.6 1.57-4.22 1.57Z"/></svg>
        </div>

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnFormat'+this.getProp('selectedColumn'))=='number_de'"
          (click)="setFormat(this.getProp('selectedColumn'), 'number_de')">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" style="padding-top: 2px;"><path fill="currentColor" d="M9 4.75A.75.75 0 0 1 9.75 4h4a.75.75 0 0 1 .53 1.28l-1.89 1.892c.312.076.604.18.867.319c.742.391 1.244 1.063 1.244 2.005c0 .653-.231 1.208-.629 1.627c-.386.408-.894.653-1.408.777c-1.01.243-2.225.063-3.124-.527a.751.751 0 0 1 .822-1.254c.534.35 1.32.474 1.951.322c.306-.073.53-.201.67-.349c.129-.136.218-.32.218-.596c0-.308-.123-.509-.444-.678c-.373-.197-.98-.318-1.806-.318a.75.75 0 0 1-.53-1.28l1.72-1.72H9.75A.75.75 0 0 1 9 4.75Zm-3.587 5.763c-.35-.05-.77.113-.983.572a.75.75 0 1 1-1.36-.632c.508-1.094 1.589-1.565 2.558-1.425c1 .145 1.872.945 1.872 2.222c0 1.433-1.088 2.192-1.79 2.681c-.308.216-.571.397-.772.573H7a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75c0-.69.3-1.211.67-1.61c.348-.372.8-.676 1.15-.92c.8-.56 1.18-.904 1.18-1.474c0-.473-.267-.69-.587-.737ZM5.604.089A.75.75 0 0 1 6 .75v4.77h.711a.75.75 0 0 1 0 1.5H3.759a.75.75 0 0 1 0-1.5H4.5V2.15l-.334.223a.75.75 0 0 1-.832-1.248l1.5-1a.75.75 0 0 1 .77-.037Z"/></svg>
        </div>

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnFormat'+this.getProp('selectedColumn'))=='date_de'"
          (click)="setFormat(this.getProp('selectedColumn'), 'date_de')">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" style="padding-top: 2px;"><path fill="currentColor" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699ZM1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756L1.4 7.742Zm5.267 6.877v1.666H5v-1.666h1.667Zm4.166 0v1.666H9.167v-1.666h1.666Zm4.167 0v1.666h-1.667v-1.666H15Zm-8.333-3.977v1.666H5v-1.666h1.667Zm4.166 0v1.666H9.167v-1.666h1.666Zm4.167 0v1.666h-1.667v-1.666H15ZM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0v-.92Z"/></svg>
        </div>

      </div>

      <!-- TEXT ALIGNMENT -->
      <h2 translate>rightMenu.textAlignment</h2>
      <div style="display: flex; border: 1px solid black; border-radius: 0.25rem;"> <!-- HorizontalMenu -->

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnAlign'+this.getProp('selectedColumn'))=='left'"
          (click)="setAlign(this.getProp('selectedColumn'), 'left')">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M4 19h16v2H4zm0-4h11v2H4zm0-4h16v2H4zm0-8h16v2H4zm0 4h11v2H4z"/></svg>
        </div>

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnAlign'+this.getProp('selectedColumn'))=='center'"
          (click)="setAlign(this.getProp('selectedColumn'), 'center')">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M4 19h16v2H4zm3-4h10v2H7zm-3-4h16v2H4zm0-8h16v2H4zm3 4h10v2H7z"/></svg>
        </div>

        <div class="menuSymbolButton"
          [class.activemenuSymbolButton]="getProp('columnAlign'+this.getProp('selectedColumn'))=='right'"
          (click)="setAlign(this.getProp('selectedColumn'), 'right')">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M4 19h16v2H4zm5-4h11v2H9zm-5-4h16v2H4zm0-8h16v2H4zm5 4h11v2H9z"/></svg>
        </div>

      </div>

      <!-- Footer -->
      <p [style]="'width: 100%; margin-left: 10px; margin-top: 10px;'">
        <mat-slide-toggle
          #thisToggle3
          (change)="setFooter(thisToggle3.checked?'1':'0')"
          [checked]="getProp('footer')=='1'"
        >
          <span translate>rightMenu.footer</span>
        </mat-slide-toggle>
      </p>

      <!-- Footer type -->
      <!-- selectedColumn is a number as a string -->
      <mat-form-field appearance="fill" style="width: 100%; margin-top: 10px;">
        <mat-label translate>rightMenu.footerType</mat-label>
        <mat-select #footerTypeSelect
          (selectionChange)="setFooterType(footerTypeSelect.value)"
          [value]="getProp('footerType' + this.getProp('selectedColumn'))"
        >
          <mat-option value="nothing">Nichts</mat-option>
          <mat-option value="sum">Spaltensumme</mat-option>
          <mat-option value="count">Anzahl der Zeilen</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <a
      mat-raised-button
      color="primary"
      (click)="editTableDialog = true"
      style="margin-top: 22px; margin-bottom: 10px"
    >
      <span class="mat-button-span">Tabelle Bearbeiten</span>
    </a>
</div>

<edit-table-dialog *ngIf="editTableDialog" (closed)="closeEditTableDialog()"></edit-table-dialog>
