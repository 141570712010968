<action style="width: 100%;" [name]="'Call API'" [action]="action">

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Url</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Url</mat-label>
      <input matInput type="text" [(ngModel)]="url">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Method</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Method</mat-label>
      <input matInput type="text" [(ngModel)]="method">
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Body</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Body</mat-label>
      <textarea matInput type="text" [(ngModel)]="body" style="height: 400px;"></textarea>
    </mat-form-field>
  </div>

  <div class="element-selection-area" *ngIf="action">
    <div class="leftElementSelectionArea">
      <pre><span style="font-size: 14pt;"><strong>Headers</strong></span></pre>
    </div>
    <mat-form-field appearance="fill" id="matSelect" class="matSelect">
      <mat-label>Headers</mat-label>
      <textarea matInput type="text" [(ngModel)]="headers" style="height: 400px;"></textarea>
    </mat-form-field>
  </div>

</action>
