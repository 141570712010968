import { Pipe, PipeTransform } from '@angular/core';

import { Project, ProjectsService } from '../projects';

@Pipe({
  name: 'projectFilter',
  pure: false
})
export class ProjectFilterPipe implements PipeTransform {
  public constructor(private readonly projectsService: ProjectsService) {
  }

  public transform(items: Project[]): Project[] {
    if (!items) { return [] };
    return items.filter((project) => project.id !== this.projectsService.currentProject?.id);
  }
}
