import { Component } from '@angular/core';
import { menu } from '../../menus/menu/menu';

var name = "matSlideToggle"

@Component({
  selector: name+'-menu',
  templateUrl: 'menu.html'
})
export class MatSlideToggleMenu extends menu {

  name = name

  isHelper = true

}
