import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-select',
  templateUrl: './matSelect.component.html'
})
export class SelectComponent extends LayoutComponent {
  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService) {
    super(elementService, renderer, dataService);
    this.componentType = 'SelectComponent';
  }

	setInitialProps(){

    if(this.getProp("Label") == "")	this.setProp({key:"Label", value: "Neues Auswahlfeld", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Erscheinungsbild") == "")	this.setProp({key:"Erscheinungsbild", value: "fill", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Menge") == "")	this.setProp({key:"Menge", value: "2", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Label1") == "")	this.setProp({key:"Label1", value: "Heute", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    if(this.getProp("Label2") == "")	this.setProp({key:"Label2", value: "Gestern", second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})

    this.elementService.changeDetector.detectChanges()
  }

  amounts = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
  ]

  getAmount(){

    return this.amounts.slice(0, Number(this.getProp('Menge')))
  }

  getAppearance(){
    let appearance: MatFormFieldAppearance = "fill"

    switch (this.getProp("Erscheinungsbild")) {
      case "fill":
        appearance = "fill"
        break
      case "outline":
        appearance = "outline"
        break;
    }

    return appearance
  }

  // =================
  // TEMPLATE STRING
  // =================
  public getTemplateString(tabs: string): string {

    let attrMap: Map<string, string> = new Map<string, string>()
    let children = []
    let childrenTabs = "\t"+tabs

    /*
      <mat-form-field [appearance]="getAppearance()">
        <mat-label>{{getProp('Label')}}</mat-label>
        <mat-select [disabled]="getProp('disabled')=='1'" #thisSelect>

          <mat-select-trigger>
            <mat-icon *ngIf="getProp('Icon'+thisSelect.value) != '' && getProp('iconRight')!='1'">{{getProp('Icon'+thisSelect.value)}}</mat-icon>
            {{getProp('Label'+thisSelect.value)}}
            <mat-icon *ngIf="getProp('Icon'+thisSelect.value) != '' && getProp('iconRight')=='1'">{{getProp('Icon'+thisSelect.value)}}</mat-icon>
          </mat-select-trigger>

          <mat-option *ngFor="let i of getAmount()" [value]="i">
            <mat-icon *ngIf="getProp('Icon'+i) != '' && getProp('iconRight')!='1'">{{getProp('Icon'+i)}}</mat-icon>
            {{getProp("Label"+i)}}
            <mat-icon *ngIf="getProp('Icon'+i) != '' && getProp('iconRight')=='1'">{{getProp('Icon'+i)}}</mat-icon>
          </mat-option>

        </mat-select>
      </mat-form-field>
    */
    // <mat-label>{{getProp('Label')}}</mat-label>
    children.push(this.buildLeafTag(childrenTabs, "mat-label", undefined, this.getProp('Label')))

    let childrenL2: any[] = []
    let childrenL2Tabs = "\t"+childrenTabs

    // <mat-select-trigger>
      // <mat-icon *ngIf="thisSelect.value.split(';')[1] != ''">{{thisSelect.value.split(';')[1]}}</mat-icon>
      // {{thisSelect.value.split(';')[0]}}
      // <mat-icon *ngIf="thisSelect.value.split(';')[1] != ''">{{thisSelect.value.split(';')[1]}}</mat-icon>

        attrMap.clear()
        let childrenL3Tabs = "\t"+childrenL2Tabs

        let childrenL3Left: any[] = []

        let iconPart = "thisSelect.value?.split(';')[1]"
        let iconName = "{{"+iconPart+"}}"
        let ngIfString = iconPart+" != ''"
        let textName = "{{thisSelect.value?.split(';')[0]}}"

        if(this.getProp('iconRight')!='1'){

          attrMap.set("*ngIf", ngIfString)
          childrenL3Left.push(this.buildLeafTag(childrenL3Tabs, "mat-icon", attrMap, iconName))
        }

        let childrenL3Right: any[] = []
        if(this.getProp('iconRight')=='1'){

          attrMap.set("*ngIf", ngIfString)
          childrenL3Right.push(this.buildLeafTag(childrenL3Tabs, "mat-icon", attrMap, iconName))
        }

        childrenL2.push(this.buildTagRightChildren(childrenL2Tabs, "mat-select-trigger", undefined, childrenL3Left, textName, childrenL3Right))

    // </mat-select-trigger>

    // <mat-option *ngFor="let i of getAmount()" [value]="i">
        this.getAmount().forEach((i) => {

          attrMap.clear()
          let childrenL3Tabs = "\t"+childrenL2Tabs

          let childrenL3Left: any[] = []

          if(this.getProp('Icon'+i) != '' && this.getProp('iconRight')!='1'){

            childrenL3Left.push(this.buildLeafTag(childrenL3Tabs, "mat-icon", undefined, this.getProp("Icon"+i)))
          }

          let childrenL3Right: any[] = []
          if(this.getProp('Icon'+i) != '' && this.getProp('iconRight')=='1'){

            childrenL3Right.push(this.buildLeafTag(childrenL3Tabs, "mat-icon", undefined, this.getProp("Icon"+i)))
          }

          attrMap.set("value", this.getProp("Label"+i)+";"+this.getProp('Icon'+i))
          childrenL2.push(this.buildTagRightChildren(childrenL2Tabs, "mat-option", attrMap, childrenL3Left, this.getProp("Label"+i), childrenL3Right))
        })
    // </mat-option>

    // <select matNativeControl [disabled]="getProp('disabled')=='1'">
                                            attrMap.clear()
    if(this.getProp('disabled')=='1')       attrMap.set("disabled", "")
    if(this.getProp("ngModel") != "")       attrMap.set("[(ngModel)]", this.getProp("ngModel"))
    children.push(this.buildNormalTagWithHashTag(childrenTabs, "mat-select", attrMap, childrenL2, "", "thisSelect"))

    // END CHILDREN
                                                attrMap.clear()
                                                attrMap.set("appearance", this.getAppearance())
                                                attrMap.set("id", this.getProp("displayName"))
    if(this.getClassesWithout() != "")          attrMap.set("class", this.getClassesWithout())
    if(this.getStyleAttributesForInner() != "")     attrMap.set("class", this.getProp('displayName'))

    let result = this.buildNormalTag(tabs, "mat-form-field", attrMap, children, "")
    return result
  }
}
