<div *ngIf="elementService?.allOfType('sf-kanban')">

  <!-- OWN API Call -->
  <a mat-raised-button color="primary" (click)="showApiCallDialog = true" style="margin-bottom: 22px">
    <span class="mat-button-span">Eigener API Call</span>
  </a>
  <apiCallDialog *ngIf="showApiCallDialog"
                  [elementType]="getType()"
                  (returnColumns)="columnsArrayFromApiCall = $event"
                  (returnData)="dataArrayFromApiCall = $event"
                  (closed)="closeApiCallDialog($event)"></apiCallDialog>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.dataSource</mat-label>
    <textarea style="height: 200px;"
      #dataSource
      type="text"
      matInput
      [value]="getProp('dataSource')"
      (input)="setDataSource(dataSource.value)"
    ></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.keyField</mat-label>
    <input
      #keyField
      type="text"
      matInput
      [value]="getProp('keyField')"
      (input)="setKeyField(keyField.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.keyFields</mat-label>
    <input
      #keyFields
      type="text"
      matInput
      [value]="getProp('keyFields')"
      (input)="setKeyFields(keyFields.value)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.headerTexts</mat-label>
    <input
      #headerTexts
      type="text"
      matInput
      [value]="getProp('headerTexts')"
      (input)="setHeaderTexts(headerTexts.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.swimlaneSettingsKeyField</mat-label>
    <input
      #swimlaneSettingsKeyField
      type="text"
      matInput
      [value]="getProp('swimlaneSettingsKeyField')"
      (input)="setSwimlaneSettingsKeyField(swimlaneSettingsKeyField.value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.headerField</mat-label>
    <input
      #headerField
      type="text"
      matInput
      [value]="getProp('headerField')"
      (input)="setHeaderField(headerField.value)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.headerTextField</mat-label>
    <input
      #headerTextField
      type="text"
      matInput
      [value]="getProp('headerTextField')"
      (input)="setHeaderTextField(headerTextField.value)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" [style]="'width: 100%;'">
    <mat-label translate>rightMenu.contentField</mat-label>
    <input
      #contentField
      type="text"
      matInput
      [value]="getProp('contentField')"
      (input)="setContentField(contentField.value)"
    />
  </mat-form-field>

  <div style="display: flex; align-items: center; margin-bottom: 22px;">
    <input
      #swimlaneSettingsAllowDragAndDrop
      type="checkbox"
      [checked]="getProp('swimlaneSettingsAllowDragAndDrop') === 'true' ? true : false"
      (change)="setSwimlaneSettingsAllowDragAndDrop(swimlaneSettingsAllowDragAndDrop.checked)"
    />
    <label for="swimlaneSettingsAllowDragAndDrop" translate>rightMenu.swimlaneSettingsAllowDragAndDrop</label>
  </div>

  <div *ngFor="let column of columns; let colIndex = index" style="margin-bottom: 22px;">
    <mat-form-field appearance="fill" style="width: 100%;" subscriptSizing="dynamic">
      <mat-label>
        <span translate>rightMenu.transitionColumns</span>
        <span> {{colIndex}}</span>
      </mat-label>
      <input
        #transitionColumns
        type="text"
        matInput
        [value]="getProp('transitionColumns' + colIndex)"
        (input)="setTransitionColumns(colIndex, 'transitionColumns' + colIndex, transitionColumns.value)"
      />
    </mat-form-field>

    <div style="display: flex; align-items: center;">
      <input
        #swimlaneSettingsAllowDrag
        type="checkbox"
        [checked]="getProp('swimlaneSettingsAllowDrag' + colIndex) === 'true' ? true : false"
        (change)="setSwimlaneSettingsAllowDrag(colIndex, 'swimlaneSettingsAllowDrag' + colIndex, swimlaneSettingsAllowDrag.checked)"
      />
      <label for="swimlaneSettingsAllowDrop">
        <span translate>rightMenu.swimlaneSettingsAllowDrag</span>
        <span>{{colIndex}}</span>
      </label>
    </div>

    <div style="display: flex; align-items: center;">
      <input
        #swimlaneSettingsAllowDrop
        type="checkbox"
        [checked]="getProp('swimlaneSettingsAllowDrop' + colIndex) === 'true' ? true : false"
        (change)="setSwimlaneSettingsAllowDrop(colIndex, 'swimlaneSettingsAllowDrop' + colIndex, swimlaneSettingsAllowDrop.checked)"
      />
      <label for="swimlaneSettingsAllowDrop">
        <span translate>rightMenu.swimlaneSettingsAllowDrop</span>
        <span>{{colIndex}}</span>
      </label>
    </div>
  </div>

</div>
