<mat-form-field appearance="fill" [style]="'width: '+ width +';'">
  <mat-label translate>rightMenu.border</mat-label>
  <mat-select #borderType
              (selectionChange)="setProp('border-style', borderType.value)"
              [value]="getProp('border-style')"
  >
    <mat-option value="solid"><span translate>borderType.solid</span></mat-option>
    <mat-option value="dashed"><span translate>borderType.dashed</span></mat-option>
    <mat-option value="dotted"><span translate>borderType.dotted</span></mat-option>
    <mat-option value="double"><span translate>borderType.double</span></mat-option>
    <mat-option value="groove"><span translate>borderType.groove</span></mat-option>
    <mat-option value="ridge"><span translate>borderType.ridge</span></mat-option>
    <mat-option value="inset"><span translate>borderType.inset</span></mat-option>
    <mat-option value="outset"><span translate>borderType.outset</span></mat-option>
    <mat-option value="none"><span translate>borderType.none</span></mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="getProp('border-style') !== 'none' && getProp('border-style')">
  <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
    <mat-label translate>rightMenu.borderArea</mat-label>
    <mat-select #borderArea
                (selectionChange)="changeBorderArea(borderArea.value)"
                [value]="getProp('border-width-area')"
    >
      <mat-option value="trlb"><span translate>borderArea.around</span></mat-option>
      <mat-option value="rl"><span translate>borderArea.leftRight</span></mat-option>
      <mat-option value="tb"><span translate>borderArea.topBottom</span></mat-option>
      <mat-option value="t"><span translate>borderArea.top</span></mat-option>
      <mat-option value="r"><span translate>borderArea.right</span></mat-option>
      <mat-option value="b"><span translate>borderArea.bottom</span></mat-option>
      <mat-option value="l"><span translate>borderArea.left</span></mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
    <mat-label translate>rightMenu.borderWidth</mat-label>
    <input
      #borderWidth
      type="number"
      matInput
      [value]="getProp('border-single-width')"
      (input)="changeBorderWidth(borderWidth.value)">
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
    <mat-label translate>rightMenu.borderRadius</mat-label>
    <input
      #borderRadius
      type="number"
      matInput
      [value]="getProp('border-radius')"
      (input)="setProp('border-radius', borderRadius.value, 'px')">
  </mat-form-field>

  <mat-form-field appearance="fill" [style]="'width: '+ width +';'">
    <mat-label translate>rightMenu.borderColor</mat-label>
    <input
      #borderColor
      type="color"
      matInput
      [value]="getSetPropertyValue()"
      (input)="setProp('border-color', borderColor.value)">
  </mat-form-field>
</ng-container>
