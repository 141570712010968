import { AfterViewInit, Component, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

import { ElementService } from '../element.service';
import { DataService } from '../data.service';
import { TreeSearchService } from '../tree-search.service';
import { LayoutComponent } from '../layout/layout.component';
import { ProjectsService, SaveLayout } from '../projects';

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   ROUTER OUTLET PLACEHOLDER COMPONENT               |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS COMPONENT IS TO             |
					 |   * STORE ROUTING INFORMATION FOR THE               |
           |   * GENERATION OF THE ROUTER.                       |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Component({
  selector: 'app-router-outlet-placeholder',
  templateUrl: './router-outlet-placeholder.component.html',
  styleUrls: ['./router-outlet-placeholder.component.scss']
})
export class RouterOutletPlaceholderComponent extends LayoutComponent implements AfterViewInit {
  @ViewChild('thenBlock', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;
  
  public selectedPages: string[] = [];
  public defaultPage: string;

  private loadedPage: string | undefined;
  private loadedComponent: LayoutComponent | undefined;

  public constructor(public readonly elementService: ElementService, public readonly renderer: Renderer2, public readonly dataService: DataService, public readonly treeSearchService: TreeSearchService, public readonly projectsService: ProjectsService) {
    super(elementService, renderer, dataService);
    this.componentType = 'RouterOutletPlaceholderComponent';
  }

  public ngAfterViewInit(): void {
    this.viewContainerAnker = this.container;

    if (this.getProp('selectedPages') === '') { this.setProp({ key:'selectedPages', value: '[]', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false }); }
    if (this.getProp('defaultPage') === '') { this.setProp({ key:'defaultPage', value: '', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false }); }

    setTimeout(() => {
      this.selectedPages = JSON.parse(this.getProp('selectedPages'));
      this.defaultPage = this.getProp('defaultPage');

      if (this.isFirstInAppPage()) {
        this.controlAppPage();
      }

      this.loadPage();
    }, 10);
  }

  private isFirstInAppPage(): boolean {
    const appPage = this.projectsService.currentProject!.pages[0];

    let result = false;

    if (this.dataService.currentPage.name === appPage.name) {
      const firstRouterOutletPlaceholder = this.treeSearchService.findFirstRouterOutletPlaceholder(appPage);
      if (firstRouterOutletPlaceholder) {
        result = this.dataService.treeSearchService.getId(firstRouterOutletPlaceholder) === this.getProp('id');
      }
    }

    return result;
  }

  /**
   * ### Control App Page
   * Preselects the pages that are not getting linked from any other page.
   */
  private controlAppPage(): void {
    const previousDefaultPage = this.defaultPage;
    const previousSelectedPages = this.selectedPages;

    this.setProp({ key:'defaultPage', value: '', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.defaultPage = '';

    this.setProp({ key:'selectedPages', value: '[]', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.selectedPages = [];

    for (const page of this.projectsService.currentProject!.pages) {
      if (this.projectsService.currentProject!.pages.indexOf(page) === 0) { continue; }

      const linkingDefaultPages = this.treeSearchService.getLinkingDefaultPages(this.projectsService.currentProject!, page);
      const linkingPages = this.treeSearchService.getLinkingPages(this.projectsService.currentProject!, page);

      const linking = [...linkingDefaultPages, ...linkingPages];
      if (linking.length === 0) {
        this.selectedPages.push(this.dataService.treeSearchService.getId(page));
      }
    }

    for (const previousPage of previousSelectedPages) {
      if (this.selectedPages.includes(previousPage)) { continue; }
      this.selectedPages.push(previousPage);
    }

    this.setProp({ key: 'selectedPages', value: JSON.stringify(this.selectedPages), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.defaultPage = this.selectedPages.includes(previousDefaultPage) ? previousDefaultPage : '';
    this.setProp({ key: 'defaultPage', value: this.defaultPage, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });

    this.elementService.changeDetector.detectChanges();
  }

  public setDefaultPage(value: string): void {
    this.setProp({ key: 'defaultPage', value: value, second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.defaultPage = value;
    this.loadPage();
  }

  public route(index: number): void {
    this.loadedPage = this.dataService.treeSearchService.getId(this.projectsService.currentProject!.pages[index]);
    if (!this.loadedPage) {
      const projectComponentId = this.treeSearchService.getProjectComponentForRouterOutletPlaceholder(this.dataService.projectContext, this.id) ?? '';
      this.loadedPage = this.dataService.treeSearchService.getId(this.dataService.projectContext.projectStateTable.get(projectComponentId)?.pages[index]!);
    }
    this.loadPage();
  }

  /**
   * ### Load Page
   * Loads the default page into the router outlet placeholder in order to create the preview.
   */
  private loadPage(): void {
    if (!this.viewContainerAnker) { return; }
    if (!this.defaultPage) { return; }

    const pageId: string = this.loadedPage ?? this.defaultPage;
    let page = this.projectsService.currentProject!.pages.find((page) => this.dataService.treeSearchService.getId(page) === pageId);
    if (!page) {
      const projectComponentId = this.treeSearchService.getProjectComponentForRouterOutletPlaceholder(this.dataService.projectContext, this.id) ?? '';
      page = this.dataService.projectContext.projectStateTable.get(projectComponentId)?.pages.find((page: SaveLayout) => this.dataService.treeSearchService.getId(page) === pageId);
      if (!page) { return; }
    }

    this.clear();

    if (!this.selectedPages.includes(pageId)) { return; }

    try {
      this.dataService.routerOutletPlaceholdersAreLoaded.set(this.id, false);
      this.loadedComponent = this.dataService.loadElement(this, page, { append: false, isEmbedded: true, prefix: this.getProp('displayName') });
      this.dataService.routerOutletPlaceholdersAreLoaded.set(this.id, true);
    } catch {
      return;
    }
    this.loadedComponent.setEditable(false);

    this.dataService.automationsService.loadAutomationsForLayoutComponent(this.loadedComponent);
		this.dataService.automationsService.reRegisterTriggers(this.loadedComponent);

    this.elementService.changeDetector.detectChanges();
  }

  private clear() {
    if (this.loadedComponent) {
      this.loadedComponent.removeMe();
      this.loadedComponent = undefined;
    }

    this.children.forEach((child: LayoutComponent) => {
      child.removeMe();
    });

    if (this.container) {
      this.container.clear();
    }
  }

  public setSelectedPages(value: string[]): void {
    this.setProp({ key: 'selectedPages', value: JSON.stringify(value), second: '', isHelper: true, isTailwind: false, renderOnlyOuter: false });
    this.selectedPages = value;
  }

  protected beforeRemoveMe(): void {
    this.clear();
    this.loadedPage = undefined;
  }

  public getHtmlTemplate(): string {
    return '<router-outlet></router-outlet>';
  }

  public getCssTemplate(): string {
    return '';
  }

  public getCodeTemplate(): string {
    return '';
  }
}
