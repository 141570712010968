import { WorkBook } from 'xlsx';

import { Action } from '../action';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { SplitAndTrimActionComponent } from '../../Dialogs/Dialog Automations/actions/split-and-trim/split-and-trim.component';
import { ExcelService } from 'src/app/excel.service';

export class SplitAndTrimAction extends Action {

  public constructor() {

			super()
  }

  public uiClass: SplitAndTrimActionComponent;

	public excelService: ExcelService;

	excelData: WorkBook;

	getAllStuff(){

		// Get the UI Element of the trigger of the current automation
		const selectedFileUploadId = this.uiClass.automationsService.currentAutomation.trigger?.saveLayoutId!;
		const selectedFileUpload = this.uiClass.elementService.findElementById(selectedFileUploadId)[0] as FileUploadComponent;
		if(!selectedFileUpload) return console.error('No FileUpload selected. I\'m trying to get a file upload from the trigger')

		// Get the excelData of the UI Element
		// The prop is set in the onSelectFile method of the FileUploadComponent
		const excelDataString = selectedFileUpload.getProp('excelData')
		if(excelDataString == "") return console.error('There is no excelData in the trigger element, have you selected a file?')
		this.excelData = JSON.parse(excelDataString) as WorkBook

		this.getAllSheets()
		this.getAllColumns()
	}


	/*
███████╗██╗  ██╗███████╗███████╗████████╗    ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔════╝██║  ██║██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
███████╗███████║█████╗  █████╗     ██║       ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
╚════██║██╔══██║██╔══╝  ██╔══╝     ██║       ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
███████║██║  ██║███████╗███████╗   ██║       ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝   ╚═╝       ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
	*/
  public sheet: string = '';
	public get selectedSheet(): string {

		return this.sheet
	}

	public set selectedSheet(value: string) {

		this.sheet = value
		this.uiClass.automationsService.saveAutomations(this.uiClass.automationsService.currentLayout)
		this.getAllStuff()
	}

	allSheets: string[] = []
	/**
	 * @returns The list of sheetnames of the excelData of the UI Element of the trigger of the current automation
	 */
	public getAllSheets() {

		// Return the list of sheetnames
		this.allSheets = this.excelData.SheetNames

		console.log('allSheets:', this.allSheets)
	}

	/*
 ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗    ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║    ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║    ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║    ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
 ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝    ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

	*/

  public _column: string = ''
	public get column(): string {

		return this._column
	}

	public set column(value: string) {

		this._column = value
		this.uiClass?.automationsService.saveAutomations(this.uiClass.automationsService.currentLayout)
	}

	allColumns: string[] = []
	/**
	 * @returns The list of sheetnames of the excelData of the UI Element of the trigger of the current automation
	 */
	public getAllColumns() {
		console.log('getAllColumns called')

		const selectedSheet = this.excelData.Sheets[this.selectedSheet]
		if(!selectedSheet) return console.error('No sheet selected. I\'m trying to get all columns from the trigger')
		console.log('This is the selected sheet:', selectedSheet)

		const columns = []
		for (let key in selectedSheet) {

			if (key.length === 2 && key.endsWith('1')) columns.push(key.charAt(0))
		}

		this.allColumns = columns
	}

	private separatorTimeout: NodeJS.Timeout;

  public _separator: string = ''
  public get separator(): string {

		return this._separator
	}
	public set separator(value: string) {

		this._separator = value

		if (this.separatorTimeout) {

			clearTimeout(this.separatorTimeout)
		}
		this.separatorTimeout = setTimeout(() => this.uiClass?.automationsService.saveAutomations(this.uiClass.automationsService.currentLayout), 800)
	}

  public file: any;

	/**
   * Split & Trim ACTION
	 *
	 * Uses the selected sheet and uses the selected column,
	 *
	 * iterates over all rows and splits the value of the selected column by the
	 * separator.
	 *
	 * For each splitting, it creates a new row with the same values as the original
	 * row,
	 *
	 * except for the selected column, which is the splitted value.
	 *
   * Der Sinn und Zweck dieser Action ist es, die in der Variable column
	 * angegebene Spalte zu durchlaufen und alle Zellen, die den in der Variable
	 * separator angegebenen Wert enthalten, zu splitten.
	 *
   * Es muss dabei sichergestellt werden, dass die Zellen, die den Wert nicht
	 * enthalten, nicht verändert werden.
	 *
   * Es muss dabei sichergestellt werden, dass die Zellen, die den Wert enthalten,
	 * in mehrere Zellen aufgeteilt werden, wobei dafür neue Zeilen eingefügt werden
	 * müssen, diese neuen Zeilen müssen dabei die gleichen Werte in den anderen
	 * Spalten enthalten.
	 *
   * Wenn einmal eine Zelle aufgesplittet wurde und danach eine Zelle kommt, die
	 * nicht aufgesplittet werden muss, dann muss die geprüfte Zelle richtig sein
	 * und auch die Daten der anderen Spalten. Die Daten der anderen Spalten müssen
	 * weiterhin aus der korrekten Zeile kommen.
	 *
   * @returns The updated sheet
   */
  public action(): void {

		// All input fields must have a value
    if (!this.selectedSheet) return console.log('No sheet selected')
    if (!this.column) return console.log('No column selected')
    if (!this.separator) return console.log('No separator selected')

    const sheet = this.excelService.excelData.Sheets[this.selectedSheet]
    //console.log(excelData)

    // Store a clone of the sheet
    const sheetClone = { ...sheet }

    if (!sheet) return console.log('Sheet not found')

    // Iterate over all rows of the selected sheet in the selected column
    let rowIndex = 0
    let cloneRowIndex = 1
    while (sheet[this.column + (rowIndex + 1)]) {

      rowIndex++
      let oldCellKey = this.column + rowIndex
      let cloneCellKey = this.column + cloneRowIndex

      // console.log('going over ', oldCellKey)
      const cellContent = sheet[oldCellKey]

      const isString = cellContent.t == 's'
      const containsSeparator = isString && cellContent.v.includes(this.separator)

      if (!isString || !containsSeparator) {

        // Replace the cell in sheetClone
        sheetClone[cloneCellKey] = { ...cellContent }
        // console.log('Writing from '+oldCellKey+' to new '+cloneCellKey+':', sheetClone[cloneCellKey].v)

        // Copy the rest of the row
        this.allColumns.forEach((column: string) => {

          if (column == this.column) return
          const sourceCellKey = column + rowIndex
          const targetCellKey = column + cloneRowIndex

          // Copy the value from the original cell to the new cell
          // if (column == "B") console.log('Copying value from old', sourceCellKey, 'to new', targetCellKey, "value is", sheet[sourceCellKey].v)
          sheetClone[targetCellKey] = { ...sheet[sourceCellKey] }
        })
        cloneRowIndex++
        cloneCellKey = this.column + cloneRowIndex

        continue
      }
      // console.log('We need to split:', cellContent)

      // Split the value by the separator
      const splittedValues = cellContent.v.split(this.separator)
      // console.log('We need to split cell '+oldCellKey+' with these values:', splittedValues)

      splittedValues.forEach((value: string, index: number) => {

        sheetClone[cloneCellKey] = { ...cellContent, v: value.trim() }
        // console.log('Writing '+sheetClone[cloneCellKey].v+' into new', cloneCellKey)

        // Copy the rest of the row
        this.allColumns.forEach((column: string) => {

          if (column == this.column) return
          const sourceCellKey = column + rowIndex
          const targetCellKey = column + cloneRowIndex

          // Copy the value from the original cell to the new cell
          // if (column == "B") console.log('Copying value from old', sourceCellKey, 'to new', targetCellKey, "value is", sheet[sourceCellKey].v)
          sheetClone[targetCellKey] = { ...sheet[sourceCellKey] }
        })

        cloneRowIndex++
        cloneCellKey = this.column + cloneRowIndex
      })
    }

    console.log('Updated sheet:', sheetClone)
		this.output = sheetClone
  }

	public getSaveAction(): any {
    return {
      id: this.id,
      name: this.name,
			excelData: this.excelData,
			sheet: this.sheet,
			allSheets: this.allSheets,
			column: this.column,
			allColumns: this.allColumns,
			separator: this.separator
    }
  }
}
