import { Component, Injectable } from '@angular/core';
import { menu } from '../../menus/menu/menu';
import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from 'src/app/element.service'

var name = "matTable"

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: name+'-menu',
  templateUrl: 'matTable.menu.html',
  styleUrls: ['matTable.menu.scss']
})
export class matTableComponentMenu extends menu {

  editTableDialog: boolean = false;

	onInit(editor: any, selected: LayoutComponent) {

		ElementService.monacoEditor = editor
    const code = this.getProp(`Col${this.getProp("selectedColumn")}Code`)
    this.editorCode = code
	}

  editorOptions = {
		theme: 'vs-dark',
		language: 'javascript',
		automaticLayout: true,
		minimap: {
			enabled: false,
		},
    insertSpaces: false,
	}

  private _editorCode: string = '';
	public get editorCode(): string {

		return this._editorCode;
	}
	public set editorCode(value: string) {

		this._editorCode = value;
    if (this.getProp("selectedColumn")) {
      this.setProp(`Col${this.getProp("selectedColumn")}Code`, value);
      this.elementService.selectedObjects[0].element?.processColumns();
    }
	}

  name = name

  isHelper = true

  setHorizontalBorder(value: string){

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.setProp({key:"horizontalBorder", value: value, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
      selectedObject.element.setBordersHorizontal("border-bottom", value=="1"?1:0)
    })
  }

  setVerticalBorder(value: string){

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.setProp({key:"verticalBorder", value: value, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
      selectedObject.element.setBordersVertical("border-right", value=="1"?1:0)
    })
  }

  /**
   * Activates or deactivates the footer
   * @param value 1 or 0
   */
  setFooter(value: string){

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.setProp({ key:"footer", value: value, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
      selectedObject.element.processColumns();
    })
  }

  setFooterType(value: string) {

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.setProp({ key: `footerType${selectedObject.element.getProp("selectedColumn")}`, value: value, second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false });
      selectedObject.element.processColumns();
    })
  }

  deleteColumn(colNum: string){

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.delete(colNum)
    })
  }

  setFormat(colNum: string, format: string){

    if(this.getProp('columnFormat'+colNum) != "" && this.getProp('columnFormat'+colNum) == format) format = ""

    this.setProp("columnFormat"+colNum, format)

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.processColumns();
    })
  }

  setAlign(colNum: string, align: string){

    if(this.getProp('columnAlign'+colNum) != "" && this.getProp('columnAlign'+colNum) == align) align = ""

    this.setProp("columnAlign"+colNum, align)

    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.processColumns();
    })
  }

  // Field key or fieldNameSort textfield
  private _inputValue: string | undefined
  public get inputValue(): string | undefined {

    let stringValue = this.elementService.getProp('fieldNameSort'+this.getProp('selectedColumn'))

    this._inputValue = stringValue == "" ? undefined : stringValue

    return this._inputValue
  }
  public set inputValue(value: string | undefined) {

    if(!value) return

    this.elementService.setProp(
      {key: 'fieldNameSort'+this.getProp('selectedColumn'), value: value, second: "", isHelper: true, isTailwind: false, renderOnlyOuter: false}
    )
    this._inputValue = value;
    this.elementService.selectedObjects.forEach(selectedObject => {

      selectedObject.element.renameExistingColumn()
    })
  }

  public closeEditTableDialog() {
    this.editTableDialog = false;
    this.elementService.selectedObjects.forEach(selectedObject => {
      selectedObject.element.ngAfterViewInit();
    });
  }
}
