import { ChangeDetectorRef, ComponentRef, EventEmitter, Injectable, Renderer2, Type } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonComponent } from './Angular Material/matButton/matButton.component';
import { MatCheckboxComponent } from './Angular Material/matCheckbox/component';
import { MatChipsComponent } from './Angular Material/matChips/matChips.component';
import { MatInputComponent } from './Angular Material/matInput/matInput.component';
import { MatRadioComponent } from './Angular Material/matRadio/matRadio.component';
import { SelectComponent } from './Angular Material/matSelect/matSelect.component';
import { MatSlideToggleComponent } from './Angular Material/matSlideToggle/component';
import { MatSliderComponent } from './Angular Material/matSlider/matSlider.component';
import { matTableComponent } from './Angular Material/matTable/matTable.component';
import { MatTextareaComponent } from './Angular Material/matTextarea/component';
import { TreeComponent } from './Angular Material/matTree/tree.component';
import { ChartJsComponent } from './JS Libraries/chart.js/chartJs.component';
import { langchain } from './JS Libraries/langchain/langchain';
import { leafletJs } from './JS Libraries/leaflet.js/leafletJs.component';
import { richText } from './JS Libraries/richText/richText';
import { xSpreadsheet } from './JS Libraries/xSpreadsheet/xSpreadsheet';
import { CssButtonComponent } from './css-js Elements/cssButton/cssButton.component';
import { CssInputComponent } from './css-js Elements/cssInput/css-input.component';
import { TextComponent } from './css-js Elements/text/text.component';
import { DataService } from './data.service';
import { FormulaDirective } from './formulaInput/formula.directive';
import { LayoutComponent } from './layout/layout.component';
import { TreeAppHierarchyComponent } from './tree-app-hierarchy/tree-app-hierarchy.component';
import { RouterOutletPlaceholderComponent } from './router-outlet-placeholder/router-outlet-placeholder.component';
import { TreeSearchService } from './tree-search.service';
import { ProjectComponent } from './project/project.component';
import { EditorComponent } from './editor/editor.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { IProperty, Project, PropertyIterator, SaveLayout, SaveLayoutIterator } from './projects';
import { KanbanComponent } from './syncfusion/kanban/kanban.component';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDatepickerComponent } from './Angular Material/matDatepicker/matDatepicker.component'
import { SfButtonComponent } from './syncfusion/buttons/button.component'

/*

         _______________________________________________________
    ()==(                                                      (@==()
         '______________________________________________________'|
           |                                                     |
           |   ELEMENT SERVICE                                   |
           |   ===============================================   |
           |   * Manages the elements in the editor              |
					 |     * CREATE NEW ELEMENTS    					             |
					 |     * FIND ELEMENTS    					                   |
					 |     * SELECTED ELEMENTS    					               |
					 |     * REMOVE / CLONE ELEMENTS				               |
					 |     * Also stores code snippets                     |
					 |     * Responsible for bottom left tree hierarchy    |
           |                                                     |
         __)_____________________________________________________|
    ()==(                                                       (@==()
         '-------------------------------------------------------'

*/

@Injectable()
export class ElementService {
	globalStylesToApply: string[] = []
	static monacoEditor: any // Stores the current monaco editor, to see if it's focussed in editor.component.ts
	static monacoEditor2: any // Stores the current monaco editor, to see if it's focussed in editor.component.ts
	public dataService: DataService;
  public appComponent: EditorComponent;
	public renderer2: Renderer2;
	public changeDetector: ChangeDetectorRef;
  public rootNode: LayoutComponent;
  public selectedObjects: LayoutComponent[] = [];
  public tree: TreeAppHierarchyComponent;
  public hoveredElement: LayoutComponent;
  public textFieldFocussed = false;
  public isLiveMode = false;
  public elementInFormulaMode: FormulaDirective | undefined;
	public componentMap: Map<string, Type<unknown>> = new Map<string, Type<unknown>>();

	public constructor(
			public readonly treeSearchService: TreeSearchService,
			private clipboard: Clipboard,
			private snackBar: MatSnackBar
		) {
		this.componentMap.set('ProjectComponent', ProjectComponent);
		this.componentMap.set('routerOutletPlaceholder', RouterOutletPlaceholderComponent);
		this.componentMap.set('layout', LayoutComponent);
		this.componentMap.set('matButton', MatButtonComponent);
		this.componentMap.set('matInput', MatInputComponent);
		this.componentMap.set('matTextarea', MatTextareaComponent);
		this.componentMap.set('matSelect', SelectComponent);
		this.componentMap.set('matRadio', MatRadioComponent);
		this.componentMap.set('matSlider', MatSliderComponent);
		this.componentMap.set('matSlideToggle', MatSlideToggleComponent);
		this.componentMap.set('matCheckbox', MatCheckboxComponent);
		this.componentMap.set('text', TextComponent);
		this.componentMap.set('matTable', matTableComponent);
		this.componentMap.set('tree', TreeComponent);
		this.componentMap.set('matChips', MatChipsComponent);
		this.componentMap.set('cssButton', CssButtonComponent);
		this.componentMap.set('chartJs', ChartJsComponent);
		this.componentMap.set('cssInput', CssInputComponent);
		this.componentMap.set('xSpreadsheet', xSpreadsheet);
		this.componentMap.set('leafletJs', leafletJs);
		this.componentMap.set('richText', richText);
		this.componentMap.set('langchain', langchain);
		this.componentMap.set('fileUpload', FileUploadComponent);
		this.componentMap.set('sf-kanban', KanbanComponent)
		this.componentMap.set('sf-button', SfButtonComponent)
		this.componentMap.set('matDatepicker', MatDatepickerComponent)
	}

	/**
	 * ### Global Style name was changed for selected elements
	 * Check if this global style exists at root, if yes, applies it to the selected elements
	 * @param value Is the name of the global style
	 */
	checkIfThisStyleExistsThenApply(value: string) {

		let globalStyles = this.rootNode.getProp(value)

		if(!globalStyles) return

		let globalStylesArray: IProperty[] = JSON.parse(globalStyles)

		this.applyStyleToAll(this.selectedObjects, globalStylesArray)
	}

	/**
	 * ### Apply global style to the given elements
	 * Applies the given global Styles to all the given elements
	 * @param elements The elements to apply the style to
	 * @param globalStylesArray The global styles to apply - a property array
	 */
	applyStyleToAll(elements: LayoutComponent[], globalStylesArray: IProperty[]) {

		elements.forEach((element) => {

			globalStylesArray.forEach((prop: IProperty) => {

				if(	prop.key == 'id' ||
						prop.key == 'displayName' ||
						prop.key == 'globalStyle' ||
						prop.key == 'componentType'
					) return

				// We need to set the correct id of the savelayout,
				// because this prop is from another element and it sill has the old saveLayout.id
				// prop.saveLayout ? prop.saveLayout.id = element.saveLayout.id : ""
				element.setProp(prop)
			})
		})
	}

	/**
	 * Save the style from the current element to the root and
	 * apply it to all who have the same global style
	 */
	saveStyleFromCurrElementToRootAndApplyToAll() {

		let nameOfTheGlobalStyle = this.selectedObjects[0].getProp('globalStyle')
		this.rootNode.setPropAsHelper(nameOfTheGlobalStyle, JSON.stringify(this.selectedObjects[0].properties))

		this.applyStyleToAll(this.findElementsByGlobalStyle(nameOfTheGlobalStyle), this.selectedObjects[0].properties)
	}

  /*
   *  ██████╗    ██████╗     ███████╗     █████╗     ████████╗    ███████╗
   * ██╔════╝    ██╔══██╗    ██╔════╝    ██╔══██╗    ╚══██╔══╝    ██╔════╝
   * ██║         ██████╔╝    █████╗      ███████║       ██║       █████╗
   * ██║         ██╔══██╗    ██╔══╝      ██╔══██║       ██║       ██╔══╝
   * ╚██████╗    ██║  ██║    ███████╗    ██║  ██║       ██║       ███████╗
   *  ╚═════╝    ╚═╝  ╚═╝    ╚══════╝    ╚═╝  ╚═╝       ╚═╝       ╚══════╝
   */

	public createRootElement(): LayoutComponent {
		const renderContainer = this.appComponent.viewContainerAnker;
		const newComponent = renderContainer.createComponent(LayoutComponent);
		const newRoot = newComponent.instance;

		newRoot.componentRef = newComponent;
		newRoot.nativeElement = newComponent.location.nativeElement;
		newRoot.name = 'App';

		// Note: The displayName prop will be set in the ngOnInit() of layout.component.ts

		newRoot.isRootNode = true;
		this.setSelected(newRoot);
		this.rootNode = newRoot;

		this.tree?.updateDatasourceAndExpand();

		return newRoot;
	}

	/**
	 * ### Create a new element as a user
	 * This may not be called from the system, but only from the user
	 * Why? Because the user will do it only once, and the system on every startup
	 * This is to make sure, that initial Properties are only set one time when the user creates this
	 * @param type An optional element type. If not given, a layout element will be created.
	 * @returns
	 */
	public createElementsIntoAllSelected(type?: string) {

		this.selectedObjects.forEach(selectedObj => {

			this.dataService.addSaveLayout(selectedObj.saveLayout, type, (saveLayout) => {

				const createdElement = this.createElementIntoParent(selectedObj, type, true, true)
				createdElement.saveLayout = saveLayout
				this.tree?.updateDatasourceAndExpand()
			})
		})
	}

	public createElementIntoLayoutComponent(parent: LayoutComponent, type?: string) {
		const createdElement = this.createElementIntoParent(parent, type, true);
		this.tree?.updateDatasourceAndExpand();
		return createdElement;
	}

	public createElement(parent: LayoutComponent, type?: string, append?: boolean) {
		return this.createElementIntoParent(parent, type, append);
	}

	/**
	 * ### Instantiate a new element into the given parent object
	 * @param parentObj LayoutComponent
	 * @param type An optional element type. If not given, a layout element will be created.
	 * @param isFirstTime If true, the element is created for the first time by the user and not by the system
	 * @returns
	 */
	private createElementIntoParent(parentObj: LayoutComponent, type?: string, append?: boolean, isFirstTime?: boolean) {

		// RenderContainer is the tag to render into
		const renderContainer = parentObj.viewContainerAnker
		let newComponentRef: ComponentRef<any>

		if (!renderContainer) {
			console.error('Could not find renderContainer for parent: ' + parentObj.name)
			throw new Error('Could not find renderContainer for parent: ' + parentObj.name)
		}

		if (type) {

			try {
				newComponentRef = renderContainer.createComponent(this.componentMap.get(type)!)
			} catch (error) {
				throw new Error('Could not create element of type: ' + type)
			}

			newComponentRef.instance.name = type
			newComponentRef.instance.isLayout = type === 'layout'
		}
		// Layout Element
		else newComponentRef = renderContainer.createComponent(LayoutComponent)

		// Get the component instance
		let newComponent = newComponentRef.instance

		// Set parent and add to parents children
		if (append) {

			newComponent.parent = parentObj
			parentObj.children?.push(newComponent)
		}

		newComponent.componentRef = newComponentRef
		newComponent.nativeElement = newComponentRef.location.nativeElement
		if(isFirstTime && newComponent.element && newComponent.element.setInitialProps)
			newComponent.initialPropsNeedToBeSet = true

		return newComponent
	}

  /*
   * ███████╗    ██╗    ███╗   ██╗    ██████╗
   * ██╔════╝    ██║    ████╗  ██║    ██╔══██╗
   * █████╗      ██║    ██╔██╗ ██║    ██║  ██║
   * ██╔══╝      ██║    ██║╚██╗██║    ██║  ██║
   * ██║         ██║    ██║ ╚████║    ██████╔╝
   * ╚═╝         ╚═╝    ╚═╝  ╚═══╝    ╚═════╝
   */

  public foundElements: LayoutComponent[] = [];

	public findElementsByName(name: string) {
		this.foundElements = [];

		let stack: LayoutComponent[] = [ this.rootNode ];

		while (stack.length > 0) {
			const child = stack.pop()!;

			if (child.getProp('displayName') === name) {
				this.foundElements.push(child);
			}

			if (child.children) {
				stack = [ ...stack, ...child.children ];
			}
		}

		return this.foundElements;
	}

	/**
	 * ### Find elements by global style
	 * Looks for elements with the given global style
	 * @param globalStyle The name of the global style to search
	 * @returns The found elements
	 */
	public findElementsByGlobalStyle(globalStyle: string) {
		this.foundElements = []

		let stack: LayoutComponent[] = [ this.rootNode ]

		while (stack.length > 0) {
			const child = stack.pop()!

			if (child.getProp('globalStyle').includes(globalStyle)) {
				this.foundElements.push(child)
			}

			if (child.children) {
				stack = [ ...stack, ...child.children ]
			}
		}

		return this.foundElements
	}

	public findElementById(id: string) {
		this.foundElements = [];

		let stack: LayoutComponent[] = [ this.rootNode ];

		while (stack.length > 0) {
			const child = stack.pop()!;

			if (child.saveLayout.id === id) {
				this.foundElements.push(child);
				stack = [];
			}

			if (this.treeSearchService.getProp(child.saveLayout, 'componentType') === 'RouterOutletPlaceholderComponent' || this.treeSearchService.getProp(child.saveLayout, 'componentType') === 'ProjectComponent') {
				const subRoot = (child as any).loadedComponent;
				if (subRoot) {
					stack = [ ...stack, subRoot ];
				}
			}

			if (child.children) {
				stack = [ ...stack, ...child.children ];
			}
		}

		return this.foundElements;
	}

	public findElementsByType(type: string) {
		this.foundElements = [];

		let stack: LayoutComponent[] = [ this.rootNode ];

		while (stack.length > 0) {
			const child = stack.pop()!;

			if (child.getProp('componentType') === type) {
				this.foundElements.push(child);
			}

			if (child.children) {
				stack = [ ...stack, ...child.children ];
			}
		}

		return this.foundElements;
	}

/*
 ██████╗██╗   ██╗██████╗ ██████╗ ███████╗███╗   ██╗████████╗    ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
██╔════╝██║   ██║██╔══██╗██╔══██╗██╔════╝████╗  ██║╚══██╔══╝    ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
██║     ██║   ██║██████╔╝██████╔╝█████╗  ██╔██╗ ██║   ██║       ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
██║     ██║   ██║██╔══██╗██╔══██╗██╔══╝  ██║╚██╗██║   ██║       ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
╚██████╗╚██████╔╝██║  ██║██║  ██║███████╗██║ ╚████║   ██║       ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
 ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝       ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

*/

	tfFocussed(isFocussed: boolean){

		this.textFieldFocussed = isFocussed
	}

	/**
	 * ### Selects an element
	 * * deselects the old one(s)
	 * * pushes it to the selectedObjects array
	 *
	 * @param newSelected The new element to select
	 */
  setSelected(newSelected: LayoutComponent) {

    // A single element was selected
    let oldSelected = this.selectedObjects[0]
    let wasSingle = this.selectedObjects.length == 1

    let hasElement = newSelected.element != undefined
    let hasDeselectAllowed = hasElement && newSelected.element.deselectAllowed != undefined
    let deselectAllowed = hasElement && hasDeselectAllowed ? newSelected.element.deselectAllowed : true
    let sameSelected = wasSingle && oldSelected == newSelected

    if(sameSelected && !deselectAllowed) return

    // Deselect old selection
    this.selectedObjects = []
    oldSelected?.element?.handleBeingDeSelected ? oldSelected.element.handleBeingDeSelected() : ''

    // Same selected -> select rootNode
    if (sameSelected && deselectAllowed) {

      this.selectedObjects.push(this.rootNode)
      newSelected = this.rootNode
    }
    // or new selected -> select new
    else {

      this.selectedObjects.push(newSelected)
      newSelected.element?.handleBeingSelected ? newSelected.element.handleBeingSelected() : ''
			this.tree?.expandTo(newSelected)
    }
  }

  setApiCalls() {

    const apiCalls = this.rootNode.getProp('apiCalls')
    if (apiCalls) apiCalls.split('###newApiCall###').forEach(apiCall => {

      this.dataService.apiCalls.push(JSON.parse(apiCall))
    })
  }

  addSelected(newSelected: LayoutComponent){

    let objs = this.selectedObjects
    let index = this.selectedObjects.indexOf(newSelected)
    if(index > -1) objs.splice(index, 1);
    else this.selectedObjects.push(newSelected)
  }

  isSelected(thisOne: LayoutComponent) {

    return this.selectedObjects.indexOf(thisOne) > -1
  }

/*
██████╗ ██████╗  ██████╗ ██████╗ ███████╗██████╗ ████████╗██╗   ██╗    ██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ██╗███╗   ██╗ ██████╗
██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝██╔══██╗╚══██╔══╝╚██╗ ██╔╝    ██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██║████╗  ██║██╔════╝
██████╔╝██████╔╝██║   ██║██████╔╝█████╗  ██████╔╝   ██║    ╚████╔╝     ███████║███████║██╔██╗ ██║██║  ██║██║     ██║██╔██╗ ██║██║  ███╗
██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗   ██║     ╚██╔╝      ██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██║██║╚██╗██║██║   ██║
██║     ██║  ██║╚██████╔╝██║     ███████╗██║  ██║   ██║      ██║       ██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗██║██║ ╚████║╚██████╔╝
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝      ╚═╝       ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝

*/
  /** I care about all the selected ones! */
  setProp(prop: IProperty) {

    // Happens in the beginning before the rootNode is there
    if (!this.selectedObjects[0]) return;

    this.selectedObjects.forEach(object => {

      object.setProp(prop)
    })

    this.changeDetector.detectChanges()
  }

  public onPropSet: EventEmitter<IProperty> = new EventEmitter<IProperty>()

  /**
   * Retrieves a property value for a single element
   * YES ONLY FOR THE FIRST[0]
   *
   * @param key The key name of the property
   * @returns The VALUE nullsafe
   */
  getProp(key: string) {

    if(!this.selectedObjects[0]) return ''

    return this.selectedObjects[0].getProp(key)
  }

  getPropObj(key: string) {

    if(!this.selectedObjects[0]) return

    return this.selectedObjects[0].getPropObj(key)
  }

  hasProp(key: string): boolean {

    if(!this.selectedObjects[0]) return false

    return this.selectedObjects[0].hasProp(key)
  }

  removeProp(key: string) {

    this.selectedObjects.forEach(object => {

      object.removeProp(key)
    })
  }

/*
██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗       ██╗        ██████╗██╗      ██████╗ ███╗   ██╗███████╗
██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝       ██║       ██╔════╝██║     ██╔═══██╗████╗  ██║██╔════╝
██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗      ████████╗    ██║     ██║     ██║   ██║██╔██╗ ██║█████╗
██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝      ██╔═██╔═╝    ██║     ██║     ██║   ██║██║╚██╗██║██╔══╝
██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗    ██████║      ╚██████╗███████╗╚██████╔╝██║ ╚████║███████╗
╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝    ╚═════╝       ╚═════╝╚══════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

*/

  public removeAllSelectedElements() {
		// Remove the elements
    this.selectedObjects.forEach(object => {
        object.deleteMe();
    });
    this.setSelected(this.rootNode);
    this.tree?.updateDatasourceAndExpand();
  }

	public copySelectedElements() {

		let copiedElements: SaveLayout[] = []
		this.selectedObjects.forEach(object => {

			copiedElements.push(object.saveLayout)
		})

		// Store the copied elements into the clipboard of the OS
		this.clipboard.copy(JSON.stringify(copiedElements))
    this.snackBar.open("Element kopiert.", undefined, {duration: 5000})

		console.log('copiedElements', JSON.stringify(copiedElements))
	}

	public async pasteElements() {

		console.log('Pasting elements...')
		// Get the copied elements from the clipboard of the OS
		let clipBoardcContent = await navigator.clipboard.readText()
		console.log('clipBoardcContent', clipBoardcContent)
		let copiedElements: SaveLayout[] = []
		try {

			copiedElements = JSON.parse(clipBoardcContent)
		}
		catch (err) {

			this.snackBar.open("⚠️ Kein Element in der zwischenablage.", undefined, {duration: 5000})
		}

		if(copiedElements.length == 0) return
		// Create the elements from the copied elements
		for (const copiedElement of copiedElements) {
			this.cloneOne(copiedElement, this.dataService.projectsService.currentProject!, this.selectedObjects[0].saveLayout!)
		}
	}

	/**
	 * Called from app.component.leftMenu.html for cloning the selected element
	 */
  public clone() {
    this.selectedObjects.forEach(object => {
      this.cloneOne(object.saveLayout, this.dataService.projectsService.currentProject!);
    });
  }

  public cloneOne(saveLayoutToClone: SaveLayout, currentProject: Project, insertIntoSaveLayout?: SaveLayout) {
		this.cloneOneAsync(saveLayoutToClone, currentProject, insertIntoSaveLayout).then(() => {
			this.dataService.loadPageIntoTheApp(this.dataService.currentPage.page);
		});
  }

  public clonePage(page: SaveLayout, name: string) {
		this.cloneOneAsync(page, this.dataService.projectsService.currentProject!, undefined, name).then(() => {
			this.dataService.loadPageIntoTheApp(this.dataService.currentPage.page);
		});
  }

	private async cloneOneAsync(saveLayoutToClone: SaveLayout, currentProject: Project, insertIntoSaveLayout?: SaveLayout, name?: string) {

		let stack: SaveLayout[] = [ saveLayoutToClone ];
		let parents: Map<string, string | undefined> = new Map<string, string | undefined>();

		parents.set(saveLayoutToClone.id, insertIntoSaveLayout ? insertIntoSaveLayout.id : saveLayoutToClone.parent?.id ?? undefined);

		while (stack.length > 0) {
			// Next SaveLayout
			const saveLayout = stack.shift()!;

			// Resolve New Parent
			const newParentId = parents.get(saveLayout.id) ?? undefined;

			// Chain Creation of the SaveLayout
			const observable = this.dataService.saveLayoutsService.createLater(
				currentProject,
				{
					projectId: currentProject.id,
					projectOwner: currentProject.owner,
					name: newParentId ? saveLayout.name : name ?? saveLayout.name,
					parentId: newParentId,
					parentOwner: newParentId ? saveLayout.parent?.owner : undefined,
					isLayout: saveLayout.isLayout,
					orderNumber: newParentId ? saveLayout.orderNumber : saveLayout.orderNumber + 1
				}
			);
			const clonedSaveLayout = (await observable.toPromise())!

			// Chain Creation of the Properties
			const propertyIterator = new PropertyIterator(this.dataService.propertiesService, saveLayout);
			const attributes = [ ...propertyIterator ];
			if (attributes.length > 0) {
				for (const attribute of attributes) {
					await this.dataService.propertiesService.createLater(clonedSaveLayout, attribute).toPromise();
				}
			}

			// Look for Children
			const childrenIterator = new SaveLayoutIterator(this.dataService.saveLayoutsService, saveLayout);
			const children = [ ...childrenIterator ];
			if (children.length > 0) {
				for (const child of children) {
					parents.set(child.id, clonedSaveLayout.id);
				}
				stack = [ ...stack, ...children ];
			}
		}

		await this.dataService.saveLayoutsService.findAll().toPromise()
		await this.dataService.propertiesService.findAll().toPromise()
	}

/*
 ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗██╗███╗   ██╗ ██████╗     ███████╗████████╗██╗   ██╗███████╗███████╗
██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝██║████╗  ██║██╔════╝     ██╔════╝╚══██╔══╝██║   ██║██╔════╝██╔════╝
██║     ███████║█████╗  ██║     █████╔╝ ██║██╔██╗ ██║██║  ███╗    ███████╗   ██║   ██║   ██║█████╗  █████╗
██║     ██╔══██║██╔══╝  ██║     ██╔═██╗ ██║██║╚██╗██║██║   ██║    ╚════██║   ██║   ██║   ██║██╔══╝  ██╔══╝
╚██████╗██║  ██║███████╗╚██████╗██║  ██╗██║██║ ╚████║╚██████╔╝    ███████║   ██║   ╚██████╔╝██║     ██║
 ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝     ╚══════╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝

*/
  allLayouts(): boolean{

    if(!this.selectedObjects[0]) return false

    for(let object of this.selectedObjects) if(!object.isLayout) return false

    return true
  }

  rootObjectSelected(): boolean{

    if(!this.selectedObjects[0]) return true

    for(let object of this.selectedObjects) if(object.isRootNode) return true

    return false
  }

  allOfType(type: string): boolean{

    if(!this.selectedObjects[0]) return false

    for(let object of this.selectedObjects) if(object.name != type) return false

    return true
  }

  public getDummy(){

    let dummyObject = new LayoutComponent(this, this.renderer2, this.dataService)
    dummyObject.name = 'dummy'

    return dummyObject
  }


	/*
 ██████╗ ██████╗ ██████╗ ███████╗    ███████╗███╗   ██╗██╗██████╗ ██████╗ ███████╗████████╗███████╗
██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝████╗  ██║██║██╔══██╗██╔══██╗██╔════╝╚══██╔══╝██╔════╝
██║     ██║   ██║██║  ██║█████╗      ███████╗██╔██╗ ██║██║██████╔╝██████╔╝█████╗     ██║   ███████╗
██║     ██║   ██║██║  ██║██╔══╝      ╚════██║██║╚██╗██║██║██╔═══╝ ██╔═══╝ ██╔══╝     ██║   ╚════██║
╚██████╗╚██████╔╝██████╔╝███████╗    ███████║██║ ╚████║██║██║     ██║     ███████╗   ██║   ███████║
 ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝    ╚══════╝╚═╝  ╚═══╝╚═╝╚═╝     ╚═╝     ╚══════╝   ╚═╝   ╚══════╝

	*/
  /*
    Storage and Generation of above and below code.
    This must be here, because it must exist one time globally.
    In the LayoutComponent it would be for the local element.
    IDEA: maybe we can use a static class method in layoutComponent for this.
  */
		aboveCode: string = ''
		ngOnInitCode: string = ''
		belowCode: string = ''

		addAboveCodeForGermanPipeLocale(){

			this.aboveCode.includes("registerLocaleData(localeDe, 'de-DE', localeDeExtra)") ?
															'' :
															this.aboveCode += `
			// For the german currency-pipes, you should import this in your module
			/*

				In app.module.ts:
				import { LOCALE_ID } from '@angular/core'; //<------------------------------
				import { registerLocaleData } from '@angular/common'; //<------------------------------
				import localeDe from '@angular/common/locales/de'; //<------------------------------
				import localeDeExtra from '@angular/common/locales/extra/de'; //<-----------------------

				registerLocaleData(localeDe, 'de-DE', localeDeExtra); //<------------------------------

				@NgModule({
					declarations: [
						AppComponent
					],
					imports: [
						BrowserModule
					],
					providers: [
						{
							provide: LOCALE_ID, //<------------------------------
							useValue: 'de-DE' //<------------------------------
						},
					],
					bootstrap: [AppComponent]
				})
				export class AppModule { }

			*/

			`
		}

		addAboveCodeForApiCall(){

			this.aboveCode.includes("import _ from 'lodash'") ?
															'' :
															this.aboveCode += `import _ from 'lodash'
			// npm install lodash
			// npm i --save-dev @types/lodash
			/*

				In tsconfig.json:
				{
					"compilerOptions": {
							"allowSyntheticDefaultImports": true,
							// Ihre restlichen Optionen hier...
					}
				}


			*/
			`
		}

	addBelowCodeForApiCall(){

		this.belowCode.includes('fetchDataForChart') ? '' : this.belowCode += `
async function fetchDataForChart(call_type: string, url: string, authorization?: string, customHeader?: string, body?: string) {
	const headers: {[key: string]: string} = {
		'Content-Type': 'application/json',
		'target-url': url,
	};

	if (authorization) {
    headers['Authorization'] = JSON.parse(authorization)['value'];
  }

	if (customHeader) {
    const parsedCustomHeader = JSON.parse(customHeader);
		for (let index = 1; index < Object.keys(parsedCustomHeader).length; index += 2) {
      const key = parsedCustomHeader[Object.keys(parsedCustomHeader)[index - 1]];
      const value = parsedCustomHeader[Object.keys(parsedCustomHeader)[index]];
      headers[key] = value;
    }
	}

	const response = await fetch('http://localhost:3000/api/call', {
		method: call_type,
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: headers,
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
		body: body
	});

	return response.json();
}
`;
	}

	getApiCallCode(caller: LayoutComponent){

		return `${caller.getProp("preCall")==`true`?`
		preCallResponse: any
		fetchPreCall(){

			fetchDataForChart(\'${caller.getProp("preCall_type")}\', \'${caller.getProp("preCall_url")}\', ${caller.getProp("preCall_authorization")!=''?
						`\'${caller.getProp("preCall_authorization")}\'`:`undefined`}, undefined, undefined, ${caller.getProp("preCall_body")!=''?
						`\'${caller.getProp("preCall_body")}\'`:"undefined"}).then((data) => {

				this.preCallResponse = data
				this.fetchCall()
			})
		}
		`:``
		}`

	}
}