import { AfterViewInit, Component, HostListener, Renderer2 } from '@angular/core';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartType, ChartTypeRegistry, DoughnutController, LineController, LineElement, LinearScale, PieController, PointElement, Tooltip } from 'chart.js';

import { LayoutComponent } from '../../layout/layout.component';
import { ElementService } from '../../element.service';
import { DataService } from '../../data.service';
import { ApiCallGenerator } from '../../code-generation/generators/snippets/api-call.generator';

Chart.register(ArcElement, BarController, BarElement, CategoryScale, DoughnutController, LineController, LineElement, LinearScale, PieController, PointElement, Tooltip);

@Component({
  selector: 'chartJs',
  templateUrl: './chartJs.component.html',
})
export class ChartJsComponent extends LayoutComponent implements AfterViewInit {
  private chart?: Chart<keyof ChartTypeRegistry, string[], string>;
  private xAxisLabelsArray: string[];
  private ctx: any;

  constructor(
    public readonly elementService: ElementService,
    public readonly renderer: Renderer2,
    public readonly dataService: DataService,
  ) {
    super(elementService, renderer, dataService);
    this.componentType = 'ChartJsComponent';

    this.setPropAsHelper('event', '$event');
  }

  public ngAfterViewInit(): void {
    this.ensureProp({ key: 'label', value: '# of Votes', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'xAxisLabels', value: 'Red, Blue, Yellow, Green, Purple, Orange', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'data', value: '12, 19, 3, 5, 2, 3', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'borderWidth', value: '1', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });
    this.ensureProp({ key: 'position', value: 'relative', second: '', isTailwind: false, isHelper: false, renderOnlyOuter: false });
    this.ensureProp({ key: 'type', value: 'bar', second: '', isTailwind: false, isHelper: true, renderOnlyOuter: false });

    this.xAxisLabelsArray = this.getProp('xAxisLabels').split(', ');

    this.createOrUpdateChart();

    this.elementService.onPropSet.subscribe((prop) => {
      if (this.properties.includes(prop)) {
        this.createOrUpdateChart();
      }
    });
  }

  public createOrUpdateChart(): void {
    const displayName = this.getProp('displayName');
    if (!displayName) { return; }

    this.xAxisLabelsArray = this.getProp('xAxisLabels').split(', ');

    this.ctx = document.getElementById(displayName + '_canvas');
    if (!this.ctx) { return; }

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(this.ctx, {
      type: (this.getProp('type')==''?'bar':this.getProp('type')) as ChartType,
      data: {
        labels:  this.xAxisLabelsArray,
        datasets: [{
          label: this.getProp('label'), // Title of the chart
          data: this.getProp('data').split(', '),
          borderWidth: +this.getProp('borderWidth'),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    this.elementService.changeDetector.detectChanges();
  }

  public setColumnAndDataArray(columnsArrayFromApiCall: string[], dataArrayFromApiCall: any[]) {
    // The dataArrayFromApiCall is an array of objects, each object has only one key-value pair
    let columnsArray: string[] = []
    let dataArray: string[] = []
    dataArrayFromApiCall.forEach((item: any) => {
			let columnsValue = Object.values(item)[0]+""
      columnsArray.push(columnsValue.replace(/,/g, ""))

      dataArray.push(Object.values(item)[1]+"")
    })
    this.setProp({key:"xAxisLabels", value: columnsArray.join(", "), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
    this.setProp({key:"data", value: dataArray.join(", "), second: "", isTailwind: false, isHelper: true, renderOnlyOuter: false})
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    if (!this.chart) { return; }
    this.chart.resize();
  }

  protected beforeRemoveMe(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  /*
   *  ██████╗ ██████╗ ██████╗ ███████╗     ██████╗ ███████╗███╗   ██╗███████╗██████╗ ██╗███████╗██████╗ ██╗   ██╗███╗   ██╗ ██████╗
   * ██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██║██╔════╝██╔══██╗██║   ██║████╗  ██║██╔════╝
   * ██║     ██║   ██║██║  ██║█████╗      ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝██║█████╗  ██████╔╝██║   ██║██╔██╗ ██║██║  ███╗
   * ██║     ██║   ██║██║  ██║██╔══╝      ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██║██╔══╝  ██╔══██╗██║   ██║██║╚██╗██║██║   ██║
   * ╚██████╗╚██████╔╝██████╔╝███████╗    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║███████╗██║  ██║╚██████╔╝██║ ╚████║╚██████╔╝
   *  ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝
   */

  public getHtmlTemplate(): string {
    const template =
`<div class="getProp('displayName')">
  <canvas id="${(this.getProp('displayName') == '' ? 'myChart' : "getProp('displayName')") + '_canvas'}"></canvas>
</div>`;
    return template;
  }

  public getCodeTemplate(): string {
    const apiCallGenerator = new ApiCallGenerator(this.dataService);
    apiCallGenerator.generate(this.dataService.projectContext.dataTable.get(this.id)!);

    const importCode =
`import { HostListener } from '@angular/core';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartType, ChartTypeRegistry, DoughnutController, LineController, LineElement, LinearScale, PieController, PointElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, BarController, BarElement, CategoryScale, DoughnutController, LineController, LineElement, LinearScale, PieController, PointElement, Tooltip);`;

    if (!this.elementService.aboveCode.includes(importCode)) {
      this.elementService.aboveCode += importCode;
    }

    if (+this.getProp('apiCalls') > 0) {
      this.elementService.addAboveCodeForApiCall()
    }

    const ngOnInitCode =
`this.createOrUpdateChart_${this.getProp('displayName')}();`;

    if (!this.elementService.ngOnInitCode.includes(ngOnInitCode)) {
      this.elementService.ngOnInitCode += '\n    ' + ngOnInitCode;
    }

    const code = `
  private chart_getProp('displayName')?: Chart<keyof ChartTypeRegistry, ${this.getProp('data').startsWith("'") || this.getProp('data').startsWith("\"") ? 'string' : 'number'}[], string>;
  private xAxisLabelsArray_getProp('displayName'): string[] = ["${this.getProp("xAxisLabels").replace(/, /g, "\", \"")}"];
  private ctx_getProp('displayName'): any;

  public createOrUpdateChart_getProp('displayName')(): void {
    ${+this.getProp('apiCalls') > 0 ? 'function ' + apiCallGenerator.snippet : ''}

    const displayName_getProp('displayName') = 'getProp('displayName')';
    if (!displayName_getProp('displayName')) { return; }

    this.ctx_getProp('displayName') = document.getElementById(displayName_getProp('displayName') + '_canvas');
    if (!this.ctx_getProp('displayName')) { return; }

    if (this.chart_getProp('displayName')) {
      this.chart_getProp('displayName').destroy();
    }

    this.chart_getProp('displayName') = new Chart(this.ctx_getProp('displayName'), {
      type: 'getProp('type')' as ChartType,
      data: {
        labels: this.xAxisLabelsArray_getProp('displayName'),
        datasets: [{
          label: 'getProp('label')',
          data: [${this.getProp('apiCall')=='true'?'':this.getProp('data')}],
          borderWidth: getProp('borderWidth'),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    ${+this.getProp('apiCalls') > 0 ? `fetchCall().then((result) => this.update_getProp('displayName')(this.xAxisLabelsArray_getProp('displayName'), result?.data ?? []));` : ''}
  }

  update_getProp('displayName')(columns: string[], data: any[]) {

    let dataArray: any[] = []
    data.forEach((item: any) => {

      dataArray.push(Object.values(item)[1])
    })

    if(!this.chart_getProp('displayName')) return

    this.chart_getProp('displayName').resize()

    this.chart_getProp('displayName').data.labels = columns
    this.chart_getProp('displayName').data.datasets[0].data = dataArray
    this.chart_getProp('displayName').update()
  }

  @HostListener('window:resize', ['$event'])
  public onResize_getProp('displayName')(): void {
    if (!this.chart_getProp('displayName')) { return; }
    this.chart_getProp('displayName').resize();
  }
`;

    if (+this.getProp('apiCalls') > 0) {
      this.elementService.addBelowCodeForApiCall();
    }

    return code;
  }
}
