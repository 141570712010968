import { SaveLayout } from './save-layout.entity';

export interface IProject {
  name: string;
}

export class Project implements IProject {
  public id: string;
  public owner: string;
  public name: string;
  public pages: SaveLayout[];
}
