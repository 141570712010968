import { Pipe, PipeTransform } from '@angular/core';

import { DataService } from './data.service';
import { SaveLayout } from './projects';

/*

				 _______________________________________________________
		()==(                                                      (@==()
				 '______________________________________________________'|
					 |                                                     |
					 |   PAGE FILTER PIPE                                  |
					 |   ===============================================   |
					 |   * THE PURPOSE OF THIS PIPE IS TO FILTER THE       |
					 |   * LIST OF PAGES. IT REMOVES THE CURRENT PAGE.     |
           |   * THIS IS USED TO MAKE SURE THE PAGE WON'T        |
           |   * REFERENCE ITSELF.                               |
					 |                                                     |
				 __)_____________________________________________________|
		()==(                                                       (@==()
				 '-------------------------------------------------------'

*/

@Pipe({
  name: 'pageFilter',
  pure: false
})
export class PageFilterPipe implements PipeTransform {
  public constructor(private dataService: DataService) {
  }

  transform(items: SaveLayout[]): SaveLayout[] {
    if (!items) { return []; };
    return this.dataService.projectsService.currentProject!.pages.filter((page) => page.name !== this.dataService.currentPage.name);
  }
}
