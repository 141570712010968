<div class="css-form-field">
  <div class="css-form-field-wrapper">
    <div class="css-form-field-flex">
      <div class="css-form-field-label" translate>Distanz in X Richtung</div>
      <mat-slider class="css-form-field-editor" min="0" max="100" step="1" discrete="true">
        <input matSliderThumb [(ngModel)]="distanceX">
      </mat-slider>
    </div>
  </div>
</div>

<div class="css-form-field">
  <div class="css-form-field-wrapper">
    <div class="css-form-field-flex">
      <div class="css-form-field-label" translate>Distanz in Y Richtung</div>
      <mat-slider class="css-form-field-editor" min="0" max="100" step="1" discrete="true">
        <input matSliderThumb [(ngModel)]="distanceY">
      </mat-slider>
    </div>
  </div>
</div>

<div class="css-form-field">
  <div class="css-form-field-wrapper">
    <div class="css-form-field-flex">
      <div class="css-form-field-label" translate>Schatten Radius</div>
      <mat-slider class="css-form-field-editor" min="0" max="100" step="1" discrete="true">
        <input matSliderThumb [(ngModel)]="shadowRadius">
      </mat-slider>
    </div>
  </div>
</div>

<mat-form-field appearance="fill" style="width: 100%;">
  <mat-label translate>Schatten Farbe</mat-label>
  <input
    #color
    type="color"
    matInput
    [value]="shadowColor ?? '#000000'"
    (input)="shadowColor = color.value">
</mat-form-field>
