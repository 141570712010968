import { Component, Input } from '@angular/core';

import { AutomationsService, DownloadFileAction } from '../../../../automations';

@Component({
  selector: "download-file-action",
  templateUrl: "./download-file.component.html",
  styleUrls: ["./download-file.component.scss"]
})
export class DownloadFileActionComponent {
  @Input()
  public action: DownloadFileAction;

  public constructor(private readonly automationsService: AutomationsService) {
  }

  private fileNameTimeout: NodeJS.Timeout;
  public get fileName(): string {
		return this.action.fileName;
	}
	public set fileName(value: string) {
		this.action.fileName = value;

		if (this.fileNameTimeout) {
			clearTimeout(this.fileNameTimeout);
		}
		this.fileNameTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
	}

  private fileExtensionTimeout: NodeJS.Timeout;
  public get fileExtension(): string {
    return this.action.fileExtension;
  }
  public set fileExtension(value: string) {
    this.action.fileExtension = value;

    if (this.fileExtensionTimeout) {
      clearTimeout(this.fileExtensionTimeout);
    }
    this.fileExtensionTimeout = setTimeout(() => this.automationsService.saveAutomations(this.automationsService.currentLayout), 800);
  }
}
