<!--
████████╗ ██████╗ ██████╗     ████████╗ ██████╗  ██████╗ ██╗     ██████╗  █████╗ ██████╗
╚══██╔══╝██╔═══██╗██╔══██╗    ╚══██╔══╝██╔═══██╗██╔═══██╗██║     ██╔══██╗██╔══██╗██╔══██╗
   ██║   ██║   ██║██████╔╝       ██║   ██║   ██║██║   ██║██║     ██████╔╝███████║██████╔╝
   ██║   ██║   ██║██╔═══╝        ██║   ██║   ██║██║   ██║██║     ██╔══██╗██╔══██║██╔══██╗
   ██║   ╚██████╔╝██║            ██║   ╚██████╔╝╚██████╔╝███████╗██████╔╝██║  ██║██║  ██║
   ╚═╝    ╚═════╝ ╚═╝            ╚═╝    ╚═════╝  ╚═════╝ ╚══════╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝

 -->
 <mat-toolbar color="primary" class="toolbar">
	<div style="display: flex; align-items: center; justify-content: flex-start">
		<span>UI Editor</span>

		<a
			title="Projects"
			style="width: 40px; margin-left: 30px; background-color: white; color: black;"
			mat-mini-fab
			(click)="toggleProjectsDialog()"
		>
			<mat-icon>source</mat-icon>
		</a>

		<!-- BROWSER TOP MENU -->
		<!-- file upload -->
		<input
			type="file"
			name="files[]"
			style="display: none"
			(change)="dataService.onFileSelected($event)"
			#fileUpload
			accept="*.json"
		/>
		<a
			*ngIf="!isElectron"
			title="Upload JSON"
			style="
				width: 40px;
				margin-left: 10px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="fileUpload.click()"
			><mat-icon>upload</mat-icon></a
		>

		<!-- direct file download -->
		<a
			*ngIf="!isElectron"
			title="Download JSON"
			style="
				width: 40px;
				margin-left: 10px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="
				dataService.openedPath
					? dataService.downloadFile(dataService.openedPath)
					: toggleFileDialog()
			"
			><mat-icon>download</mat-icon></a
		>
		<!-- file download "save as" -->
		<a
			*ngIf="!isElectron"
			title="JSON Speichern unter"
			style="
				margin-left: 10px;
				width: 40px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="toggleFileDialog()"
			><mat-icon>save_as</mat-icon></a
		>

		<!-- ELECTRON TOP MENU -->
		<!-- <a
			*ngIf="isElectron"
			style="
				width: 40px;
				margin-left: 30px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="dataService.openDialog()"
			><mat-icon>file_open</mat-icon></a
		>
		<a
			*ngIf="isElectron"
			style="
				width: 40px;
				margin-left: 10px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="dataService.writeFile()"
			><mat-icon>save</mat-icon></a
		> -->

		<!-- GENERAL TOP MENU -->
		<a
			title="Code herunterladen"
			style="width: 40px; margin-left: 10px"
			mat-mini-fab
			color="accent"
			(click)="downloadCode()"
			><mat-icon>code</mat-icon></a
		>
		<a
			title="Vorschau-Ansicht umschalten"
			style="
				margin-left: 10px;
				width: 40px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="toggleLiveMode()"
			><mat-icon>highlight_alt</mat-icon></a
		>
		<a
			title="API-Calls"
			style="
				margin-left: 10px;
				width: 40px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="openGeneralApiCallDialog()"
			><mat-icon>swap_horiz</mat-icon></a
		>
		<a
			title="Automations"
			style="
				margin-left: 10px;
				width: 40px;
				background-color: white;
				color: black;
			"
			mat-mini-fab
			(click)="openAutomationsDialog()"
			><mat-icon>rebase_edit</mat-icon></a
		>
	</div>

	<div>
		<div><span>{{ keycloakService.getUsername() }}</span><button mat-raised-button color="accent" style="margin-left: 8px;" (click)="keycloakService.logout()">Logout</button></div>
		<div><span>{{ currentProjectName }}{{ dataService.currentPage.name ? ' | ' + dataService.currentPage.name : '' }}</span></div>
	</div>

	<div style="display: flex; align-items: center; justify-content: center">
		<!-- TRANSLATIONS-->
		<mat-select
			#languageSelect
			(selectionChange)="setLanguage(languageSelect.value)"
			[value]="getLanguage()"
			style="
				padding: 2px;
				border: 1px #000000;
				border-style: solid;
				border-radius: 4px;
				width: 150px;
				font-size: 16px;
				color: white;
			"
		>
			<mat-option value="en">English</mat-option>
			<mat-option value="de">Deutsch</mat-option>
		</mat-select>

		<!-- DELETE ALL! -->
		<a
			title="⚠️ Neues Projekt ⚠️"
			style="margin-left: 10px; width: 40px"
			mat-mini-fab
			color="warn"
			(click)="deleteAllButtonClicked()"
			><mat-icon>note</mat-icon></a
		>
		<span style="margin-left: 10px; font-size: 20px">{{
			dataService.openedPath
				? ("download.file" | translate) + dataService.openedPath
				: ("download.noFileOpened" | translate)
		}}</span>
	</div>
</mat-toolbar>
<!-- ############### END TOP TOOLBAR ############### -->

<mat-sidenav-container class="sidenav-global-container" autosize>
	<!--
  ███████╗██╗██████╗ ███████╗███╗   ██╗ █████╗ ██╗   ██╗    ██╗     ██╗███╗   ██╗██╗  ██╗███████╗
  ██╔════╝██║██╔══██╗██╔════╝████╗  ██║██╔══██╗██║   ██║    ██║     ██║████╗  ██║██║ ██╔╝██╔════╝
  ███████╗██║██║  ██║█████╗  ██╔██╗ ██║███████║██║   ██║    ██║     ██║██╔██╗ ██║█████╔╝ ███████╗
  ╚════██║██║██║  ██║██╔══╝  ██║╚██╗██║██╔══██║╚██╗ ██╔╝    ██║     ██║██║╚██╗██║██╔═██╗ ╚════██║
  ███████║██║██████╔╝███████╗██║ ╚████║██║  ██║ ╚████╔╝     ███████╗██║██║ ╚████║██║  ██╗███████║
  ╚══════╝╚═╝╚═════╝ ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝      ╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝

  -->
	<mat-sidenav
		#drawer
		[hidden]="elementService.isLiveMode"
		opened
		fixedInViewport="true"
		fixedTopGap="80"
		fixedBottomGap="0"
		class="sidenavLeft"
		mode="side"
	>
		<app-leftMenu class="sidenavLeftContent column"></app-leftMenu>
	</mat-sidenav>
	<!-- ############### END SIDENAV LINKS ############### -->

	<!--
███████╗██████╗ ██╗████████╗ ██████╗ ██████╗      █████╗ ██████╗ ███████╗ █████╗
██╔════╝██╔══██╗██║╚══██╔══╝██╔═══██╗██╔══██╗    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
█████╗  ██║  ██║██║   ██║   ██║   ██║██████╔╝    ███████║██████╔╝█████╗  ███████║
██╔══╝  ██║  ██║██║   ██║   ██║   ██║██╔══██╗    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
███████╗██████╔╝██║   ██║   ╚██████╔╝██║  ██║    ██║  ██║██║  ██║███████╗██║  ██║
╚══════╝╚═════╝ ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝    ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

-->
	<mat-sidenav-content class="content-area">
		<ng-container #viewContainerAnker></ng-container>
	</mat-sidenav-content>

	<!-- ############### END EDITOR AREA ############### -->

	<!--
███████╗██╗██████╗ ███████╗███╗   ██╗ █████╗ ██╗   ██╗    ██████╗ ███████╗ ██████╗██╗  ██╗████████╗███████╗
██╔════╝██║██╔══██╗██╔════╝████╗  ██║██╔══██╗██║   ██║    ██╔══██╗██╔════╝██╔════╝██║  ██║╚══██╔══╝██╔════╝
███████╗██║██║  ██║█████╗  ██╔██╗ ██║███████║██║   ██║    ██████╔╝█████╗  ██║     ███████║   ██║   ███████╗
╚════██║██║██║  ██║██╔══╝  ██║╚██╗██║██╔══██║╚██╗ ██╔╝    ██╔══██╗██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
███████║██║██████╔╝███████╗██║ ╚████║██║  ██║ ╚████╔╝     ██║  ██║███████╗╚██████╗██║  ██║   ██║   ███████║
╚══════╝╚═╝╚═════╝ ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝  ╚═══╝      ╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

███╗   ███╗███████╗███╗   ██╗██╗   ██╗     █████╗ ██████╗ ███████╗ █████╗
████╗ ████║██╔════╝████╗  ██║██║   ██║    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║    ███████║██████╔╝█████╗  ███████║
██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝    ██║  ██║██║  ██║███████╗██║  ██║
╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

-->
	<mat-sidenav
		[hidden]="elementService.isLiveMode"
		opened
		mode="side"
		position="end"
		fixedInViewport="true"
		fixedTopGap="80"
		fixedBottomGap="0"
		class="sidenavRight"
	>
		<div class="sidenavRightContent">
			<div style="width: calc(100% - 5px)">
				<!-- Name -->
				<app-menu-display-name-input
					id="nameInput"
					[width]="'100%'"
				></app-menu-display-name-input>

				<!-- Global Style -->
				<mat-form-field appearance="fill" style="width: 100%;">

					<button
						matPrefix
						mat-icon-button
						(click)="elementService.removeProp('globalStyle');
						globalStyle = undefined"
					>
						<mat-icon>close</mat-icon>
					</button>

					<mat-label translate>menuInputLabels.globalStyle</mat-label>

					<input
						matInput
						type="text"
						[(ngModel)]="globalStyle"
						(focus)="elementService.tfFocussed(true)"
						(focusout)="elementService.tfFocussed(false)"
					>

				</mat-form-field>


				<b translate>rightMenu.dimensions</b>

				<div class="horizontalMenu">

					<!-- WIDTH -->
					<div style="width: 49%">

						<!-- Volle Höhe? -->
						<p style="margin-left: 10px">
							<mat-slide-toggle
								#fullWidthToggle
								(change)="
									setProp(
										'width',
										fullWidthToggle.checked ? '-webkit-fill-available' : '',
										'',
										true,
										false
									)
								"
								[checked]="getProp('width') == '-webkit-fill-available'"
							>
								<span translate>rightMenu.fullWidth</span>
							</mat-slide-toggle>
						</p>

						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'width'"
							[renderOnlyOuter]="true"
							[disabled]="getProp('width') == '-webkit-fill-available'"
						></app-menu-unit-input>

					</div>

					<!-- HEIGHT -->
					<div style="width: 49%">

						<!-- Volle Höhe? -->
						<p style="margin-left: 10px">
							<mat-slide-toggle
								#fullHeightToggle
								(change)="
									setProp(
										'height',
										fullHeightToggle.checked ? '-webkit-fill-available' : '',
										'',
										true,
										false
									)
								"
								[checked]="getProp('height') == '-webkit-fill-available'"
							>
								<span translate>rightMenu.fullHeight</span>
							</mat-slide-toggle>
						</p>

						<app-menu-unit-input
							[width]="'100%'"
							[name]="'height'"
							[renderOnlyOuter]="true"
							[disabled]="getProp('height') == '-webkit-fill-available'"
						></app-menu-unit-input>
					</div>

				</div>

				<div class="horizontalMenu">

					<!-- MIN-WIDTH -->
					<div style="width: 49%">

						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'min-width'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>

					</div>

					<!-- MIN-HEIGHT -->
					<div style="width: 49%">

						<app-menu-unit-input
							[width]="'100%'"
							[name]="'min-height'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
					</div>

				</div>

				<div class="horizontalMenu">

					<!-- MAX-WIDTH -->
					<div style="width: 49%">

						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'max-width'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>

					</div>

					<!-- MAX-HEIGHT -->
					<div style="width: 49%">

						<app-menu-unit-input
							[width]="'100%'"
							[name]="'max-height'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
					</div>

				</div>

				<div class="horizontalMenu">
					<b translate>rightMenu.spacing</b>
					<!-- Einzeln? -->
					<p style="margin-left: 10px">
						<mat-slide-toggle
							#spacingSplitToggle
							(change)="
								setProp(
									'spacingSplit',
									spacingSplitToggle.checked ? 'true' : 'false',
									'',
									false,
									true
								)
							"
							[checked]="getProp('spacingSplit') == 'true'"
						>
							<span translate>rightMenu.spacingSwitch</span>
						</mat-slide-toggle>
					</p>
				</div>

				<div class="horizontalMenu" *ngIf="!spacingSplitToggle.checked">
					<!-- margin -->
					<app-menu-unit-input
						style="width: 49%"
						[width]="'100%'"
						[name]="'margin'"
						[renderOnlyOuter]="true"
					></app-menu-unit-input>
					<!-- padding -->
					<app-menu-unit-input
						style="width: 49%"
						[width]="'100%'"
						[name]="'padding'"
					></app-menu-unit-input>
				</div>

				<div *ngIf="spacingSplitToggle.checked">
					<!-- margin -->
					<div>
						<p>Margin:</p>
						<div class="horizontalMenu">
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'margin-top'"
								[shortName]="'top'"
								[renderOnlyOuter]="true"
							></app-menu-unit-input>
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'margin-right'"
								[shortName]="'right'"
								[renderOnlyOuter]="true"
							></app-menu-unit-input>
						</div>
						<div class="horizontalMenu">
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'margin-bottom'"
								[shortName]="'bottom'"
								[renderOnlyOuter]="true"
							></app-menu-unit-input>
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'margin-left'"
								[shortName]="'left'"
								[renderOnlyOuter]="true"
							></app-menu-unit-input>
						</div>
					</div>
					<!-- padding -->
					<div>
						<p>Padding:</p>
						<div class="horizontalMenu">
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'padding-top'"
								[shortName]="'top'"
							></app-menu-unit-input>
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'padding-right'"
								[shortName]="'right'"
							></app-menu-unit-input>
						</div>
						<div class="horizontalMenu">
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'padding-bottom'"
								[shortName]="'bottom'"
							></app-menu-unit-input>
							<app-menu-unit-input
								style="width: 49%"
								[width]="'100%'"
								[name]="'padding-left'"
								[shortName]="'left'"
							></app-menu-unit-input>
						</div>
					</div>
				</div>

				<!-- POSITION -->
				<b translate>rightMenu.positioning</b>

				<div class="horizontalMenu">
					<mat-form-field appearance="fill" style="width: 49%">
						<mat-label>Position</mat-label>
						<mat-select
							#posSelect
							(selectionChange)="setProp('position', posSelect.value, '', true)"
							[value]="getProp('position')"
						>
							<mat-option value="static">static</mat-option>
							<mat-option value="relative">relative</mat-option>
							<mat-option value="absolute">absolute</mat-option>
							<mat-option value="fixed">fixed</mat-option>
							<mat-option value="sticky">sticky</mat-option>
						</mat-select>
					</mat-form-field>

					<!-- z-index -->
					<app-menu-input
						*ngIf="posSelect.value && posSelect.value != 'static'"
						style="width: 49%"
						[width]="'100%'"
						[name]="'z-index'"
						[type]="'number'"
						[isHelper]="false"
					></app-menu-input>
				</div>

				<div
					style="position: relative"
					*ngIf="posSelect.value && posSelect.value != 'static'"
				>
					<!-- top -->
					<div class="horizontalMenu">
						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'top'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
						<!-- right -->
						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'right'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
					</div>
					<!-- center -->
					<div
						class="rounded-lg"
						[style]="
							(elementService.hasProp('transform')
								? 'background-color: rgb(59 130 246); '
								: 'background-color: #fff; ') +
							'cursor: pointer; border: 1px #8b8b8b solid; position: absolute; left: 50%; transform: translate(-50%, -50%); top: 44%; width: 20px; height: 20px; display: flex; align-items: center;'
						"
						(click)="switchCenter()"
					>
						<mat-icon svgIcon="center" style="fill: #8b8b8b"></mat-icon>
					</div>
					<!-- bottom -->
					<div class="horizontalMenu">
						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'bottom'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
						<!-- left -->
						<app-menu-unit-input
							style="width: 49%"
							[width]="'100%'"
							[name]="'left'"
							[renderOnlyOuter]="true"
						></app-menu-unit-input>
					</div>
				</div>

				<div style="display: flex; gap: 10px;">

					<!-- Edit Element Code -->
					<a
						mat-raised-button
						color="primary"
						(click)="elementCodeDialog = true"
						style="margin-bottom: 10px"
					>
						<span class="mat-button-span">Code Generierung</span>
					</a>

					<!-- save as style -->
					<a
					mat-raised-button
					color="primary"
					style="margin-bottom: 10px"
					(click)="elementService.saveStyleFromCurrElementToRootAndApplyToAll()"
					>
						<span class="mat-button-span">Als Style speichern</span>
					</a>

				</div>

				<!--
        ██╗      █████╗ ██╗   ██╗ ██████╗ ██╗   ██╗████████╗███████╗
        ██║     ██╔══██╗╚██╗ ██╔╝██╔═══██╗██║   ██║╚══██╔══╝██╔════╝
        ██║     ███████║ ╚████╔╝ ██║   ██║██║   ██║   ██║   ███████╗
        ██║     ██╔══██║  ╚██╔╝  ██║   ██║██║   ██║   ██║   ╚════██║
        ███████╗██║  ██║   ██║   ╚██████╔╝╚██████╔╝   ██║   ███████║
        ╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝
        -->
					<!-- flex-grow -->
					<!-- logic: The (change) event is fired after the toggle is already changed
                Example: It is currently checked and the user clicks on it, then is is not checked anymore
                        Then the (change) event fires and sees that itself is currently NOT CHECKED
                        and calls the setProp() accordingly.
          -->
					<mat-slide-toggle
					#growToggle
					(change)="setProp('flex-grow', growToggle.checked ? '1' : '0','',true);
										setProp('display', 'flex','',true)"
					[checked]="getProp('flex-grow') == '1'"
				>
					Grow?
				</mat-slide-toggle>
				<p *ngIf="this.elementService?.allLayouts()" style="margin-left: 10px">

					<!-- overflow -->
					<mat-slide-toggle
						style="margin-left: 10px"
						#overflowToggle
						(change)="
							setProp('overflow', overflowToggle.checked ? 'hidden' : 'visible')
						"
						[checked]="getProp('overflow') == 'hidden'"
					>
						Overflow hidden?
					</mat-slide-toggle>
				</p>

				<!-- Anordnung -->
				<div *ngIf="this.elementService?.allLayouts()">
					<b translate>rightMenu.arrangement</b>

					<div
						style="
							display: flex;
							flex-direction: column;
							justify-content: space-between;
						"
					>
						<!-- HorizontalMenu -->
						<p style="margin-left: 10px">
							<mat-slide-toggle
								#columnToggle
								(change)="
									setProp(
										'flex-direction',
										getProp('flex-direction') == 'column' ? 'row' : 'column'
									)
								"
								[checked]="getProp('flex-direction') == 'column'"
							>
								<span translate>rightMenu.flexDirection</span>
							</mat-slide-toggle>
						</p>

						<!-- flex-wrap -->
						<p style="margin-left: 10px">
							<mat-slide-toggle
								#wrapToggle
								(change)="
									setProp('flex-wrap', wrapToggle.checked ? 'wrap' : 'nowrap')
								"
								[checked]="getProp('flex-wrap') == 'wrap'"
							>
								Wrap?
							</mat-slide-toggle>
						</p>
					</div>

					<div style="display: flex; justify-content: space-between"><!-- HorizontalMenu -->

						<!-- align-items -->
						<mat-form-field
							appearance="fill"
							[ngStyle]="{
								order: getProp('flex-direction') == 'column' ? '2' : '1'
							}"
							style="width: 49%"
						>
							<mat-label>{{
								getProp("flex-direction") == "column"
									? dataService.justifyContentTranslate
									: dataService.alignItemsTranslate
							}}</mat-label>
							<mat-select
								#vSelect
								(selectionChange)="
									setProp('align-items', vSelect.value);
									getProp('flex-wrap') == 'wrap'
										? setProp('align-content', vSelect.value)
										: ''
								"
								[value]="
									getProp('flex-wrap') == 'wrap'
										? getProp('align-content') == 'normal'
											? 'stretch'
											: getProp('align-content')
										: getProp('align-items') == 'normal'
										? 'stretch'
										: getProp('align-items')
								"
							>
								<mat-option value="flex-start">flex-start</mat-option>
								<mat-option value="center">Center</mat-option>
								<mat-option value="flex-end">flex-end</mat-option>
								<mat-option value="stretch">Stretch</mat-option>
								<mat-option
									*ngIf="getProp('flex-wrap') == 'wrap'"
									value="space-between"
									>Space between</mat-option
								>
								<mat-option
									*ngIf="getProp('flex-wrap') == 'wrap'"
									value="space-around"
									>Space around</mat-option
								>
								<mat-option
									*ngIf="getProp('flex-wrap') == 'wrap'"
									value="space-evenly"
									>Space evenly</mat-option
								>
							</mat-select>
						</mat-form-field>

						<!-- justify-content -->
						<mat-form-field
							appearance="fill"
							[ngStyle]="{
								order: getProp('flex-direction') == 'column' ? '1' : '2'
							}"
							style="width: 49%"
						>
							<mat-label>{{
								getProp("flex-direction") == "column"
									? dataService.alignItemsTranslate
									: dataService.justifyContentTranslate
							}}</mat-label>
							<mat-select
								#hSelect
								(selectionChange)="setProp('justify-content', hSelect.value)"
								[value]="
									getProp('justify-content') == 'normal'
										? 'flex-start'
										: getProp('justify-content')
								"
							>
								<mat-option value="flex-start">flex-start</mat-option>
								<mat-option value="center">Center</mat-option>
								<mat-option value="flex-end">flex-end</mat-option>
								<mat-option value="space-between">Space between</mat-option>
								<mat-option value="space-around">Space around</mat-option>
								<mat-option value="space-evenly">Space evenly</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<!-- Gap -->
					<mat-gap-menu style="width: 49%"></mat-gap-menu>

					<!-- Background Color -->
					<app-menu-background></app-menu-background>

					<!--  BORDER  -->
					<app-menu-border></app-menu-border>
					<app-menu-box-shadow></app-menu-box-shadow>

					<!-- Cursor -->
					<mat-cursor-menu style="width: 49%"></mat-cursor-menu>

					<!-- Pages -->
					<pageRouting></pageRouting>
				</div>
				<!-- Ende Anordnung -->

				<mat-divider
					style="border-color: rgba(0, 0, 0, 0.12); margin-bottom: 10px"
				></mat-divider>

				<!-- ELEMENT MENUS -->
				<text-menu></text-menu>
				<matButton-menu></matButton-menu>
				<matInput-menu></matInput-menu>
				<matTextareaMenu></matTextareaMenu>
				<matSelect-menu></matSelect-menu>
				<matRadio-menu></matRadio-menu>
				<matSlider-menu></matSlider-menu>
				<matSlideToggle-menu></matSlideToggle-menu>
				<matCheckbox-menu></matCheckbox-menu>
				<matTable-menu></matTable-menu>
				<matChips-menu></matChips-menu>
				<cssButton-menu></cssButton-menu>
				<chartJs-menu></chartJs-menu>
				<css-input-menu></css-input-menu>
				<xSpreadsheetMenu></xSpreadsheetMenu>
				<leafletJsMenu></leafletJsMenu>
				<richTextMenu></richTextMenu>
				<langchainMenu></langchainMenu>
				<router-outlet-placeholder-menu></router-outlet-placeholder-menu>
				<project-menu></project-menu>
				<sf-kanban-menu></sf-kanban-menu>
				<sf-button-menu></sf-button-menu>
				<app-matDatepicker-menu></app-matDatepicker-menu>
				<!-- ⚠️ ATTENTION!: use the "-menu" suffix here! -->

				<mat-divider
					style="border-color: rgba(0, 0, 0, 0.12); margin-bottom: 10px"
				></mat-divider>

				<div style="border: 1px dashed #000000">
					<div
						*ngFor="
							let property of elementService.selectedObjects[0]?.properties
						"
					>
						<p>
							{{ property.isTailwind ? "(Tailwind)" : ""
							}}{{ property.isHelper ? "(Helper)" : "" }}{{ property.key }}:
							{{ property.value
							}}{{ property.second ? "-" + property.second : "" }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</mat-sidenav>
	<!-- ############### END SIDENAV RECHTS ############### -->
</mat-sidenav-container>

<!--
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗ ███████╗
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝ ██╔════╝
██║  ██║██║███████║██║     ██║   ██║██║  ███╗███████╗
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║╚════██║
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝███████║
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝ ╚══════╝

 -->
<automationsDialog
	*ngIf="automationsService.showAutomationsDialog"
	(closed)="automationsService.showAutomationsDialog = false"
></automationsDialog>
<generalApiDialog
	*ngIf="showGeneralApiCallDialog"
	(closed)="showGeneralApiCallDialog = false"
></generalApiDialog>
<fileName-dialog
	*ngIf="showFileDialog"
	(download)="dataService.downloadFile($event)"
	(closed)="showFileDialog = false"
></fileName-dialog>
<loading-dialog
	*ngIf="showLoadingDialog"
	(closed)="showLoadingDialog = false"
></loading-dialog>
<elementCodeDialog
	*ngIf="elementCodeDialog"
	(closed)="elementCodeDialog = false"
></elementCodeDialog>
<app-projects-dialog
  *ngIf="dataService.projectsService.isProjectsDialogOpen"
	(closed)="onProjectsDialogClosed()"
>
</app-projects-dialog>
<app-import-options-dialog
  *ngIf="showImportOptionsDialog"
	(closed)="showImportOptionsDialog = false"
></app-import-options-dialog>

<!-- ############### END Dialogs ############### -->
