import { AfterViewChecked, AfterViewInit, Component, Renderer2 } from '@angular/core'

import { ElementService } from 'src/app/element.service'
import { DataService } from 'src/app/data.service'
import { TreeSearchService } from 'src/app/tree-search.service'

@Component({
	selector: 'app-leftMenu',
	templateUrl: './app.component.leftMenu.html',
})
export class AppComponentLeftMenu implements AfterViewInit, AfterViewChecked {
	constructor(
		public elementService: ElementService,
		public dataService: DataService,
		public treeSearchService: TreeSearchService,
		private renderer: Renderer2
	) {
	}

	ngAfterViewChecked(): void {
		let hasToBeUpdated = this.nativeElement?.getElementsByClassName(
			'mat-tab-header-pagination'
		)

		if (hasToBeUpdated) {
			this.updateWidthAndPagination()
		}
	}

	ngAfterViewInit(): void {
		this.nativeElement = document.getElementsByTagName(
			'app-leftMenu'
		)[0] as HTMLElement

		this.updateWidthAndPagination()
	}

	nativeElement: HTMLElement

	updateWidthAndPagination() {
		// mat-tab-label
		let matTabLabels =
			this.nativeElement?.getElementsByClassName('mat-tab-label')

		if (!matTabLabels) return
		for (let i = 0; i < matTabLabels.length; i++) {
			this.renderer.setStyle(matTabLabels.item(i), 'width', '70px')
			this.renderer.setStyle(matTabLabels.item(i), 'min-width', '5px')
		}

		// mat-tab-body-wrapper
		let matTabBodyWrappers = this.nativeElement.getElementsByClassName(
			'mat-tab-body-wrapper'
		)

		if (!matTabBodyWrappers) return
		for (let i = 0; i < matTabBodyWrappers.length; i++) {
			this.renderer.setStyle(matTabBodyWrappers.item(i), 'padding-top', '10px')
		}

		//mat-tab-header-pagination
		let matTabHeaderPaginations = this.nativeElement.getElementsByClassName(
			'mat-tab-header-pagination'
		)

		let length = matTabHeaderPaginations.length

		if (!matTabHeaderPaginations) return
		for (let i = length - 1; i >= 0; i--) {
			matTabHeaderPaginations.item(i)?.remove()
		}
	}
}
