import { Component, Input } from '@angular/core';

import { AutomationsService, Trigger } from '../../../../automations';

@Component({
  selector: 'trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss']
})
export class TriggerComponent {
  @Input()
  public trigger: Trigger;

  @Input()
  public name: string = '';

  public constructor(public readonly automationsService: AutomationsService) {
  }
}
