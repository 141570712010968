<mat-form-field>
  <mat-label translate>rightMenu.orientation</mat-label>
  <mat-select #selectAriaOrientation
    (selectionChange)="setProp('ariaOrientation', selectAriaOrientation.value)"
    [value]="getProp('ariaOrientation')"
  >
    <mat-option value="horizontal"><span translate>selectAriaOrientation.horizontal</span></mat-option>
    <mat-option value="vertical"><span translate>selectAriaOrientation.vertical</span></mat-option>
  </mat-select>
</mat-form-field>
