import { Component, Input } from '@angular/core';
import { menu } from '../menu/menu';

@Component({
  selector: 'mat-LabelPosition-menu',
  templateUrl: 'menu.html'
})
export class MatLabelPositionMenu extends menu {

  @Input() width = "100%"
  @Input() name = ""
  @Input() shortName = ""
  @Input() firstValue = "1"
  @Input() secondValue = "0"

  renderOnlyOuter = false
  @Input() isTailwind = false
  @Input() isHelper = true

  positions = [
    "davor",
    "dahinter",
  ]

}
