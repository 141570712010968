import { Component, Input } from '@angular/core';

import { menu } from '../../menus/menu/menu';
import { ElementService } from '../../element.service';

@Component({
  selector: 'css-input-menu',
  templateUrl: 'css-input.menu.html',
  styleUrls: ['./css-input.menu.scss']
})
export class CssInputComponentMenu extends menu {
  @Input() renderOnlyOuter = false;
  @Input() isTailwind = false;
  @Input() isHelper = true;

  constructor(
    public elementService: ElementService
  ) {
    super(elementService)
  }
}
